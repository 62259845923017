import React, { useState } from "react";
import { Card } from "semantic-ui-react";
import { CompanyProfileModel } from "../types";
import CompanyListItem from "./CompanyListItem";

interface Props {
  companies: CompanyProfileModel[];
  handleApprove?: (companyId: number) => Promise<any>;
  handleReject?: (companyId: number, reason: string) => void;
}

const CompanyList: React.FC<Props> = ({ companies, handleApprove, handleReject }) => {
  const [activeIndices, setActiveIndex] = useState<number[]>([]);

  const handleAccordionClick = (_: any, titleProps: any) => {
    const { index } = titleProps;
    activeIndices.includes(index)
      ? setActiveIndex(activeIndices.filter((x) => x !== index))
      : setActiveIndex([...activeIndices, index]);
  };

  return (
    <Card.Group className="company-list">
      {companies.map((company, index) => (
        <CompanyListItem
          key={company.id}
          company={company}
          index={index}
          active={activeIndices.includes(index)}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleAccordionClick={handleAccordionClick}
        />
      ))}
    </Card.Group>
  );
};

export default CompanyList;
