import React, { PropsWithChildren, useContext } from "react";
// eslint-disable-next-line no-restricted-imports
import { Controller, useFormContext } from "react-hook-form";
import { PropsInjectedByController, PropsForController } from "./types";
import { AppFormContext } from "./Form";

export function withController<T extends PropsInjectedByController>(WrappedComponent: React.ComponentType<T>) {
  const ComponentWithController = ({
    name,
    rules,
    children,
    defaultValue,
    ...props
  }: PropsWithChildren<PropsForController & Omit<T, keyof PropsInjectedByController>>) => {
    const { control } = useFormContext();
    const { isDisabled } = useContext(AppFormContext);
    return (
      <Controller
        {...{ control, name, rules, defaultValue }}
        render={({ field, fieldState }) => (
          <WrappedComponent
            {...(props as unknown as T)}
            {...{
              value: field.value,
              onChange: field.onChange,
              onBlur: field.onBlur,
              isDisabled,
              isValid: !fieldState.invalid,
              name: field.name,
            }}
          >
            {children}
          </WrappedComponent>
        )}
      />
    );
  };

  ComponentWithController.displayName = `withController(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return ComponentWithController;
}
