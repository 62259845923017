import {
  UserState,
  UserActionTypes,
  GET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_FAIL,
  UPDATE_USER,
} from "../../app/store/types/user";
import { localStorageKeys } from "../../app/helpers/localStorageHelpers";

const initialState: UserState = {
  id: "",
  email: "",
  token: "",
  isLoggedIn: Boolean(JSON.parse(localStorage.getItem(localStorageKeys.IS_LOGGED_IN_KEY) || "false")),
  status: "",
  roles: (localStorage.getItem(localStorageKeys.ROLES_KEY) || "").split(","),
  subjectId: "",
};

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case GET_CURRENT_USER:
      return action.user;
    case LOGIN_USER:
      return action.user;
    case LOGOUT_USER:
      return initialState;
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case LOGIN_FAIL:
      return {
        ...initialState,
        status: action.status,
        isLoggedIn: false,
        roles: [],
      };
    default:
      return state;
  }
};
