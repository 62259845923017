import React from "react";
import { BooleanQuestion, ReflectionReportModelBooleanValue } from "./models";
import { useTranslation } from "react-i18next";
import ReportOptionValue from "./ReportOptionValue";

interface Props {
  question: BooleanQuestion;
  value: ReflectionReportModelBooleanValue;
}

const defaultBooleanOptions: BooleanQuestion["options"] = {
  true: "common.yes",
  false: "common.no",
};

const ReflectionReportBoolean = ({ value, question }: Props) => {
  const { t } = useTranslation();
  const options = question.options ?? defaultBooleanOptions;

  const getBadge = (answer: number) => {
    const optionValue = value.answers.find((x) => x.answer == answer);

    return (
      <ReportOptionValue answersCount={optionValue?.answersCount ?? 0} percentage={optionValue?.percentage ?? 0} />
    );
  };

  return (
    <ul className="reflection-view__list">
      <li>
        {t(options.true)} {getBadge(1)}
      </li>
      <li>
        {t(options.false)} {getBadge(0)}
      </li>
    </ul>
  );
};

export default ReflectionReportBoolean;
