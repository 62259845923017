import React from "react";
import VideoSlide from "./VideoSlide";
import Slider from "../../../components/slider/Slider";
import "./styles.less";

const videos: Array<{ youtubeId: string; title: string; author: string }> = [
  {
    youtubeId: "K7SagTOiizQ",
    title: "Kaip daugybėje skaičių įžvelgti artėjančią ekonominę krizę?",
    author: "Viktorija Grybauskaitė, ekonomistė",
  },
  {
    youtubeId: "OzHC340BphM",
    title: "Darbas su startuoliais – intensyvus ir kompleksiškas.",
    author: "Greta Monstavičė, „Katalista Ventures“ vadovė",
  },
  {
    youtubeId: "PB0_fVX8-Cw",
    title: "Ką veikia finansinių duomenų specialistai?",
    author: "Barbora Dulkytė, „Moody‘s“ jaunesnioji specialistė",
  },
  {
    youtubeId: "r2iLlp0kvZo",
    title: "Kaip sujungti dvi naujausias tendencijas - crypto ir žaidimus?",
    author: "Thomas Tumosa, crypto žaidimų kūrėjas",
  },
  {
    youtubeId: "wD5XXBwXHZg",
    title: "Žurnalistika yra prasminga profesija.",
    author: "Rasa Lukaitytė-Vnarauskienė, „DELFI“ vyriausioji redaktorė",
  },
  {
    youtubeId: "CAYSgRz80eo",
    title: "Dėl ko kasdien suka galvą startuolio įkūrėjas?",
    author: "Tomas Moška",
  },
  {
    youtubeId: "O5dzTkfYxUc",
    title: "Dirbant banke mane įkvepia galimybė dalintis žiniomis.",
    author: "Sigita Strockytė-Varnė, SEB banko ekspertė",
  },
  {
    youtubeId: "6pMY6ijwYqQ",
    title: "Vadovo užduotis – spręsti vis kitas problemas.",
    author: "Justas Graužinis, „MobilePay“ produkto vadovas",
  },
  {
    youtubeId: "RyrE0S9bOTI",
    title: "Testuotojams taip pat praverčia programavimo žinios.",
    author: "Alina Kalenkovič, „Accenture“ testuotoja",
  },
  {
    youtubeId: "xC_EagW-HAw",
    title: "Savęs klauskite, ką norite išmokti, o ne kuo norite būti.",
    author: "Urtė Neniškytė, neuromokslininkė",
  },
  {
    youtubeId: "KDvThDhyKs0",
    title: "Kodėl verta rinktis psichologo profesiją?",
    author: "Žilvinas Žymančius, psichologas",
  },
  {
    youtubeId: "UMZozW4XQGQ",
    title: "Nebijokite eksperimentuoti.",
    author: "Laura Blaževičiūtė, „TV3“ generalinė direktorė",
  },
  {
    youtubeId: "8Bt7tUC1-vs",
    title: "Programavimo kalbų išmokau internete: mokėkite mokytis.",
    author: "Beatričė Farias, „Android“ programuotoja",
  },
  {
    youtubeId: "60hYJaotnEY",
    title: "Lietuva turi puikias sąlygas paruošti kvalifikuotas IT specialistes.",
    author: "Eglė Radvilė, IT specialistė",
  },
  {
    youtubeId: "rakgIP2ODNQ",
    title: "Mokykloje būkite aktyvūs ir nesėdėkite vienoje vietoje.",
    author: "Gintarė Alaburdaitė-Žemaitė, projektų vadovė",
  },
  {
    youtubeId: "KvZfFpmPWsw",
    title: "Kokia profesija puikiai tinka „blogiukams“?",
    author: "Justina Tamulevičiūtė, kibernetinio saugumo ekspertė",
  },
  {
    youtubeId: "8jjwxcHE8A0",
    title: "Ką rinktis mėgstantiems ir kurti, ir skaičiuoti?",
    author: "Justina Ramanauskaitė, produkto dizainerė",
  },
  {
    youtubeId: "MjqI2se07BE",
    title: "Patarimas moksleiviams: švęskite nesėkmes.",
    author: "Lukas Jablonskas, produkto vadovas",
  },
  {
    youtubeId: "cM_1rOLJ2jU",
    title: "Būsimiems studentams: mokykitės kalbų ir nepamirškite matematikos.",
    author: "Reda Stalioraitytė, teisininkė",
  },
  {
    youtubeId: "aHsic5W8QMM",
    title: "Tapk nekilnojamojo turto brokeriu.",
    author: "Rasa Pavarienė, nekilnojamojo turto ekspertė",
  },
];

const HomePageVideoSlider = () => {
  return (
    <section className="homepage-video-slider">
      <h2 className="article__title">Pažinkime profesijas</h2>

      <Slider>
        {videos.map((video) => (
          <VideoSlide {...video} key={video.youtubeId}></VideoSlide>
        ))}
      </Slider>
    </section>
  );
};

export default HomePageVideoSlider;
