import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../auth/routes";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer__title">
        &copy; {t("footer.title")}
        {", "}
        <Link to={NonAuthRoutes.privacyPolicy} target="_blank" rel="noopener noreferrer">
          {t("common.privacy_policy")}
        </Link>
      </div>

      <div className="footer__contacts">
        <a href={"tel:" + t("footer.phone")}>{t("footer.phone")}</a> |{" "}
        <a href={"mailto:" + t("footer.email")}>{t("footer.email")}</a>
      </div>

      <div className="footer__creators">
        {t("common.created_by")}:
        <a href="https://ugipro.lt" target="blank">
          <img src="/assets/ugipro.svg" alt="ugipro" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
