import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import { RootState } from "../../app/store/configureStore";
import HomePage from "../home-page/HomePage";
import LoginPage from "../user/components/login/LoginPage";
import ReflectionModal from "./ReflectionModal";

const FillReflections = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { isLoggedIn } = useSelector((state: RootState) => state.currentUser);
  const { apiError } = useToaster();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      agent.ApplicationReflections.canFillReflection(parseInt(applicationId!))
        .then((response) => setShowModal(response))
        .catch((error) => apiError(error));
    }
  }, [applicationId, apiError, isLoggedIn]);

  if (!isLoggedIn) {
    return <LoginPage returnUrl={`/fill-reflections/${applicationId}`} />;
  }

  const handleReflectionSubmit = () => {
    navigate("/");
  };

  return (
    <>
      <HomePage />
      {showModal && (
        <ReflectionModal applicationId={+applicationId!} handleReflectionSubmit={handleReflectionSubmit} isOpen />
      )}
    </>
  );
};

export default FillReflections;
