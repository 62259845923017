import React from "react";
import "./styles.less";
import PageTitle from "../../components/page-title/PageTitle";
import { useTranslation } from "react-i18next";
import PartnersItem from "./PartnersItem";
import { partners } from "./content";

const PartnersPage = () => {
  const { t } = useTranslation();

  return (
    <article className="partners-page">
      <section className="partners-slogan">
        <div className="partners-slogan__image">
          <img src="/assets/partner/main.png" alt="Partners" />
        </div>
        <div className="partners-slogan__content">
          <PageTitle
            title={t("navbar.partners")}
            description={`Nei viena Lietuvos Junior Achievement programa moksleiviams nebūtų įmanoma be geranoriškų partnerių ir poveikio investuotojų, kurie tiki Lietuvos jaunimo ateitimi. Jų dėka galime ugdyti stiprias ir atsakingas asmenybes, kurios netrukus taps neatsiejama Lietuvos verslo, pilietinės ir visuomeninės veiklos dalimi.`}
          />
        </div>
      </section>

      <section className="partners-list">
        <h2 className="partners-list__title">Lietuvos Junior Achievement misiją palaiko šios organizacijos:</h2>

        {partners.map((partner) => (
          <PartnersItem key={partner.title} partner={partner} />
        ))}
      </section>
    </article>
  );
};

export default PartnersPage;
