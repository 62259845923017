import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { PasswordStepProps } from "../StudentRegistrationSteps";
import { PASSWORD_STRENGTH_REGEXP } from "../../../../../../app/helpers/regexp";
import RegistrationActions from "../../RegistrationActions";
import AppForm from "../../../../../../components/form/Form";
import { useAppForm } from "../../../../../../components/form/useForm";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../../../../app/auth/routes";

interface IProps {
  store: PasswordStepProps;
  onSubmit: (data: PasswordStepProps) => void;
  handlePreviousStepClick?: () => void;
  isSaving: boolean;
}

type FormValues = {
  password: string;
  repeatedPassword: string;
  privacyPolicy: boolean;
};

const stepInfo = {
  current: 3,
  total: 3,
};

const PasswordStep: React.FC<IProps> = ({ onSubmit, isSaving, handlePreviousStepClick }) => {
  const { t } = useTranslation();

  const formHook = useAppForm<FormValues>({
    defaultValues: {
      password: "",
      repeatedPassword: "",
      privacyPolicy: false,
    },
  });

  const handleNextStep = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <>
      <AppForm formHook={formHook} onSubmit={handleNextStep} isDisabled={isSaving}>
        <AppForm.Group name="password" label="auth.steps.password.create_password">
          <AppForm.Password
            name="password"
            rules={{
              required: true,
              pattern: {
                value: PASSWORD_STRENGTH_REGEXP,
                message: "auth.steps.password.validation.password_strenght",
              },
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="repeatedPassword" label="auth.steps.password.confirm_password">
          <AppForm.Password
            name="repeatedPassword"
            rules={{
              required: true,
              validate: (v) =>
                v !== formHook.getValues("password") ? "auth.steps.password.validation.password_match" : true,
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="privacyPolicy">
          <AppForm.Checkbox name="privacyPolicy" label="Sutinku su" rules={{ required: true }} />{" "}
          <Link to={NonAuthRoutes.privacyPolicy} target="_blank" rel="noopener noreferrer">
            {t("common.privacy_policy")}
          </Link>
        </AppForm.Group>
      </AppForm>

      <RegistrationActions stepInfo={stepInfo}>
        <Button
          primary
          content={t("auth.steps.complete")}
          disabled={isSaving}
          loading={isSaving}
          onClick={formHook.handleSubmit(handleNextStep)}
        />
        {handlePreviousStepClick && (
          <Button basic content={t("auth.steps.back")} disabled={isSaving} onClick={handlePreviousStepClick} />
        )}
      </RegistrationActions>
    </>
  );
};

export default PasswordStep;
