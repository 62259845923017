import React from "react";
import { Popup } from "semantic-ui-react";
import ShIcon from "../../components/ShIcon";
import { useTranslation } from "react-i18next";

const ExcursionExplanationIcon = () => {
  const { t } = useTranslation();

  return (
    <Popup
      content={t("common.excursion_description")}
      style={{ whiteSpace: "pre-line" }}
      wide="very"
      basic
      trigger={
        <div className="popup-icon">
          <ShIcon name={"question"} />
        </div>
      }
    />
  );
};

export default ExcursionExplanationIcon;
