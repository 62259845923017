import React from "react";
import { Button } from "semantic-ui-react";
import RegistrationActions from "../user/components/registration/RegistrationActions";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: (...args: any[]) => any;
  onBack: (...args: any[]) => any;
  isDisabled: boolean;
  isSaving: boolean;
  page: number;
  total: number;
}

const ReflectionActions = ({ onSubmit, onBack, isDisabled, isSaving, page, total }: Props) => {
  const { t } = useTranslation();
  const isLastPage = page + 1 === total;

  return (
    <RegistrationActions stepInfo={{ current: page + 1, total }}>
      <Button primary disabled={isDisabled} loading={isSaving} onClick={onSubmit}>
        {t(isLastPage ? "reflection.submit" : "reflection.next_page")}
      </Button>
      {page > 0 && (
        <Button basic disabled={isDisabled} onClick={onBack}>
          {t("reflection.previous_page")}
        </Button>
      )}
    </RegistrationActions>
  );
};

export default ReflectionActions;
