import React, { useState } from "react";
import { Excursion } from "./types";
import { Button } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { useTranslation } from "react-i18next";
import useToaster from "../../app/hooks/useToaster";

interface Props {
  excursion: Excursion;
  onUpdate?: () => void;
}

const ExcursionOccupationToggle = ({ excursion, onUpdate }: Props) => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [isUpdating, setUpdating] = useState(false);

  const handleClick = async () => {
    try {
      setUpdating(true);
      await agent.Excursions.updateOccupation(excursion.id, !excursion.occupied);
      success("excursion.form.successfully_updated");
      onUpdate?.();
    } catch (error) {
      apiError(error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      <Button primary loading={isUpdating} onClick={() => handleClick()} size="small">
        {excursion.occupied ? t("excursion.unoccupy") : t("excursion.occupy")}
      </Button>
    </div>
  );
};

export default ExcursionOccupationToggle;
