import { createDateTime } from "../../app/helpers/dateHelpers";
import {
  ShadowingCreateFormModel,
  ShadowingCreateRequestPayload,
  ShadowingEditFormModel,
  ShadowingUpdateRequestPayload,
} from "./types";

export function getCreateRequestPayload(model: ShadowingCreateFormModel): ShadowingCreateRequestPayload {
  const { useHqAddress, address, city, responsiblePerson, date, time, ...rest } = model;
  const dateTime = createDateTime(date, time);
  const addressModel = useHqAddress ? { useHqAddress } : { useHqAddress, address, city };
  const names = responsiblePerson.fullName.trim().split(" ");
  const surname = names.splice(-1).join(" ");
  const firstName = names.join(" ");
  const person = {
    ...responsiblePerson,
    firstName,
    surname,
  };

  return { ...rest, ...addressModel, responsiblePerson: person, date: dateTime };
}

export function getUpdateRequestPayload(model: ShadowingEditFormModel): ShadowingUpdateRequestPayload {
  const { useHqAddress, address, city, online, date, time, industryIds } = model;
  const addressModel = useHqAddress ? { useHqAddress } : { useHqAddress, address, city };
  const dateTime = createDateTime(date, time);

  return {
    position: model.position,
    description: model.description,
    date: dateTime,
    participantsCapacity: model.participantsCapacity,
    visitDuration: model.visitDuration,
    online,
    industryIds,
    ...addressModel,
  };
}
