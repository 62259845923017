import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import FormModal from "../../../components/FormModal";
import { CompanyProfileModel } from "../../companies/types";
import { Student } from "../../students/types";
import CompanyProfileForm, { CompanyUpdateForm } from "./company/CompanyProfileForm";
import SchoolProfileForm, { SchoolUpdateForm } from "./school/SchoolProfileForm";
import StudentProfileForm, { StudentUpdateForm } from "./student/StudentProfileForm";
import MunicipalityProfileForm, { MunicipalityUpdateForm } from "./municipality/MunicipalityProfileForm";
import { SchoolProfileModel } from "../../schools/types";
import { MunicipalityProfileModel } from "../../municipalities/types";

const SubjectTypes = {
  Student: "Student",
  Company: "Company",
  School: "School",
  Municipality: "Municipality",
} as const;

type SubjectTypes = (typeof SubjectTypes)[keyof typeof SubjectTypes];

interface Props {
  isSaving: boolean;
  triggerModal: boolean;
  subject: Student | CompanyProfileModel | SchoolProfileModel | MunicipalityProfileModel;
  type: SubjectTypes;
  handleUpdate: (data: any) => void;
  isForceUpdateModal?: boolean;
}

type FormType = StudentUpdateForm | CompanyUpdateForm | SchoolUpdateForm | MunicipalityUpdateForm;

const ProfileFormModal: React.FC<Props> = ({
  isSaving,
  triggerModal,
  subject,
  type,
  handleUpdate,
  isForceUpdateModal,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [triggerModal]);

  useEffect(() => {
    if (isForceUpdateModal) {
      setOpen(true);
    }
  }, [isForceUpdateModal]);

  const renderEditForm = () => {
    if (type === SubjectTypes.Student)
      return <StudentProfileForm isSaving={isSaving} student={subject as Student} handleSubmit={onFormUpdate} />;

    if (type === SubjectTypes.Company)
      return (
        <CompanyProfileForm
          isSaving={isSaving}
          company={subject as CompanyProfileModel}
          handleSubmit={onFormUpdate}
          handleCancel={handleCancel}
        />
      );

    if (type === SubjectTypes.School)
      return (
        <SchoolProfileForm
          isSaving={isSaving}
          school={subject as SchoolProfileModel}
          handleSubmit={onFormUpdate}
          handleCancel={handleCancel}
        />
      );

    if (type === SubjectTypes.Municipality)
      return (
        <MunicipalityProfileForm
          isSaving={isSaving}
          municipality={subject as MunicipalityProfileModel}
          handleSubmit={onFormUpdate}
          handleCancel={handleCancel}
        />
      );

    return null;
  };

  const handleCancel = () => setOpen(false);

  const onFormUpdate = (data: FormType) => {
    handleUpdate(data);
  };

  return (
    <FormModal
      title="my_profile.profile_edit"
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={isForceUpdateModal ? null : <Button primary>{t("common.edit")}</Button>}
    >
      <p> {t(isForceUpdateModal ? "my_profile.update_help" : "my_profile.edit_help")}</p>
      <Modal.Description>{renderEditForm()}</Modal.Description>
    </FormModal>
  );
};

export default ProfileFormModal;
