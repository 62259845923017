import React, { useMemo } from "react";
import { ReflectionReportModel } from "./models";
import "./reflection-view-styles.less";
import ReflectionReportItem from "./ReflectionReportItem";
import { getQuestions } from "./utils";

interface Props {
  values: ReflectionReportModel;
}

const ReflectionReportList = ({ values }: Props) => {
  const questions = useMemo(() => getQuestions(values ?? {}), [values]);

  return (
    <div className="reflection-view__group">
      {questions
        .filter((question) => !!values[question.id])
        .map((question) => (
          <ReflectionReportItem question={question} value={values[question.id]} key={question.id} />
        ))}
    </div>
  );
};

export default ReflectionReportList;
