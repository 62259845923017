import React, { PropsWithChildren, useState } from "react";
import { TextboxQuestion, ReflectionReportModelTextValue } from "./models";
import { Modal } from "semantic-ui-react";
import ReflectionReportTextAnswer from "./ReflectionReportTextAnswer";

interface Props {
  question: TextboxQuestion;
  value: ReflectionReportModelTextValue;
}

const ReflectionReportTextModal = ({ question, value, children }: PropsWithChildren<Props>) => {
  const [isOpen, setOpen] = useState(false);

  const answers = value.answers.reverse();

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={children}
    >
      <Modal.Header>{question.title}</Modal.Header>
      <Modal.Content>
        <div className="reflection-view">
          <ul className="reflection-view__text-list">
            {answers.map((answer, i) => (
              <li key={i}>
                <ReflectionReportTextAnswer answer={answer} />
              </li>
            ))}
          </ul>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ReflectionReportTextModal;
