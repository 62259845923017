import React from "react";
import person from "../icons/person.svg";

interface Props {
  value: string | number | React.ReactNode;
  image?: string;
}

const Badge = ({ value, image = person }: Props) => {
  return (
    <span className="badge">
      <img src={image} alt="" />
      {value}
    </span>
  );
};

export default Badge;
