import React from "react";
import "./styles.less";

const HomePageArticleList: React.FC = () => {
  return (
    <article className="article homepage-article-list">
      <h2 className="article__title">Kas sudaro LJA „Šešėliavimas“ programą?</h2>
      <div className="article__content">
        <section className="article__item">
          <img src="/assets/list1.jpg" alt="Pasiruošimas" className="article__image" />
          <h3 className="article__title-sm">
            <b>1.</b> Pasiruošimas
          </h3>
          <p>
            Pirmų dviejų užsiėmimų metu mokiniai atlieka savęs pažinimo testą, analizuoja savo gebėjimus ir interesus
            atitinkančias profesijas bei ruošiasi vizitui organizacijoje.
          </p>
        </section>
        <section className="article__item">
          <img src="/assets/list2.jpg" alt="Vizitas" className="article__image" />
          <h3 className="article__title-sm">
            <b>2.</b> „Šešėliavimo“ vizitas
          </h3>
          <p>
            „Šešėliavimo“ vizito metu mokiniai lankosi savo pasirinktoje organizacijoje, kur iš arti susipažįsta su
            pasirinkta profesija. Vizito metu (trukmė nuo 3 valandų iki visos darbo dienos) jie turi galimybę praktiškai
            išbandyti įvairias profesijos užduotis, sužinoti daugiau apie jos ypatumus ir tiesiogiai bendrauti su tos
            srities specialistu.
          </p>
        </section>
        <section className="article__item">
          <img src="/assets/list3.jpg" alt="Refleksija" className="article__image" />
          <h3 className="article__title-sm">
            <b>3.</b> Refleksija
          </h3>
          <p>
            Trečio užsiėmimo metu mokiniai atlieka savirefleksiją, dalinasi įspūdžiais ir pastebėjimais tarpusavyje bei
            ruošia padėkos laiškus juos priėmusioms organizacijoms.
          </p>
        </section>
      </div>
    </article>
  );
};

export default HomePageArticleList;
