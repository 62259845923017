import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import { NonAuthRoutes, AuthRoutes } from "../../app/auth/routes";
import { userHelpers } from "../../app/helpers/userHelpers";
import useScrollHeader from "../../app/hooks/useScrollHeader";
import SideNav from "./SideNav";
import ShIcon from "../../components/ShIcon";

const NavBar = () => {
  const { t } = useTranslation();
  const [isVisible, isScrolled] = useScrollHeader(200);
  const [isSideNavVisible, setSideNavVisible] = useState(false);

  const renderStudentMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="content" as={NavLink} to={AuthRoutes.guide}>
          {t("navbar.content")}
        </Menu.Item>
        <Menu.Item name="applications" as={NavLink} to={AuthRoutes.applications}>
          {t("navbar.applications")}
        </Menu.Item>
        <Menu.Item name="myCompetencies" as={NavLink} to={AuthRoutes.myCompetencies}>
          {t("navbar.my_competencies")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderSchoolMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="schoolContent" as={NavLink} to={AuthRoutes.schoolContent}>
          {t("navbar.content")}
        </Menu.Item>
        <Menu.Item name="students" as={NavLink} to={AuthRoutes.students}>
          {t("navbar.students")}
        </Menu.Item>
        <Menu.Item name="reports" as={NavLink} to={AuthRoutes.reports}>
          {t("navbar.reports")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderCompanyMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="myExcursions" as={NavLink} to={AuthRoutes.myExcursions}>
          {t("navbar.my_excursions")}
        </Menu.Item>
        <Menu.Item name="myPositions" as={NavLink} to={AuthRoutes.myPositions}>
          {t("navbar.my_positions")}
        </Menu.Item>
        <Menu.Item name="reports" as={NavLink} to={AuthRoutes.reports}>
          {t("navbar.reports")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderMunicipalityMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="schools" as={NavLink} to={AuthRoutes.schools}>
          {t("navbar.schools")}
        </Menu.Item>
        <Menu.Item name="municipalityContent" as={NavLink} to={AuthRoutes.municipalityContent}>
          {t("navbar.content")}
        </Menu.Item>
        <Menu.Item name="companies" as={NavLink} to={AuthRoutes.companies}>
          {t("navbar.companies")}
        </Menu.Item>
        <Menu.Item name="reports" as={NavLink} to={AuthRoutes.reports}>
          {t("navbar.reports")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderAdminMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="schools" as={NavLink} to={AuthRoutes.schools}>
          {t("navbar.schools")}
        </Menu.Item>
        <Menu.Item name="studentAgreements" as={NavLink} to={AuthRoutes.studentAgreements}>
          {t("navbar.studentAgreements")}
        </Menu.Item>
        <Menu.Item name="companies" as={NavLink} to={AuthRoutes.companies}>
          {t("navbar.companies")}
        </Menu.Item>
        <Menu.Item name="seasons" as={NavLink} to={AuthRoutes.seasons}>
          {t("navbar.seasons")}
        </Menu.Item>
        <Menu.Item name="reports" as={NavLink} to={AuthRoutes.reports}>
          {t("navbar.reports")}
        </Menu.Item>
        <Menu.Item name="users" as={NavLink} to={AuthRoutes.users}>
          {t("navbar.users")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderDefaultMenu = () => {
    return (
      <>
        <Menu.Item name="shadowings" as={NavLink} to={NonAuthRoutes.shadowings}>
          {t("navbar.shadowings")}
        </Menu.Item>
        <Menu.Item name="excursions" as={NavLink} to={NonAuthRoutes.excursions}>
          {t("navbar.excursions")}
        </Menu.Item>
        <Menu.Item name="partners" as={NavLink} to={NonAuthRoutes.partners}>
          {t("navbar.partners")}
        </Menu.Item>
        <Menu.Item name="about" as={NavLink} to={NonAuthRoutes.about}>
          {t("navbar.about")}
        </Menu.Item>
      </>
    );
  };

  const renderByRole = () => {
    if (userHelpers.isStudent()) {
      return renderStudentMenu();
    }

    if (userHelpers.isSchool()) {
      return renderSchoolMenu();
    }

    if (userHelpers.isCompany()) {
      return renderCompanyMenu();
    }

    if (userHelpers.isMunicipality()) {
      return renderMunicipalityMenu();
    }

    if (userHelpers.isAdmin()) {
      return renderAdminMenu();
    }

    return renderDefaultMenu();
  };

  return (
    <>
      <Menu text fixed="top" className={`${isScrolled ? "is-scrolled" : ""} ${isVisible ? "" : "is-hidden"}`}>
        <Menu.Item as={NavLink} to={NonAuthRoutes.about} className="logo">
          <img src="/assets/lja.png" alt="logo" />
        </Menu.Item>

        <Menu.Menu position="right">
          {renderByRole()}
          <ProfileInfo />
          <span></span>
        </Menu.Menu>

        <button onClick={() => setSideNavVisible((p) => !p)} className="sidenav-trigger">
          <ShIcon name="hesburger" />
        </button>
      </Menu>
      <SideNav isVisible={isSideNavVisible} onClose={() => setSideNavVisible(false)}>
        <Menu vertical>{renderByRole()}</Menu>
        <Menu vertical className="auth-menu">
          <ProfileInfo renderOnlyMenuItems />
        </Menu>
      </SideNav>
    </>
  );
};

export default NavBar;
