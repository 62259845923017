import React, { useState } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import GenderImage from "../../../components/GenderImage";
import ReflectionPreviewModal from "../../reflections/ReflectionPreviewModal";
import { Student } from "../../students/types";
import { Question, ReflectionFormModel } from "../../reflections/models";
import ReflectionWriteModal from "../../reflections/ReflectionWriteModal";
import { reflectionQuestions } from "../../reflections/reflection-questions";
import { saveApplicationFeedback } from "../../shadowings/actions";
import { isExistingReflection } from "../../../app/helpers/reflections";
import ShadowingMetadata from "../../../components/shadowing/ShadowingMetadata";
import { useLocation, useNavigate } from "react-router-dom";
import { ShadowingWithApplications } from "../../shadowings/types";
import GroupedFeedbackModal from "./GroupedFeedbackModal";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface Props {
  shadowing: ShadowingWithApplications;
  openOnLoad?: boolean;
}

const studentQuestions: Question[] = [21, 22, 27, 28].map((x) => reflectionQuestions[x]);

const FeedbackListModal = ({ shadowing, openOnLoad = false }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(openOnLoad);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSaveFeedbackAboutStudent = async (applicationId: number, value: ReflectionFormModel): Promise<any> => {
    await dispatch(saveApplicationFeedback(applicationId, value));
  };

  const onClose = () => {
    setOpen(false);
    if (location.pathname.startsWith("/open-feedback")) {
      navigate("/myPositions", { replace: true });
    }
  };

  const hasAnyFeedbackFromStudent = shadowing.applications.some((application) =>
    isExistingReflection(application.reflections)
  );

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={onClose}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={<Button primary>{t("common.read_feedbacks")}</Button>}
    >
      <Modal.Header>{t("reflection.feedback_modal_title")}</Modal.Header>
      <Modal.Content>
        <Grid>
          {shadowing.applications.map((application) => (
            <Grid.Row key={application.id} className="student__item">
              <Grid.Column width={2}>
                <GenderImage gender={application.student.gender} />
              </Grid.Column>
              <Grid.Column width={14}>
                <div className="student__name">
                  {application.student.contactInfo.fullName} {application.student.grade}
                </div>
                <ShadowingMetadata
                  locationValue={application.student.school}
                  personValue={`${application.student.contactInfo.email}`}
                />
                {isExistingReflection(application.reflections) && (
                  <ReflectionPreviewModal
                    title={getStudentModalTitle(application.student)}
                    reflections={application.reflections}
                  >
                    <Button primary>{t("reflection.read_student_feedback")}</Button>
                  </ReflectionPreviewModal>
                )}
                {isExistingReflection(application.businessFeedback) ? (
                  <ReflectionPreviewModal
                    title="reflection.feedback_modal_title"
                    reflections={application.businessFeedback}
                  >
                    <Button basic>{t("reflection.read_writen_feedback")}</Button>
                  </ReflectionPreviewModal>
                ) : (
                  <ReflectionWriteModal
                    title="reflection.feedback_modal_title"
                    questions={studentQuestions}
                    successMessage="reflection.feedback_success"
                    onSave={(value) => handleSaveFeedbackAboutStudent(application.id, value)}
                  >
                    <Button basic>{t("reflection.write_feedback")}</Button>
                  </ReflectionWriteModal>
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {hasAnyFeedbackFromStudent && (
          <GroupedFeedbackModal applications={shadowing.applications}>
            <Button primary>{t("reflection.read_all_student_feedbacks")}</Button>
          </GroupedFeedbackModal>
        )}
      </Modal.Actions>
    </Modal>
  );
};

function getStudentModalTitle(student: Student): string {
  return `${student.contactInfo.fullName} ${student.grade}`;
}

export default FeedbackListModal;
