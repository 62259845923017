import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider } from "semantic-ui-react";
import { PASSWORD_STRENGTH_REGEXP } from "../../../../app/helpers/regexp";
import AppForm from "../../../../components/form/Form";
import { useAppForm } from "../../../../components/form/useForm";

export interface PasswordChangeFormData {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
}

interface Props {
  handleSubmit: (data: PasswordChangeFormData) => void;
}

const PasswordChangeForm: React.FC<Props> = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const formHook = useAppForm<PasswordChangeFormData>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatedPassword: "",
    },
  });

  const passwordMatches = (repeatedPassword: string) => {
    return formHook.watch("newPassword") === repeatedPassword
      ? true
      : t("auth.steps.password.validation.password_match");
  };

  return (
    <>
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <AppForm.Group name="currentPassword" label="auth.current_password">
          <AppForm.Password
            name="currentPassword"
            placeholder=""
            autoComplete="new-password"
            rules={{ required: true }}
          />
        </AppForm.Group>
        <Divider />
        <AppForm.Group name="newPassword" label="auth.password_reset_confirm.new_password">
          <AppForm.Password
            name="newPassword"
            placeholder=""
            autoComplete="new-password"
            rules={{
              required: true,
              pattern: {
                value: PASSWORD_STRENGTH_REGEXP,
                message: t("auth.steps.password.validation.password_strenght"),
              },
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="repeatedPassword" label="auth.steps.password.confirm_password">
          <AppForm.Password
            name="repeatedPassword"
            placeholder=""
            rules={{ required: true, validate: passwordMatches }}
          />
        </AppForm.Group>
      </AppForm>
      <Button primary onClick={formHook.handleSubmit(handleSubmit)}>
        {t("auth.password_reset.form.confirm")}
      </Button>
    </>
  );
};

export default PasswordChangeForm;
