import React, { PropsWithChildren, useState } from "react";
import { Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import AppForm from "../../components/form/Form";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import FormModal from "../../components/FormModal";
import { useAppForm } from "../../components/form/useForm";
import { Excursion, ExcursionCreateFormModel } from "./types";
import { getCreateRequestPayload } from "./utils";
import ExcursionForm from "./ExcursionForm";
import DuplicateExcursionDropdown from "./DuplicateExcursionDropdown";

interface Props {
  onCreate?: (id: number) => void;
}

const ExcursionCreateModal = ({ children: trigger, onCreate }: PropsWithChildren<Props>) => {
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const formHook = useAppForm({ defaultValues: emptyExcursionModel });
  const isDisabled = isSaving;

  const clearForm = () => formHook.reset(emptyExcursionModel);

  const close = () => {
    clearForm();
    setOpen(false);
  };

  const handleSubmit = async (model: ExcursionCreateFormModel) => {
    setSaving(true);
    try {
      const id = await agent.Excursions.create(getCreateRequestPayload(model));
      success("excursion.form.successfully_created");
      onCreate?.(id);
      close();
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const prefillData = (excursion: Excursion) => {
    formHook.setValue("title", excursion.title);
    formHook.setValue("description", excursion.description);
    formHook.setValue("visitDuration", excursion.visitDuration!);
    formHook.setValue("participantsCapacity", excursion.participantsCapacity);
    formHook.setValue("online", excursion.online);
    formHook.setValue(
      "responsiblePerson.fullName",
      `${excursion.responsiblePerson.firstName} ${excursion.responsiblePerson.surname}`
    );
    formHook.setValue("responsiblePerson.jobTitle", excursion.responsiblePerson.jobTitle);
    formHook.setValue("responsiblePerson.email", excursion.responsiblePerson.email);
    formHook.setValue("responsiblePerson.phoneNumber", excursion.responsiblePerson.phoneNumber);
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isDisabled} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("excursion.form.create_button")}
      </Button>
      <Button negative disabled={isDisabled} onClick={close}>
        {t("common.actions.cancel")}
      </Button>
    </>
  );

  return (
    <FormModal
      title="excursion.create_excursion"
      {...{ isDisabled, isOpen, actions, trigger }}
      onClose={close}
      onOpen={() => setOpen(true)}
    >
      <AppForm formHook={formHook} isDisabled={isDisabled} onSubmit={handleSubmit}>
        <DuplicateExcursionDropdown onChange={prefillData} />
        <Divider />
        <ExcursionForm />
      </AppForm>
    </FormModal>
  );
};

export default ExcursionCreateModal;

const emptyExcursionModel: ExcursionCreateFormModel = {
  title: "",
  city: "",
  address: "",
  participantsCapacity: null!,
  visitDuration: null!,
  online: false,
  date: "",
  time: "",
  description: "",
  useHqAddress: true,
  industryIds: [],
  responsiblePerson: {
    fullName: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
  },
};
