/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Reflections } from "./models";
import ReflectionPreview from "./ReflectionPreview";
import Loader from "../../components/Loader";
import { getQuestions } from "./utils";

interface Props {
  title: string;
  reflections: Reflections | (() => Promise<Reflections>);
}

const ReflectionPreviewModal = ({ title, reflections, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [model, setModel] = useState<Reflections | undefined>(undefined);

  useEffect(() => {
    if (typeof reflections === "function") {
      reflections().then(setModel);
    } else {
      setModel(reflections);
    }
  }, [reflections]);

  const questions = useMemo(() => getQuestions(model ?? {}), [model]);

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={children}
    >
      <Modal.Header>{t(title)}</Modal.Header>
      <Modal.Content>{model ? <ReflectionPreview questions={questions} model={model} /> : <Loader />}</Modal.Content>
    </Modal>
  );
};

export default ReflectionPreviewModal;
