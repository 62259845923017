import React from "react";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import MunicipalityItem from "./MunicipalityItem";
import useMunicipalities from "../../../app/hooks/useMunicipalities";

const MunicipalityList = () => {
  const { t } = useTranslation();
  const { municipalities } = useMunicipalities();

  const handleActiveToggle = (id: number) => {
    return agent.Municipalities.toggleActive(id);
  };

  return (
    <Table celled padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t("common.title")}</Table.HeaderCell>
          <Table.HeaderCell>{t("common.contacts")}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {municipalities.map((municipality) => (
          <MunicipalityItem key={municipality.id} municipality={municipality} handleActiveToggle={handleActiveToggle} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default MunicipalityList;
