import React from "react";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
  subtitle: string;
  image: string;
  className?: string;
}

const ArticleSlide = ({ title, subtitle, image, children, className }: PropsWithChildren<Props>) => {
  return (
    <article className={`article-slide ${className}`}>
      <div className="article-slide__image">
        <img src={image} alt="" />
      </div>
      <header className="article-slide__header">
        <h3 className="article-slide__title">{title}</h3>
        {subtitle}
      </header>
      {children}
    </article>
  );
};

export default ArticleSlide;
