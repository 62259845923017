import React from "react";
import BaseAuthPage from "../BaseAuthPage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../../app/auth/routes";
import PasswordRecoveryConfirmationForm from "./PasswordRecoveryConfirmationForm";
import { useLocation } from "react-router";
import AuthLayout from "../AuthLayout";

const PasswordRecoveryConfirmationPage = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);

  return (
    <AuthLayout>
      <BaseAuthPage
        header={<h1>{t("auth.password_reset_confirm.title")}</h1>}
        content={<PasswordRecoveryConfirmationForm token={query.get("token") || ""} />}
        footer={<Link to={NonAuthRoutes.registration}>{t("auth.no_account")}</Link>}
      />
    </AuthLayout>
  );
};

export default PasswordRecoveryConfirmationPage;
