import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Button } from "semantic-ui-react";
import BusinessRegistration from "../../../businessRegistration/BusinessRegistration";
import RegistrationActions from "./RegistrationActions";
import StudentRegistrationSteps from "./student/StudentRegistrationSteps";
import SchoolRegistration from "../../../schoolRegistration/SchoolRegistration";

interface TypeSelectionProps {
  handlePreviousStepClick?: () => void;
  activeType?: "student" | "business" | "school";
}

const TypeSelectionStep: React.FC<TypeSelectionProps> = ({ handlePreviousStepClick, activeType }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState<string>(activeType || "");
  const [selected, setSelected] = useState<string>("");

  const menu = [
    { key: "school", value: t("auth.types.school") },
    { key: "student", value: t("auth.types.student") },
    { key: "business", value: t("auth.types.business") },
  ];

  const handleItemClick = (e: any, { name }: any) => {
    setActive(name);
    setSelected(name);
  };

  const renderByRegistrationType = () => {
    if (active === "school") return <SchoolRegistration onBackToRegistration={() => setSelected("")} />;
    if (active === "student") return <StudentRegistrationSteps onBackToRegistration={() => setSelected("")} />;
    if (active === "business") return <BusinessRegistration onBackToRegistration={() => setSelected("")} />;

    return null;
  };

  return (
    <Fragment>
      {selected ? (
        renderByRegistrationType()
      ) : (
        <Fragment>
          <label>{t("auth.select_type")}</label>
          <Menu fluid secondary vertical className="registration-menu">
            {menu.map((item) => (
              <Menu.Item key={item.key} name={item.key} active={active === item.key} onClick={handleItemClick}>
                {item.value}
              </Menu.Item>
            ))}
          </Menu>

          <div className="registration-extra-text">
            {t("auth.steps.type_selection.other_type_text")}{" "}
            <a href={"mailto:" + t("footer.email")}>{t("footer.email")}</a>.
          </div>

          <RegistrationActions>
            {handlePreviousStepClick && (
              <Button basic content={t("auth.steps.back")} onClick={handlePreviousStepClick} />
            )}
          </RegistrationActions>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TypeSelectionStep;
