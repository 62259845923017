import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";
import CompanyReportsCompanyReflections from "./CompanyReportsCompanyReflections";
import CompanyReportsStatistics from "./CompanyReportsStatistics";
import CompanyReportsStudentFeedback from "./CompanyReportsStudentFeedback";

const CompanyReportsPage = () => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("reports.statistics"),
      render: () => <CompanyReportsStatistics />,
    },
    {
      menuItem: t("reports.companyReflections"),
      render: () => <CompanyReportsCompanyReflections />,
    },
    {
      menuItem: t("reports.studentReflections"),
      render: () => <CompanyReportsStudentFeedback />,
    },
  ];

  return (
    <div className="reports-page">
      <Tab className="tabs" menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default CompanyReportsPage;
