/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  onChange: (v: Date) => void;
}

const getYear = (d: Date) => d.getFullYear();
const getMonth = (d: Date) => d.getMonth();

const DateHeader: React.FC<Props> = ({
  date,
  decreaseMonth,
  prevMonthButtonDisabled,
  changeYear,
  changeMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  onChange,
}) => {
  const { t } = useTranslation();

  const years = Array.from({ length: 150 }).map((_, i) => 1950 + i);
  const months = [
    t("common.january"),
    t("common.february"),
    t("common.march"),
    t("common.april"),
    t("common.may"),
    t("common.june"),
    t("common.july"),
    t("common.august"),
    t("common.september"),
    t("common.october"),
    t("common.november"),
    t("common.december"),
  ];

  const handleYearChange = (value: number) => {
    const newDate = new Date(+date);
    newDate.setFullYear(value);
    newDate.setDate(1);
    onChange(newDate);
    changeYear(value);
  };

  const handleMonthChange = (value: number) => {
    const newDate = new Date(+date);
    newDate.setMonth(value);
    newDate.setDate(1);
    onChange(newDate);
    changeMonth(value);
  };

  return (
    <div className="react-datepicker__custom-header">
      <button onClick={decreaseMonth} type="button" disabled={prevMonthButtonDisabled}>
        Previous
      </button>

      <select value={getYear(date)} onChange={({ target: { value } }) => handleYearChange(Number(value))}>
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => handleMonthChange(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button onClick={increaseMonth} type="button" disabled={nextMonthButtonDisabled}>
        Next
      </button>
    </div>
  );
};

export default DateHeader;
