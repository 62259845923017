import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Question, ReflectionFormModel } from "./models";
import ReflectionForm from "./ReflectionForm";
import { reflectionQuestions } from "./reflection-questions";
import FormModal from "../../components/FormModal";
import ReflectionActions from "./ReflectionActions";
import useToaster from "../../app/hooks/useToaster";
import agent from "../../app/api/agent";

interface Props {
  isOpen?: boolean;
  applicationId: number;
  handleReflectionSubmit: () => void;
}

const pages: Question[][] = [
  [1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11],
  [12, 13, 14, 10014, 15, 10015, 16, 17, 18, 19, 20],
].map((page) => page.map((x) => reflectionQuestions[x]));

const ReflectionModal = ({
  children: trigger,
  applicationId,
  handleReflectionSubmit,
  isOpen: isOpenInitial = false,
}: PropsWithChildren<Props>) => {
  const [page, setPage] = useState(0);
  const [model, setModel] = useState<ReflectionFormModel>({});
  const [isOpen, setOpen] = useState(isOpenInitial);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const { apiError, success } = useToaster();
  const { t } = useTranslation();

  const isDisabled = isSaving;
  const isFormDisabled = isDisabled;
  const isFirstPage = page === 0;
  const isLastPage = page === pages.length - 1;

  const handleSubmit = (data: ReflectionFormModel) => {
    const formValue = { ...model, ...data };
    setModel(formValue);

    if (isLastPage) {
      save(formValue);
    } else {
      setPage((p) => p + 1);
    }
  };

  const save = async (value: ReflectionFormModel) => {
    setSaving(true);

    try {
      await agent.ApplicationReflections.create(applicationId, value);
      success("reflection.success");
      handleReflectionSubmit();
      closeModal();
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const openModal = async () => {
    setOpen(true);
    setLoading(true);

    try {
      setModel(await agent.ApplicationReflections.get(applicationId));
      setLoading(false);
    } catch (error) {
      apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setPage(0);
    setModel({});
  };

  return (
    <>
      {!isLoading && (
        <ReflectionForm questions={pages[page]} model={model} onSubmit={handleSubmit} isDisabled={isFormDisabled}>
          {(form, formHook) => (
            <FormModal
              title="reflection.modal_title"
              {...{ isDisabled, isOpen, actions: null, trigger }}
              onClose={closeModal}
              onOpen={openModal}
              actions={
                <ReflectionActions
                  page={page}
                  total={pages.length}
                  isDisabled={isDisabled}
                  isSaving={isSaving}
                  onSubmit={formHook.handleSubmit(handleSubmit)}
                  onBack={() => setPage((p) => p - 1)}
                />
              }
            >
              {page === 1 && <p>{t("reflection.help")}</p>}
              {form}
            </FormModal>
          )}
        </ReflectionForm>
      )}
    </>
  );
};

export default ReflectionModal;
