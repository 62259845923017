import React from "react";
import { InputProps } from "semantic-ui-react";
import { PropsInjectedByController } from "./types";
import AppInput from "./Input";
import { withController } from "./withController";
import { URL_REGEXP } from "../../app/helpers/regexp";

type Props = InputProps;

const AppUrl: React.FC<PropsInjectedByController & Props> = ({ onBlur, onChange, isValid, value, ...props }) => {
  const handleBlur = () => {
    if (isValid) {
      const trimmedValue = value.trim();
      if (trimmedValue && !trimmedValue.startsWith("http://") && !trimmedValue.startsWith("https://")) {
        onChange(`http://${trimmedValue}`);
      }
    }
    onBlur();
  };

  return (
    <AppInput
      {...props}
      onChange={onChange}
      onBlur={handleBlur}
      value={value}
      isValid={isValid}
      inputMode="url"
      type="text"
    />
  );
};
const AppUrlController = withController(AppUrl);
const AppUrlControllerWithRules = ({ rules, ...props }: Parameters<typeof AppUrlController>[0]) => (
  <AppUrlController
    rules={{
      ...rules,
      pattern: {
        value: URL_REGEXP,
        message: "errors.url",
      },
    }}
    {...props}
  />
);

export { AppUrlControllerWithRules as AppUrlController };
export default AppUrl;
