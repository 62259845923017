import React, { useEffect, useState } from "react";
import Datepicker from "react-datepicker";

export interface Props {
  value: { start: Date; end: Date } | null;
  onChange: (value: { start: Date; end: Date }) => void;
}

const ListFilterDateRange = ({ value, onChange }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleChange = ([start, end]: [Date, Date | null]) => {
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onChange({ start, end });
    }
  };

  useEffect(() => {
    setStartDate(value?.start || null);
    setEndDate(value?.end || null);
  }, [value]);

  return (
    <>
      <Datepicker
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
    </>
  );
};

export default ListFilterDateRange;
