import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "semantic-ui-react";
import SideNav from "../../features/nav/SideNav";
import ShIcon from "../ShIcon";
import { ListFilterConfig, ListFilterConfigItem, ListFilterConfigItemType, ListFilterValue } from "./models";
import useListFilter from "./useListFilter";

interface Props {
  config: ListFilterConfig;
  value: ListFilterValue;
  onChange: (value: ListFilterValue) => void;
}

const ListFilter = ({ config, value, onChange }: Props) => {
  const { t } = useTranslation();
  const { renderDropdowns, renderDeleteBadges, renderSearch, renderFilterAccordion, hasSearch } = useListFilter({
    config,
    value,
    onChange,
  });
  const [isFilterOpened, setFilterOpened] = useState(false);

  // TODO: implement optional sorting and reuse this component in ShadowingHome component

  return (
    <>
      <Grid.Row only="computer">
        <Grid.Column>
          <div className="list-filter">
            <div className="list-filter-options">
              <div className="list-filter-options-available">
                <span>{t("common.filter_by")}</span>
                {renderDropdowns()}
              </div>
              <div className="list-filter-options-selected">{renderDeleteBadges()}</div>
            </div>
            {hasSearch && <div className="list-filter-search">{renderSearch()}</div>}
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row only="mobile tablet">
        <Grid.Column>
          <Button.Group widths="1">
            <Button onClick={() => setFilterOpened(true)}>
              <ShIcon name="filter" />
              {t("common.filter")}
            </Button>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>

      <SideNav title="common.filter" isVisible={isFilterOpened} onClose={() => setFilterOpened(false)}>
        <div className="shadowing-filter">
          {renderSearch()}
          {renderFilterAccordion()}
        </div>
      </SideNav>
    </>
  );
};

export const getFilterInitialValue = (config: ListFilterConfig) => {
  return config.reduce((acc, filter) => ({ ...acc, [filter.name]: getFilterItemInitialValue(filter) }), {});
};

export const getFilterItemInitialValue = (configItem: ListFilterConfigItem): any[] | string => {
  return configItem.type === ListFilterConfigItemType.Search ? "" : ([] as any[]);
};

export default ListFilter;
