import React, { useState } from "react";
import { Container, Grid, Divider, Button } from "semantic-ui-react";
import WelcomePhotos from "../shadowings-home/WelcomePhotos";
import PageTitle from "../../components/page-title/PageTitle";
import { useTranslation } from "react-i18next";
import { SORT_BY } from "../shadowingsFilter/sort_options";
import ShIcon from "../../components/ShIcon";
import useExcursions from "./useExcursions";
import ExcursionList from "./ExcursionList";
import ExcursionSorter from "./ExcursionSorter";
import useExcursionFilter from "./useExcursionFilter";
import SideNav from "../nav/SideNav";
import { pluralize } from "../../app/helpers/pluralize";

const ExcursionPage = () => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(SORT_BY.nearestFirst);
  const [isFilterOpened, setFilterOpened] = useState(false);

  const { value: filter, renderDeleteBadges, renderSearch, renderFilterAccordion } = useExcursionFilter();
  const { excursions, isLoading, renderPagination, reload, totalCount } = useExcursions(filter, sortBy);
  const title = totalCount ? pluralize("excursion.page_title", totalCount) : "excursion.page_title";

  return (
    <>
      <Container>
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column mobile={16} only="mobile">
              <WelcomePhotos />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={7} computer={9}>
              <PageTitle
                titleHighlight={totalCount ? String(totalCount) : undefined}
                title={t(title)}
                description={t("excursion.page_description")}
                extra={t("excursion.page_extra")}
              />
            </Grid.Column>
            <Grid.Column computer={7} tablet={9} only="tablet computer">
              <WelcomePhotos />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row only="computer">
            <Grid.Column width={4}>
              <h4>{t("common.filter")}</h4>
            </Grid.Column>
            <Grid.Column width={9}>{renderDeleteBadges()}</Grid.Column>
            <Grid.Column width={3}>
              <ExcursionSorter sortBy={sortBy} onSortChange={setSortBy} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only="mobile tablet">
            <Grid.Column>
              <Button.Group widths="2">
                <ExcursionSorter
                  icon={<ShIcon name="sort" />}
                  text={t("common.sort")}
                  sortBy={sortBy}
                  onSortChange={setSortBy}
                />
                <Button onClick={() => setFilterOpened(true)}>
                  <ShIcon name="filter" />
                  {t("common.filter")}
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <SideNav title="common.filter" isVisible={isFilterOpened} onClose={() => setFilterOpened(false)}>
            <div className="shadowing-filter">
              {renderSearch()}
              {renderFilterAccordion()}
            </div>
          </SideNav>
          <Grid.Row>
            <Grid.Column width={4} only="computer">
              <div className="shadowing-filter">
                {renderSearch()}
                {renderFilterAccordion()}
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} computer={12}>
              <ExcursionList excursions={excursions} isLoading={isLoading} onUpdate={reload}>
                {renderPagination()}
              </ExcursionList>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default ExcursionPage;
