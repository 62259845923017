import React, { useEffect, useState, useCallback } from "react";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import PaginationComponent from "../../components/pagination/Pagination";
import { Excursion } from "./types";

const useMyExcursions = (past: boolean) => {
  const [excursions, setExcursions] = useState<Excursion[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const { apiError } = useToaster();

  const loadExcursions = useCallback(
    async (page: number) => {
      setLoading(true);

      try {
        const response = await agent.Excursions.listMyExcursions({ past }, page);
        setExcursions(response.items);
        setTotalPages(response.totalPages);
        setTotalCount(response.totalCount);
      } catch (error) {
        setExcursions([]);
        apiError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiError, past]
  );

  useEffect(() => {
    setTotalCount(undefined);
    setPageNumber(1);
    loadExcursions(1);
  }, [loadExcursions]);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    loadExcursions(page);
  };

  const renderPagination = () => (
    <PaginationComponent totalPages={totalPages} pageNumber={pageNumber} handlePageChange={handlePageChange} />
  );

  return {
    excursions,
    isLoading,
    totalCount,
    reload: () => loadExcursions(pageNumber),
    renderPagination,
  };
};

export default useMyExcursions;
