import { Reflections } from "./../reflections/models";
import { Dispatch } from "redux";
import { Shadowing } from "./types";
import { RootState } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import {
  AdActionTypes,
  ADD_SHADOWING,
  FETCH_SHADOWINGS,
  DELETE_SHADOWING,
  EDIT_SHADOWING,
} from "../../app/store/types/shadowings";
import { CompanyPositionsFilter } from "../companies/types";
import { FETCH_SHADOWINGS_REQUEST, SET_APPLICATION_FEEDBACK } from "../../app/store/types/shadowings";

export const addShadowing =
  (shadowing: Shadowing) => async (dispatch: Dispatch<AdActionTypes>, getState: () => RootState) => {
    await agent.Shadowings.createObsolete(shadowing).then((id) => {
      const companiesState = getState().companies;
      const company = companiesState.companies.find((c) => c.id === Number(shadowing.companyId));

      if (!company) return;

      dispatch({
        type: ADD_SHADOWING,
        shadowing: {
          ...shadowing,
          id,
          company: company,
        },
      });
    });
  };

export const deleteShadowing = (id: number) => async (dispatch: Dispatch<AdActionTypes>) => {
  await agent.Shadowings.delete(id).then(() => {
    dispatch({
      type: DELETE_SHADOWING,
      id,
    });
  });
};

export const editShadowing = (id: number, data: Shadowing) => async (dispatch: Dispatch<AdActionTypes>) => {
  const shadowing = { ...data, id };

  await agent.Shadowings.updateLegacy(shadowing).then(() => {
    dispatch({
      type: EDIT_SHADOWING,
      payload: shadowing,
    });
  });
};

export const fetchShadowings = () => async (dispatch: Dispatch<AdActionTypes>, getState: () => RootState) => {
  dispatch({ type: FETCH_SHADOWINGS_REQUEST });

  const filter = getState().shadowingsFilter;
  const data = await agent.Shadowings.list(filter);

  dispatch({
    type: FETCH_SHADOWINGS,
    shadowings: data.items,
    pagination: { pageNumber: data.pageNumber, totalPages: data.totalPages, totalCount: data.totalCount },
  });
};

export const fetchCompanyShadowings = (companyId: number) => async (dispatch: Dispatch<AdActionTypes>) => {
  const data = await agent.Shadowings.listForCompany(companyId);

  dispatch({
    type: FETCH_SHADOWINGS,
    shadowings: data,
  });
};

export const fetchMyShadowings =
  (filter: CompanyPositionsFilter, withLoader = true) =>
  async (dispatch: Dispatch<AdActionTypes>) => {
    if (withLoader) dispatch({ type: FETCH_SHADOWINGS_REQUEST });

    const data = await agent.Shadowings.listMyShadowings(filter);

    dispatch({
      type: FETCH_SHADOWINGS,
      shadowings: data,
    });
  };

export const saveApplicationFeedback =
  (applicationId: number, feedback: Reflections) => async (dispatch: Dispatch<AdActionTypes>) => {
    await agent.ApplicationReflections.writeFeedbackAboutStudent(applicationId, feedback);

    dispatch({
      type: SET_APPLICATION_FEEDBACK,
      payload: { applicationId, feedback },
    });
  };
