import { ReflectionReportModel, Reflections } from "./models";
import { reflectionQuestionOrder, reflectionQuestions } from "./reflection-questions";

export const getQuestions = (reflections: Reflections | ReflectionReportModel) => {
  return reflectionQuestionOrder
    .filter((questionId) => reflections[questionId] != null)
    .map((questionId) => reflectionQuestions[questionId]);
};

export const getQuestionsByIds = (questionIds: number[]) => {
  return reflectionQuestionOrder
    .filter((questionId) => questionIds.includes(questionId))
    .map((questionId) => reflectionQuestions[questionId]);
};
