import { Reflections } from "./../../../features/reflections/models";
import { Shadowing, ShadowingWithApplications } from "../../../features/shadowings/types";

export interface ShadowingState {
  shadowings: ShadowingWithApplications[];
  isLoading: boolean;
  pagination?: {
    pageNumber: number;
    totalPages: number;
    totalCount: number;
  };
}

export const ADD_SHADOWING = "ADD_SHADOWING";
export const DELETE_SHADOWING = "DELETE_SHADOWING";
export const EDIT_SHADOWING = "EDIT_SHADOWING";
export const FETCH_SHADOWINGS_REQUEST = "FETCH_SHADOWINGS_REQUEST";
export const FETCH_SHADOWINGS = "FETCH_SHADOWINGS";
export const SET_APPLICATION_FEEDBACK = "SET_APPLICATION_FEEDBACK";

interface AddShadowingAction {
  type: typeof ADD_SHADOWING;
  shadowing: Shadowing;
}

interface DeleteShadowingAction {
  type: typeof DELETE_SHADOWING;
  id: number;
}

interface EditShadowingAction {
  type: typeof EDIT_SHADOWING;
  payload: Shadowing;
}

interface FetchShadowingsAction {
  type: typeof FETCH_SHADOWINGS;
  shadowings: ShadowingWithApplications[];
  pagination?: { pageNumber: number; totalPages: number; totalCount: number };
}

interface FetchShadowingsRequestAction {
  type: typeof FETCH_SHADOWINGS_REQUEST;
}

interface SetApplicationFeedback {
  type: typeof SET_APPLICATION_FEEDBACK;
  payload: {
    applicationId: number;
    feedback: Reflections;
  };
}

export type AdActionTypes =
  | AddShadowingAction
  | DeleteShadowingAction
  | EditShadowingAction
  | FetchShadowingsAction
  | FetchShadowingsRequestAction
  | SetApplicationFeedback;
