import React, { PropsWithChildren } from "react";

interface Props {
  stepInfo?: { current: number; total: number };
}

const RegistrationActions: React.FC<PropsWithChildren<Props>> = ({ stepInfo, children }) => {
  return (
    <div className="registration-actions">
      {children && <div className="registration-buttons">{children}</div>}
      {stepInfo && (
        <div className="registration-counter">
          {stepInfo.current}
          {"/"}
          {stepInfo.total}
        </div>
      )}
    </div>
  );
};

export default RegistrationActions;
