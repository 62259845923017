import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Question, ReflectionFormModel } from "./models";
import ReflectionForm from "./ReflectionForm";
import { reflectionQuestions } from "./reflection-questions";
import FormModal from "../../components/FormModal";
import ReflectionActions from "./ReflectionActions";
import useToaster from "../../app/hooks/useToaster";
import agent from "../../app/api/agent";
import { userActions } from "../user/actions";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";

const pages: Question[][] = [
  [1, 2],
  [3, 4, 5, 6, 7, 8, 9, 10, 11],
].map((page) => page.map((x) => reflectionQuestions[x]));

interface IProps {
  onCancel?: () => void;
}

const InitialReflectionModal = ({ onCancel: handleCancel }: IProps) => {
  const [page, setPage] = useState(0);
  const [model, setModel] = useState<ReflectionFormModel>({});
  const [isSaving, setSaving] = useState(false);
  const { apiError, success } = useToaster();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isDisabled = isSaving;
  const isFormDisabled = isDisabled;
  const isFirstPage = page === 0;
  const isLastPage = page === pages.length - 1;

  const handleSubmit = (data: ReflectionFormModel) => {
    const formValue = { ...model, ...data };
    setModel(formValue);

    if (isLastPage) {
      save(formValue);
    } else {
      setPage((p) => p + 1);
    }
  };

  const save = async (value: ReflectionFormModel) => {
    setSaving(true);

    try {
      await agent.ApplicationReflections.createInitial(value);
      success("reflection.success");
      dispatch(userActions.updateUser({ needToFillReflection: false }));
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <ReflectionForm questions={pages[page]} model={model} onSubmit={handleSubmit} isDisabled={isFormDisabled}>
        {(form, formHook) => (
          <FormModal
            title="reflection.modal_title_initial"
            {...{ isDisabled, isOpen: true, actions: null, trigger: null, onClose: handleCancel }}
            actions={
              <ReflectionActions
                page={page}
                total={pages.length}
                isDisabled={isDisabled}
                isSaving={isSaving}
                onSubmit={formHook.handleSubmit(handleSubmit)}
                onBack={() => setPage((p) => p - 1)}
              />
            }
          >
            {page === 1 && <p>{t("reflection.help_initial")}</p>}
            {form}
          </FormModal>
        )}
      </ReflectionForm>
    </>
  );
};

export default InitialReflectionModal;
