import React, { PropsWithChildren, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "semantic-ui-react";
import { isExistingReflection } from "../../../app/helpers/reflections";
import { Application } from "../../applications/types";
import { ReflectionReportModel } from "../../reflections/models";
import ReflectionReportList from "../../reflections/ReflectionReportList";
import { getQuestionsByIds } from "../../reflections/utils";
import { calculateApplicationAnswers } from "./calculate-application-answers";

interface Props {
  applications: Application[];
}

const GroupedFeedbackModal = ({ applications, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const values = useMemo<ReflectionReportModel>(() => {
    const questionIds = applications
      .flatMap((application) =>
        isExistingReflection(application.reflections) ? Object.keys(application.reflections) : []
      )
      .map((id) => Number(id));
    const questions = getQuestionsByIds(questionIds);

    return questions.reduce<ReflectionReportModel>(
      (acc, question) => ({
        ...acc,
        [question.id]: calculateApplicationAnswers(applications, question),
      }),
      {}
    );
  }, [applications]);

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={children}
    >
      <Modal.Header>{t("reflection.all_feedback_modal_title")}</Modal.Header>
      <Modal.Content>
        <ReflectionReportList values={values} />
      </Modal.Content>
    </Modal>
  );
};

export default GroupedFeedbackModal;
