import React from "react";
import boy from "../icons/boy.svg";
import girl from "../icons/girl.svg";

interface IProps {
  gender: string;
}

const GenderImage: React.FC<IProps> = ({ gender }) => {
  const renderGenderIcon = () => {
    switch (gender) {
      case "M":
        return <img src={boy} alt="boy" />;
      case "F":
        return <img src={girl} alt="girl" />;
      default:
        return null;
    }
  };

  return renderGenderIcon();
};

export default GenderImage;
