import React, { useCallback, useEffect, useState } from "react";
import { Season } from "./models";
import useToaster from "../../app/hooks/useToaster";
import agent from "../../app/api/agent";
import "./styles.less";
import Loader from "../../components/Loader";
import { createSeason, endSeason, createRound, endRound } from "./season-utils";
import SeasonsAdmin from "./SeasonsAdmin";
import { Confirm } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/page-title/PageTitle";

interface ConfirmationData {
  content: string;
  confirmButton: string;
  cancelButton: string;
  onConfirm: () => void;
}

const SeasonsAdminPage = () => {
  const [seasons, setSeasons] = useState<Season[]>(undefined!);
  const [isSeasonStateChanging, setSeasonStateChanging] = useState(false);
  const [isRoundStateChanging, setRoundStateChanging] = useState(false);
  const [confirmation, setConfirmation] = useState<ConfirmationData | undefined>(undefined);
  const { apiError } = useToaster();
  const { t } = useTranslation();

  const startSeason = useCallback(
    () =>
      (async () => {
        setConfirmation(undefined);
        setSeasonStateChanging(true);
        try {
          await agent.Seasons.startSeason();
          setSeasons((seasons) => [createSeason(), ...seasons]);
        } catch (error) {
          apiError(error);
        } finally {
          setSeasonStateChanging(false);
        }
      })(),
    [apiError]
  );

  const stopSeason = useCallback(
    () =>
      (async () => {
        setConfirmation(undefined);
        setSeasonStateChanging(true);
        try {
          await agent.Seasons.stopSeason();
          setSeasons((seasons) => [endSeason(seasons[0]), ...seasons.slice(1)]);
        } catch (error) {
          apiError(error);
        } finally {
          setSeasonStateChanging(false);
        }
      })(),
    [apiError]
  );

  const startRound = useCallback(
    () =>
      (async () => {
        setConfirmation(undefined);
        setRoundStateChanging(true);
        try {
          await agent.Seasons.startRound();
          setSeasons((seasons) => [
            {
              ...seasons[0],
              rounds: [createRound(), ...seasons[0].rounds],
            },
            ...seasons.slice(1),
          ]);
        } catch (error) {
          apiError(error);
        } finally {
          setRoundStateChanging(false);
        }
      })(),
    [apiError]
  );

  const stopRound = useCallback(
    () =>
      (async () => {
        setConfirmation(undefined);
        setRoundStateChanging(true);
        try {
          await agent.Seasons.stopRound();
          setSeasons((seasons) => [
            {
              ...seasons[0],
              rounds: [endRound(seasons[0].rounds[0]), ...seasons[0].rounds.slice(1)],
            },
            ...seasons.slice(1),
          ]);
        } catch (error) {
          apiError(error);
        } finally {
          setRoundStateChanging(false);
        }
      })(),
    [apiError]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await agent.Seasons.list();
        setSeasons(response.seasons);
      } catch (error) {
        apiError(error);
      }
    })();
  }, [apiError]);

  const confirmations = {
    seasonStart: {
      content: t("seasons.startSeasonConfirmation"),
      confirmButton: t("seasons.startSeason"),
      cancelButton: t("common.actions.cancel"),
      onConfirm: startSeason,
    } as ConfirmationData,
    seasonStop: {
      content: t("seasons.stopSeasonConfirmation"),
      confirmButton: t("seasons.stopSeason"),
      cancelButton: t("seasons.continueSeason"),
      onConfirm: stopSeason,
    } as ConfirmationData,
    roundStart: {
      content: t("seasons.startRoundConfirmation"),
      confirmButton: t("seasons.startRound"),
      cancelButton: t("common.actions.cancel"),
      onConfirm: startRound,
    } as ConfirmationData,
    roundStop: {
      content: t("seasons.stopRoundConfirmation"),
      confirmButton: t("seasons.stopRound"),
      cancelButton: t("seasons.continueRound"),
      onConfirm: stopRound,
    } as ConfirmationData,
  };

  if (!seasons) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={t("seasons.pageTitle")} />
      <div className="block">
        <SeasonsAdmin
          seasons={seasons}
          onSeasonStart={() => setConfirmation(confirmations.seasonStart)}
          onSeasonStop={() => setConfirmation(confirmations.seasonStop)}
          onRoundStart={() => setConfirmation(confirmations.roundStart)}
          onRoundStop={() => setConfirmation(confirmations.roundStop)}
          isSeasonStateChanging={isSeasonStateChanging}
          isRoundStateChanging={isRoundStateChanging}
        />
        {confirmation && (
          <Confirm
            closeOnDimmerClick={false}
            closeOnEscape={false}
            size="tiny"
            open={true}
            content={confirmation.content}
            confirmButton={confirmation.confirmButton}
            cancelButton={confirmation.cancelButton}
            onCancel={() => setConfirmation(undefined)}
            onConfirm={confirmation.onConfirm}
          />
        )}
      </div>
    </>
  );
};

export default SeasonsAdminPage;
