import React, { PropsWithChildren, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ReflectionReportModel } from "./models";
import ReflectionReportList from "./ReflectionReportList";

interface Props {
  title: string;
  values: ReflectionReportModel;
}

const ReflectionReportPreviewModal = ({ title, values, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={children}
    >
      <Modal.Header>{t(title)}</Modal.Header>
      <Modal.Content>
        <ReflectionReportList values={values} />
      </Modal.Content>
    </Modal>
  );
};

export default ReflectionReportPreviewModal;
