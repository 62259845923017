import React, { useEffect, useState, useCallback } from "react";
import agent from "../../app/api/agent";
import { UserRoles } from "../../app/auth/roles";
import useToaster from "../../app/hooks/useToaster";
import { ListFilterConfigItemMultiSelectOption } from "../../components/list-filter/models";
import PaginationComponent from "../../components/pagination/Pagination";
import { SiteUser } from "./types";

const useUsers = (roles?: ListFilterConfigItemMultiSelectOption[], search?: string) => {
  const [users, setUsers] = useState<SiteUser[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const { apiError } = useToaster();

  const loadUsers = useCallback(
    async (page: number) => {
      setLoading(true);

      try {
        const response = await agent.Users.list({
          search: search || undefined,
          roles: roles?.map((role) => role.value as UserRoles),
          activePage: page,
        });
        setUsers(response.items);
        setTotalPages(response.totalPages);
      } catch (error) {
        setUsers([]);
        apiError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiError, roles, search]
  );

  useEffect(() => {
    setPageNumber(1);
    loadUsers(1);
  }, [roles, search, loadUsers]);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
    loadUsers(page);
  };

  const renderPagination = () => (
    <PaginationComponent totalPages={totalPages} pageNumber={pageNumber} handlePageChange={handlePageChange} />
  );

  return {
    users,
    isLoading,
    reloadUsers: () => loadUsers(pageNumber),
    renderPagination,
  };
};

export default useUsers;
