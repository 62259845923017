import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../auth/roles";

const roles: UserRoles[] = [
  UserRoles.student,
  UserRoles.school,
  UserRoles.municipality,
  UserRoles.company,
  UserRoles.admin,
];

const useUserRoles = () => {
  const { t } = useTranslation();

  const labels = useMemo<Record<UserRoles, string>>(
    () => ({
      [UserRoles.student]: t("userRoles.students"),
      [UserRoles.school]: t("userRoles.schools"),
      [UserRoles.municipality]: t("userRoles.municipalities"),
      [UserRoles.company]: t("userRoles.companies"),
      [UserRoles.admin]: t("userRoles.admins"),
    }),
    [t]
  );

  return useMemo(
    () => ({
      roles,
      roleOptions: roles.map((role) => ({
        key: role,
        value: role,
        text: labels[role],
      })),
    }),
    [labels]
  );
};

export default useUserRoles;
