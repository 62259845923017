import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReportsData, ReportDataType } from "../models";
import ReportsContainer from "../ReportsContainer";
import { ReportFilterContext } from "../useReportFilter";

const dataResourceFn = async (filter?: ListFilterValue): Promise<ReportsData[]> => {
  const params: { gender?: string[]; grade?: string[]; years?: number[] } = {};
  if (Array.isArray(filter?.gender) && filter?.gender.length) {
    params.gender = filter.gender.map(getValue);
  }
  if (Array.isArray(filter?.grade) && filter?.grade.length) {
    params.grade = filter.grade.map(getValue);
  }
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map(getValue);
  }

  const response = await agent.Reports.companyStats(params);

  const data: ReportsData[] = [
    {
      title: "reports.reflection.total",
      type: ReportDataType.Count,
      value: [
        {
          unit: "reports.reflection.positionsCount",
          value: response.shadowingsCount,
        },
        {
          unit: "reports.reflection.applicationCount",
          value: response.applicationCount,
        },
        {
          unit: "reports.reflection.visitsCount",
          value: response.visitsCount,
        },
      ],
    },
  ];

  if (response.bestShadowings?.length > 0) {
    data.push({
      title: "reports.reflection.bestShadowings",
      type: ReportDataType.Shadowing,
      value: response.bestShadowings,
    });
  }

  return data;
};

const getValue = <T extends { value: any }>(x: T) => x.value;

const CompanyReportsStatistics = () => {
  const { t } = useTranslation();

  const { years, grades, genders } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years, grades, genders], [years, grades, genders]);

  return (
    <>
      <PageTitle title={t("reports.statistics")} />
      <ReportsContainer dataResourceFn={dataResourceFn} filterConfig={filters} />
    </>
  );
};

export default CompanyReportsStatistics;
