import React, { useCallback, useEffect, useState } from "react";
import { CompetenciesModel } from "./models";
import { ListFilterConfig, ListFilterValue } from "../../components/list-filter/models";
import { getFilterInitialValue } from "../../components/list-filter/ListFilter";
import useToaster from "../../app/hooks/useToaster";
import Competencies from "./Competencies";

interface Props {
  filterConfig?: ListFilterConfig;
  initialFilterValue?: ListFilterValue;
  dataResourceFn: (filterValue?: ListFilterValue) => Promise<CompetenciesModel>;
}

const CompetenciesContainer = ({ filterConfig, initialFilterValue, dataResourceFn }: Props) => {
  const [data, setData] = useState<CompetenciesModel>(undefined!);
  const [filterValue, setFilterValue] = useState(
    initialFilterValue ?? (filterConfig && getFilterInitialValue(filterConfig))
  );
  const [isLoading, setLoading] = useState(true);
  const { apiError } = useToaster();

  const loadData = useCallback(
    async (filter?: ListFilterValue) => {
      setLoading(true);

      try {
        const response = await dataResourceFn(filter);
        setData(response);
      } catch (error) {
        apiError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiError, dataResourceFn]
  );

  useEffect(() => {
    loadData(filterValue);
  }, [filterValue, loadData]);

  return (
    <Competencies
      isLoading={isLoading}
      data={data}
      filterConfig={filterConfig}
      filterValue={filterValue}
      onFilterChange={setFilterValue}
    ></Competencies>
  );
};

export default CompetenciesContainer;
