export const fileSizeValidator =
  (limit = 5 * 1024 * 1024, message = "errors.file_size") =>
  (value: unknown) =>
    value instanceof File && value.size > limit ? message : true;

export const fileTypeValidator =
  (extensions: string[] | string, message = "errors.file_type") =>
  (value: unknown) => {
    if (!(value instanceof File)) {
      return true;
    }

    const extenstion = value.name.split(".").pop()!.toLocaleLowerCase();

    return [extensions].flat().includes(extenstion) ? true : message;
  };
