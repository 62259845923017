import React from "react";

interface Props {
  width: number | string;
  height: number | string;
  url: string;
}

const ScaledImage = ({ width, height, url }: Props) => {
  return (
    <div style={{ width, height, background: `url(${url}) center center no-repeat`, backgroundSize: "contain" }} />
  );
};

export default ScaledImage;
