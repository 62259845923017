import React from "react";
import { Navigate } from "react-router-dom";
import { NonAuthRoutes } from "./routes";
import { useSelector } from "react-redux";
import { RootState } from "../store/configureStore";

interface Props {
  requiredRoles: string[];
}

const AuthRoute = ({ children, requiredRoles }: Props & { children: JSX.Element }) => {
  const { isLoggedIn, roles: userRoles } = useSelector((state: RootState) => state.currentUser);
  const hasRequiredRole = requiredRoles.some((requiredRole) => userRoles.includes(requiredRole));
  const message = hasRequiredRole ? "Please log in to view this page" : "You can't be here!";

  return isLoggedIn && hasRequiredRole ? children : <Navigate to={NonAuthRoutes.home} state={{ message }} replace />;
};

export default AuthRoute;
