import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import { AuthRoutes } from "../../../app/auth/routes";

const AgreementMissingModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button content={t("shadowing.actions.applicate")} primary />}
    >
      <Modal.Header>{t("shadowing.agreement_modal.title")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>{t("shadowing.agreement_modal.text")}</p>
          <Link to={AuthRoutes.profile}>{t("shadowing.agreement_modal.link")}</Link>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default AgreementMissingModal;
