import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import { NonAuthRoutes } from "../../../../app/auth/routes";
import { PASSWORD_STRENGTH_REGEXP } from "../../../../app/helpers/regexp";
import AppForm from "../../../../components/form/Form";
import { useAppForm } from "../../../../components/form/useForm";
import { useNavigate } from "react-router-dom";

interface FormData {
  email: string;
  password: string;
  repeatedPassword: string;
}

interface Props {
  token: string;
}

const PasswordRecoveryConfirmationForm: React.FC<Props> = ({ token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formHook = useAppForm<FormData>({ defaultValues: { email: "", password: "", repeatedPassword: "" } });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!token) navigate("/");
  }, [navigate, token]);

  const onSubmit = async (data: FormData) => {
    agent.Users.passwordResetConfirm(data.email, data.password, token)
      .then(() => {
        navigate(NonAuthRoutes.login);
      })
      .catch(() => {
        setError(true);
      });
  };

  const passwordMatches = (repeatedPassword: string) => {
    return formHook.watch("password") === repeatedPassword ? true : t("auth.steps.password.validation.password_match");
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return <Message negative>{t("errors.general")}</Message>;
  };

  return (
    <Fragment>
      {renderError()}
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <AppForm.Group name="email" label="common.email">
          <AppForm.Email name="email" rules={{ required: true }} />
        </AppForm.Group>
        <AppForm.Group name="password" label="auth.password_reset_confirm.new_password">
          <AppForm.Password
            name="password"
            autoComplete="new-password"
            rules={{
              required: true,
              pattern: {
                value: PASSWORD_STRENGTH_REGEXP,
                message: t("auth.steps.password.validation.password_strenght"),
              },
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="repeatedPassword" label="auth.steps.password.confirm_password">
          <AppForm.Password name="repeatedPassword" rules={{ required: true, validate: passwordMatches }} />
        </AppForm.Group>
      </AppForm>
      <Button primary onClick={formHook.handleSubmit(onSubmit)}>
        {t("auth.password_reset.form.confirm")}
      </Button>
    </Fragment>
  );
};

export default PasswordRecoveryConfirmationForm;
