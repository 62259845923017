import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { BasicDetailsStepProps } from "../StudentRegistrationSteps";
import { genderTranslation } from "../../../../../../app/helpers/genderHelpers";
import { NAME_REGEXP, SURNAME_REGEXP } from "../../../../../../app/helpers/regexp";
import RegistrationActions from "../../RegistrationActions";
import { useAppForm } from "../../../../../../components/form/useForm";
import AppForm from "../../../../../../components/form/Form";

interface IProps {
  store: BasicDetailsStepProps;
  onSubmit: (data: BasicDetailsStepProps) => void;
  handlePreviousStepClick?: () => void;
  handleNextStepClick?: () => void;
}

type FormValues = {
  name: string;
  surname: string;
  email: string;
  gender: string;
  phoneNumber: string;
};

const stepInfo = {
  current: 2,
  total: 3,
};

const BasicDetailsStep: React.FC<IProps> = ({ onSubmit, store, handlePreviousStepClick, handleNextStepClick }) => {
  const { t } = useTranslation();
  const formHook = useAppForm<FormValues>({
    defaultValues: store,
  });

  const genderOptions = [
    { key: "M", value: "M", text: genderTranslation("M", t) },
    { key: "F", value: "F", text: genderTranslation("F", t) },
  ];

  const handleNextStep = (data: FormValues) => {
    onSubmit(data);
    handleNextStepClick && handleNextStepClick();
  };

  return (
    <>
      <AppForm formHook={formHook} onSubmit={handleNextStep}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <AppForm.Group name="name" label="auth.steps.basic.name">
                <AppForm.Input
                  name="name"
                  placeholder="auth.steps.basic.form_placeholders.name"
                  rules={{
                    required: true,
                    pattern: {
                      value: NAME_REGEXP,
                      message: "",
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <AppForm.Group name="surname" label="auth.steps.basic.surname">
                <AppForm.Input
                  name="surname"
                  placeholder="auth.steps.basic.form_placeholders.surname"
                  rules={{
                    required: true,
                    pattern: {
                      value: SURNAME_REGEXP,
                      message: "",
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="gender" label="auth.steps.basic.gender">
                <AppForm.Select name="gender" options={genderOptions} rules={{ required: true }} disableAutoSelect />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="email" label="auth.steps.basic.email">
                <AppForm.Email
                  name="email"
                  placeholder="auth.steps.basic.form_placeholders.email"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="phoneNumber" label="auth.steps.basic.phone">
                <AppForm.Phone name="phoneNumber" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>

      <RegistrationActions stepInfo={stepInfo}>
        {handleNextStepClick && (
          <Button primary content={t("auth.steps.next")} onClick={formHook.handleSubmit(handleNextStep)} />
        )}
        {handlePreviousStepClick && <Button basic content={t("auth.steps.back")} onClick={handlePreviousStepClick} />}
      </RegistrationActions>
    </>
  );
};

export default BasicDetailsStep;
