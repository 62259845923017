import { useEffect, useMemo, useState } from "react";
import agent from "../api/agent";

export type City = string;

const useCities = () => {
  const [cities, setCities] = useState<City[]>([]);

  useEffect(() => {
    agent.Cities.list().then(setCities);
  }, []);

  return useMemo(
    () => ({
      cities,
      cityOptions: cities.map((city) => ({ key: city, value: city, text: city })),
    }),
    [cities]
  );
};

export default useCities;
