import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import FormModal from "../../components/FormModal";
import useToaster from "../../app/hooks/useToaster";
import AppForm from "../../components/form/Form";
import { useAppForm } from "../../components/form/useForm";
import SchoolCreateForm from "./SchoolForm";
import { School, SchoolEditFormModel } from "./types";
import { HasRole } from "../../app/helpers/userHelpers";
import { UserRoles } from "../../app/auth/roles";
import { localStorageHelpers } from "../../app/helpers/localStorageHelpers";
import { Link } from "react-router-dom";
import agent from "../../app/api/agent";

interface Props {
  school: School;
  reloadSchools: () => void;
}

const SchoolEditModal: React.FC<Props> = ({ school, reloadSchools }) => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [isSaving, setSaving] = useState(false);

  const [open, setOpen] = useState(false);

  const isMunicipality = HasRole(UserRoles.municipality);
  const municipalityId = isMunicipality ? localStorageHelpers.getSubjectId() : "0";

  const formHook = useAppForm<SchoolEditFormModel>({ defaultValues: { ...school, municipalityId: municipalityId } });

  const handleSubmit = async (data: SchoolEditFormModel) => {
    setSaving(true);
    try {
      await agent.Schools.update(school.id, data);
      success("schools.updated");
      reloadSchools();
      setOpen(false);
    } catch (error) {
      apiError(error);
      reloadSchools();
    } finally {
      setSaving(false);
    }
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("common.actions.confirm")}
      </Button>
      <Button negative disabled={isSaving} onClick={() => setOpen(false)}>
        {t("common.actions.cancel")}
      </Button>
    </>
  );

  return (
    <FormModal
      title="schools.edit"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      isOpen={open}
      actions={actions}
      trigger={<Link to="#">{t("schools.edit")}</Link>}
    >
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <SchoolCreateForm isEditMode />
      </AppForm>
    </FormModal>
  );
};

export default SchoolEditModal;
