import { useEffect, useRef, useState } from "react";

const expandWhenScrollingUp = true;

const useScrollHeader = (treshold: number) => {
  const [isVisible, setVisible] = useState(true);
  const [isScrolled, setScrolled] = useState(false);
  const position = useRef(0);

  useEffect(() => {
    const onScroll = () => {
      const nextPosition = window.scrollY;
      const isScrollingUp = position.current - nextPosition > 0;
      if (isScrollingUp) {
        setVisible(expandWhenScrollingUp ? true : nextPosition < treshold);
      } else {
        setVisible(nextPosition < treshold);
      }
      if ((nextPosition === 0) === isScrolled) {
        setScrolled(nextPosition > 0);
      }
      position.current = nextPosition;
    };

    position.current = window.scrollY;
    onScroll();

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, [treshold, isScrolled]);

  return [isVisible, isScrolled];
};

export default useScrollHeader;
