import {
  ShadowingFilterActionTypes,
  UPDATE_INDUSTRY_FILTER,
  UPDATE_COMPANY_FILTER,
  UPDATE_SEARCH_FILTER,
  UPDATE_SORT_FILTER,
  SET_ACTIVE_PAGE,
  SET_HAS_APPLICATIONS,
  SET_PAST_APPLICATIONS,
  UPDATE_DATE_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_ONLINE_FILTER,
} from "../../app/store/types/shadowingFilter";

import { Dispatch } from "redux";
import { SORT_BY } from "./sort_options";
import { CLEAR_FILTERS } from "../../app/store/types/shadowingFilter";

export const updateShadowingOnlineFilter = (value: boolean) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_ONLINE_FILTER,
    value: value,
  });
};

export const updateShadowingCityFilter = (value: string) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_CITY_FILTER,
    selectedCity: value,
  });
};

export const updateShadowingIndustryFilter = (filter: number) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_INDUSTRY_FILTER,
    selectedId: filter,
  });
};

export const updateShadowingCompanyFilter = (filter: number) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_COMPANY_FILTER,
    selectedId: filter,
  });
};

export const updateShadowingSearchFilter = (searchText: string) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_SEARCH_FILTER,
    searchText: searchText,
  });
};

export const updateShadowingSortFilter = (sortBy: SORT_BY) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: UPDATE_SORT_FILTER,
    sortBy: sortBy,
  });
};

export const updateShadowingDateFilter =
  (payload?: { start: Date; end: Date }) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
    dispatch({
      type: UPDATE_DATE_FILTER,
      payload,
    });
  };

export const setShadowingActivePage = (activePage: number) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: SET_ACTIVE_PAGE,
    activePage: activePage,
  });
};

export const setHasApplicationsFilter =
  (hasApplications?: boolean) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
    dispatch({
      type: SET_HAS_APPLICATIONS,
      isActive: hasApplications,
    });
  };

export const setPastApplicationsFilter =
  (pastApplications?: boolean) => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
    dispatch({
      type: SET_PAST_APPLICATIONS,
      isActive: pastApplications,
    });
  };

export const clearFilters = () => (dispatch: Dispatch<ShadowingFilterActionTypes>) => {
  dispatch({
    type: CLEAR_FILTERS,
  });
};
