import React from "react";
import { useTranslation } from "react-i18next";
import TextBlock from "../../components/TextBlock";
import { BusinessRegistrationModel } from "./types";

interface Props {
  model: BusinessRegistrationModel;
}

const BusinessRegistrationSuccess: React.FC<Props> = ({ model }) => {
  const { t } = useTranslation();
  const email = model.contactInfo.email;

  return (
    <TextBlock
      title={t("auth.steps.business.success.title")}
      body={
        <>
          <p>{t("auth.steps.business.success.body")}</p>
          <p>
            {t("auth.steps.business.success.email")}
            <a href={"mailto:" + email}>{email}</a>
          </p>
        </>
      }
    />
  );
};

export default BusinessRegistrationSuccess;
