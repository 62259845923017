import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";
import SchoolReportsStatistics from "./SchoolReportsStatistics";
import SchoolReportsCompanyReflections from "./SchoolReportsCompanyReflections";
import SchoolReportsStudentFeedback from "./SchoolReportsStudentFeedback";
import SchoolReportsCompetencies from "./SchoolReportsCompetencies";

const SchoolReportsPage = () => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("reports.statistics"),
      render: () => <SchoolReportsStatistics />,
    },
    {
      menuItem: t("reports.competences"),
      render: () => <SchoolReportsCompetencies />,
    },
    {
      menuItem: t("reports.companyReflections"),
      render: () => <SchoolReportsCompanyReflections />,
    },
    {
      menuItem: t("reports.studentReflections"),
      render: () => <SchoolReportsStudentFeedback />,
    },
  ];

  return (
    <div className="reports-page">
      <Tab className="tabs" menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default SchoolReportsPage;
