import { useEffect, useMemo, useState } from "react";
import { CompanyBasic } from "../../features/companies/types";
import agent from "../api/agent";

const useCompanies = () => {
  const [companies, setCompanies] = useState<CompanyBasic[]>([]);

  useEffect(() => {
    agent.Companies.list().then(setCompanies);
  }, []);

  return useMemo(
    () => ({
      companies,
      companyOptions: companies.map((company) => ({ key: company.id, value: company.id, text: company.name })),
    }),
    [companies]
  );
};

export default useCompanies;
