import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";
import AdminReportsCompanyReflections from "./AdminReportsCompanyReflections";
import AdminReportsStatistics from "./AdminReportsStatistics";
import AdminReportsStudentFeedback from "./AdminReportsStudentFeedback";
import AdminReportsCompetencies from "./AdminReportsCompetencies";

const AdminReportsPage = () => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("reports.statistics"),
      render: () => <AdminReportsStatistics />,
    },
    {
      menuItem: t("reports.competences"),
      render: () => <AdminReportsCompetencies />,
    },
    {
      menuItem: t("reports.companyReflections"),
      render: () => <AdminReportsCompanyReflections />,
    },
    {
      menuItem: t("reports.studentReflections"),
      render: () => <AdminReportsStudentFeedback />,
    },
  ];

  return (
    <div className="reports-page">
      <Tab className="tabs" menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default AdminReportsPage;
