import React from "react";
import { NonAuthRoutes } from "../../../app/auth/routes";
import useIsLoggedIn from "../../../app/hooks/useIsLoggedIn";
import { Button } from "semantic-ui-react";
import "./styles.less";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePageTwoArticles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  return (
    <div className="homepage-two-articles">
      <article className="article">
        <img src="/assets/homepage/two-article-1.jpg" alt="" className="article__image" />
        <h2 className="article__title-sm">
          Esate viešojo sektoriaus ar verslo organizacija? Pasidalinkite savo patirtimi su moksleiviais!
        </h2>
        <p>
          Jei siekiate prisidėti prie jaunosios kartos (ir savo potencialių kolegų) ugdymo karjerai, įtraukti ir
          motyvuoti savo dabartinius kolegas bei gauti grįžtamąjį ryšį apie savo organizaciją, jau 2024 m. lapkričio
          11-15 dienomis kviečiame priimti moksleivius šešėliauti ir iš arčiau susipažinti su jūsų organizacija!
        </p>
        {!isLoggedIn && (
          <Button primary basic onClick={() => navigate(NonAuthRoutes.registration)}>
            {t("auth.registration")}
          </Button>
        )}
      </article>
      <article className="article">
        <img src="/assets/homepage/two-article-2.jpg" alt="" className="article__image" />
        <h2 className="article__title-sm">
          Esate mokytojas, karjeros specialistas ar savivaldybės atstovas? Prisijunkite prie LJA „Šešėliavimas“
          programos!
        </h2>
        <p>
          Jei siekiate padėti moksleiviams geriau pažinti save, susipažinti su skirtingomis profesijomis ir sėkmingai
          siekti savo karjeros tikslų, susisiekite su mūsų komanda dėl programos įgyvendinimo el. paštu{" "}
          <a href="mailto:ask@lja.lt">ask@lja.lt</a>.
        </p>
        <a className="ui basic primary button" href="mailto:ask@lja.lt">
          Susisiekti el. paštu
        </a>
      </article>
    </div>
  );
};

export default HomePageTwoArticles;
