/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "../ButtonLink";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props {
  placeholder?: string;
  accept?: string[]; //image/png, image/jpeg, image/*, .gif,
}

const AppFile: React.FC<PropsInjectedByController<File | null> & Props> = ({
  onBlur,
  onChange,
  placeholder = "common.file.placeholder",
  accept,
  isDisabled,
  value,
}) => {
  const { t } = useTranslation();

  const changeValue = (value: File | null) => {
    onChange(value);
    setTimeout(onBlur);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target;
    if (!input.files) {
      return changeValue(null);
    }

    const file = Array.from(input.files!)[0];
    changeValue(file);
  };

  const allowedExtensions = accept ? accept.join(",") : "";
  const filename = getFilename(value);
  const preview = useMemo(() => getPreview(value), [value]);

  const renderInput = () => {
    return <input disabled={isDisabled} type="file" accept={allowedExtensions} onChange={handleFileSelect} />;
  };

  const renderValue = () => (
    <>
      {preview && <div className="file-input__preview" style={{ backgroundImage: `url(${preview})` }} />}
      <div className="file-input__value">
        <div className="file-input__filename" title={filename}>
          {filename}
        </div>
      </div>
      <div className="file-input__actions">
        <label className="link" tabIndex={0} role="button">
          {renderInput()}
          {t("common.file.update")}
        </label>
        <ButtonLink type="button" onClick={() => changeValue(null)}>
          {t("common.file.delete")}
        </ButtonLink>
      </div>
    </>
  );

  const renderNoValue = () => {
    return (
      <label className="file-input__placeholder link">
        {renderInput()}
        {t(placeholder)}
      </label>
    );
  };

  return (
    <div className={"file-input " + (isDisabled ? "is-disabled" : "")}>{value ? renderValue() : renderNoValue()}</div>
  );
};

function getFilename(value: string | File | null): string {
  if (!value) {
    return "";
  }

  const file = value instanceof File ? value.name : "";
  const filename = file.split("/").pop()!;

  return filename;
}

function getPreview(value: string | File | null): string | null {
  if (value instanceof File) {
    return isImage(value.name) ? URL.createObjectURL(value) : null;
  }

  return value && isImage(value) ? value : null;
}

function isImage(file: string): boolean {
  return ["jpg", "jpeg", "png", "gif", "svg", "bmp"].includes(file.split("?")[0].split(".").pop()!.toLowerCase());
}

const AppFileController = withController(AppFile);

export { AppFileController };
export default AppFile;
