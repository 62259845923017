import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userHelpers } from "../../../app/helpers/userHelpers";
import { RootState } from "../../../app/store/configureStore";
import LoginPage from "../../user/components/login/LoginPage";
import MyPositionsPage from "./MyPositionsPage";

const OpenFeedback = () => {
  const navigate = useNavigate();
  const { shadowingId } = useParams<{ shadowingId: string }>();
  const { isLoggedIn } = useSelector((state: RootState) => state.currentUser);

  if (!isLoggedIn) {
    return <LoginPage returnUrl={`/open-feedback/${shadowingId}`} />;
  }

  if (!userHelpers.isCompany()) {
    navigate("/");
  }

  return <MyPositionsPage showFeedback={+shadowingId! || undefined} />;
};

export default OpenFeedback;
