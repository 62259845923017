import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReflectionReportModel } from "../../reflections/models";
import ReflectionReportContainer from "../../reflections/ReflectionReportContainer";
import agent from "../../../app/api/agent";
import { ReportFilterContext } from "../useReportFilter";

const MunicipalityReportsCompanyReflections = () => {
  const { t } = useTranslation();
  const { years, companies } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years, companies], [companies, years]);

  return (
    <>
      <PageTitle title={t("reports.companyReflections")} />
      <ReflectionReportContainer filterConfig={filters} dataResourceFn={dataResourceFn} />
    </>
  );
};

const dataResourceFn = (filter?: ListFilterValue): Promise<ReflectionReportModel> => {
  const params: { years?: number[]; companyIds?: number[]; schoolIds?: number[] } = {};
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map((x) => x.value);
  }
  if (Array.isArray(filter?.company) && filter?.company.length) {
    params.companyIds = filter.company.map((x) => x.value);
  }

  return agent.Reports.municipalityReflections(params);
};

export default MunicipalityReportsCompanyReflections;
