import React from "react";
import { useTranslation } from "react-i18next";
import Multiline from "../../components/Multiline";
import { BooleanQuestion, Question, QuestionType, ReflectionFormModel } from "./models";

interface Props {
  model: ReflectionFormModel;
  questions: Question[];
}

const defaultBooleanOptions: BooleanQuestion["options"] = {
  true: "common.yes",
  false: "common.no",
};

const ReflectionPreview = ({ model, questions }: Props) => {
  const { t } = useTranslation();

  const getAnswer = (value: any, question: Question): React.ReactNode => {
    if (value == null) {
      return "-";
    }
    switch (question.type) {
      case QuestionType.Boolean: {
        const options = question.options ?? defaultBooleanOptions;
        const answer = String(!!Number(value)) as "true" | "false";
        return t(options[answer]);
      }
      case QuestionType.Points: {
        return (
          <>
            <strong className="reflection-preview__point">{value}</strong> / 10
          </>
        );
      }
      case QuestionType.Radio: {
        return question.options.find((x) => x.value == (value as any))!.title;
      }
      case QuestionType.Textbox: {
        return value ? <Multiline value={value} /> : "-";
      }
      default: {
        return "-";
      }
    }
  };

  return (
    <div className="reflection-preview-list">
      {questions.map((q, i) => (
        <div key={q.id} className={`reflection-preview reflection-preview--${q.type}`}>
          <h3 className="reflection-preview__title">
            {i + 1}. {q.title}
          </h3>
          <div className="reflection-preview__answer">{getAnswer(model[q.id], q)}</div>
        </div>
      ))}
    </div>
  );
};

export default ReflectionPreview;
