import React from "react";
import { RadioQuestion, ReflectionReportModelRadioValue } from "./models";
import ReportOptionValue from "./ReportOptionValue";

interface Props {
  question: RadioQuestion;
  value: ReflectionReportModelRadioValue;
}

const ReflectionReportRadio = ({ question, value }: Props) => {
  const getBadge = (option: RadioQuestion["options"][0]) => {
    const optionValue = value.answers.find((x) => x.answer === option.value);

    return (
      <ReportOptionValue answersCount={optionValue?.answersCount ?? 0} percentage={optionValue?.percentage ?? 0} />
    );
  };

  return (
    <ul className="reflection-view__list">
      {question.options.map((option, i) => (
        <li key={i}>
          {option.title} {getBadge(option)}
        </li>
      ))}
    </ul>
  );
};

export default ReflectionReportRadio;
