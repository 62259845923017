import React from "react";
import Datepicker, { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";
import { createDateTime, getTime } from "../../app/helpers/dateHelpers";

interface Props extends Omit<ReactDatePickerProps, "placeholderText" | "selected" | "onChange"> {
  placeholder?: string;
  value?: string;
}

const currentDate = new Date().toISOString();

const AppTime: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  value,
  placeholder = "common.time_placeholder",
  isDisabled,
  isValid,
  ...props
}) => {
  const { t } = useTranslation();
  const selected = value && new Date(createDateTime(currentDate, value));

  return (
    <Datepicker
      disabled={isDisabled}
      locale="lt"
      calendarClassName="react-timepicker"
      timeCaption={t("common.time")}
      showTimeSelect
      showTimeSelectOnly
      popperPlacement="bottom-start"
      timeFormat="p"
      dateFormat="p"
      timeIntervals={30}
      selected={selected}
      {...props}
      placeholderText={placeholder && t(placeholder)}
      onChange={(value) => onChange(value instanceof Date ? getTime(value) : value)}
    />
  );
};

const AppTimeController = withController(AppTime);

export { AppTimeController };
export default AppTime;
