import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import CompetenciesGraph from "../../../components/competencies/CompetenciesGraph";
import PageTitle from "../../../components/page-title/PageTitle";
import { Reflections } from "../../reflections/models";
import MyCompetenceTable from "./MyCompetenceTable";
import { CompetenciesModel } from "../../../components/competencies/models";
import { getCurrentAcademicYearStart } from "../../../app/helpers/dateHelpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import InitialReflectionModal from "../../reflections/InitialReflectionModal";

const buildFilterOptions = () => {
  const startYear = 2019;
  const options = [];

  for (let index = startYear; index <= getCurrentAcademicYearStart(); index++) {
    options.unshift({
      key: index,
      text: (
        <h4>
          {index} - {index + 1}
        </h4>
      ),
      value: index,
      content: `${index} - ${index + 1}`,
    });
  }

  return options;
};

const MyCompetencies = () => {
  const { t } = useTranslation();
  const [reflections, setReflections] = useState<Record<string, Reflections>>({});
  const [otherQuestions, setOtherQuestions] = useState<Record<string, Reflections>>({});

  const filterOptions = buildFilterOptions();
  const [currentYearFilter, setCurrentYearFilter] = useState(filterOptions[0].value);
  const needToFillReflection = useSelector((state: RootState) => !!state.currentUser.needToFillReflection);
  const [isReflectionModalClosed, closeReflectionModal] = useState(false);

  useEffect(() => {
    agent.ApplicationReflections.getAll(currentYearFilter).then((response) => {
      setReflections(response);
    });

    agent.ApplicationReflections.getTextQuestions(currentYearFilter).then((response) => {
      setOtherQuestions(response);
    });
  }, [currentYearFilter]);

  const competencies: CompetenciesModel = useMemo(
    () =>
      Object.values(reflections).reduce(
        (acc, value, i) => ({
          ...acc,
          [i === 0 ? "before" : "after"]: Object.values(value).map((v) => ({ questionId: 0, value: v })),
        }),
        {} as CompetenciesModel
      ),
    [reflections]
  );

  const handleFilterChange = (_: any, { value }: any) => {
    setCurrentYearFilter(value);
  };

  return (
    <>
      <PageTitle title={t("my_competencies.page_title")} />
      <div>
        {t("reflection.academic_year")}:{" "}
        <Dropdown options={filterOptions} value={currentYearFilter} onChange={handleFilterChange} />
      </div>
      <br />
      {Object.values(competencies).length > 0 ? (
        <>
          <CompetenciesGraph data={competencies} />
          <MyCompetenceTable reflections={otherQuestions} />
        </>
      ) : (
        <span>{t("common.no_records")}</span>
      )}
      {needToFillReflection && !isReflectionModalClosed && (
        <InitialReflectionModal onCancel={() => closeReflectionModal(true)} />
      )}
    </>
  );
};

export default MyCompetencies;
