import React from "react";
import { useTranslation } from "react-i18next";
import { Loader as SemanticLoader } from "semantic-ui-react";

const Loader = () => {
  const { t } = useTranslation();

  return <SemanticLoader active>{t("common.loading")}</SemanticLoader>;
};

export default Loader;
