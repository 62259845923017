import React from "react";
import Multiline from "../../components/Multiline";

interface Props {
  answer: string | { name: string; value: string };
}

const ReflectionReportTextAnswer = ({ answer }: Props) => {
  if (typeof answer === "string") {
    return <Multiline value={answer} />;
  }
  return (
    <>
      <strong>{answer.name}</strong> <Multiline value={answer.value} />
    </>
  );
};

export default ReflectionReportTextAnswer;
