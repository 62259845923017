import React, { PropsWithChildren, useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ShadowingCreateFormModel, ShadowingEditFormModel } from "./types";
import ShadowingForm from "./ShadowingForm";
import AppForm from "../../components/form/Form";
import agent from "../../app/api/agent";
import { getUpdateRequestPayload } from "./utils";
import useEventBus from "../../app/hooks/useEventBus";
import useToaster from "../../app/hooks/useToaster";
import { Shadowing } from "../shadowings/types";
import FormModal from "../../components/FormModal";
import { useAppForm } from "../../components/form/useForm";
import { getTime } from "../../app/helpers/dateHelpers";

interface Props {
  shadowing: Shadowing;
}

const ShadowingEditModal: React.FC<PropsWithChildren<Props>> = ({ children: trigger, shadowing }) => {
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const { emit } = useEventBus();
  const { success, apiError } = useToaster();
  const defaultValues: ShadowingEditFormModel = {
    ...shadowing,
    visitDuration: shadowing.visitDuration ?? null!,
    useHqAddress: !shadowing.city && !shadowing.address,
    time: getTime(shadowing.date),
    industryIds: shadowing.industryIds ?? [],
  };
  const formHook = useAppForm({ defaultValues });

  const isDisabled = isSaving;

  const handleSubmit = async (model: ShadowingCreateFormModel | ShadowingEditFormModel) => {
    setSaving(true);

    try {
      await agent.Shadowings.update(shadowing.id, getUpdateRequestPayload(model));
      success("shadowing.successfully_updated");
      emit("shadowingUpdated", shadowing.id);
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = async () => {
    setShowConfirmation(false);
    setSaving(true);

    try {
      await agent.Shadowings.cancel(shadowing.id);
      success("shadowing.successfully_canceled");
      emit("shadowingCanceled", shadowing.id);
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isDisabled} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("shadowing.update_button")}
      </Button>
      <Button negative disabled={isDisabled} onClick={() => setOpen(false)}>
        {t("common.actions.cancel")}
      </Button>
      <Button color="red" disabled={isDisabled} onClick={() => setShowConfirmation(true)}>
        {t("shadowing.cancel_button")}
      </Button>
      <Confirm
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size="tiny"
        content={t("shadowing.cancel_confirmation.text")}
        open={showConfirmation}
        confirmButton={t("shadowing.cancel_confirmation.yes")}
        cancelButton={t("shadowing.cancel_confirmation.no")}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleCancel}
      />
    </>
  );

  return (
    <FormModal
      title="shadowing.edit_position"
      {...{ isDisabled, isOpen, actions, trigger }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <p>{t("shadowing.edit_help")}</p>
      <AppForm formHook={formHook} isDisabled={isDisabled} onSubmit={handleSubmit}>
        <ShadowingForm isEditMode />
      </AppForm>
    </FormModal>
  );
};

export default ShadowingEditModal;
