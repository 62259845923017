import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import RegistrationActions from "../user/components/registration/RegistrationActions";
import { SchoolRegistrationContext } from "./SchoolRegistration";

interface Props {
  onSubmit: (...args: any[]) => any;
}

const SchoolRegistrationActions = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const { steps, currentStep, handleBack, isDisabled, isCreating } = useContext(SchoolRegistrationContext);

  const stepInfo = {
    current: steps.indexOf(currentStep) + 1,
    total: steps.length,
  };

  return (
    <RegistrationActions stepInfo={stepInfo}>
      <Button primary disabled={isDisabled} loading={isCreating} onClick={onSubmit}>
        {t(stepInfo.current === stepInfo.total ? "auth.steps.complete" : "auth.steps.next")}
      </Button>
      <Button basic disabled={isDisabled} onClick={handleBack}>
        {t("auth.steps.back")}
      </Button>
    </RegistrationActions>
  );
};

export default SchoolRegistrationActions;
