import React, { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Municipality } from "../types";
import { useAppForm } from "../../../components/form/useForm";
import { useNavigate } from "react-router-dom";

interface IFormData {
  name: string;
  contactEmail: string;
  contactFullName: string;
  phone: string;
}

const MunicipalityForm = () => {
  const [options, setOptions] = useState<string[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    agent.Municipalities.options().then((response) => {
      setOptions(response);
    });
  }, []);

  const onSubmit = (data: IFormData) => {
    const municipality: Municipality = {
      id: 0,
      name: data.name,
      isActive: true,
      contactInfo: {
        fullName: data.contactFullName,
        phoneNumber: data.phone,
        email: data.contactEmail,
      },
    };

    agent.Municipalities.create(municipality).then(() => {
      navigate("/municipalities");
    });
  };

  const { register, handleSubmit } = useAppForm<IFormData>({});

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="attached segment">
        <Form.Field>
          <label>{t("common.municipality")}</label>
          <select {...register("name", { required: true })}>
            <option value="" hidden>
              {t("municipalities.form.placeholder.select_municipality")}
            </option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </Form.Field>
        <h4>{t("common.contact_info")}</h4>
        <Form.Group widths={3}>
          <Form.Field>
            <label>{t("common.fullName")}</label>
            <input placeholder="Vardenis Pavardenis" {...register("contactFullName", { required: true })} />
          </Form.Field>
          <Form.Field>
            <label>{t("common.email")}</label>
            <input placeholder="vardenis.pavardenis@gmail.com" {...register("contactEmail", { required: true })} />
          </Form.Field>
          <Form.Field>
            <label>{t("common.phone")}</label>
            <input placeholder="+37062688123" {...register("phone", { required: true })} />
          </Form.Field>
        </Form.Group>
        <Form.Button>{t("common.actions.confirm")}</Form.Button>
      </Form>
      <Message
        icon="warning"
        attached="bottom"
        warning
        header={t("municipalities.form.new_user_warning.header")}
        content={t("municipalities.form.new_user_warning.body")}
      />
    </div>
  );
};

export default MunicipalityForm;
