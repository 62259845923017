import React from "react";
import { Pagination as SemanticPagination, Icon } from "semantic-ui-react";

interface IProps {
  totalPages: number;
  pageNumber: number;
  handlePageChange(page: number): void;
}
const Pagination: React.FC<IProps> = ({ totalPages, pageNumber, handlePageChange }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <SemanticPagination
        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
        firstItem={null}
        lastItem={null}
        prevItem={null}
        nextItem={null}
        totalPages={totalPages}
        activePage={pageNumber}
        onPageChange={(_, { activePage }) => handlePageChange(Number(activePage))}
        secondary
      />
    </div>
  );
};

export default Pagination;
