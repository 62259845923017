import React, { Fragment } from "react";
import { StudentApplicationToApprove } from "../types";
import { useTranslation } from "react-i18next";
import { Table, Segment, Button } from "semantic-ui-react";
import GenderImage from "../../../components/GenderImage";

interface IProps {
  pendingApplications: StudentApplicationToApprove[];
  handleApproveApplication: (studentId: number, applicationId: number) => void;
}

const PendingApplications: React.FC<IProps> = ({ pendingApplications, handleApproveApplication }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Segment placeholder padded className="pending-registrations-segment">
        <div className="pending-registrations">
          <p>{t("pending_applications_list.description")}</p>
          <div className="pending-registrations-text">
            {t("pending_applications_list.title")}: {pendingApplications.length}
          </div>
          <Table basic="very" className="student-list pending">
            <Table.Body>
              {pendingApplications.map((student: StudentApplicationToApprove) => (
                <Table.Row key={student.applicationId}>
                  <Table.Cell>
                    <GenderImage gender={student.gender} />
                    {student.studentFullName}, {student.grade} kl.
                  </Table.Cell>
                  <Table.Cell>{student.positionCompany}</Table.Cell>
                  <Table.Cell>{student.positionTitle}</Table.Cell>
                  <Table.Cell>
                    {t("pending_applications_list.shadowing_date")}: {student.positionDate}
                  </Table.Cell>
                  <Table.Cell className="student-list-actions" singleLine>
                    <Button
                      primary
                      content={t("pending_applications_list.actions.approve")}
                      onClick={() => handleApproveApplication(student.id, student.applicationId)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </Fragment>
  );
};

export default PendingApplications;
