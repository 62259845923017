import { AgreementInfo, SeasonInfo } from "../../../features/students/types";
import { User } from "../../../features/user/types";

export interface UserState {
  id: string;
  email: string;
  token: string;
  isLoggedIn: boolean;
  status: string;
  roles: string[];
  subjectId: string;
  seasonInfo?: SeasonInfo;
  needToFillReflection?: boolean; // student only
  needToUpdateProfile?: boolean;
  agreement?: AgreementInfo;
}

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_FAIL = "LOGIN_FAIL";

interface GetCurrentUser {
  type: typeof GET_CURRENT_USER;
  user: User;
}

interface LoginUser {
  type: typeof LOGIN_USER;
  user: User;
}
interface UpdateUser {
  type: typeof UPDATE_USER;
  payload: Partial<UserState>;
}

interface FailLogin {
  type: typeof LOGIN_FAIL;
  status: string;
}

interface LogoutUser {
  type: typeof LOGOUT_USER;
}

export type UserActionTypes = GetCurrentUser | UpdateUser | LoginUser | LogoutUser | FailLogin;
