import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import "semantic-ui-less/semantic.less";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./app/store/configureStore";
import "./i18n/config";
import { ToastContainer } from "react-toastify";
import { Loader } from "semantic-ui-react";
import { createRoot } from "react-dom/client";

// react-datepicker configuration
import lt from "date-fns/locale/lt";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { BrowserRouter } from "react-router-dom";
registerLocale("lt", lt as any);
setDefaultLocale("lt");

const store = configureStore();
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Loader active>Kraunasi...</Loader>}>
        <App />
      </Suspense>
    </Provider>
    <ToastContainer />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
