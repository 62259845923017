import React from "react";
import { ReflectionReportModel } from "./models";
import "./reflection-view-styles.less";
import ReflectionReportList from "./ReflectionReportList";
import { ListFilterConfig, ListFilterValue } from "../../components/list-filter/models";
import { Grid } from "semantic-ui-react";
import ListFilter from "../../components/list-filter/ListFilter";
import Loader from "../../components/Loader";

interface Props {
  isLoading: boolean;
  values: ReflectionReportModel;
  filterConfig?: ListFilterConfig;
  filterValue?: ListFilterValue;
  onFilterChange?: (value: ListFilterValue) => void;
}

const ReflectionReport = ({ isLoading, values, filterConfig, filterValue, onFilterChange }: Props) => {
  const renderFilter = () =>
    filterConfig && (
      <Grid className="list-filter">
        <ListFilter config={filterConfig} value={filterValue!} onChange={onFilterChange!} />
      </Grid>
    );

  return (
    <>
      {renderFilter()}
      <div className="block block--mh">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ReflectionReportList values={values} />
          </>
        )}
      </div>
    </>
  );
};

export default ReflectionReport;
