import React from "react";
import { escape as escapeHTML } from "lodash-es";

interface Props {
  value: string;
}

const Multiline = ({ value }: Props) => {
  const html = addLineBreaks(value);

  return <p dangerouslySetInnerHTML={{ __html: html }}></p>;
};

function addLineBreaks(value: string): string {
  const html = escapeHTML(value).replace(/\r\n|\r|\n/g, "<br>");

  return removeRepeatedLineBreaks(html);
}

function removeRepeatedLineBreaks(value: string): string {
  const newValue = value.replace(/<br>\s*<br>/g, "<br>");
  return newValue === value ? value : removeRepeatedLineBreaks(newValue);
}

export default Multiline;
