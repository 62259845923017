import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import useToaster from "../../../../app/hooks/useToaster";
import FormModal from "../../../../components/FormModal";
import PasswordChangeForm, { PasswordChangeFormData } from "./PasswordChangeForm";

const PasswordChangeModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { apiError, success } = useToaster();

  const handleSubmit = (data: PasswordChangeFormData) => {
    agent.Users.changePassword(data.currentPassword, data.newPassword)
      .then(() => {
        success(t("auth.notifications.success.password_changed"));
        setOpen(false);
      })
      .catch((error) => {
        apiError(error);
      });
  };

  return (
    <FormModal
      title="my_profile.profile_edit"
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Link to="#">{t("my_profile.change_password")}</Link>}
    >
      <Modal.Description>
        <PasswordChangeForm handleSubmit={handleSubmit} />
      </Modal.Description>
    </FormModal>
  );
};

export default PasswordChangeModal;
