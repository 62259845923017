import React, { useState, Fragment } from "react";
import SchoolSelectionStep from "./steps/SchoolSelectionStep";
import agent from "../../../../../app/api/agent";
import BasicDetailsStep from "./steps/BasicDetailsStep";
import PasswordStep from "./steps/PasswordStep";
import SuccessStep from "./steps/SuccessStep";
import { StudentRegistrationModel } from "../../../../students/types";
import useToaster from "../../../../../app/hooks/useToaster";

interface Props {
  onBackToRegistration: () => void;
}

enum Step {
  SCHOOL,
  BASIC,
  PASSWORD,
  SUCCESS,
}

export interface RegistrationStore {
  schoolStep: SchoolStepProps;
  basicDetailsStep: BasicDetailsStepProps;
  passwordStep: PasswordStepProps;
}

export interface SchoolStepProps {
  municipality: string;
  schoolId: any;
  grade: string;
  careerCoordinatorFullName: string;
}

export interface BasicDetailsStepProps {
  name: string;
  surname: string;
  email: string;
  gender: string;
  phoneNumber: string;
}

export interface PasswordStepProps {
  repeatedPassword: string;
  password: string;
}

const Steps = [Step.SCHOOL, Step.BASIC, Step.PASSWORD, Step.SUCCESS];

const StudentRegistrationSteps: React.FC<Props> = ({ onBackToRegistration: handleBackToRegistration }) => {
  const [activeStep, setActiveStep] = useState<Step>(Step.SCHOOL);

  const [store, setStore] = useState<RegistrationStore>({
    schoolStep: { municipality: "", schoolId: "", grade: "", careerCoordinatorFullName: "" },
    basicDetailsStep: { name: "", surname: "", email: "", gender: "", phoneNumber: "" },
    passwordStep: { password: "", repeatedPassword: "" },
  });

  const [isSaving, setSaving] = useState(false);
  const { apiError } = useToaster();

  const onSubmitSchoolStep = (data: SchoolStepProps) => {
    setStore({
      ...store,
      schoolStep: data,
    });
  };

  const onSubmitBasicDetailsStep = (data: BasicDetailsStepProps) => {
    setStore({
      ...store,
      basicDetailsStep: data,
    });
  };

  const onSubmitPasswordStep = async (data: PasswordStepProps) => {
    setStore({
      ...store,
      passwordStep: data,
    });

    setSaving(true);

    const registrationModel: StudentRegistrationModel = {
      ...store.schoolStep,
      ...store.basicDetailsStep,
      ...data,
    };

    try {
      await agent.Students.register(registrationModel);
      setActiveStep(Steps[activeStep + 1]);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const renderStep = () => {
    switch (activeStep) {
      case Step.SCHOOL:
        return (
          <SchoolSelectionStep
            onSubmit={onSubmitSchoolStep}
            handlePreviousStepClick={handlePreviousStepClick}
            handleNextStepClick={handleNextStepClick}
            store={store.schoolStep}
          />
        );
      case Step.BASIC:
        return (
          <BasicDetailsStep
            onSubmit={onSubmitBasicDetailsStep}
            handlePreviousStepClick={handlePreviousStepClick}
            handleNextStepClick={handleNextStepClick}
            store={store.basicDetailsStep}
          />
        );
      case Step.PASSWORD:
        return (
          <PasswordStep
            onSubmit={onSubmitPasswordStep}
            handlePreviousStepClick={handlePreviousStepClick}
            store={store.passwordStep}
            isSaving={isSaving}
          />
        );
      case Step.SUCCESS:
        return <SuccessStep />;
      default:
        return null;
    }
  };

  const handleNextStepClick = () => {
    setActiveStep(Steps[activeStep + 1]);
  };

  const handlePreviousStepClick = () => {
    if (activeStep === Steps[0]) {
      handleBackToRegistration();
    }

    setActiveStep(Steps[activeStep - 1]);
  };

  return <Fragment>{renderStep()}</Fragment>;
};

export default StudentRegistrationSteps;
