import React, { createContext, useState } from "react";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import { SchoolRegistrationModel, SchoolRegistrationStep } from "./types";
import SchoolRegistrationSuccess from "./SchoolRegistrationSuccess";
import SchoolRegistrationPasswordStep from "./steps/PasswordStep";
import SchoolRegistrationContactStep from "./steps/ContactStep";

interface Props {
  onBackToRegistration: () => void;
}

const steps = [SchoolRegistrationStep.Contacts, SchoolRegistrationStep.Password];

export const SchoolRegistrationContext = createContext<{
  steps: SchoolRegistrationStep[];
  currentStep: SchoolRegistrationStep;
  handleBack: () => void;
  isDisabled: boolean;
  isCreating: boolean;
}>(null!);

const SchoolRegistration = ({ onBackToRegistration: handleBackToRegistration }: Props) => {
  const [currentStep, setCurrentStep] = useState<SchoolRegistrationStep>(SchoolRegistrationStep.Contacts);
  const [model, setModel] = useState<Partial<SchoolRegistrationModel>>({});
  const { apiError } = useToaster();
  const [isCreating, setCreating] = useState(false);
  const isDisabled = isCreating;

  const handleSubmit = (data: Partial<SchoolRegistrationModel>) => {
    setModel((m) => ({ ...m, ...data }));

    if (currentStep === SchoolRegistrationStep.Password) {
      register({ ...model, ...data } as SchoolRegistrationModel);
    } else {
      setCurrentStep(getNextStep(currentStep));
    }
  };

  const register = async (value: SchoolRegistrationModel) => {
    setCreating(true);

    try {
      await agent.Schools.register(value);
      setCurrentStep(SchoolRegistrationStep.Success);
    } catch (error) {
      apiError(error);
    } finally {
      setCreating(false);
    }
  };

  const handleBack = () => {
    if (currentStep === steps[0]) {
      handleBackToRegistration();

      return;
    }
    setCurrentStep(getPreviousStep(currentStep));
  };

  const renderStep = () => {
    switch (currentStep) {
      case SchoolRegistrationStep.Contacts:
        return <SchoolRegistrationContactStep onSubmit={handleSubmit} model={model} />;
      case SchoolRegistrationStep.Password:
        return <SchoolRegistrationPasswordStep onSubmit={handleSubmit} model={model} isDisabled={isDisabled} />;
      case SchoolRegistrationStep.Success:
        return <SchoolRegistrationSuccess model={model as SchoolRegistrationModel} />;
    }
  };

  return (
    <SchoolRegistrationContext.Provider value={{ steps, currentStep, handleBack, isDisabled, isCreating }}>
      {renderStep()}
    </SchoolRegistrationContext.Provider>
  );
};

function getNextStep(step: SchoolRegistrationStep) {
  const index = steps.indexOf(step);

  return steps[index + 1];
}

function getPreviousStep(step: SchoolRegistrationStep) {
  const index = steps.indexOf(step);

  return steps[index - 1];
}

export default SchoolRegistration;
