import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { SchoolStepProps } from "../StudentRegistrationSteps";
import RegistrationActions from "../../RegistrationActions";
import { useAppForm } from "../../../../../../components/form/useForm";
import AppForm from "../../../../../../components/form/Form";
import useSchools from "../../../../../../app/hooks/useSchools";
import useGrades from "../../../../../../app/hooks/useGrades";
import { FULLNAME_REGEXP } from "../../../../../../app/helpers/regexp";

interface IProps {
  store: SchoolStepProps;
  onSubmit: (data: SchoolStepProps) => void;
  handlePreviousStepClick?: () => void;
  handleNextStepClick?: () => void;
}

type FormValues = {
  grade: string;
  schoolId: string;
  municipality: string;
  careerCoordinatorFullName: string;
};

const stepInfo = {
  current: 1,
  total: 3,
};

const SchoolSelectionStep: React.FC<IProps> = ({ onSubmit, handlePreviousStepClick, handleNextStepClick, store }) => {
  const { t } = useTranslation();

  const formHook = useAppForm({
    defaultValues: store,
  });

  const { schoolOptions, isLoading: isLoadingSchools } = useSchools();

  const { gradeOptions } = useGrades();

  const handleNextStep = (data: FormValues) => {
    onSubmit(data);
    handleNextStepClick && handleNextStepClick();
  };

  return (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11}>
              <AppForm.Group name="schoolId" label="auth.steps.school.select_school">
                <AppForm.Select
                  name="schoolId"
                  search
                  disableAutoSelect
                  options={schoolOptions}
                  rules={{ required: true }}
                  isLoading={isLoadingSchools}
                  disabled={isLoadingSchools}
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={5}>
              <AppForm.Group name="grade" label="auth.steps.school.enter_grade">
                <AppForm.Select name="grade" options={gradeOptions} rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="careerCoordinatorFullName" label="auth.steps.basic.career_coordinator_fullname">
                <AppForm.Input
                  name="careerCoordinatorFullName"
                  placeholder="auth.steps.basic.form_placeholders.fullname"
                  rules={{
                    required: true,
                    pattern: {
                      value: FULLNAME_REGEXP,
                      message: "",
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>

      <RegistrationActions stepInfo={stepInfo}>
        {handleNextStepClick && (
          <Button primary content={t("auth.steps.next")} onClick={formHook.handleSubmit(handleNextStep)} />
        )}
        {handlePreviousStepClick && <Button basic content={t("auth.steps.back")} onClick={handlePreviousStepClick} />}
      </RegistrationActions>
    </>
  );
};

export default SchoolSelectionStep;
