import React from "react";
import {
  Question,
  QuestionType,
  ReflectionReportModelBooleanValue,
  ReflectionReportModelPointValue,
  ReflectionReportModelRadioValue,
  ReflectionReportModelTextValue,
  ReflectionReportModelValue,
} from "./models";
import ReflectionReportBoolean from "./ReflectionReportBoolean";
import ReflectionReportPoints from "./ReflectionReportPoints";
import ReflectionReportRadio from "./ReflectionReportRadio";
import ReflectionReportText from "./ReflectionReportText";

interface Props {
  question: Question;
  value: ReflectionReportModelValue;
}

const ReflectionReportItem = ({ question, value }: Props) => {
  const renderReflectionReport = (question: Question, value: ReflectionReportModelValue) => {
    switch (question.type) {
      case QuestionType.Boolean:
        return <ReflectionReportBoolean question={question} value={value as ReflectionReportModelBooleanValue} />;
      case QuestionType.Points:
        return <ReflectionReportPoints question={question} value={value as ReflectionReportModelPointValue} />;
      case QuestionType.Radio:
        return <ReflectionReportRadio question={question} value={value as ReflectionReportModelRadioValue} />;
      case QuestionType.Textbox:
        return <ReflectionReportText question={question} value={value as ReflectionReportModelTextValue} />;
      default:
        return null;
    }
  };

  return (
    <div className={`reflection-view__item reflection-view__item--${question.type}`} key={question.id}>
      <h3 className="reflection-view__title">{question.title}</h3>
      {renderReflectionReport(question, value)}
    </div>
  );
};

export default ReflectionReportItem;
