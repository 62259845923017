import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import { isExistingReflection } from "../../../app/helpers/reflections";
import { Question, ReflectionReportModel, Reflections } from "../../reflections/models";
import { reflectionQuestions } from "../../reflections/reflection-questions";
import ReflectionReportPreviewModal from "../../reflections/ReflectionReportPreviewModal";
import ReflectionWriteModal from "../../reflections/ReflectionWriteModal";

const questions: Question[] = [23, 24, 25, 26].map((x) => reflectionQuestions[x]);

export const CompanyFeedbackButton = () => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState<ReflectionReportModel | undefined>(undefined);
  const isLoading = !feedback;

  useEffect(() => {
    loadFeedback();
  }, []);

  const loadFeedback = async () => {
    setFeedback((await agent.ApplicationReflections.getCompanyFeedback()) || {});
  };

  const saveFeedback = async (feedback: Reflections) => {
    await agent.ApplicationReflections.writeCompanyFeedback(feedback);
    await loadFeedback();
  };

  if (isLoading) {
    return (
      <Button basic disabled>
        {t("reflection.write_feedback_as_company")}
      </Button>
    );
  }

  if (isExistingReflection(feedback)) {
    return (
      <ReflectionReportPreviewModal title="reflection.feedback_modal_title" values={feedback}>
        <Button basic>{t("reflection.read_writen_feedback_as_company")}</Button>
      </ReflectionReportPreviewModal>
    );
  } else {
    return (
      <ReflectionWriteModal
        title="reflection.feedback_modal_title"
        questions={questions}
        successMessage="reflection.feedback_success"
        onSave={(value) => saveFeedback(value)}
      >
        <Button basic>{t("reflection.write_feedback_as_company")}</Button>
      </ReflectionWriteModal>
    );
  }
};
