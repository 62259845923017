import { combineReducers } from "redux";
import shadowingsReducer from "../../features/shadowings/reducer";
import companiesReducer from "../../features/companies/reducer";
import currentUserReducer from "../../features/user/reducer";
import shadowingFilterReducer from "../../features/shadowingsFilter/reducer";

const rootReducer = combineReducers({
  shadowings: shadowingsReducer,
  companies: companiesReducer,
  currentUser: currentUserReducer,
  shadowingsFilter: shadowingFilterReducer,
});

export default rootReducer;
