import { createStore, applyMiddleware, Action } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default () => {
  return store;
};
