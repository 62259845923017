import React, { useEffect, useState, Fragment } from "react";
import SchoolList from "./SchoolList";
import agent from "../../app/api/agent";
import { School } from "./types";
import PageTitle from "../../components/page-title/PageTitle";
import { useTranslation } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import PagePhotos from "../../components/page-photos/PagePhotos";
import Loader from "../../components/Loader";
import SchoolCreateModal from "./SchoolCreateModal";
import {
  ListFilterConfig,
  ListFilterConfigItemMultiSelectOption,
  ListFilterConfigItemType,
} from "../../components/list-filter/models";
import ListFilter from "../../components/list-filter/ListFilter";
import useMunicipalities from "../../app/hooks/useMunicipalities";
import { UserRoles } from "../../app/auth/roles";
import { HasRole } from "../../app/helpers/userHelpers";
import SchoolPendingRegistrations from "./SchoolPendingRegistrations";

const SchoolDashboard: React.FC = () => {
  const { t } = useTranslation();
  const [schools, setSchools] = useState<School[]>([]);
  const [isLoading, setLoading] = useState(false);
  const canCreate = HasRole(UserRoles.admin);
  const canApprove = HasRole(UserRoles.admin);

  const [filter, setFilter] = useState<{
    municipalities: ListFilterConfigItemMultiSelectOption[];
    search: string;
    hadTraining: ListFilterConfigItemMultiSelectOption[];
  }>({
    municipalities: [],
    search: "",
    hadTraining: [],
  });

  const { municipalityOptions } = useMunicipalities();

  const filterConfig: ListFilterConfig = [
    {
      name: "municipalities",
      title: "companies.filter.municipality",
      type: ListFilterConfigItemType.MultiSelect,
      options: municipalityOptions,
      accessRole: [UserRoles.admin],
    },
    {
      name: "hadTraining",
      title: "school_filter_tab.trainingStatus.title",
      type: ListFilterConfigItemType.MultiSelect,
      options: [
        { key: 1, value: "true", text: t("school_filter_tab.trainingStatus.onlyTrained") },
        { key: 2, value: "false", text: t("school_filter_tab.trainingStatus.onlyNotTrained") },
      ],
      accessRole: [UserRoles.admin],
    },
    {
      name: "search",
      title: "schools.search",
      type: ListFilterConfigItemType.Search,
    },
  ];

  const loadSchools = () => {
    setLoading(true);

    const params = {
      municipalityIds: filter.municipalities.length === 0 ? undefined : filter.municipalities.map(getValue),
      hadTraining: filter.hadTraining.length === 0 ? undefined : filter.hadTraining.map(getValue),
      search: filter.search ? filter.search : undefined,
    };

    agent.Schools.list(params)
      .then((response) => {
        setSchools(response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Fragment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <PageTitle title={t("schools.page_title")} description={t("schools.page_description")} />
            {canCreate && <SchoolCreateModal reloadSchools={loadSchools} />}
          </Grid.Column>
          <Grid.Column width={8}>
            <PagePhotos count={5} />
          </Grid.Column>
        </Grid.Row>

        {canApprove && (
          <Grid.Row>
            <Grid.Column>
              <SchoolPendingRegistrations onUpdate={loadSchools} />
            </Grid.Column>
          </Grid.Row>
        )}

        <ListFilter value={filter} config={filterConfig} onChange={(v) => setFilter(v as any)} />
        <Grid.Row>
          <Grid.Column>
            {isLoading ? (
              <Loader />
            ) : schools.length > 0 ? (
              <Segment padded>
                <SchoolList schools={schools} reloadSchools={loadSchools} />
              </Segment>
            ) : (
              t("common.no_records")
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

const getValue = <T extends { value: any }>(x: T) => x.value;

export default SchoolDashboard;
