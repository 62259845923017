import React, { PropsWithChildren, useState } from "react";
import ShIcon from "../../../components/ShIcon";

interface Props {
  title: string;
}

const FaqItem = ({ title, children }: PropsWithChildren<Props>) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <article className={`faq-item ${isOpen ? "visible" : ""}`}>
      <button className="faq-item__title" onClick={() => setOpen((value) => !value)}>
        {title}
        <ShIcon name="caret" />
      </button>
      <div className="faq-item__content">{children}</div>
    </article>
  );
};

export default FaqItem;
