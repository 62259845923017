import React from "react";
import { DebounceInput } from "react-debounce-input";
import Search from "./Search";

interface Props {
  onChange: (value: string) => void;
  placeholder: string;
  value: string;
}

const DebouncedSearch = ({ value, onChange, placeholder }: Props) => {
  return (
    <DebounceInput
      element={Search as any}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      debounceTimeout={800}
    />
  );
};

export default DebouncedSearch;
