import React from "react";

interface Props {
  header: JSX.Element;
  content: JSX.Element;
  footer: JSX.Element;
}

const BaseAuthPage: React.FC<Props> = ({ header, content, footer }) => {
  return (
    <div className="registration">
      <div className="registration-header">{header}</div>
      <div className="registration-content">{content}</div>
      <div className="registration-bottom">{footer}</div>
    </div>
  );
};

export default BaseAuthPage;
