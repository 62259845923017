import React, { Fragment } from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/configureStore";
import { userActions } from "../user/actions";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";

const myProfileStyle = {
  border: "1px solid #00A0AF",
  borderRadius: "20px",
  marginLeft: "20px",
};

interface Props {
  renderOnlyMenuItems?: boolean;
}

const ProfileInfo = ({ renderOnlyMenuItems = false }: Props) => {
  const { t } = useTranslation();
  const { isLoggedIn, email } = useSelector((state: RootState) => state.currentUser);
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(userActions.logoutUser());
  };

  const renderProfileInfo = () => {
    return (
      <Dropdown item text={email} style={myProfileStyle}>
        <Dropdown.Menu>
          <Dropdown.Item text={t("user.my_profile")} as={Link} to={"/profile"} />
          <Dropdown.Item icon="power" text={t("user.form.actions.logout")} onClick={handleLogout} />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderLoginButton = () => {
    return (
      <Menu.Item name="login" as={NavLink} to="/login">
        {t("user.form.actions.login_register")}
      </Menu.Item>
    );
  };

  if (renderOnlyMenuItems && isLoggedIn) {
    return (
      <>
        <Menu.Item name="profile" as={Link} to="/profile">
          {t("user.my_profile")}
        </Menu.Item>
        <Menu.Item name="logout" onClick={handleLogout}>
          {t("user.form.actions.logout")}
        </Menu.Item>
      </>
    );
  } else if (renderOnlyMenuItems) {
    return renderLoginButton();
  }

  return <Fragment>{isLoggedIn ? renderProfileInfo() : renderLoginButton()}</Fragment>;
};

export default ProfileInfo;
