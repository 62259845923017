import { Season, SeasonRound } from "./models";

export const getActive = <T extends { startDate: string; stopDate: string | null }>(item?: T[]): T | undefined => {
  if (!item?.[0]) {
    return undefined;
  }

  const now = new Date();

  if (new Date(item[0].startDate) > now) {
    return undefined;
  }

  if (item[0].stopDate && new Date(item[0].stopDate) <= now) {
    return undefined;
  }

  return item[0];
};

export const createSeason = (): Season => {
  const startDate = new Date();

  return {
    id: Number(startDate),
    startDate: startDate.toISOString(),
    stopDate: null,
    rounds: [],
  };
};

export const endSeason = (season: Season, endDate?: string): Season => {
  const stopDate = endDate ?? new Date().toISOString();
  // const rounds = !season.rounds[0]?.stopDate
  //   ? [endRound(season.rounds[0], stopDate), ...season.rounds.slice(1)]
  //   : season.rounds;

  return {
    ...season,
    stopDate,
  };
};

export const createRound = (): SeasonRound => {
  const startDate = new Date();

  return {
    startDate: startDate.toISOString(),
    stopDate: null,
  };
};

export const endRound = (round: SeasonRound, endDate?: string): SeasonRound => {
  const stopDate = endDate ?? new Date().toISOString();

  return {
    ...round,
    stopDate,
  };
};
