import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import ListFilter from "../../components/list-filter/ListFilter";
import Loader from "../../components/Loader";
import PageTitle from "../../components/page-title/PageTitle";
import { login } from "../user/login";
import { SiteUser } from "./types";
import UsersList from "./UsersList";
import useUsers from "./useUsers";
import useUsersFilter from "./useUsersFilter";

const UsersDashboard = () => {
  const { t } = useTranslation();
  const { apiError } = useToaster();

  const { value: filter, setValue: setFilter, config: filterConfig } = useUsersFilter();
  const { users, isLoading, renderPagination, reloadUsers } = useUsers(filter.roles, filter.search);

  const handleImpersonate = async (user: SiteUser) => {
    try {
      login(await agent.Admin.impersonate(user.email));
    } catch (error: any) {
      apiError(error);
    }
  };

  const handleConfirmEmail = async (user: SiteUser) => {
    try {
      await agent.Admin.confirmEmail(user);
      reloadUsers();
    } catch (error: any) {
      apiError(error);
    }
  };

  const renderBody = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (users.length === 0) {
      return <Segment>{t("common.no_records")}</Segment>;
    }

    return (
      <>
        <UsersList
          users={users}
          onImpersonate={handleImpersonate}
          onConfirmEmail={handleConfirmEmail}
          onUserUpdate={reloadUsers}
        />

        {renderPagination()}
      </>
    );
  };

  return (
    <>
      <PageTitle title={t("navbar.users")} />
      <Grid>
        <ListFilter value={filter} config={filterConfig} onChange={(v) => setFilter(v as any)} />
      </Grid>

      {renderBody()}
    </>
  );
};

export default UsersDashboard;
