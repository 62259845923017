import React from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/page-title/PageTitle";
import { Tab, TabProps } from "semantic-ui-react";
import { setPastApplicationsFilter } from "../actions";
import ShadowingPageTitle from "../../shadowings/components/ShadowingPageTitle";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const SchoolTabFilter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleTabChange = (_: any, { activeIndex }: TabProps) => {
    activeIndex === 1 ? dispatch(setPastApplicationsFilter(true)) : dispatch(setPastApplicationsFilter(false));
  };

  const panes = [
    {
      menuItem: t("school_filter_tab.open_positions.tab_title"),
      render: () => <ShadowingPageTitle />,
    },
    // TODO: not working correctly, commenting out temporary
    // {
    //   menuItem: t("school_filter_tab.past_applications.tab_title"),
    //   render: () => (
    //     <PageTitle
    //       title={t("school_filter_tab.past_applications.page_title")}
    //       description={t("school_filter_tab.past_applications.page_description")}
    //     />
    //   ),
    // },
  ];

  return (
    <Tab
      onTabChange={handleTabChange}
      className={"applications-filter"}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  );
};

export default SchoolTabFilter;
