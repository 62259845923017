import React, { useState, useEffect } from "react";
import { Container, Grid, Divider, Button } from "semantic-ui-react";
import ShadowingList from "../shadowings/components/ShadowingList";
import WelcomePhotos from "./WelcomePhotos";
import ShadowingFilter from "../shadowingsFilter/components/ShadowingFilter";
import ShadowingSorter from "../shadowingsFilter/components/ShadowingSorter";
import { useTranslation } from "react-i18next";
import HomePageFilterTags from "./HomePageFilterTags";
import { CompanyBasic } from "../companies/types";
import agent from "../../app/api/agent";
import { userHelpers } from "../../app/helpers/userHelpers";
import SchoolTabFilter from "../shadowingsFilter/components/SchoolTabFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/configureStore";
import SideNav from "../nav/SideNav";
import ShIcon from "../../components/ShIcon";
import HomePageArticleSlider from "../home-page/article-slider/HomePageArticleSlider";
import ShadowingPageTitle from "../shadowings/components/ShadowingPageTitle";
import useCities from "../../app/hooks/useCities";
import useIndustries from "../../app/hooks/useIndustries";

const ShadowingsHome: React.FC = () => {
  const { t } = useTranslation();

  const [isFilterOpened, setFilterOpened] = useState(false);

  const { cities } = useCities();
  const [companies, setCompanies] = useState<CompanyBasic[]>([]);
  const { industries } = useIndustries();

  useEffect(() => {
    agent.Companies.list().then((response) => {
      setCompanies(response);
    });
  }, []);

  const pastApplications: boolean | undefined = useSelector((state: RootState) => state.shadowingsFilter.past);

  const showHasApplicationsFilter = () => (userHelpers.isSchool() || userHelpers.isAdmin()) && !pastApplications;

  return (
    <>
      <Container>
        <Grid>
          {userHelpers.isSchool() ? (
            <Grid.Row>
              <SchoolTabFilter />
            </Grid.Row>
          ) : (
            <Grid.Row columns={16}>
              <Grid.Column mobile={16} only="mobile">
                <WelcomePhotos />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={7} computer={9}>
                <ShadowingPageTitle />
              </Grid.Column>
              <Grid.Column computer={7} tablet={9} only="tablet computer">
                <WelcomePhotos />
              </Grid.Column>
            </Grid.Row>
          )}
          <Divider />

          <Grid.Row only="computer">
            <Grid.Column width={4}>
              <h4>{t("common.filter")}</h4>
            </Grid.Column>
            <Grid.Column width={9}>
              <HomePageFilterTags companies={companies} industries={industries} />
            </Grid.Column>
            <Grid.Column width={3}>
              <ShadowingSorter />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row only="mobile tablet">
            <Grid.Column>
              <Button.Group widths="2">
                <ShadowingSorter icon={<ShIcon name="sort" />} text={t("common.sort")} />
                <Button onClick={() => setFilterOpened(true)}>
                  <ShIcon name="filter" />
                  {t("common.filter")}
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>

          <SideNav title="common.filter" isVisible={isFilterOpened} onClose={() => setFilterOpened(false)}>
            <ShadowingFilter
              companies={companies}
              industries={industries}
              cities={cities}
              showHasApplicationsFilter={showHasApplicationsFilter()}
            />
          </SideNav>

          <Grid.Row>
            <Grid.Column width={4} only="computer">
              <ShadowingFilter
                companies={companies}
                industries={industries}
                cities={cities}
                showHasApplicationsFilter={showHasApplicationsFilter()}
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={12}>
              <ShadowingList />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <HomePageArticleSlider />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default ShadowingsHome;
