import { getCurrentAcademicYearStart } from "../../../app/helpers/dateHelpers";

const currentYear = getCurrentAcademicYearStart();

export const ADMIN_REPORTS_INITIAL_FILTER_VALUE = {
  years: [{ key: currentYear, text: `${currentYear}-${currentYear + 1}`, value: currentYear }],
  grade: [],
  gender: [],
  company: [],
  school: [],
};
