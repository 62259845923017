import React from "react";
import { Menu } from "semantic-ui-react";
import { Guide } from "../models";

interface Props {
  items: Guide[];
  active: Guide;
  onChange: (v: Guide) => void;
}

const GuideMenu = ({ items, active, onChange: handleChange }: Props) => {
  return (
    <Menu fluid secondary vertical className="guide__menu">
      {items.map((item, index) => (
        <Menu.Item name={item.title} key={index} active={active === item} onClick={() => handleChange(item)}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default GuideMenu;
