import React, { useEffect, useState } from "react";
import Datepicker from "react-datepicker";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import { updateShadowingDateFilter } from "../actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

export interface DateRange {
  start: Date;
  end: Date;
}

const DateFilter: React.FC = () => {
  const selectedDateRange = useSelector((state: RootState) => state.shadowingsFilter.dateRange);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const dispatch = useAppDispatch();

  const onChange = (dates: any) => {
    const [start, end] = dates as [Date, Date | null];
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      dispatch(updateShadowingDateFilter({ start, end }));
    }
  };

  useEffect(() => {
    setStartDate(selectedDateRange?.start || null);
    setEndDate(selectedDateRange?.end || null);
  }, [selectedDateRange]);

  return (
    <>
      <Datepicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
    </>
  );
};

export default DateFilter;
