import React, { useState, useEffect } from "react";
import ApplicationItem from "./ApplicationItem";
import { SeparatedApplications } from "../types";
import agent from "../../../app/api/agent";
import { Card } from "semantic-ui-react";
import { userActions } from "../../user/actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface IProps {
  activeFilter: number;
}

const FILTER_INDEX_MAP = {
  coming: 0,
  past: 1,
};

const ApplicationList: React.FC<IProps> = ({ activeFilter }) => {
  const dispatch = useAppDispatch();
  const [applications, setApplications] = useState<SeparatedApplications>({
    past: [],
    upcoming: [],
  });

  const loadApplications = () => {
    agent.Applications.myApplications().then((response) => {
      setApplications(response);
    });
  };

  useEffect(() => {
    loadApplications();
  }, []);

  const filteredApplications = () => {
    return activeFilter === FILTER_INDEX_MAP.coming ? applications.upcoming : applications.past;
  };

  const handleCancel = (applicationId: number) => {
    agent.Applications.cancel(applicationId).then(() => {
      dispatch(userActions.getCurrentUser());
      loadApplications();
    });
  };

  return (
    <Card.Group stackable itemsPerRow={3} className="application">
      {filteredApplications().map((application) => (
        <ApplicationItem
          key={application.id}
          application={application}
          handleCancel={handleCancel}
          past={activeFilter === FILTER_INDEX_MAP.past}
        />
      ))}
    </Card.Group>
  );
};

export default ApplicationList;
