import React from "react";
import { ReportsDataTop } from "./models";
import { useTranslation } from "react-i18next";
import Badge from "../../components/Badge";

interface Props {
  data: ReportsDataTop;
}

const ReportsTop = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="report report--top">
      <h3 className="report__title">{t(data.title)}</h3>
      <ol className="report__top">
        {data.value.map((item, i) => (
          <li key={i}>
            {item.title}
            <Badge value={`${item.value} ${data.unit ? ` ${t(data.unit)}` : ""}`} />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ReportsTop;
