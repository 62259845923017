import React, { PropsWithChildren } from "react";
import usePageClass from "../../../app/hooks/usePageClass";

const AuthLayout = ({ children }: PropsWithChildren<any>) => {
  usePageClass("page-auth");

  return (
    <div className="auth-layout">
      <img src="/assets/auth/bg-mob.jpg" alt="" className="auth-layout__mob-img" />
      <img src="/assets/auth/bg.jpg" alt="" className="auth-layout__img" />

      <div className="auth-layout__content">{children}</div>
    </div>
  );
};

export default AuthLayout;
