import { useEffect, useMemo, useState } from "react";
import agent from "../api/agent";
import { School } from "../../features/schools/types";

const useSchools = (isVirtual = false) => {
  const [isLoading, setLoading] = useState(false);
  const [schools, setSchools] = useState<School[]>([]);

  useEffect(() => {
    setLoading(true);
    const promise = agent.Schools.list(isVirtual ? { isVirtual } : undefined);
    promise.then(setSchools).finally(() => setLoading(false));
  }, [isVirtual]);

  return useMemo(
    () => ({
      isLoading,
      schools,
      schoolOptions: schools.map(({ id, name }) => ({ key: id, value: id, text: name })),
    }),
    [schools, isLoading]
  );
};

export default useSchools;
