import React, { Fragment } from "react";
import StudentProfile from "./student/StudentProfile";
import SchoolProfile from "./school/SchoolProfile";
import CompanyProfile from "./company/CompanyProfile";
import MunicipalityProfile from "./municipality/MunicipalityProfile";
import AdminProfile from "./admin/AdminProfile";
import { userHelpers } from "../../../app/helpers/userHelpers";

const Profile = () => {
  const renderProfile = () => {
    if (userHelpers.isStudent()) return <StudentProfile />;
    if (userHelpers.isSchool()) return <SchoolProfile />;
    if (userHelpers.isCompany()) return <CompanyProfile />;
    if (userHelpers.isMunicipality()) return <MunicipalityProfile />;
    if (userHelpers.isAdmin()) return <AdminProfile />;

    return null;
  };

  return <Fragment>{renderProfile()}</Fragment>;
};

export default Profile;
