import React from "react";
import { useTranslation } from "react-i18next";
import { Segment, Table } from "semantic-ui-react";
import { UserRoles } from "../../app/auth/roles";
import ButtonLink from "../../components/ButtonLink";
import { SiteUser } from "./types";
import UserEditModal from "./UserEditModal";

interface Props {
  users: SiteUser[];
  onImpersonate: (user: SiteUser) => any;
  onConfirmEmail: (user: SiteUser) => any;
  onUserUpdate: () => void;
}

const UsersList = ({ users, onImpersonate, onConfirmEmail, onUserUpdate }: Props) => {
  const { t } = useTranslation();

  const getRoleLabel = (role: UserRoles) => t(`userRoles.${role.toLowerCase()}`);

  return (
    <Segment>
      <Table basic="very">
        <Table.Body>
          {users.map((user) => (
            <Table.Row key={user.id}>
              <Table.Cell singleLine>{user.fullName}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                {user.emailConfirmed ? t("users.email_confirmed") : t("users.email_not_confirmed")}{" "}
                {!user.emailConfirmed && (
                  <ButtonLink onClick={() => onConfirmEmail(user)}>{t("users.confirm_email")}</ButtonLink>
                )}
              </Table.Cell>
              <Table.Cell>{getRoleLabel(user.role)}</Table.Cell>
              <Table.Cell textAlign="right">
                <UserEditModal user={user} onUserUpdate={onUserUpdate} />
                {" | "}
                <ButtonLink onClick={() => onImpersonate(user)}>{t("users.loginAs")}</ButtonLink>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default UsersList;
