import React from "react";
import AppForm from "../../../components/form/Form";
import { SchoolRegistrationModel, SchoolRegistrationPasswordStepFormModel } from "../types";
import SchoolRegistrationActions from "../SchoolRegistrationActions";
import { PASSWORD_STRENGTH_REGEXP } from "../../../app/helpers/regexp";
import { useAppForm } from "../../../components/form/useForm";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../app/auth/routes";
import { useTranslation } from "react-i18next";

interface Props {
  model: Partial<SchoolRegistrationModel>;
  onSubmit: (m: SchoolRegistrationPasswordStepFormModel) => any;
  isDisabled: boolean;
}

const SchoolRegistrationPasswordStep = ({ onSubmit, isDisabled }: Props) => {
  const formHook = useAppForm({ defaultValues: { ...defaultValues, repeatedPassword: "" } });
  const { t } = useTranslation();

  return (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit} isDisabled={isDisabled}>
        <AppForm.Group name="password" label="auth.steps.school.password">
          <AppForm.Password
            name="password"
            rules={{
              required: true,
              pattern: {
                value: PASSWORD_STRENGTH_REGEXP,
                message: "auth.steps.password.validation.password_strenght",
              },
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="repeatedPassword" label="auth.steps.school.repeatedPassword">
          <AppForm.Password
            name="repeatedPassword"
            rules={{
              required: true,
              validate: (v) =>
                v !== formHook.getValues("password") ? "auth.steps.password.validation.password_match" : true,
            }}
          />
        </AppForm.Group>
        <AppForm.Group name="privacyPolicy">
          <AppForm.Checkbox name="privacyPolicy" label="Sutinku su" rules={{ required: true }} />{" "}
          <Link to={NonAuthRoutes.privacyPolicy} target="_blank" rel="noopener noreferrer">
            {t("common.privacy_policy")}
          </Link>
        </AppForm.Group>
      </AppForm>
      <SchoolRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: SchoolRegistrationPasswordStepFormModel = {
  password: "",
};

export default SchoolRegistrationPasswordStep;
