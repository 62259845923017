import React, { createContext, useState } from "react";
import BusinessRegistrationCompanyStep from "./steps/CompanyStep";
import { BusinessRegistrationModel, BusinessRegistrationStep } from "./types";
import BusinessRegistrationContactInfoStep from "./steps/ContactInfoStep";
import BusinessRegistrationDescriptionStep from "./steps/DescriptionStep";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import BusinessRegistrationSuccess from "./BusinessRegistrationSuccess";

interface Props {
  onBackToRegistration: () => void;
}

const steps = [
  BusinessRegistrationStep.Company,
  BusinessRegistrationStep.Description,
  BusinessRegistrationStep.ContactInfo,
];

export const BusinessRegistrationContext = createContext<{
  steps: BusinessRegistrationStep[];
  currentStep: BusinessRegistrationStep;
  handleBack: () => void;
  isDisabled: boolean;
  isCreating: boolean;
}>(null!);

const BusinessRegistration: React.FC<Props> = ({ onBackToRegistration: handleBackToRegistration }) => {
  const [currentStep, setCurrentStep] = useState<BusinessRegistrationStep>(BusinessRegistrationStep.Company);
  const [model, setModel] = useState<Partial<BusinessRegistrationModel>>({});
  const { apiError } = useToaster();
  const [isCreating, setCreating] = useState(false);
  const isDisabled = isCreating;

  const handleSubmit = (data: Partial<BusinessRegistrationModel>) => {
    setModel((m) => ({ ...m, ...data }));

    if (currentStep === BusinessRegistrationStep.ContactInfo) {
      register({ ...model, ...data } as BusinessRegistrationModel);
    } else {
      setCurrentStep(getNextStep(currentStep));
    }
  };

  const register = async (value: BusinessRegistrationModel) => {
    setCreating(true);

    try {
      await agent.Companies.register(value);
      setCurrentStep(BusinessRegistrationStep.Success);
    } catch (error) {
      apiError(error);
    } finally {
      setCreating(false);
    }
  };

  const handleBack = () => {
    if (currentStep === steps[0]) {
      handleBackToRegistration();

      return;
    }
    setCurrentStep(getPreviousStep(currentStep));
  };

  const renderStep = () => {
    switch (currentStep) {
      case BusinessRegistrationStep.Company:
        return <BusinessRegistrationCompanyStep onSubmit={handleSubmit} model={model} />;
      case BusinessRegistrationStep.ContactInfo:
        return <BusinessRegistrationContactInfoStep onSubmit={handleSubmit} model={model} isDisabled={isDisabled} />;
      case BusinessRegistrationStep.Description:
        return <BusinessRegistrationDescriptionStep onSubmit={handleSubmit} model={model} />;
      case BusinessRegistrationStep.Success:
        return <BusinessRegistrationSuccess model={model as BusinessRegistrationModel} />;
    }
  };

  return (
    <BusinessRegistrationContext.Provider value={{ steps, currentStep, handleBack, isDisabled, isCreating }}>
      {renderStep()}
    </BusinessRegistrationContext.Provider>
  );
};

function getNextStep(step: BusinessRegistrationStep) {
  const index = steps.indexOf(step);

  return steps[index + 1];
}

function getPreviousStep(step: BusinessRegistrationStep) {
  const index = steps.indexOf(step);

  return steps[index - 1];
}

export default BusinessRegistration;
