import React, { Fragment, useState, useEffect, useMemo } from "react";
import StudentList from "./StudentList";
import { Grid, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/page-title/PageTitle";
import PendingRegistrations from "./PendingRegistrations";
import { Grade, Student, StudentApplicationToApprove } from "../types";
import agent from "../../../app/api/agent";
import Loader from "../../../components/Loader";
import ListFilter from "../../../components/list-filter/ListFilter";
import {
  ListFilterConfig,
  ListFilterConfigItemMultiSelectOption,
  ListFilterConfigItemType,
} from "../../../components/list-filter/models";
import PendingApplications from "./PendingApplications";

const years = Array.from({ length: new Date().getFullYear() - 2021 + 1 }, (_, i) => 2021 + i);

const StudentDashboard = () => {
  const { t } = useTranslation();
  const [grades, setGrades] = useState<Grade[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [pendingRegistrations, setPendingRegistrations] = useState<Student[]>([]);
  const [pendingApplications, setPendingApplications] = useState<StudentApplicationToApprove[]>([]);
  const [isLoading, setLoading] = useState(false);

  const filterConfig: ListFilterConfig = useMemo(
    () => [
      {
        name: "hasApplications",
        title: "students_filter.has_applications.title",
        type: ListFilterConfigItemType.MultiSelect,
        options: [
          { key: "true", value: "true", text: t("students_filter.has_applications.yes") },
          { key: "false", value: "false", text: t("students_filter.has_applications.no") },
        ],
      },
      {
        name: "grade",
        title: "students_filter.grade.title",
        type: ListFilterConfigItemType.MultiSelect,
        options: grades.map((g) => ({ key: g.grade, value: g.grade, text: g.grade })),
      },
      {
        name: "registrationYear",
        title: "students_filter.registrationYear",
        type: ListFilterConfigItemType.MultiSelect,
        options: years.map((year) => ({ key: year, value: year, text: String(year) })),
      },
      {
        name: "applicationYear",
        title: "students_filter.applicationYear",
        type: ListFilterConfigItemType.MultiSelect,
        options: years.map((year) => ({ key: year, value: year, text: String(year) })),
      },
      {
        name: "onlyDeactivated",
        title: "students_filter.only_deactivated.title",
        type: ListFilterConfigItemType.MultiSelect,
        options: [{ key: "true", value: "true", text: t("students_filter.only_deactivated.yes") }],
      },
      {
        name: "name",
        title: "students_filter.fullName",
        type: ListFilterConfigItemType.Search,
      },
    ],
    [grades, t]
  );

  const [filter, setFilter] = useState<{
    hasApplications: ListFilterConfigItemMultiSelectOption[];
    grade: ListFilterConfigItemMultiSelectOption[];
    onlyDeactivated: ListFilterConfigItemMultiSelectOption[];
    registrationYear: ListFilterConfigItemMultiSelectOption[];
    applicationYear: ListFilterConfigItemMultiSelectOption[];
    name: string;
  }>({ hasApplications: [], grade: [], onlyDeactivated: [], name: "", registrationYear: [], applicationYear: [] });

  useEffect(() => {
    loadGrades();
    loadPendingRegistrations();
    loadPendingApplications();
  }, []);

  useEffect(() => {
    loadStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const loadGrades = () => {
    agent.Schools.listGrades().then((response) => setGrades(response));
  };

  const loadPendingRegistrations = () => {
    agent.Students.listPendingRegistrations().then((response) => {
      setPendingRegistrations(response);
    });
  };

  const loadPendingApplications = () => {
    agent.Students.listPendingApplications().then((response) => {
      setPendingApplications(response);
    });
  };

  const loadStudents = () => {
    setLoading(true);

    const params = {
      hasApplications: filter.hasApplications.map((v) => String(v.value)),
      grade: filter.grade.map((v) => String(v.value)),
      onlyDeactivated: filter.onlyDeactivated.map((v) => String(v.value)),
      name: filter.name.trim().length > 0 ? filter.name : undefined,
      registrationYear: filter.registrationYear.map((v) => Number(v.value)),
      applicationYear: filter.applicationYear.map((v) => Number(v.value)),
    };

    agent.Students.list(params)
      .then((response) => {
        setStudents(response);
      })
      .finally(() => setLoading(false));
  };

  const handleApprove = (studentId: number) => {
    agent.Students.approveRegistration(studentId).then(() => {
      loadGrades();
      loadPendingRegistrations();
      loadPendingApplications();
      loadStudents();
    });
  };

  const handleApproveApplication = (studentId: number, applicationId: number) => {
    agent.Students.approveApplication(studentId, applicationId).then(() => {
      loadGrades();
      loadPendingRegistrations();
      loadPendingApplications();
      loadStudents();
    });
  };

  const handleReject = (studentId: number) => {
    agent.Students.rejectRegistration(studentId).then(() => {
      loadPendingRegistrations();
    });
  };

  const renderApprovedStudents = () => {
    if (isLoading) return <Loader />;

    return students.length > 0 ? (
      <Segment>
        <StudentList students={students} onStudentUpdate={loadStudents} />
      </Segment>
    ) : (
      t("common.no_records")
    );
  };

  return (
    <Fragment>
      <PageTitle title={t("common.students")} />
      {pendingRegistrations.length > 0 && (
        <PendingRegistrations
          pendingRegistrations={pendingRegistrations}
          handleApprove={handleApprove}
          handleReject={handleReject}
        />
      )}
      {pendingApplications.length > 0 && (
        <PendingApplications
          pendingApplications={pendingApplications}
          handleApproveApplication={handleApproveApplication}
        />
      )}
      <Grid>
        <ListFilter config={filterConfig} value={filter} onChange={(v) => setFilter(v as any)} />
      </Grid>
      {renderApprovedStudents()}
    </Fragment>
  );
};

export default StudentDashboard;
