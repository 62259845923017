export enum QuestionType {
  Points = "points",
  Boolean = "boolean",
  // Checkbox = 'checkbox',
  // MultiCheckbox = 'multiCheckbox',
  Radio = "radio",
  // Text = 'text',
  Textbox = "textbox",
}

interface QuestionBase {
  id: number | string;
  title: string;
  optional?: boolean;
}

export interface BooleanQuestion extends QuestionBase {
  type: QuestionType.Boolean;
  options?: Record<`${boolean}`, string>;
}

export interface RadioQuestion extends QuestionBase {
  type: QuestionType.Radio;
  options: Array<{ title: string; value: string | number | boolean }>;
}

export interface PointQuestion extends QuestionBase {
  type: QuestionType.Points;
}

export interface TextboxQuestion extends QuestionBase {
  type: QuestionType.Textbox;
}

export type Question = PointQuestion | BooleanQuestion | RadioQuestion | TextboxQuestion;

export type ReflectionFormModel = Record<Question["id"], number | boolean | string>;

export type Reflections = ReflectionFormModel;

interface ReflectionReportModelBaseValue {
  questionId: number;
}
export interface ReflectionReportModelPointValue extends ReflectionReportModelBaseValue {
  answersCount: number;
  value: number;
}

export interface ReflectionReportModelRadioValue extends ReflectionReportModelBaseValue {
  answers: Array<{ answer: number; answersCount: number; percentage: number }>;
}

export type ReflectionReportModelBooleanValue = ReflectionReportModelRadioValue;

export interface ReflectionReportModelTextValue extends ReflectionReportModelBaseValue {
  answers: Array<string | { name: string; value: string }>;
}

export type ReflectionReportModel = Record<Question["id"], ReflectionReportModelValue>;

export type ReflectionReportModelValue =
  | ReflectionReportModelPointValue
  | ReflectionReportModelBooleanValue
  | ReflectionReportModelRadioValue
  | ReflectionReportModelTextValue;
