import { useEffect } from "react";

const usePageClass = (className?: string) => {
  useEffect(() => {
    if (!className) {
      return;
    }
    document.body.classList.add(className);

    return () => document.body.classList.remove(className);
  }, [className]);
};

export default usePageClass;
