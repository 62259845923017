/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Application } from "../types";
import { isExistingReflection } from "../../../app/helpers/reflections";
import { Reflections } from "../../reflections/models";
import { Student } from "../../students/types";
import ReflectionPreview from "../../reflections/ReflectionPreview";
import { getQuestions } from "../../reflections/utils";

interface Props {
  application: Application;
  student: Student;
}

const ApplicationItemReflection = ({ application, student, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const renderReflection = (reflections?: Reflections) => {
    if (!isExistingReflection(reflections)) {
      return <p>{t("reflection.no_answers")}</p>;
    }

    const questions = getQuestions(reflections);

    return <ReflectionPreview questions={questions} model={reflections} />;
  };

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
      trigger={children}
    >
      <Modal.Header>
        {student.contactInfo.fullName} {student.grade}
      </Modal.Header>
      <Modal.Content>
        <h5>{t("reflection.students_reflection")}</h5>
        {renderReflection(application.reflections)}

        <h5>{t("reflection.business_reflection")}</h5>
        {renderReflection(application.businessFeedback)}
      </Modal.Content>
    </Modal>
  );
};

export default ApplicationItemReflection;
