import React from "react";
import Datepicker, { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import DateHeader from "./DateHeader";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props extends Omit<ReactDatePickerProps, "placeholderText" | "selected" | "onChange"> {
  placeholder?: string;
  value?: string;
}

const AppDate: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  value,
  placeholder = "common.date_placeholder",
  isDisabled,
  isValid,
  ...props
}) => {
  const { t } = useTranslation();
  const selected = value && new Date(value);
  // TODO: icon

  return (
    <Datepicker
      renderCustomHeader={(p) => <DateHeader {...p} onChange={onChange} />}
      disabled={isDisabled}
      locale="lt"
      selected={selected}
      dateFormat="P"
      popperPlacement="bottom-start"
      {...props}
      placeholderText={placeholder && t(placeholder)}
      onChange={(value) => onChange(value instanceof Date ? value.toISOString() : value)}
    />
  );
};

const AppDateController = withController(AppDate);

export { AppDateController };
export default AppDate;
