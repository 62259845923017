import React, { useState } from "react";
import Links from "../../../components/Links";
import { Guide } from "../models";
import GuideMenu from "./GuideMenu";
import GuidePdf from "./GuidePdf";
import "../styles.less";

interface Props {
  items: Guide[];
}

const GuideBase: React.FC<Props> = ({ items }) => {
  const [active, setActive] = useState(items[0]);

  return (
    <div className="guide">
      <div className="guide__sidebar">
        <GuideMenu items={items} active={active} onChange={setActive} />
      </div>
      <div className="guide__container">
        <h1 className="guide__title">{active.title}</h1>
        {active.pdfUrl && <GuidePdf url={active.pdfUrl} />}
        {active.links && (
          <div className="guide__links">
            <Links items={active.links} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GuideBase;
