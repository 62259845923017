import React, { useMemo } from "react";
import { defaults as ChartJSDefaults } from "chart.js";
import { Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { CompetenciesModel } from "./models";
import "chart.js/auto";

ChartJSDefaults.font.family = "Spartan";

interface Props {
  data: CompetenciesModel;
}

const CompetenciesGraph = ({ data }: Props) => {
  const { t } = useTranslation();

  const radarData = useMemo(
    () => ({
      labels,
      datasets: [
        ...(data?.before?.length
          ? [
              {
                label: t("reflection.legend.before"),
                data: data.before.map(({ value }) => value),
                backgroundColor: getRandomColor(),
                borderColor: "grey",
                borderWidth: 1,
              },
            ]
          : []),
        ...(data?.after?.length
          ? [
              {
                label: t("reflection.legend.after"),
                data: data.after.map(({ value }) => value),
                backgroundColor: getRandomColor(),
                borderColor: "grey",
                borderWidth: 1,
              },
            ]
          : []),
      ],
    }),
    [data?.after, data?.before, t]
  );

  if (!radarData.datasets.length) {
    return <>{t("common.no_records")}</>;
  }

  return <Radar data={radarData} options={options} />;
};

const options = {
  scales: {
    r: {
      suggestedMin: 0,
      suggestedMax: 10,
    },
  },
};

const labels = [
  "Kritinis ir analitinis mąstymas",
  "Problemų sprendimas",
  "Karjeros planavimas",
  "Įtraukianti komunikacija",
  "Sprendimų priėmimas",
  "Tarpasmeniniai įgūdžiai",
  "Savarankiškumas",
  "Kultūrinis aktyvumas",
  "Kūrybiškumas",
];

function getRandomColor() {
  const x = Math.floor(Math.random() * 256);
  const y = Math.floor(Math.random() * 256);
  const z = Math.floor(Math.random() * 256);
  return `rgb(${x},${y},${z}, 0.2)`;
}

export default CompetenciesGraph;
