import React from "react";
import company from "../../icons/company.svg";
import location from "../../icons/location.svg";
import person from "../../icons/person.svg";
import date from "../../icons/date.svg";

interface IProps {
  companyName?: string;
  locationValue?: string;
  personValue?: string;
  dateValue?: string;
}

const ShadowingMetadata: React.FC<IProps> = ({ companyName, locationValue, personValue, dateValue }) => {
  return (
    <div className="shadowing-metadata">
      {locationValue && (
        <span>
          <img src={location} alt="location" />
          {locationValue}
        </span>
      )}
      {companyName && (
        <span>
          <img src={company} alt="company" />
          {companyName}
        </span>
      )}
      {personValue && (
        <span>
          <img src={person} alt="person" />
          {personValue}
        </span>
      )}
      {dateValue && (
        <span>
          <img src={date} alt="date" />
          {dateValue}
        </span>
      )}
    </div>
  );
};

export default ShadowingMetadata;
