import React from "react";
import { Divider } from "semantic-ui-react";
import usePageClass from "../../app/hooks/usePageClass";
import HomePagePromo from "./promo/HomePagePromo";
import HomePageTwoArticles from "./two-articles/HomePageTwoArticles";

import HomePageArticleList from "./list/HomePageArticleList";
import HomePageFaq from "./faq/HomePageFaq";
import HomePageVideoSlider from "./video-slider/HomePageVideoSlider";
import HomePageArticleWithImage from "./article-with-image/HomePageArticleWithImage";
import HomePageArticleSlider from "./article-slider/HomePageArticleSlider";

const HomePage: React.FC = () => {
  usePageClass("page-homepage");

  return (
    <>
      <HomePagePromo />
      <HomePageTwoArticles />
      {/* <HomePageArticleWithImage /> */}
      <HomePageArticleList />
      <Divider />
      <HomePageArticleSlider />
      <HomePageVideoSlider />
      <HomePageFaq />
    </>
  );
};

export default HomePage;
