import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../../app/auth/roles";
import { localStorageHelpers } from "../../../app/helpers/localStorageHelpers";
import { HasRole } from "../../../app/helpers/userHelpers";
import { ShadowingWithApplications } from "../types";

interface Props {
  shadowing: ShadowingWithApplications;
}

const ShadowingCapacity: React.FC<Props> = ({ shadowing }) => {
  const { t } = useTranslation();

  const isStudent = HasRole(UserRoles.student);
  const isCompany = HasRole(UserRoles.company);
  const isOwner = isCompany && localStorageHelpers.getSubjectId() === shadowing.company?.id.toString();

  const canSeeFullInformation = isStudent || isOwner;

  const renderCapacity = () => {
    if (canSeeFullInformation) {
      return (
        <Fragment>
          {t("shadowing.occupied_slots")}: {shadowing.occupiedApplications} {t("common.from")}{" "}
          {shadowing.participantsCapacity}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {t("shadowing.slots_count")}: {shadowing.participantsCapacity}
      </Fragment>
    );
  };

  return <>{renderCapacity()}</>;
};

export default ShadowingCapacity;
