import React, { Fragment, useState } from "react";
import ApplicationList from "./ApplicationList";
import ApplicationFilters from "./ApplicationFilters";

const StudentApplicationsPage = () => {
  const [activeFilter, setActiveFilter] = useState<number>(0);

  const handleTabChange = (_: any, { activeIndex }: any) => {
    setActiveFilter(activeIndex);
  };

  return (
    <Fragment>
      <ApplicationFilters handleTabChange={handleTabChange} />

      <ApplicationList activeFilter={activeFilter} />
    </Fragment>
  );
};

export default StudentApplicationsPage;
