import React from "react";
import { Guide } from "./models";
import GuideBase from "./components/GuideBase";

const items: Guide[] = [
  {
    title: "Programos įgyvendinimo gairės",
    pdfUrl: "",
    links: [
      {
        title: "Mokytojo vadovas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Mokytojo vadovas.pdf",
      },
    ],
  },
  {
    title: "Naudinga informacija",
    pdfUrl: "",
    links: [
      {
        title: "Praktiniai patarimai kaip (ne)rašyti CV",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Praktiniai_patarimai_kaip_(ne)rašyti_CV.pdf",
      },
      {
        title: "Kaip nestresuoti per darbo pokalbį",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Kaip_nestresuoti_per_darbo_pokalbį.pdf",
      },
      {
        title: "Kaip keisis mokymasis ir ateities profesijos su dirbtiniu intelektu?",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Kaip%20keisis%20mokymasis%20ir%20ateities%20profesijos%20su%20dirbtiniu%20intelektu.pdf",
      },
      {
        title: "Iliuzijos žengiant pirmus žingsnius karjeroje",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Iliuzijos%20z%CC%8Cengiant%20pirmus%20z%CC%8Cingsnius%20karjerore.pdf",
      },
      {
        title: "Ar yra vienas tiesus karjeros kelias?",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Ar%20yra%20vienas%20tiesus%20karjeros%20kelias.pdf",
      },
    ],
  },
  {
    title: "1 pamoka - pažink save",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/1 uzsiemimas.pdf",
    links: [
      {
        title: "1 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/1 užduotis.pdf",
      },
      {
        title: "2 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/2 užduotis.pdf",
      },
      {
        title: "3 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/3 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Jūsų gyvenimo aprašymas (CV) ir internetinis profilis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - CV.pdf",
      },
      {
        title: "Dalomoji medžiaga - Karjeros kryptys",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - Karjeros kryptys.pdf",
      },
      {
        title: "Dalomoji medžiaga - Motyvacinis laiškas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - Motyvacinis laiškas.pdf",
      },
    ],
  },
  {
    title: "2 pamoka - pasiruošk save pristatyti",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/2 uzsiemimas.pdf",
    links: [
      {
        title: "4 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/4 užduotis.docx",
      },
      {
        title: "5 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/5 užduotis.pdf",
      },
      {
        title: "6 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/6 užduotis.pdf",
      },
      {
        title: "7 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/7 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Sėkmingo „šešėliavimo“ žingsniai & Kaip sėkmingai „šešėliauti“",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - Šešėliavimo žingsniai.pdf",
      },
    ],
  },
  {
    title: "3 pamoka - reflektuok",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/3 uzsiemimas.pdf",
    links: [
      {
        title: "8 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/8 užduotis.pdf",
      },
      {
        title: "9 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/9 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Jūsų gyvenimo aprašymas (CV) ir internetinis profilis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - CV.pdf",
      },
    ],
  },
];

const GuideSchoolPage = () => {
  return <GuideBase items={items} />;
};

export default GuideSchoolPage;
