import React, { useEffect, useState } from "react";
import { SchoolRegistrationContactStepFormModel } from "../schoolRegistration/types";
import { useTranslation } from "react-i18next";
import { Button, Segment, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";

interface Props {
  onUpdate: () => void;
}

const SchoolPendingRegistrations = ({ onUpdate }: Props) => {
  const { t } = useTranslation();

  const [pendingRegistrations, setPendingRegistrations] = useState<SchoolRegistrationContactStepFormModel[]>([]);
  const [isUpdating, setUpdating] = useState(false);
  const { apiError } = useToaster();

  const loadPendingRegistrations = async () => {
    setPendingRegistrations(await agent.Schools.listPendingRegistrations());
  };

  const handleReject = async (school: SchoolRegistrationContactStepFormModel) => {
    setUpdating(true);
    try {
      await agent.Schools.rejectRegistration(school.id);
      onUpdate();
      await loadPendingRegistrations();
    } catch (error: any) {
      apiError(error);
    }
    setUpdating(false);
  };

  const handleApprove = async (school: SchoolRegistrationContactStepFormModel) => {
    setUpdating(true);
    try {
      await agent.Schools.approveRegistration(school.id);
      onUpdate();
      await loadPendingRegistrations();
    } catch (error: any) {
      apiError(error);
    }
    setUpdating(false);
  };

  useEffect(() => {
    loadPendingRegistrations();
  }, []);

  if (pendingRegistrations.length === 0) {
    return null;
  }

  return (
    <>
      <Segment placeholder padded className="pending-registrations-segment">
        <div className="pending-registrations">
          <div className="pending-registrations-text">
            {t("pending_list.title")}: {pendingRegistrations.length}
          </div>
          <Table basic="very" className="student-list pending">
            <Table.Body>
              {pendingRegistrations.map((school) => (
                <Table.Row key={school.id}>
                  <Table.Cell>{school.name}</Table.Cell>
                  <Table.Cell>{school.contactInfo.fullName}</Table.Cell>
                  <Table.Cell>
                    <a href={"tel:" + school.contactInfo.phoneNumber}>{school.contactInfo.phoneNumber}</a>
                  </Table.Cell>
                  <Table.Cell>
                    <a href={"mailto:" + school.contactInfo.email}>{school.contactInfo.email}</a>
                  </Table.Cell>
                  <Table.Cell className="student-list-actions" singleLine>
                    <Button
                      primary
                      content={t("pending_list.actions.approve")}
                      disabled={isUpdating}
                      onClick={() => handleApprove(school)}
                    />
                    <Button
                      primary
                      negative
                      disabled={isUpdating}
                      content={t("pending_list.actions.reject")}
                      onClick={() => handleReject(school)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </>
  );
};

export default SchoolPendingRegistrations;
