import React, { useMemo } from "react";
import { ListFilterConfigItemMultiSelect, ListFilterConfigItemMultiSelectOption } from "./models";
import { Checkbox, Form } from "semantic-ui-react";
import useItemFilter from "../../app/hooks/useItemFilter";

interface Props {
  item: ListFilterConfigItemMultiSelect;
  value: ListFilterConfigItemMultiSelectOption[];
  onChange: (option: ListFilterConfigItemMultiSelectOption, checked: boolean) => void;
}

export const ListFilterMultiselect = ({ item, value, onChange }: Props) => {
  const showFilter = item.options.length > 1;

  const options = useMemo(() => item.options.map((option) => ({ name: option.text, option })), [item.options]);
  const { filteredItems, renderFilter } = useItemFilter(options);

  return (
    <>
      {showFilter && renderFilter()}
      <Form className="filter-checkbox-group shadowing-filter__checkbox-group">
        <Form.Field>
          {filteredItems.map(({ option }) => (
            <Checkbox
              onChange={(_, { checked }) => onChange(option, !!checked)}
              key={option.key}
              value={option.value}
              checked={value.some((x: any) => x.key === option.key)}
              className="sh-filter"
              label={<label>{option.text}</label>}
            />
          ))}
        </Form.Field>
      </Form>
    </>
  );
};

export default ListFilterMultiselect;
