import React from "react";
import { Season } from "./models";
import "./styles.less";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { prettifyDateWithoutTime } from "../../app/helpers/dateHelpers";
import { getActive } from "./season-utils";

interface Props {
  activeSeason: Season;
  isDisabled: boolean;
  isRoundStateChanging: boolean;
  onRoundStart: () => void;
  onRoundStop: () => void;
}

const SeasonsRoundAdmin = ({
  activeSeason,
  isDisabled,
  isRoundStateChanging,
  onRoundStart: handleRoundStart,
  onRoundStop: handleRoundStop,
}: Props) => {
  const { t } = useTranslation();

  const activeRound = getActive(activeSeason.rounds);
  const lastRound = activeSeason?.rounds[0];

  if (!activeRound) {
    return (
      <div className="season__round">
        <p>
          {t("seasons.noActiveRound")}
          {lastRound?.stopDate ? (
            <>
              <br />
              {t("seasons.lastRoundFinished", { date: prettifyDateWithoutTime(lastRound.stopDate) })}
            </>
          ) : (
            ""
          )}
        </p>
        <Button primary onClick={handleRoundStart} loading={isRoundStateChanging} disabled={isDisabled}>
          {t("seasons.startRound")}
        </Button>
      </div>
    );
  }

  return (
    <div className="season__round">
      <p>{t("seasons.roundStarted", { date: prettifyDateWithoutTime(activeRound.startDate) })}</p>
      <Button color="red" onClick={handleRoundStop} loading={isRoundStateChanging} disabled={isDisabled}>
        {t("seasons.stopRound")}
      </Button>
    </div>
  );
};

export default SeasonsRoundAdmin;
