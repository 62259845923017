import React from "react";
import Datepicker, { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props extends Omit<ReactDatePickerProps, "placeholderText" | "selected" | "onChange"> {
  placeholder?: string;
  value?: string;
}

const AppDateTime: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  value,
  placeholder = "common.datetime_placeholder",
  isDisabled,
  isValid,
  ...props
}) => {
  const { t } = useTranslation();
  const selected = value && new Date(value);

  return (
    <Datepicker
      disabled={isDisabled}
      locale="lt"
      calendarClassName="react-datetimepicker"
      timeCaption={t("common.time")}
      showTimeSelect
      dateFormat="Pp"
      timeFormat="p"
      popperPlacement="bottom-start"
      timeIntervals={30}
      selected={selected}
      {...props}
      placeholderText={placeholder && t(placeholder)}
      onChange={(value) => onChange(value instanceof Date ? value.toISOString() : value)}
    />
  );
};

const AppDateTimeController = withController(AppDateTime);

export { AppDateTimeController };
export default AppDateTime;
