import React from "react";
import { Link } from "../features/guide/models";
import { useTranslation } from "react-i18next";

interface Props {
  items: Link[];
}

const Links = ({ items }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="links">
      <h3 className="links__title">{t("common.links")}</h3>
      {items.map((link) => (
        <a key={link.url} className="links__item" href={link.url} rel="noopener noreferrer" download>
          {link.title}
          <span>{t("common.download")}</span>
        </a>
      ))}
    </div>
  );
};

export default Links;
