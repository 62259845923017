import React from "react";
import { Loader, Select } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { StrictSelectProps } from "semantic-ui-react/dist/commonjs/addons/Select";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props extends StrictSelectProps {
  isLoading?: boolean;
  disableAutoSelect?: boolean;
}

const AppSelect: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  placeholder = "common.select_from_list",
  isDisabled: disabled,
  options,
  isValid,
  isLoading: loading,
  disableAutoSelect,
  ...props
}) => {
  const { t } = useTranslation();
  const isLoading = loading ?? options.length === 0;
  const isDisabled = disabled ?? isLoading;

  return (
    <Select
      icon={isLoading ? <Loader active /> : undefined}
      disabled={isDisabled}
      deburr
      {...props}
      options={options}
      placeholder={placeholder && t(placeholder)}
      onChange={(_, data) => onChange(data.value)}
      selectOnBlur={!disableAutoSelect}
    />
  );
};

const AppSelectController = withController(AppSelect);

export { AppSelectController };
export default AppSelect;
