import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserRoles } from "../../../app/auth/roles";
import { localStorageHelpers } from "../../../app/helpers/localStorageHelpers";
import { HasRole } from "../../../app/helpers/userHelpers";
import { RootState } from "../../../app/store/configureStore";
import ApplicationsModal from "../../applications/components/ApplicationsModal";
import FeedbackListModal from "../../companies/components/FeedbackListModal";
import ShadowingEditModal from "../../shadowing/ShadowingEditModal";
import { ShadowingWithApplications } from "../types";
import ResponsiblePersonModal from "./ResponsiblePersonModal";
import ShadowingApplyAction from "./ShadowingApplyAction";

interface Props {
  shadowing: ShadowingWithApplications;
  handleApplicationCancel: (applicationId: number) => void;
  openFeedbackModal?: boolean;
}

const ShadowingItemActions: React.FC<Props> = ({ shadowing, handleApplicationCancel, openFeedbackModal = false }) => {
  const { t } = useTranslation();
  const canApplyToShadowing = useSelector((state: RootState) => !!state.currentUser.seasonInfo?.canApply);
  const isStudent = HasRole(UserRoles.student);
  const isCompany = HasRole(UserRoles.company);
  const isSchool = HasRole(UserRoles.school);
  const isAdmin = HasRole(UserRoles.admin);

  const isPast = () => {
    const currentDate = new Date();
    const shadowingDate = new Date(shadowing.date);

    return shadowingDate <= currentDate;
  };

  const isOwner = isCompany && localStorageHelpers.getSubjectId() === shadowing.company?.id.toString();
  const notFull = shadowing.occupiedApplications < shadowing.participantsCapacity;
  const alreadyApplied = !!(isStudent && shadowing.applications?.length && !isPast());
  const canApply = isStudent && !alreadyApplied && !isPast() && notFull && canApplyToShadowing;
  const canSeeFeedback = isPast() && isOwner && shadowing.applications.length > 0;
  const canEditPosition = isOwner && !isPast();
  const canSeeResponsiblePerson = isOwner || isAdmin;
  const canSeeApplicants = (!isPast() && (isSchool || isOwner)) || isAdmin;
  const canViewNewApplications = !isPast() && (isOwner || isAdmin);

  const renderApplicationsModalTrigger = () => {
    return <ApplicationsModal approved={false} shadowing={shadowing} />;
  };

  const renderUpdatePositionTrigger = () => (
    <ShadowingEditModal shadowing={shadowing}>
      <Link to="#">{t("common.actions.update_position")}</Link>
    </ShadowingEditModal>
  );

  const renderAlreadyApplied = () => {
    if (shadowing.applications.length !== 1) return;

    const applicationId = shadowing.applications[0].id;

    return (
      <div>
        {t("shadowing.already_applied")}.{" "}
        <Link to="#" onClick={() => handleApplicationCancel(applicationId)}>
          {t("common.actions.cancel")}
        </Link>
      </div>
    );
  };

  return (
    <>
      {alreadyApplied && renderAlreadyApplied()}
      {canApply && <ShadowingApplyAction shadowing={shadowing} />}
      {canSeeApplicants && (
        <div>
          <ApplicationsModal approved={true} shadowing={shadowing} />
        </div>
      )}
      <div>
        {canSeeResponsiblePerson && <ResponsiblePersonModal item={shadowing} />}
        {canSeeResponsiblePerson && canEditPosition && " | "}
        {canEditPosition && <span>{renderUpdatePositionTrigger()}</span>}
      </div>
      {canSeeFeedback && (
        <div>
          <FeedbackListModal shadowing={shadowing} openOnLoad={openFeedbackModal} />
        </div>
      )}
      {canViewNewApplications && renderApplicationsModalTrigger()}
    </>
  );
};

export default ShadowingItemActions;
