import { FETCH_SHADOWINGS_REQUEST, SET_APPLICATION_FEEDBACK } from "../../app/store/types/shadowings";
import {
  ShadowingState,
  ADD_SHADOWING,
  AdActionTypes,
  DELETE_SHADOWING,
  EDIT_SHADOWING,
  FETCH_SHADOWINGS,
} from "../../app/store/types/shadowings";

const initialState: ShadowingState = {
  shadowings: [],
  isLoading: false,
};

export default (state = initialState, action: AdActionTypes): ShadowingState => {
  switch (action.type) {
    case ADD_SHADOWING:
      return {
        // shadowings: [...state.shadowings, action.shadowing],
        ...state,
        shadowings: [],
      };
    case DELETE_SHADOWING:
      return {
        ...state,
        shadowings: state.shadowings.filter(({ id }) => id !== action.id),
      };
    case EDIT_SHADOWING:
      return {
        ...state,
        shadowings: [],
        // shadowings: [...state.shadowings.filter((x) => x.id !== action.payload.id), action.payload],
      };
    case FETCH_SHADOWINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SHADOWINGS:
      return {
        ...state,
        isLoading: false,
        shadowings: action.shadowings,
        pagination: action.pagination,
      };
    case SET_APPLICATION_FEEDBACK:
      return {
        ...state,
        shadowings: state.shadowings.map((s) => {
          const containsFeedbackApplication = s.applications.some((a) => a.id === action.payload.applicationId);
          if (!containsFeedbackApplication) {
            return s;
          }

          const applications = s.applications.map((a) =>
            a.id === action.payload.applicationId ? { ...a, businessFeedback: action.payload.feedback } : a
          );
          return { ...s, applications };
        }),
      };
    default:
      return state;
  }
};
