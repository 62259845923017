import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import FormModal from "../../components/FormModal";
import useToaster from "../../app/hooks/useToaster";
import AppForm from "../../components/form/Form";
import { useAppForm } from "../../components/form/useForm";
import SchoolCreateForm from "./SchoolForm";
import agent from "../../app/api/agent";
import { SchoolCreateFormModel } from "./types";
import { HasRole } from "../../app/helpers/userHelpers";
import { UserRoles } from "../../app/auth/roles";
import { localStorageHelpers } from "../../app/helpers/localStorageHelpers";

interface Props {
  reloadSchools: () => void;
}

const SchoolCreateFormModal: React.FC<Props> = ({ reloadSchools }) => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [isSaving, setSaving] = useState(false);

  const [open, setOpen] = useState(false);

  const isMunicipality = HasRole(UserRoles.municipality);
  const municipalityId = isMunicipality ? localStorageHelpers.getSubjectId() : 2;

  const formHook = useAppForm<SchoolCreateFormModel>({
    defaultValues: {
      municipalityId: municipalityId.toString(),
      name: "",
      address: "",
      city: "",
      website: "",
      email: "",
      phoneNumber: "",
      contactInfo: {
        fullName: "",
        jobTitle: "",
        phoneNumber: "",
        email: "",
      },
    },
  });

  const handleSubmit = async (data: SchoolCreateFormModel) => {
    setSaving(true);
    try {
      await agent.Schools.create(data);
      success("schools.created");
      reloadSchools();
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("common.actions.create")}
      </Button>
      <Button negative disabled={isSaving} onClick={() => setOpen(false)}>
        {t("common.actions.cancel")}
      </Button>
    </>
  );

  return (
    <FormModal
      title="schools.create"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      isOpen={open}
      actions={actions}
      trigger={<Button content={t("schools.create")} primary />}
    >
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <SchoolCreateForm />
      </AppForm>
    </FormModal>
  );
};

export default SchoolCreateFormModal;
