import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReportDataType, ReportsData } from "../models";
import ReportsContainer from "../ReportsContainer";
import agent from "../../../app/api/agent";
import { ReportFilterContext } from "../useReportFilter";
import { ADMIN_REPORTS_INITIAL_FILTER_VALUE } from "./initial-filter-value";

const dataResourceFn = async (filter?: ListFilterValue): Promise<ReportsData[]> => {
  const params: {
    years?: number[];
    grade?: string[];
    gender?: string[];
    companyIds?: number[];
    schoolIds?: number[];
  } = {};
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map(getValue);
  }
  if (Array.isArray(filter?.grade) && filter?.grade.length) {
    params.grade = filter.grade.map(getValue);
  }
  if (Array.isArray(filter?.gender) && filter?.gender.length) {
    params.gender = filter.gender.map(getValue);
  }
  if (Array.isArray(filter?.company) && filter?.company.length) {
    params.companyIds = filter.company.map(getValue);
  }
  if (Array.isArray(filter?.school) && filter?.school.length) {
    params.schoolIds = filter.school.map(getValue);
  }

  const response = await agent.Reports.adminStats(params);

  const data: ReportsData[] = [
    {
      title: "reports.reflection.total",
      type: ReportDataType.Count,
      value: [
        {
          unit: "reports.reflection.totalRegisteredStudentsCount",
          value: response.totalRegisteredStudentsCount,
        },
        {
          unit: "reports.reflection.positionsCount",
          value: response.shadowingsCount,
        },
        {
          unit: "reports.reflection.applicationCount",
          value: response.applicationCount,
        },
        {
          unit: "reports.reflection.visitsCount",
          value: response.visitsCount,
        },
        {
          unit: "reports.reflection.finishedProgramCount",
          value: response.finishedProgramCount,
        },
      ],
    },
  ];

  if (response.bestShadowings?.length > 0) {
    data.push({
      title: "reports.reflection.bestShadowings",
      type: ReportDataType.Shadowing,
      value: response.bestShadowings,
    });
  }

  if (response.bestCompanies?.length > 0) {
    data.push({
      title: "reports.reflection.bestCompanies",
      type: ReportDataType.Top,
      value: response.bestCompanies.map((company) => ({
        title: company.companyTitle,
        value: company.visitsCount,
      })),
    });
  }

  if (response.mostActiveGrades?.length > 0) {
    data.push({
      title: "reports.reflection.mostActiveGrades",
      type: ReportDataType.Top,
      value: response.mostActiveGrades.map((item) => ({
        title: `${item.schoolName ? item.schoolName : ""} ${item.grade}`,
        value: item.count,
      })),
    });
  }

  if (response.bestSchools?.length > 0) {
    data.push({
      title: "reports.reflection.bestSchools",
      type: ReportDataType.Top,
      value: response.bestSchools.map((item) => ({
        title: item.name,
        value: item.visitsCount,
      })),
    });
  }

  return data;
};

const getValue = <T extends { value: any }>(x: T) => x.value;

const AdminReportsStatistics = () => {
  const { t } = useTranslation();
  const { years, grades, genders, schools, companies } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(
    () => [years, companies, schools, grades, genders],
    [years, grades, genders, schools, companies]
  );

  return (
    <>
      <PageTitle title={t("reports.statistics")} />
      <ReportsContainer
        dataResourceFn={dataResourceFn}
        initialFilterValue={ADMIN_REPORTS_INITIAL_FILTER_VALUE}
        filterConfig={filters}
      />
    </>
  );
};

export default AdminReportsStatistics;
