import React from "react";
import { Item } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import company from "../../icons/company.svg";
import location from "../../icons/location.svg";
import person from "../../icons/person.svg";
import date from "../../icons/date.svg";
import time from "../../icons/time.svg";
import { Excursion } from "./types";
import { UserRoles } from "../../app/auth/roles";
import { prettifyDate, prettifyDuration } from "../../app/helpers/dateHelpers";
import { HasRole } from "../../app/helpers/userHelpers";
import Multiline from "../../components/Multiline";
import { localStorageHelpers } from "../../app/helpers/localStorageHelpers";
import ResponsiblePersonModal from "../shadowings/components/ResponsiblePersonModal";
import ExcursionEditModal from "./ExcursionEditModal";
import { Link } from "react-router-dom";
import IndustryTags from "../industries/IndustryTags";
import { Industry } from "../industries/types";
import ExcursionOccupationToggle from "./ExcursionOccupationToggle";

const NOW = new Date();

interface Props {
  excursion: Excursion;
  hideLogo?: boolean;
  allIndustries: Industry[];
  onUpdate?: () => void;
}

const ExcursionItem = ({ excursion, hideLogo, allIndustries, onUpdate }: Props) => {
  const { t } = useTranslation();
  const isPast = () => {
    const currentDate = new Date();
    const shadowingDate = new Date(excursion.date);

    return shadowingDate <= currentDate;
  };
  const isAdmin = HasRole(UserRoles.admin);
  const isInactive = excursion.occupied || new Date(excursion.date) < NOW;
  const isCompany = HasRole(UserRoles.company);
  const isOwner = isCompany && localStorageHelpers.getSubjectId() === excursion.company?.id.toString();
  const canSeeResponsiblePerson = isOwner || isAdmin;
  const canEdit = isOwner && !isPast();

  const renderLocation = () => {
    return excursion.online ? t("shadowing.remote") : `${excursion.city}, ${excursion.address}`;
  };

  return (
    <Item className={`shadowing-list-item ${isInactive ? "shadowing-list-item--inactive" : ""}`}>
      {!hideLogo && <Item.Image src={excursion.company.logoUrl || "/assets/logo_placeholder.png"} alt="logo" />}
      <Item.Content>
        <Item.Header>{excursion.title}</Item.Header>
        <Item.Meta>
          <span>
            <img src={location} alt="location" />
            {renderLocation()}
          </span>
          <span>
            <img src={company} alt="company" />
            {excursion.company.name}
          </span>
          <span>
            <img src={person} alt="person" />
            {t("shadowing.slots_count")}: {excursion.participantsCapacity}
          </span>
          <span>
            <img src={date} alt="date" />
            {prettifyDate(excursion.date)}
          </span>
          {!!excursion.visitDuration && (
            <span>
              <img src={time} alt="duration" />
              {t("shadowing.visit_duration")}: {prettifyDuration(excursion.visitDuration)}
            </span>
          )}
          {!!excursion.occupied && <span>{t("excursion.occupied")}</span>}
        </Item.Meta>
        <Item.Description>
          <Multiline value={excursion.description} />
          {excursion.industryIds && <IndustryTags industryIds={excursion.industryIds} allIndustries={allIndustries} />}
        </Item.Description>
        <Item.Extra className="shadowing-item-extra">
          <div>
            {canSeeResponsiblePerson && <ResponsiblePersonModal item={excursion} />}
            {canSeeResponsiblePerson && canEdit && " | "}
            {canEdit && (
              <span>
                <ExcursionEditModal excursion={excursion} onUpdate={onUpdate}>
                  <Link to="#">{t("excursion.update_button")}</Link>
                </ExcursionEditModal>
              </span>
            )}
          </div>
          {isAdmin && <ExcursionOccupationToggle excursion={excursion} onUpdate={onUpdate} />}
          {isAdmin && (
            <div className="shadowing-item-extra__date">
              {t("shadowing.created_on")}
              {": "}
              <span>{prettifyDate(excursion.createdOn)}</span>
            </div>
          )}
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

export default ExcursionItem;
