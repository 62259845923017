import React from "react";
import { useSelector } from "react-redux";
import { updateShadowingSearchFilter } from "../actions";
import { RootState } from "../../../app/store/configureStore";
import DebouncedSearch from "../../../components/filters/DebouncedSearch";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const SearchFilter = () => {
  const dispatch = useAppDispatch();
  const value: string = useSelector((state: RootState) => state.shadowingsFilter.search);

  return (
    <DebouncedSearch
      value={value}
      placeholder="Ieškokite pozicijų"
      onChange={(v) => dispatch(updateShadowingSearchFilter(v))}
    />
  );
};

export default SearchFilter;
