import React from "react";
import { Table } from "semantic-ui-react";
import { prettifyDateWithoutTime } from "../../../app/helpers/dateHelpers";
import { Question, QuestionType, Reflections } from "../../reflections/models";
import { reflectionQuestions } from "../../reflections/reflection-questions";
import { useTranslation } from "react-i18next";
import Multiline from "../../../components/Multiline";

interface Props {
  reflections: Record<string, Reflections>;
}

const questions: Question[] = [1, 2, 12, 13].map((x) => reflectionQuestions[x]);

const MyCompetenceTable = ({ reflections }: Props) => {
  const { t } = useTranslation();
  const dates = Object.keys(reflections);

  const getAnswer = (question: Question, value?: string | number | boolean, defaultValue = "-"): React.ReactNode => {
    if (value == null) {
      return defaultValue;
    }

    if (question.type === QuestionType.Radio) {
      const answer = question.options.find((option) => option.value == value);
      return t(answer?.title ?? defaultValue);
    }

    if (question.type === QuestionType.Boolean) {
      const answer = !!Number(value);
      return t(answer ? "common.yes" : "common.no");
    }

    return <Multiline value={String(value)} />;
  };

  return (
    <Table definition unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          {dates.map((date) => (
            <Table.HeaderCell key={date}>{prettifyDateWithoutTime(date)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {questions.map((q) => (
          <Table.Row key={q.id}>
            <Table.Cell>{q.title}</Table.Cell>
            {dates.map((date) => (
              <Table.Cell key={q.id + "-" + date}>{getAnswer(q, reflections[date][q.id])}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default MyCompetenceTable;
