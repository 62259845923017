const TOKEN_KEY = "jwt";
const IS_LOGGED_IN_KEY = "isLoggedIn";
const ROLES_KEY = "roles";
const USER_ID_KEY = "userId";
const SUBJECT_ID_KEY = "subjectId";

const getUserToken = (): string => {
  return localStorage.getItem(TOKEN_KEY) || "";
};

const getUserRoles = (): string[] => {
  const roles = localStorage.getItem(ROLES_KEY) || "";

  return roles.split(",");
};

const getUserId = (): string => localStorage.getItem(USER_ID_KEY) || "";

const getSubjectId = (): string => localStorage.getItem(SUBJECT_ID_KEY) || "";

const setUserInfo = (userId: string, token: string, isLoggedIn: boolean, roles: string[], subjectId: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(IS_LOGGED_IN_KEY, isLoggedIn.toString());
  localStorage.setItem(ROLES_KEY, roles.toString());
  localStorage.setItem(USER_ID_KEY, userId);
  localStorage.setItem(SUBJECT_ID_KEY, subjectId);
};

const clearStorage = (): void => {
  localStorage.clear();
};

export const localStorageHelpers = {
  getUserToken,
  clearStorage,
  setUserInfo,
  getUserRoles,
  getUserId,
  getSubjectId,
};

export const localStorageKeys = { TOKEN_KEY, IS_LOGGED_IN_KEY, ROLES_KEY };
