import React, { useState } from "react";
import "./styles.less";
import FaqItem from "./FaqItem";
import { faqTabs } from "./data";
import { NonAuthRoutes } from "../../../app/auth/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePageFaq = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(faqTabs[0]);
  const isStudentTab = activeTab.key === "students";
  const isBusinessTab = activeTab.key === "companies";
  // const isSchoolTab = activeTab.label === "schools";

  return (
    <div className="homepage-faq article">
      <div className="homepage-faq-wrapper">
        <h2 className="article__title">D.U.K.</h2>

        {faqTabs.length > 1 && (
          <div className="quick-tabs">
            {faqTabs.map((tab) => (
              <button onClick={() => setActiveTab(tab)} className={tab === activeTab ? "active" : ""} key={tab.key}>
                {tab.label}
              </button>
            ))}
          </div>
        )}

        {isStudentTab && (
          <FaqItem title="Kaip vyksta registracijos procesas?">
            {"Kviečiame registruotis „Šešėliavimo“ platformoje čia - "}
            <Link to={NonAuthRoutes.registration}>{t("auth.registration")}</Link>
            {
              ". Pasirinkite savo mokyklą, pateikite teisingus kontaktinius duomenis ir viskas! Užsiregistravus, nepamirškite patvirtinti savo el. pašto adreso ir laukite, kol platformos administratorius patvirtins jūsų paskyrą."
            }
          </FaqItem>
        )}
        {isBusinessTab && (
          <FaqItem title="Kaip vyksta registracijos procesas?">
            {"Kviečiame registruotis „JA Šešėliavimo“ platformoje čia - "}
            <Link to={NonAuthRoutes.registration}>{t("auth.registration")}</Link>
            {
              ". Užsiregistravus, nepamirškite patvirtinti savo el. pašto adreso ir laukite, kol platformos administratorius patvirtins jūsų paskyrą."
            }
          </FaqItem>
        )}

        {activeTab.items.map((item, index) => (
          <FaqItem title={item.title} key={activeTab.key + index}>
            {item.text}
          </FaqItem>
        ))}
      </div>
    </div>
  );
};

export default HomePageFaq;
