import React from "react";
import { Grid } from "semantic-ui-react";
import { Application } from "../types";
import StudentApplicationItem from "./StudentApplicationItem";

interface Props {
  applications: Application[];
  handleConfirmation: (applicationId: number, approved: boolean) => void;
  handleCancellation: (applicationId: number) => void;
}

const StudentApplicationList: React.FC<Props> = ({ applications, handleConfirmation, handleCancellation }) => {
  return (
    <Grid className="student-application-item">
      {applications.map((application) => (
        <StudentApplicationItem
          key={application.id}
          application={application}
          handleConfirmation={handleConfirmation}
          handleCancellation={handleCancellation}
        />
      ))}
    </Grid>
  );
};

export default StudentApplicationList;
