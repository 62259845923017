import React, { Fragment } from "react";
import { Student } from "../types";
import { useTranslation } from "react-i18next";
import { Table, Segment, Button } from "semantic-ui-react";
import GenderImage from "../../../components/GenderImage";

interface IProps {
  pendingRegistrations: Student[];
  handleApprove: (studentId: number) => void;
  handleReject: (studentId: number) => void;
}

const PendingRegistrations: React.FC<IProps> = ({ pendingRegistrations, handleApprove, handleReject }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Segment placeholder padded className="pending-registrations-segment">
        <div className="pending-registrations">
          <div className="pending-registrations-text">
            {t("pending_list.title")}: {pendingRegistrations.length}
          </div>
          <Table basic="very" className="student-list pending">
            <Table.Body>
              {pendingRegistrations.map((student: Student) => (
                <Table.Row key={student.id}>
                  <Table.Cell>
                    <GenderImage gender={student.gender} />
                    {student.contactInfo.fullName}, {student.grade} kl.
                  </Table.Cell>
                  <Table.Cell>
                    <a href={"tel:" + student.contactInfo.phoneNumber}>{student.contactInfo.phoneNumber}</a>
                  </Table.Cell>
                  <Table.Cell>
                    <a href={"mailto:" + student.contactInfo.email}>{student.contactInfo.email}</a>
                  </Table.Cell>
                  <Table.Cell className="student-list-actions" singleLine>
                    <Button
                      primary
                      content={t("pending_list.actions.approve")}
                      onClick={() => handleApprove(student.id)}
                    />
                    <Button
                      primary
                      negative
                      content={t("pending_list.actions.reject")}
                      onClick={() => handleReject(student.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </Fragment>
  );
};

export default PendingRegistrations;
