import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../../../app/api/agent";
import { Student } from "../../../students/types";
import ProfileFormModal from "../ProfileFormModal";
import { StudentUpdateForm } from "./StudentProfileForm";
import useToaster from "../../../../app/hooks/useToaster";

interface Props {
  student: Student;
  isForceUpdateModal?: boolean;
  onUpdate?: (student: Student) => void;
}

const StudentProfileModal = ({ student, onUpdate, isForceUpdateModal }: Props) => {
  const { t } = useTranslation();
  const { apiError, success } = useToaster();

  const [triggerModal, setTriggerModal] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleUpdate = (data: StudentUpdateForm) => {
    setSaving(true);

    agent.Students.update(student.id, data.grade, data.phoneNumber)
      .then((response) => {
        success(t("my_profile.save_success"));
        setTriggerModal(!triggerModal);
        onUpdate?.(response);
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <ProfileFormModal
      isSaving={isSaving}
      triggerModal={triggerModal}
      subject={student}
      type="Student"
      handleUpdate={handleUpdate}
      isForceUpdateModal={isForceUpdateModal}
    />
  );
};

export default StudentProfileModal;
