import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyList from "./CompanyList";
import PageTitle from "../../../components/page-title/PageTitle";
import { CompanyProfileModel } from "../types";
import agent from "../../../app/api/agent";
import PendingRegistrations from "./PendingRegistrations";
import { Grid } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import useToaster from "../../../app/hooks/useToaster";
import {
  ListFilterConfig,
  ListFilterConfigItemType,
  ListFilterConfigItemMultiSelectOption,
} from "../../../components/list-filter/models";
import useMunicipalities from "../../../app/hooks/useMunicipalities";
import useCompanyTypes from "../../../app/hooks/useCompanyType";
import useIndustries from "../../../app/hooks/useIndustries";
import { UserRoles } from "../../../app/auth/roles";
import ListFilter from "../../../components/list-filter/ListFilter";
import { pluralize } from "../../../app/helpers/pluralize";

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [companies, setCompanies] = useState<CompanyProfileModel[]>([]);
  const [pendingRegistrations, setPendingRegistrations] = useState<CompanyProfileModel[]>([]);

  const [filter, setFilter] = useState<{
    companyTypes: ListFilterConfigItemMultiSelectOption[];
    municipalities: ListFilterConfigItemMultiSelectOption[];
    industries: ListFilterConfigItemMultiSelectOption[];
    search: string;
  }>({
    municipalities: [],
    companyTypes: [],
    industries: [],
    search: "",
  });

  const { municipalityOptions } = useMunicipalities();
  const { companyTypeOptions } = useCompanyTypes();
  const { industryOptions } = useIndustries();

  const filterConfig: ListFilterConfig = [
    {
      name: "municipalities",
      title: "companies.filter.municipality",
      type: ListFilterConfigItemType.MultiSelect,
      options: municipalityOptions,
      accessRole: [UserRoles.admin],
    },
    {
      name: "companyTypes",
      title: "companies.filter.type",
      type: ListFilterConfigItemType.MultiSelect,
      options: companyTypeOptions,
    },
    {
      name: "industries",
      title: "companies.filter.industry",
      type: ListFilterConfigItemType.MultiSelect,
      options: industryOptions,
      accessRole: [UserRoles.admin],
    },
    {
      name: "search",
      title: "companies.filter.search",
      type: ListFilterConfigItemType.Search,
    },
  ];

  useEffect(() => {
    loadPendingRegistrations();
  }, []);

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleApprove = (companyId: number): Promise<any> => {
    return agent.Companies.approveRegistration(companyId)
      .then(() => {
        success("companies.approved");
        loadPendingRegistrations();
        loadCompanies();
      })
      .catch((error) => {
        apiError(error);
      });
  };

  const handleReject = (companyId: number, reason: string): Promise<any> => {
    return agent.Companies.rejectRegistration(companyId, reason)
      .then(() => {
        loadPendingRegistrations();
        success("companies.rejected");
      })
      .catch((error) => {
        apiError(error);
      });
  };

  const loadPendingRegistrations = () => {
    agent.Companies.listPendingRegistrations().then((response) => {
      setPendingRegistrations(response);
    });
  };

  const loadCompanies = () => {
    setLoading(true);

    const params = {
      municipalityIds: filter.municipalities.length === 0 ? undefined : filter.municipalities.map(getValue),
      companyTypes: filter.companyTypes.length === 0 ? undefined : filter.companyTypes.map(getValue),
      industryIds: filter.industries.length === 0 ? undefined : filter.industries.map(getValue),
      search: filter.search ? filter.search : undefined,
    };

    agent.Companies.listProfiles(params)
      .then((response) => {
        setCompanies(response);
        setLoaded(true);
      })
      .finally(() => setLoading(false));
  };

  const renderApprovedCompanies = () => {
    if (isLoading) return <Loader />;

    return companies.length > 0 ? <CompanyList companies={companies} /> : t("common.no_records");
  };

  const renderTitle = () => {
    const count = companies.length;

    if (!isLoaded) return <PageTitle title={t("companies.page_title")} />;

    return <PageTitle title={`${count} ${t(pluralize("companies.page_title", count))}`} />;
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{renderTitle()}</Grid.Column>
      </Grid.Row>
      {pendingRegistrations.length > 0 ? (
        <Grid.Row>
          <Grid.Column>
            <PendingRegistrations
              companies={pendingRegistrations}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          </Grid.Column>
        </Grid.Row>
      ) : null}

      <ListFilter value={filter} config={filterConfig} onChange={(v) => setFilter(v as any)} />

      <Grid.Row>
        <Grid.Column>{renderApprovedCompanies()}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const getValue = <T extends { value: any }>(x: T) => x.value;

export default CompanyDashboard;
