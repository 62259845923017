import React from "react";
import { TextboxQuestion, ReflectionReportModelTextValue } from "./models";
import { useTranslation } from "react-i18next";
import ButtonLink from "../../components/ButtonLink";
import ReflectionReportTextModal from "./ReflectionReportTextModal";
import ReflectionReportTextAnswer from "./ReflectionReportTextAnswer";

interface Props {
  question: TextboxQuestion;
  value: ReflectionReportModelTextValue;
}

const answerLimit = 3;

const ReflectionReportText = ({ question, value }: Props) => {
  const { t } = useTranslation();

  if (value.answers.length === 0) {
    return <p>-</p>;
  }

  const answers = value.answers.reverse().slice(0, 3);

  return (
    <>
      <ul className="reflection-view__text-list">
        {answers.map((answer, i) => (
          <li key={i}>
            <ReflectionReportTextAnswer answer={answer} />
          </li>
        ))}
      </ul>
      {value.answers.length > answerLimit ? (
        <ReflectionReportTextModal question={question} value={value}>
          <ButtonLink>
            {t("reports.reflection.showAllTexts")} {`(${value.answers.length})`}
          </ButtonLink>
        </ReflectionReportTextModal>
      ) : null}
    </>
  );
};

export default ReflectionReportText;
