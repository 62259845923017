import { AxiosResponse } from "axios";

export const downloadFile = async (response: AxiosResponse<Blob, any>) => {
  const url = window.URL.createObjectURL(response.data);
  const filename = unescape(response.headers["content-disposition"].split("'").pop());
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
