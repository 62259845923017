import { Application } from "../../applications/types";
import {
  Question,
  ReflectionReportModelValue,
  QuestionType,
  ReflectionReportModelPointValue,
  ReflectionReportModelBooleanValue,
  ReflectionReportModelRadioValue,
  ReflectionReportModelTextValue,
} from "../../reflections/models";

export const calculateApplicationAnswers = (
  applications: Application[],
  question: Question
): ReflectionReportModelValue => {
  switch (question.type) {
    case QuestionType.Points:
      return getPointValue(applications, Number(question.id));

    case QuestionType.Boolean:
      return getBooleanValue(applications, Number(question.id));

    case QuestionType.Radio:
      return getRadioValue(applications, Number(question.id));

    case QuestionType.Textbox:
      return getTextValue(applications, Number(question.id));
  }
};

const getPointValue = (applications: Application[], questionId: number): ReflectionReportModelPointValue => {
  const totals = applications.reduce(
    (acc, application) => {
      const value = application.reflections?.[questionId] != null ? Number(application.reflections[questionId]) : 0;
      return {
        ...acc,
        answersCount: acc.answersCount + 1,
        value: acc.value + value,
      };
    },
    {
      answersCount: 0,
      value: 0,
    }
  );

  return {
    ...totals,
    value: totals.value / totals.answersCount,
    questionId,
  };
};

const getBooleanValue = (applications: Application[], questionId: number): ReflectionReportModelBooleanValue => {
  const answers = applications.reduce(
    (acc, application) => {
      const value = application.reflections?.[questionId];
      if (value == null) {
        return acc;
      }

      return {
        trueCount: acc.trueCount + (value == "1" ? 1 : 0),
        falseCount: acc.falseCount + (value == "1" ? 0 : 1),
      };
    },
    {
      trueCount: 0,
      falseCount: 0,
    }
  );

  const totalCount = answers.falseCount + answers.trueCount;

  return {
    questionId,
    answers: [
      { answer: 1, answersCount: answers.trueCount, percentage: answers.trueCount / totalCount },
      { answer: 0, answersCount: answers.falseCount, percentage: answers.falseCount / totalCount },
    ],
  };
};

const getRadioValue = (applications: Application[], questionId: number): ReflectionReportModelRadioValue => {
  const answers = applications.reduce<{ [answerId: number]: number }>((acc, application) => {
    const value = application.reflections?.[questionId] as number | undefined;
    if (value == null) {
      return acc;
    }

    return {
      ...acc,
      [value]: (acc[value] ?? 0) + 1,
    };
  }, {});

  const totalCount = Object.values(answers).reduce((acc, count) => acc + count, 0);

  return {
    questionId,
    answers: Object.keys(answers).map((answer) => ({
      answer: Number(answer),
      answersCount: answers[Number(answer)],
      percentage: answers[Number(answer)] / totalCount,
    })),
  };
};

const getTextValue = (applications: Application[], questionId: number): ReflectionReportModelTextValue => {
  const answers = applications.flatMap((application) => {
    const value = application.reflections?.[questionId] as string | undefined;

    return value ? [{ name: application.student.contactInfo.fullName, value }] : [];
  });

  return {
    questionId,
    answers,
  };
};
