import {
  CompanyState,
  CompanyActionTypes,
  ADD_COMPANY,
  DELETE_COMPANY,
  EDIT_COMPANY,
} from "../../app/store/types/companies";

const initialState: CompanyState = {
  companies: [],
};

export default (state = initialState, action: CompanyActionTypes): CompanyState => {
  switch (action.type) {
    case ADD_COMPANY:
      return {
        companies: [...state.companies, action.company],
      };
    case DELETE_COMPANY:
      return {
        companies: state.companies.filter(({ id }) => id !== action.id),
      };
    case EDIT_COMPANY:
      return {
        companies: [...state.companies.filter((x) => x.id !== action.payload.id), action.payload],
      };
    default:
      return {
        companies: state.companies,
      };
  }
};
