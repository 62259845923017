import { useMemo, useState } from "react";
import useUserRoles from "../../app/hooks/useUserRoles";
import {
  ListFilterConfig,
  ListFilterConfigItemMultiSelectOption,
  ListFilterConfigItemType,
} from "../../components/list-filter/models";

const useUsersFilter = () => {
  const [value, setValue] = useState<{
    roles: ListFilterConfigItemMultiSelectOption[];
    search: string;
  }>({ roles: [], search: "" });
  const { roleOptions } = useUserRoles();
  const config: ListFilterConfig = useMemo(
    () => [
      {
        name: "roles",
        title: "users.filter.roles",
        type: ListFilterConfigItemType.MultiSelect,
        options: roleOptions,
      },
      {
        name: "search",
        title: "users.filter.search",
        type: ListFilterConfigItemType.Search,
      },
    ],
    [roleOptions]
  );

  return { value, setValue, config };
};

export default useUsersFilter;
