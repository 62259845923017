import { useMemo } from "react";

interface EventTypes {
  shadowingCreated: number;
  shadowingUpdated: number;
  shadowingCanceled: number;
}

const element = document.createElement("div");

function on<T extends keyof EventTypes, P extends EventTypes[T]>(type: T, cb: (payload: P) => void): () => void {
  const callback = ({ detail }: any) => cb(detail);
  element.addEventListener(type, callback);

  return () => element.removeEventListener(type, callback);
}

function emit<T extends keyof EventTypes, P extends EventTypes[T]>(type: T, payload: P): void {
  element.dispatchEvent(new CustomEvent(type, { detail: payload }));
}

const useEventBus = () => {
  return useMemo(() => ({ on, emit }), []);
};

export default useEventBus;
