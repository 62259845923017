import React from "react";
import { Button } from "semantic-ui-react";
import { NonAuthRoutes } from "../../../app/auth/routes";
import useIsLoggedIn from "../../../app/hooks/useIsLoggedIn";
import { useRandomValue } from "./useRandomValue";
import "./styles.less";
import { useNavigate } from "react-router-dom";

const words = [
  "marketingo",
  "pardavimų",
  "vadybos",
  "teisės",
  "meno",
  "robotikos",
  "informacinių technologijų",
  "personalo valdymo",
];

const HomePagePromo: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const randomWord = useRandomValue(words);

  return (
    <div className="homepage-promo">
      <article className="article">
        <div className="article__content">
          <h1 className="article__title">
            Atsisėsk į {randomWord} <br />
            profesionalo kėdę
          </h1>
          <div className="article__body">
            <p>
              Pradėk savo karjeros kelionę su ugdymo karjerai programa LJA „Šešėliavimas“! Pasinaudok galimybe susitikti
              su įvairių sričių profesionalais ir atrask organizacijas bei darbo vietas, kurios gali tapti tavo karjeros
              kelio pradžia. Registruokis jau dabar ir pasiruošk lapkričio 11-15 dienomis leistis į įkvepiantį
              šešėliavimo nuotykį!
            </p>
          </div>
          {!isLoggedIn && (
            <div className="article__actions">
              <Button primary onClick={() => navigate(NonAuthRoutes.registration)}>
                Registracija
              </Button>
            </div>
          )}
        </div>
      </article>
    </div>
  );
};

export default HomePagePromo;
