import React, { useMemo } from "react";
import Typical from "react-typical";

export const useRandomValue = (values: string[], time = 3000) => {
  const steps = useMemo(
    () =>
      values.flatMap((value) => {
        const wait = time + value.length * 90;
        return [value, wait];
      }),
    [time, values]
  );

  return <Typical loop={Infinity} steps={steps} wrapper="span" />;
};
