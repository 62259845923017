import React, { Fragment, PropsWithChildren } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { SiteUser } from "./types";
import AppForm from "../../components/form/Form";
import { useAppForm } from "../../components/form/useForm";
import ButtonLink from "../../components/ButtonLink";

export interface UserEditForm {
  email: string;
}

interface Props {
  isSaving: boolean;
  user: SiteUser;
  handleSubmit: (data: UserEditForm) => void;
  handlePasswordReset: (email: string) => void;
}

const UserUpdateForm = ({ isSaving, user, handleSubmit, handlePasswordReset, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const formHook = useAppForm<UserEditForm>({
    defaultValues: { email: user.email },
  });

  return (
    <Fragment>
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <AppForm.Group name="email" label="common.email">
          <AppForm.Email name="email" rules={{ required: true }} />
        </AppForm.Group>
      </AppForm>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start" }}>
        <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
          {t("common.actions.confirm")}
        </Button>
        <ButtonLink onClick={() => handlePasswordReset(user.email)}>
          {t("users.send_password_reset_link_to_admin")}
        </ButtonLink>
      </div>
      {children}
    </Fragment>
  );
};

export default UserUpdateForm;
