import React from "react";
import { CompetenciesModel } from "./models";
import { ListFilterConfig, ListFilterValue } from "../../components/list-filter/models";
import ListFilter from "../../components/list-filter/ListFilter";
import Loader from "../../components/Loader";
import { Grid } from "semantic-ui-react";
import CompetenciesGraph from "./CompetenciesGraph";

interface Props {
  isLoading: boolean;
  data?: CompetenciesModel;
  filterConfig?: ListFilterConfig;
  filterValue?: ListFilterValue;
  onFilterChange?: (value: ListFilterValue) => void;
}

const Competencies = ({ isLoading, data, filterConfig, filterValue, onFilterChange }: Props) => {
  const renderFilter = () =>
    filterConfig && (
      <Grid className="list-filter">
        <ListFilter config={filterConfig} value={filterValue!} onChange={onFilterChange!} />
      </Grid>
    );

  return (
    <>
      {renderFilter()}
      <div className="block block--mh">{isLoading ? <Loader /> : <CompetenciesGraph data={data!} />}</div>
    </>
  );
};

export default Competencies;
