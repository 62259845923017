import React from "react";
import "./welcome-photos.less";

const WelcomePhotos = () => {
  return (
    <div className="welcome-photos">
      <img src="/assets/welcome/1.jpg" alt="" className="welcome-photos__img1" />
      <img src="/assets/welcome/2.jpg" alt="" className="welcome-photos__img2" />
      <img src="/assets/welcome/3.jpg" alt="" className="welcome-photos__img3" />
    </div>
  );
};

export default WelcomePhotos;
