import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import useToaster from "../../../../app/hooks/useToaster";
import PageTitle from "../../../../components/page-title/PageTitle";
import { MunicipalityProfileModel } from "../../../municipalities/types";
import PasswordChangeModal from "../../../user/components/passwordChange/PasswordChangeModal";
import ProfileFormModal from "../ProfileFormModal";
import ProfileSegment from "../ProfileSegment";
import { MunicipalityUpdateForm } from "./MunicipalityProfileForm";

const MunicipalityProfile = () => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [triggerModal, setTriggerModal] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleUpdate = (data: MunicipalityUpdateForm) => {
    setSaving(true);

    agent.Municipalities.update(data.id, data)
      .then((response) => {
        setMunicipality(response);
        success(t("my_profile.save_success"));
        setTriggerModal(!triggerModal);
      })
      .catch(() => {
        apiError(t("common.errors.general"));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const [municipality, setMunicipality] = useState<MunicipalityProfileModel>({
    id: 0,
    name: "",
    address: "",
    city: "",
    email: "",
    phoneNumber: "",
    contactInfo: {
      fullName: "",
      phoneNumber: "",
      email: "",
    },
  });

  useEffect(() => {
    agent.Municipalities.getProfile().then((response) => setMunicipality(response));
  }, []);

  const fields = {
    "common.address": `${municipality.address}, ${municipality.city}`,
    "common.email": municipality.email,
    "common.phone": municipality.phoneNumber,
  };

  const personFields = {
    "common.email": municipality.contactInfo.email,
    "common.phone": municipality.contactInfo.phoneNumber,
    "common.password": <PasswordChangeModal />,
  };

  const columns = [
    { header: municipality.name, values: fields },
    { header: municipality.contactInfo.fullName, values: personFields },
  ];

  return (
    <Fragment>
      <PageTitle title={t("common.my_profile")} />
      <Segment padded="very">
        <ProfileSegment columns={columns} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ProfileFormModal
                isSaving={isSaving}
                triggerModal={triggerModal}
                subject={municipality}
                type="Municipality"
                handleUpdate={handleUpdate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default MunicipalityProfile;
