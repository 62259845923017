import React, { PropsWithChildren, useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Excursion, ExcursionEditFormModel } from "./types";
import AppForm from "../../components/form/Form";
import agent from "../../app/api/agent";
import { getUpdateRequestPayload } from "./utils";
import useToaster from "../../app/hooks/useToaster";
import FormModal from "../../components/FormModal";
import { useAppForm } from "../../components/form/useForm";
import { getTime } from "../../app/helpers/dateHelpers";
import ExcursionForm from "./ExcursionForm";

interface Props {
  excursion: Excursion;
  onUpdate?: () => void;
}

const ExcursionEditModal: React.FC<PropsWithChildren<Props>> = ({ children: trigger, excursion, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const defaultValues: ExcursionEditFormModel = {
    ...excursion,
    visitDuration: excursion.visitDuration ?? null!,
    useHqAddress: !excursion.city && !excursion.address,
    time: getTime(excursion.date),
    industryIds: excursion.industryIds ?? [],
  };
  const formHook = useAppForm({ defaultValues });

  const isDisabled = isSaving;

  const handleSubmit = async (model: ExcursionEditFormModel) => {
    setSaving(true);

    try {
      await agent.Excursions.update(excursion.id, getUpdateRequestPayload(model));
      success("excursion.form.successfully_updated");
      onUpdate?.();
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    setShowConfirmation(false);
    setSaving(true);

    try {
      await agent.Excursions.delete(excursion.id);
      success("excursion.form.successfully_canceled");
      onUpdate?.();
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isDisabled} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("excursion.form.update_button")}
      </Button>
      <Button negative disabled={isDisabled} onClick={() => setOpen(false)}>
        {t("common.actions.cancel")}
      </Button>
      <Button color="red" disabled={isDisabled} onClick={() => setShowConfirmation(true)}>
        {t("excursion.form.delete_button")}
      </Button>
      <Confirm
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size="tiny"
        content={t("excursion.form.delete_confirmation.text")}
        open={showConfirmation}
        confirmButton={t("excursion.form.delete_confirmation.yes")}
        cancelButton={t("excursion.form.delete_confirmation.no")}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
      />
    </>
  );

  return (
    <FormModal
      title="excursion.edit_excursion"
      {...{ isDisabled, isOpen, actions, trigger }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <p>{t("excursion.form.edit_help")}</p>
      <AppForm formHook={formHook} isDisabled={isDisabled} onSubmit={handleSubmit}>
        <ExcursionForm isEditMode />
      </AppForm>
    </FormModal>
  );
};

export default ExcursionEditModal;
