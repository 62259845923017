import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useCallback } from "react";

const useError = () => {
  const { t, i18n } = useTranslation();

  const getApiErrorContent = useCallback(
    (apiError: AxiosError, fallback = "errors.api.default") => {
      const status = apiError?.response?.status;
      const message = apiError?.response?.data;

      if (status === 400 && typeof message === "string") {
        return t(`errors.api.${message}`);
      }

      if (i18n.exists(`errors.api.${status}`)) {
        return t(`errors.api.${status}`);
      }

      return t(fallback);
    },
    [i18n, t]
  );

  return { getApiErrorContent };
};

export default useError;
