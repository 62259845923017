import React, { useEffect, useState } from "react";
import "./styles.less";
import PageTitle from "../../components/page-title/PageTitle";
import agent from "../../app/api/agent";
import Loader from "../../components/Loader";
import { CompanyInfoModel } from "./models";

const CompaniesInfoPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [companies, setCompanies] = useState<CompanyInfoModel[]>([]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = () => {
    setLoading(true);

    agent.Companies.listCompaniesForInfoPage()
      .then((response) => {
        setCompanies(response);
        setLoaded(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <article className="companies-page">
      <section className="companies-slogan">
        <div className="companies-slogan__content">
          <PageTitle
            title={`Organizacijos`}
            description={
              isLoaded
                ? `LJA Šešėliavimo programoje dalyvauja ir mokinius yra pasiruošusios priimti įvairių sričių organizacijos, kurių yra net ${companies.length}.`
                : ``
            }
          />
        </div>
      </section>

      {isLoading ? (
        <Loader />
      ) : (
        <section className="companies-list">
          {companies.map((company) => (
            <article key={company.id} className="companies-item">
              <div className="companies-item__images">
                <img src={company.logoUrl} alt={company.name} width={150} />
              </div>
              <div className="companies-item__content">
                <h3 className="companies-item__title">{company.name}</h3>
              </div>
            </article>
          ))}
        </section>
      )}
    </article>
  );
};

export default CompaniesInfoPage;
