import React from "react";
import { Grid } from "semantic-ui-react";

type PhotoCount = 3 | 5;

interface Props {
  count: PhotoCount;
}

const PagePhotos: React.FC<Props> = ({ count }) => {
  const render = () => {
    switch (count) {
      case 3:
        return (
          <Grid>
            <Grid.Row textAlign="right">
              <Grid.Column className="welcome-photo-left" width={12}>
                <img src="/assets/welcome/1.png" alt="primary" width="300px" />
              </Grid.Column>
              <Grid.Column className="welcome-photo-right" width={4}>
                <img src="/assets/welcome/2.png" alt="primary" width="165px" />
                <img src="/assets/welcome/3.png" alt="primary" width="165px" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case 5:
        return (
          <div className="page-photos-five">
            <div className="page-photos-five__first-column">
              <img src="/assets/page_photos/5.png" alt="primary" width="165px" />
            </div>
            <div className="page-photos-five__second-column">
              <img src="/assets/page_photos/4.png" alt="primary" width="165px" />
              <img src="/assets/page_photos/3.png" alt="primary" width="165px" />
            </div>
            <div className="page-photos-five__third-column">
              <img src="/assets/page_photos/1.png" alt="primary" width="165px" />
              <img src="/assets/page_photos/2.png" alt="primary" width="165px" />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return <>{render()}</>;
};

export default PagePhotos;
