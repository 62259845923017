import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Search from "../../components/filters/Search";

const useItemFilter = <T extends { name: string }>(items: T[]) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const filteredItems = useMemo(() => {
    const words = value
      .split(" ")
      .map((word) => word.trim().toLowerCase())
      .filter(Boolean);

    if (!words.length) {
      return items;
    }

    return items.filter((item) => words.every((word) => item.name.toLowerCase().includes(word)));
  }, [items, value]);

  const renderFilter = () => (
    <div className="item-filter">
      <Search value={value} placeholder={t("common.filter")} onChange={(event) => setValue(event.target.value)} />
    </div>
  );

  return {
    renderFilter,
    filteredItems,
  };
};

export default useItemFilter;
