export const genderTranslation = (gender: string, t: any) => {
  switch (gender) {
    case "M":
      return t("gender.m");
    case "F":
      return t("gender.f");
    default:
      return "";
  }
};
