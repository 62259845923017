import React from "react";
import { ReportsData, ReportDataType } from "./models";
import { ListFilterConfig, ListFilterValue } from "../../components/list-filter/models";
import ListFilter from "../../components/list-filter/ListFilter";
import ReportsCounts from "./ReportsCounts";
import ReportsShadowing from "./ReportsShadowing";
import ReportsTop from "./ReportsTop";
import Loader from "../../components/Loader";
import { Grid } from "semantic-ui-react";
import "./styles.less";

interface Props {
  isLoading: boolean;
  data: ReportsData[];
  filterConfig?: ListFilterConfig;
  filterValue?: ListFilterValue;
  onFilterChange?: (value: ListFilterValue) => void;
}

const Reports = ({ data, isLoading, filterConfig, filterValue, onFilterChange }: Props) => {
  const renderFilter = () =>
    filterConfig && (
      <Grid className="list-filter">
        <ListFilter config={filterConfig} value={filterValue!} onChange={onFilterChange!} />
      </Grid>
    );

  const renderData = (data: ReportsData) => {
    switch (data.type) {
      case ReportDataType.Count:
        return <ReportsCounts key={data.title} data={data} />;
      case ReportDataType.Shadowing:
        return data.value.length > 0 ? <ReportsShadowing key={data.title} data={data} /> : null;
      case ReportDataType.Top:
        return data.value.length > 0 ? <ReportsTop key={data.title} data={data} /> : null;
      default:
        return null;
    }
  };

  return (
    <>
      {renderFilter()}
      <div className="block block--mh">{isLoading ? <Loader /> : data.map(renderData)}</div>
    </>
  );
};

export default Reports;
