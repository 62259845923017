import React from "react";
import Icons from "../icons/icons.svg";

interface IProps {
  name: string;
}

const ShIcon: React.FC<IProps> = ({ name }) => {
  return (
    <i className="icon">
      <svg className={`icon sh-icon-${name}`} width={24} height={24}>
        <use xlinkHref={`${Icons}#sh-icon-${name}`} />
      </svg>
    </i>
  );
};

export default ShIcon;
