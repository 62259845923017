import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props extends Omit<CheckboxProps, "value"> {
  options: Array<{ title: string; value: string | number | boolean }>;
}

const AppRadio: React.FC<PropsInjectedByController<string | number | boolean> & Props> = ({
  onChange,
  value: groupValue,
  label,
  isDisabled,
  isValid,
  options,
  name,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ul className="radio-group">
      {options.map(({ title, value }) => (
        <li key={String(value)}>
          <Checkbox
            radio
            disabled={isDisabled}
            {...props}
            label={t(title)}
            name={name}
            checked={groupValue === value}
            onChange={() => onChange(value)}
          />
        </li>
      ))}
    </ul>
  );
};

const AppRadioController = withController(AppRadio);

export { AppRadioController };
export default AppRadio;
