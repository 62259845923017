import React from "react";
import { PointQuestion, ReflectionReportModelPointValue } from "./models";
import { round } from "../../app/helpers/numberHelpers";
import Badge from "../../components/Badge";

interface Props {
  question: PointQuestion;
  value: ReflectionReportModelPointValue;
}

const ReflectionReportPoints = ({ value }: Props) => {
  return (
    <ul className="reflection-view__points">
      <li>
        <strong>{round(value.value, undefined, false)}</strong> <Badge value={value.answersCount} />
      </li>
    </ul>
  );
};

export default ReflectionReportPoints;
