import React from "react";
import { useTranslation } from "react-i18next";
import TextBlock from "../../components/TextBlock";
import { SchoolRegistrationModel } from "./types";

interface Props {
  model: SchoolRegistrationModel;
}

const SchoolRegistrationSuccess = ({ model }: Props) => {
  const { t } = useTranslation();
  const email = model.contactInfo.email;

  return (
    <TextBlock
      title={t("auth.steps.school.success.title")}
      body={
        <>
          <p>{t("auth.steps.school.success.body")}</p>
          <p>
            {t("auth.steps.school.success.email")}
            <a href={"mailto:" + email}>{email}</a>
          </p>
        </>
      }
    />
  );
};

export default SchoolRegistrationSuccess;
