import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "semantic-ui-react";

import { pdfjs, Document, Page } from "react-pdf";
import { useTranslation } from "react-i18next";
import SimplePagination from "../../../components/SimplePagination";
import Loader from "../../../components/Loader";
import ShIcon from "../../../components/ShIcon";
import ButtonIcon from "../../../components/ButtonIcon";
import useArrowNavigation from "../../../app/hooks/useArrowNavigation";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IProps {
  url: string;
}

const GuidePdf = ({ url }: IProps) => {
  const [pagesCount, setPagesCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);
  const pdfRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const goToNextPage = useCallback(() => setPageNumber((p) => (p < pagesCount ? p + 1 : p)), [pagesCount]);
  const goToPrevPage = useCallback(() => setPageNumber((p) => (p > 2 ? p - 1 : 1)), []);
  const arrowNavigation = useMemo(
    () => ({ ArrowLeft: goToPrevPage, ArrowRight: goToNextPage }),
    [goToNextPage, goToPrevPage]
  );
  useArrowNavigation(arrowNavigation);

  const isFullscreen =
    pdfRef.current?.offsetWidth === window.innerWidth && pdfRef.current?.offsetHeight === window.innerHeight;

  const toggleZoom = () => {
    if (isFullscreen) {
      window.document.exitFullscreen();
    } else {
      pdfRef.current?.requestFullscreen();
    }
  };

  const updateWidth = () => {
    setWidth(pdfRef.current?.offsetWidth ?? 0);
  };
  useEffect(() => {
    updateWidth();

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const pagination = pagesCount > 1 && (
    <div className="guide__pagination">
      <SimplePagination count={pagesCount} active={pageNumber} onChange={setPageNumber} />
    </div>
  );

  return (
    <>
      <div className="guide__pdf" ref={pdfRef}>
        <Document file={url} onLoadSuccess={({ numPages }) => setPagesCount(numPages)} loading={<Loader />}>
          <Page pageNumber={pageNumber} width={width} />
        </Document>
        <div className="guide__pdf-actions">
          {pageNumber < pagesCount && (
            <Button primary type="button" onClick={goToNextPage}>
              {t("common.next")}
            </Button>
          )}
          {pageNumber > 1 && (
            <Button basic type="button" onClick={goToPrevPage}>
              {t("common.previous")}
            </Button>
          )}
        </div>
        <ButtonIcon
          onClick={toggleZoom}
          className="guide__zoom"
          icon={<ShIcon name={isFullscreen ? "zoomout" : "zoomin"} />}
        />
        {isFullscreen && pagination}
      </div>
      {!isFullscreen && pagination}
    </>
  );
};

export default GuidePdf;
