import React from "react";
import { Input, InputOnChangeData } from "semantic-ui-react";
import ShIcon from "../ShIcon";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  placeholder: string;
  value: string;
}

const Search: React.FC<Props> = ({ value, placeholder, onChange: handleChange }) => {
  return (
    <Input
      style={{ maxWidth: 255 }}
      icon={<ShIcon name="search" />}
      iconPosition="left"
      className="position-search"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />
  );
};

export default Search;
