import React from "react";
import ShadowingMetadata from "../../../components/shadowing/ShadowingMetadata";
import ScaledImage from "../../../components/ScaledImage";
import { ShadowingLike } from "../../reports/models";

interface Props {
  shadowing: ShadowingLike;
}

const ShadowingPreview = ({ shadowing }: Props) => {
  return (
    <div className="preview-item preview-item--shadowing">
      <div className="preview-item__image">
        <ScaledImage url={shadowing.companyLogoUrl || "/assets/logo_placeholder.png"} width={124} height={58} />
      </div>
      <div className="preview-item__content">
        <h2 className="preview-item__title">{shadowing.position}</h2>
        <div className="preview-item__meta">
          <ShadowingMetadata companyName={shadowing.companyTitle} personValue={String(shadowing.visitsCount)} />
        </div>
      </div>
    </div>
  );
};

export default ShadowingPreview;
