import { Company } from "../../../features/companies/types";

export interface CompanyState {
  companies: Company[];
}

export const ADD_COMPANY = "ADD_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";

interface AddCompanyAction {
  type: typeof ADD_COMPANY;
  company: Company;
}

interface EditCompaniesAction {
  type: typeof EDIT_COMPANY;
  payload: Company;
}

interface DeleteCompanyAction {
  type: typeof DELETE_COMPANY;
  id: number;
}

export type CompanyActionTypes = AddCompanyAction | DeleteCompanyAction | EditCompaniesAction;
