import React from "react";
import { useTranslation } from "react-i18next";
import TypeSelectionStep from "./registration/TypeSelectionStep";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../app/auth/routes";
import BaseAuthPage from "./BaseAuthPage";
import usePageClass from "../../../app/hooks/usePageClass";
import AuthLayout from "./AuthLayout";

const RegistrationPage = () => {
  const { t } = useTranslation();
  usePageClass("page-registration");

  return (
    <AuthLayout>
      <BaseAuthPage
        header={<h1>{t("auth.registration")}</h1>}
        content={<TypeSelectionStep />}
        footer={<Link to={NonAuthRoutes.login}>{t("auth.already_have_account")}</Link>}
      />
    </AuthLayout>
  );
};

export default RegistrationPage;
