import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import AppForm from "../../../../components/form/Form";
import { useAppForm } from "../../../../components/form/useForm";

interface FormData {
  email: string;
}

const PasswordRecoveryForm = () => {
  const { t } = useTranslation();

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const formHook = useAppForm<FormData>({ defaultValues: { email: "" } });

  const onSubmit = (data: FormData) => {
    agent.Users.passwordReset(data.email)
      .then(() => {
        formHook.reset({ email: "" });
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setSuccess(false);
        setError(true);
      });
  };

  const renderError = () => {
    if (!error) return null;

    return <Message negative>{t("auth.password_reset.incorrect_email_msg")}</Message>;
  };

  const renderSuccess = () => {
    if (!success) return null;

    return <Message positive>{t("auth.password_reset.success_msg")}</Message>;
  };

  return (
    <Fragment>
      {renderSuccess()}
      {renderError()}
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <AppForm.Group name="email" label="common.email">
          <AppForm.Email name="email" rules={{ required: true }} />
        </AppForm.Group>
      </AppForm>

      <Button primary onClick={formHook.handleSubmit(onSubmit)}>
        {t("auth.password_reset.form.confirm")}
      </Button>
    </Fragment>
  );
};

export default PasswordRecoveryForm;
