import React, { PropsWithChildren, useState } from "react";
import { Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ShadowingCreateFormModel } from "./types";
import ShadowingForm from "./ShadowingForm";
import AppForm from "../../components/form/Form";
import { emptyShadowingModel } from "./emptyShadowingModel";
import agent from "../../app/api/agent";
import { getCreateRequestPayload } from "./utils";
import useEventBus from "../../app/hooks/useEventBus";
import useToaster from "../../app/hooks/useToaster";
import FormModal from "../../components/FormModal";
import { useAppForm } from "../../components/form/useForm";
import DuplicateShadowingDropdown from "./DuplicateDropdown";
import { Shadowing } from "../shadowings/types";

const ShadowingCreateModal = ({ children: trigger }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { emit } = useEventBus();
  const { success, apiError } = useToaster();
  const formHook = useAppForm({ defaultValues: emptyShadowingModel });
  const isDisabled = isSaving;

  const clearForm = () => formHook.reset(emptyShadowingModel);

  const close = () => {
    clearForm();
    setOpen(false);
  };

  const handleSubmit = async (model: ShadowingCreateFormModel) => {
    setSaving(true);
    try {
      const id = await agent.Shadowings.create(getCreateRequestPayload(model));
      success("shadowing.successfully_created");
      emit("shadowingCreated", id);
      close();
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const prefillShadowingData = (shadowing: Shadowing) => {
    formHook.setValue("position", shadowing.position);
    formHook.setValue("description", shadowing.description);
    formHook.setValue("visitDuration", shadowing.visitDuration!);
    formHook.setValue("participantsCapacity", shadowing.participantsCapacity);
    formHook.setValue("online", shadowing.online);
    formHook.setValue(
      "responsiblePerson.fullName",
      `${shadowing.responsiblePerson.firstName} ${shadowing.responsiblePerson.surname}`
    );
    formHook.setValue("responsiblePerson.jobTitle", shadowing.responsiblePerson.jobTitle);
    formHook.setValue("responsiblePerson.email", shadowing.responsiblePerson.email);
    formHook.setValue("responsiblePerson.phoneNumber", shadowing.responsiblePerson.phoneNumber);
  };

  const actions = (
    <>
      <Button primary loading={isSaving} disabled={isDisabled} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("shadowing.create_button")}
      </Button>
      <Button negative disabled={isDisabled} onClick={close}>
        {t("common.actions.cancel")}
      </Button>
    </>
  );

  return (
    <FormModal
      title="shadowing.create_position"
      {...{ isDisabled, isOpen, actions, trigger }}
      onClose={close}
      onOpen={() => setOpen(true)}
    >
      <AppForm formHook={formHook} isDisabled={isDisabled} onSubmit={handleSubmit}>
        <DuplicateShadowingDropdown onChange={prefillShadowingData} />
        <Divider />
        <ShadowingForm />
      </AppForm>
    </FormModal>
  );
};

export default ShadowingCreateModal;
