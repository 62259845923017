import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReflectionReportModel } from "../../reflections/models";
import ReflectionReportContainer from "../../reflections/ReflectionReportContainer";
import { ReportFilterContext } from "../useReportFilter";

const CompanyReportsStudentFeedback = () => {
  const { t } = useTranslation();

  const { years, grades } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years, grades], [years, grades]);

  return (
    <>
      <PageTitle title={t("reports.studentReflections")} />
      <ReflectionReportContainer filterConfig={filters} dataResourceFn={dataResourceFn} />
    </>
  );
};

const dataResourceFn = (filter?: ListFilterValue): Promise<ReflectionReportModel> => {
  const params: { grade?: string[]; years?: number[] } = {};
  if (Array.isArray(filter?.grade) && filter?.grade.length) {
    params.grade = filter.grade.map((x) => x.value);
  }
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map((x) => x.value);
  }

  return agent.Reports.companyFeedback(params);
};

export default CompanyReportsStudentFeedback;
