import { prettifyDuration } from "../../app/helpers/dateHelpers";

export const createDurationOptions = (min: number, max: number, step = 30) =>
  Array.from({ length: max / step - min / step + 1 })
    .map((_, i) => min + i * step)
    .map((duration) => {
      return {
        key: duration,
        value: duration,
        text: prettifyDuration(duration),
      };
    });
