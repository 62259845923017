import { Partner } from "./models";

export const partners: Partner[] = [
  {
    title: "Kauno rajono savivaldybės administracija",
    description:
      "Kauno rajono savivaldybės administracija, bendradarbiaudama su VšĮ „Lietuvos Junior Achievement“, nuo 2021 metų sėkmingai įgyvendina LJA „Šešėliavimo“ programą, kuria siekiama stiprinti mokinių profesinį orientavimą, organizuojant vizitus į viešojo sektoriaus ir verslo įmones. Kauno rajono savivaldybės administracija buvo pirmoji, kuri patikėjo šios programos ir inovatyvaus EdTech įrankio svarba, investavo į jo kūrimą bei kasmetinį tobulinimą.",
    images: [
      {
        title: "Kauno rajono savivaldybės administracija",
        src: "/assets/partner/kauno-rajono-sav-administracija.png",
      },
    ],
  },
  {
    title: "Kauno miesto savivaldybės administracija",
    description:
      "Kauno miesto savivaldybės administracija aktyviai prisideda prie LJA „Šešėliavimo“ programos įgyvendinimo, jau nuo 2023 m. suteikdama mokiniams galimybę iš arčiau pažinti profesinį pasaulį.",
    images: [
      {
        title: "Kauno miesto savivaldybės administracija",
        src: "/assets/partner/kauno-miesto-sav-administracija.png",
        width: 183,
      },
    ],
  },
  {
    title: "Western Union",
    description:
      "Western Union kartu su „Lietuvos Junior Achievement“ 2024–2025 metais suteiks mokiniams unikalią galimybę iš arti pažinti FinTech sektorių. Iniciatyvos metu bent 100 mokinių galės apsilankyti įmonėje ir susipažinti su įvairiomis darbo pozicijomis, įskaitant tarptautinius pinigų pervedimus, IT sprendimus bei duomenų analizę. Taip pat Western Union pakvies 50 Ukrainos vaikų, siekdama jiems suteikti žinių apie šiuolaikinės technologijų įmonės veiklą ir atskleisti karjeros galimybes sparčiai augančiame FinTech sektoriuje.",
    images: [
      {
        title: "Western Union",
        src: "/assets/partner/western-union.png",
        width: 300,
        link: "https://www.westernunion.com/",
      },
    ],
  },
  {
    title: "LR Susisiekimo ministerija",
    description:
      "Susisiekimo ministerija kartu su penkiomis pavaldžiomis įstaigomis ir „Lietuvos Junior Achievement“ 2024 m. pavasarį įgyvendino išskirstinę iniciatyvą, kuria buvo siekiama didinti transporto sektoriaus profesijų patrauklumą. Gegužės mėnesį daugiau nei 50 moksleivių iš daugiau nei dviejų dešimčių Vilniaus, Kauno, Šiaulių, Alytaus, Švenčionių ir Vilkaviškio mokyklų buvo suteikta galimybė praktikuotis transporto sektoriaus įstaigose ir praktiškai išbandyti įvairiausias specialybes.",
    images: [
      {
        title: "Lietuvos Respublikos susisiekimo ministerija",
        src: "/assets/partner/lr-susisiekimo-ministerija.png",
        link: "https://sumin.lrv.lt/lt/",
        width: 331,
      },
      {
        title: "Susisiekimo karjeros laboratorija",
        src: "/assets/partner/susisiekimo-karjeros-laboratorija.png",
        link: "https://sumin.lrv.lt/lt/veiklos-sritys/kita-veikla/susisiekimo-karjeros-laboratorija/",
        width: 151,
      },
    ],
    moreInfoLink:
      "https://sumin.lrv.lt/lt/naujienos/tokia-proga-pasitaiko-itin-retai-moksleiviai-pateko-i-traukiniu-depa-kauno-uosta-ir-tiesiamo-kelio-statybu-aikstele/",
    partners: [
      {
        title: "Kelių priežiūra",
        src: "/assets/partner/keliu-prieziura.png",
        link: "https://keliuprieziura.lt/",
      },
      {
        title: "Via Lietuva",
        src: "/assets/partner/via-lietuva.png",
        link: "https://vialietuva.lt/",
      },
      {
        title: "Lietuvos geležinkeliai",
        src: "/assets/partner/ltg.png",
        link: "https://ltg.lt/",
      },
      {
        title: "Vidaus vandens kelių direkcija",
        src: "/assets/partner/vidaus-vandens-keliu-direkcija.png",
        link: "https://vvkd.lt/",
      },
      {
        title: "Viamatika",
        src: "/assets/partner/viamatika.png",
        link: "https://www.viamatika.lt/",
      },
    ],
  },
];
