import React from "react";
// eslint-disable-next-line no-restricted-imports
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  error: FieldError;
}

const AppErrors: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  if (!error?.message) return null;

  return <div className="sh-input-error-message">{t(error.message)}</div>;
};

export default AppErrors;
