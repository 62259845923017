import { useEffect } from "react";

type Props = Record<KeyboardEvent["key"], () => void>;

const useArrowNavigation = (handlers: Props) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      handlers[event.key]?.();
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [handlers]);
};

export default useArrowNavigation;
