import { UserRoles } from "../../app/auth/roles";

export interface ListFilterConfigItemBase {
  name: string;
  title: string;
  accessRole?: UserRoles[];
}

export enum ListFilterConfigItemType {
  Search = "search",
  MultiSelect = "MultiSelect",
  DateRange = "DateRange",
}

export interface ListFilterConfigItemSearch extends ListFilterConfigItemBase {
  type: ListFilterConfigItemType.Search;
}

export interface ListFilterConfigItemDateRange extends ListFilterConfigItemBase {
  type: ListFilterConfigItemType.DateRange;
}

export interface ListFilterConfigItemMultiSelectOption {
  key: string | number;
  value: string | number;
  text: string;
}

export interface ListFilterConfigItemMultiSelect extends ListFilterConfigItemBase {
  type: ListFilterConfigItemType.MultiSelect;
  options: ListFilterConfigItemMultiSelectOption[];
}

export type ListFilterConfigItem =
  | ListFilterConfigItemSearch
  | ListFilterConfigItemMultiSelect
  | ListFilterConfigItemDateRange;

export type ListFilterConfig = Array<ListFilterConfigItem>;

export type ListFilterValue = {
  [name: string]:
    | string
    | any[]
    | {
        start: Date;
        end: Date;
      };
};
