import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Item } from "semantic-ui-react";
import { RootState } from "../../../app/store/configureStore";
import { ShadowingWithApplications } from "../types";
import ShadowingItem from "./ShadowingItem";
import { ActiveShadowingFilter } from "../../../app/store/types/shadowingFilter";
import { setShadowingActivePage } from "../../shadowingsFilter/actions";
import Pagination from "../../../components/pagination/Pagination";
import useEventBus from "../../../app/hooks/useEventBus";
import { fetchShadowings } from "../actions";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import { userHelpers } from "../../../app/helpers/userHelpers";
import Alert from "../../../components/Alert";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import useIndustries from "../../../app/hooks/useIndustries";
import { AuthRoutes, NonAuthRoutes } from "../../../app/auth/routes";
import { Link } from "react-router-dom";

interface IState {
  items: ShadowingWithApplications[];
  pageNumber: number;
  totalPages: number;
}

const ShadowingList: React.FC = () => {
  const { t } = useTranslation();
  const activeFilter: ActiveShadowingFilter = useSelector((state: RootState) => state.shadowingsFilter);
  const pagination = useSelector((state: RootState) => state.shadowings.pagination);
  const isLoading = useSelector((state: RootState) => state.shadowings.isLoading);
  const { industries: allIndustries } = useIndustries();
  const applicationsLimitExceeded = useSelector(
    (state: RootState) => !!state.currentUser.seasonInfo?.applicationsLimitExceeded
  );
  const noStudentAgreement = useSelector((state: RootState) => !state.currentUser.seasonInfo?.agreementApproved);
  const schoolHadNoTraining = useSelector((state: RootState) => !!state.currentUser.seasonInfo?.schoolHadNoTraining);
  const isStudent = userHelpers.isStudent();

  const shadowings: IState = {
    items: useSelector((state: RootState) => state.shadowings.shadowings),
    pageNumber: pagination?.pageNumber || 1,
    totalPages: pagination?.totalPages || 1,
  };

  const { on } = useEventBus();

  const dispatch = useAppDispatch();
  const handlePageChange = (page: number) => {
    dispatch(setShadowingActivePage(page));
    window.scrollTo(0, 440);
  };

  useEffect(() => {
    function loadList() {
      dispatch(fetchShadowings());
    }
    loadList();

    const unsubscribeCreated = on("shadowingCreated", loadList);
    const unsubscribeUpdated = on("shadowingUpdated", loadList);
    const unsubscribeCanceled = on("shadowingCanceled", loadList);

    return () => {
      unsubscribeCreated();
      unsubscribeUpdated();
      unsubscribeCanceled();
    };
  }, [dispatch, activeFilter, on]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {shadowings.items.length > 0 ? (
        <>
          {isStudent && noStudentAgreement && (
            <Alert type="warning" header={t("applications.student.agreementNeededHeader")}>
              {t("applications.student.agreementNeeded")}
            </Alert>
          )}
          {isStudent && applicationsLimitExceeded && (
            <Alert type="warning">{t("applications.student.limitExceeded")}</Alert>
          )}
          {isStudent && schoolHadNoTraining && (
            <Alert type="warning" header={t("applications.student.schoolHadNoTrainingHeader")}>
              {t("applications.student.schoolHadNoTrainingBody")}
            </Alert>
          )}
          <Item.Group>
            {shadowings.items.map((shadowing) => (
              <ShadowingItem key={shadowing.id} shadowing={shadowing} allIndustries={allIndustries} />
            ))}
          </Item.Group>

          <Pagination
            totalPages={shadowings.totalPages}
            pageNumber={shadowings.pageNumber}
            handlePageChange={handlePageChange}
          />
        </>
      ) : (
        t("common.no_records")
      )}
    </>
  );
};

export default ShadowingList;
