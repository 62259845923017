import React from "react";
import AppForm from "../../../components/form/Form";
import { BusinessRegistrationModel, BusinessRegistrationDescriptionStepFormModel } from "../types";
import BusinessRegistrationActions from "../BusinessRegistrationActions";
import { useAppForm } from "../../../components/form/useForm";

interface Props {
  model: Partial<BusinessRegistrationModel>;
  onSubmit: (m: BusinessRegistrationDescriptionStepFormModel) => any;
}

const BusinessRegistrationDescriptionStep: React.FC<Props> = ({ model, onSubmit }) => {
  const formHook = useAppForm({ defaultValues: { ...defaultValues, ...model } });

  return (
    <>
      <div className="registration-description-step">
        <AppForm formHook={formHook} onSubmit={onSubmit}>
          <AppForm.Group name="description" label="auth.steps.business.description">
            <AppForm.TextArea name="description" rules={{ required: true }} />
          </AppForm.Group>
        </AppForm>
      </div>
      <BusinessRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: BusinessRegistrationDescriptionStepFormModel = {
  description: "",
};

export default BusinessRegistrationDescriptionStep;
