import React, { useState } from "react";
import { Modal, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Shadowing } from "../../shadowings/types";
import ShadowingMetadata from "../../../components/shadowing/ShadowingMetadata";
import { Excursion } from "../../excursions/types";

interface IProps {
  item: Shadowing | Excursion;
}

const ResponsiblePersonModal: React.FC<IProps> = ({ item }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Link to="#">{t("companies.details_page.responsible_person")}</Link>}
    >
      <Modal.Header>{t("companies.details_page.responsible_person")}</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header>
            {item.responsiblePerson.firstName} {item.responsiblePerson.surname}
          </Header>
          <ShadowingMetadata companyName={`${item.company.name}, ${item.responsiblePerson.jobTitle}`} />
          <div className="responsible-person-contacts">
            <p>
              {t("common.email")}: {item.responsiblePerson.email}
            </p>
            <p>
              {t("common.phone")}: {item.responsiblePerson.phoneNumber}
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};

export default ResponsiblePersonModal;
