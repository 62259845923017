/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import ReflectionForm from "./ReflectionForm";
import { Question, ReflectionFormModel } from "./models";
import FormModal from "../../components/FormModal";
import { Button } from "semantic-ui-react";
import useToaster from "../../app/hooks/useToaster";

interface Props {
  title: string;
  questions: Question[];
  onSave: (value: ReflectionFormModel) => Promise<any>;
  successMessage: string;
}

const ReflectionWriteModal = ({ title, questions, children, onSave, successMessage }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const { apiError, success } = useToaster();

  const isDisabled = isSaving;

  const handleSubmit = async (value: ReflectionFormModel) => {
    setSaving(true);

    try {
      await onSave(value);
      success(successMessage);
      setOpen(false);
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <ReflectionForm questions={questions} onSubmit={handleSubmit} isDisabled={isDisabled}>
      {(form, formHook) => (
        <FormModal
          title={title}
          {...{ isDisabled, isOpen, actions: null, trigger: children }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          actions={
            <>
              <Button primary onClick={formHook.handleSubmit(handleSubmit)} disabled={isDisabled} loading={isSaving}>
                {t("reflection.submit")}
              </Button>
              <Button negative onClick={() => setOpen(false)} disabled={isDisabled}>
                {t("common.actions.cancel")}
              </Button>
            </>
          }
        >
          {form}
        </FormModal>
      )}
    </ReflectionForm>
  );
};

export default ReflectionWriteModal;
