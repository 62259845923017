import React, { Fragment, useState } from "react";
import AppForm from "../../../../components/form/Form";
import { Link } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { userActions } from "../../actions";
import { NonAuthRoutes } from "../../../../app/auth/routes";
import { useAppForm } from "../../../../components/form/useForm";
import useError from "../../../../app/hooks/useError";
import { useAppDispatch } from "../../../../app/hooks/useAppDispatch";

interface FormData {
  email: string;
  password: string;
}

const LoginForm = ({ onLogin, returnUrl }: { onLogin: () => void; returnUrl: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getApiErrorContent } = useError();
  const [error, setError] = useState<string | undefined>(undefined);

  const formHook = useAppForm<FormData>({ defaultValues: { email: "", password: "" } });

  const onSubmit = async (data: FormData) => {
    onLogin();
    const response = (await dispatch(userActions.loginUser(data, returnUrl))) as unknown as { error?: any };
    if (response.error) {
      setError(getApiErrorContent(response.error, "login.fail.body"));
    }
  };

  const renderLoginError = () => {
    return (
      error && (
        <Message negative>
          <Message.Header>{t("login.fail.title")}</Message.Header>
          <p>{error}</p>
        </Message>
      )
    );
  };

  return (
    <Fragment>
      {renderLoginError()}
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <AppForm.Group name="email" label="common.email">
          <AppForm.Input name="email" rules={{ required: true }} />
        </AppForm.Group>

        <AppForm.Group name="password" label="auth.your_password">
          <AppForm.Password name="password" rules={{ required: true }} />
        </AppForm.Group>
      </AppForm>
      <Link to={NonAuthRoutes.passwordRecovery}>{t("auth.forgot_password")}</Link>
      <div className="login-button">
        <Button primary onClick={formHook.handleSubmit(onSubmit)}>
          {t("auth.login_action")}
        </Button>
      </div>
    </Fragment>
  );
};

export default LoginForm;
