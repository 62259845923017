import React from "react";
import { ReportsDataCounts } from "./models";
import { useTranslation } from "react-i18next";

interface Props {
  data: ReportsDataCounts;
}

const ReportsCounts = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="report report--count">
      <h3 className="report__title">{t(data.title)}</h3>
      <ul className="report__counts">
        {data.value.map((item, i) => (
          <li key={i}>
            <strong>{item.value}</strong>
            {t(item.unit)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportsCounts;
