import { createContext, useEffect, useMemo, useState } from "react";
import agent from "../../app/api/agent";
import { ListFilterConfigItem, ListFilterConfigItemType } from "../../components/list-filter/models";
import { ReportFilterResponse } from "./models";

const useReportFilter = () => {
  const [response, setResponse] = useState<ReportFilterResponse | undefined>(undefined);

  useEffect(() => {
    agent.Reports.filters().then(setResponse);
  }, []);

  return useMemo(
    (): Record<keyof ReportFilterResponse | "genders", ListFilterConfigItem> => ({
      companies: {
        name: "company",
        title: "reports.filter.company",
        type: ListFilterConfigItemType.MultiSelect,
        options:
          response?.companies.map((company) => ({ key: company.id, value: company.id, text: company.name })) ?? [],
      },
      schools: {
        name: "school",
        title: "reports.filter.school",
        type: ListFilterConfigItemType.MultiSelect,
        options: response?.schools.map((school) => ({ key: school.id, value: school.id, text: school.name })) ?? [],
      },
      grades: {
        name: "grade",
        title: "reports.filter.class",
        type: ListFilterConfigItemType.MultiSelect,
        options: response?.grades.map((grade) => ({ key: grade, value: grade, text: String(grade) })) ?? [],
      },
      genders: {
        name: "gender",
        title: "reports.filter.gender",
        type: ListFilterConfigItemType.MultiSelect,
        options: [
          { key: "F", value: "F", text: "Mot." },
          { key: "M", value: "M", text: "Vyr." },
        ],
      },
      years: {
        name: "years",
        title: "reports.filter.year",
        type: ListFilterConfigItemType.MultiSelect,
        options: response?.years.map((year) => ({ key: year, value: year, text: `${year}-${year + 1}` })) ?? [],
      },
    }),
    [response]
  );
};

export default useReportFilter;

export const ReportFilterContext = createContext<Record<keyof ReportFilterResponse | "genders", ListFilterConfigItem>>(
  undefined!
);
