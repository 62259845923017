import { toast, ToastContent, ToastOptions } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useCallback } from "react";
import useError from "./useError";

const useToaster = () => {
  const { t } = useTranslation();
  const { getApiErrorContent } = useError();

  const getContent = useCallback((content: ToastContent) => (typeof content === "string" ? t(content) : content), [t]);

  const success = useCallback(
    (content: ToastContent, options?: ToastOptions) => toast.success(getContent(content), options),
    [getContent]
  );
  const info = useCallback(
    (content: ToastContent, options?: ToastOptions) => toast.info(getContent(content), options),
    [getContent]
  );
  const error = useCallback(
    (content: ToastContent, options?: ToastOptions) => toast.error(getContent(content), options),
    [getContent]
  );
  const apiError = useCallback(
    (apiError: AxiosError | any, options?: ToastOptions) => error(getApiErrorContent(apiError), options),
    [error, getApiErrorContent]
  );
  const warning = useCallback(
    (content: ToastContent, options?: ToastOptions) => toast.warning(getContent(content), options),
    [getContent]
  );

  return { ...toast, success, info, error, apiError, warning, getApiErrorContent };
};

export default useToaster;
