import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import agent from "../../../app/api/agent";
import { CompetenciesModel } from "../../../components/competencies/models";
import CompetenciesContainer from "../../../components/competencies/CompetenciesContainer";
import { ReportFilterContext } from "../useReportFilter";

const dataResourceFn = async (filter?: ListFilterValue): Promise<CompetenciesModel> => {
  const params: { years?: number[]; grade?: string[]; gender?: string[]; schoolIds?: number[] } = {};
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map(getValue);
  }
  if (Array.isArray(filter?.grade) && filter?.grade.length) {
    params.grade = filter.grade.map(getValue);
  }
  if (Array.isArray(filter?.gender) && filter?.gender.length) {
    params.gender = filter.gender.map(getValue);
  }
  if (Array.isArray(filter?.school) && filter?.school.length) {
    params.schoolIds = filter.school.map(getValue);
  }

  return agent.Reports.municipalityCompetencies(params);
};

const getValue = <T extends { value: any }>(x: T) => x.value;

const MunicipalityReportsCompetencies = () => {
  const { t } = useTranslation();
  const { years, grades, genders, schools } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years, grades, genders, schools], [years, grades, genders, schools]);

  return (
    <>
      <PageTitle title={t("reports.statistics")} />
      <CompetenciesContainer dataResourceFn={dataResourceFn} filterConfig={filters} />
    </>
  );
};

export default MunicipalityReportsCompetencies;
