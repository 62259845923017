export enum SchoolRegistrationStep {
  Contacts,
  Password,
  Success,
}

export interface SchoolRegistrationContactStepFormModel {
  id: string;
  name: string;
  contactInfo: {
    fullName: string;
    jobTitle: string;
    phoneNumber: string;
    email: string;
  };
}

export interface SchoolRegistrationPasswordStepFormModel {
  password: string;
}

export interface SchoolRegistrationModel
  extends SchoolRegistrationContactStepFormModel,
    SchoolRegistrationPasswordStepFormModel {}
