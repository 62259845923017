import React, { useState } from "react";
import { Student } from "../types";
import { useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import { Link } from "react-router-dom";
import FormModal from "../../../components/FormModal";
import StudentProfileForm, { StudentUpdateForm } from "../../profile/components/student/StudentProfileForm";
import useToaster from "../../../app/hooks/useToaster";
import StudentActivationButton from "./StudentActivationButton";

interface IProps {
  student: Student;
  onStudentUpdate: () => void;
}

const StudentEditModal = ({ student, onStudentUpdate }: IProps) => {
  const { t } = useTranslation();
  const { apiError, success } = useToaster();

  const [open, setOpen] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const handleUpdate = (data: StudentUpdateForm) => {
    setUpdating(true);

    agent.Students.update(student.id, data.grade, data.phoneNumber)
      .then(() => {
        success(t("student_list.update_success"));
        onUpdate();
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const onUpdate = () => {
    setOpen(false);
    onStudentUpdate();
  };

  return (
    <FormModal
      title={`${student.contactInfo.fullName}, ${student.grade} kl.`}
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Link to="#">{t("common.edit")}</Link>}
    >
      <StudentProfileForm student={student} isSaving={isUpdating} handleSubmit={handleUpdate}>
        <StudentActivationButton student={student} onActivationChange={onUpdate} />
      </StudentProfileForm>
    </FormModal>
  );
};

export default StudentEditModal;
