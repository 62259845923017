import { DateRange } from "../../../features/shadowingsFilter/components/DateFilter";
import { SORT_BY } from "../../../features/shadowingsFilter/sort_options";

export interface ActiveShadowingFilter {
  search: string;
  cities: string[];
  industryIds: number[];
  companyIds: number[];
  isOnline: boolean;
  hasApplications?: boolean;
  past?: boolean;
  sortBy: string;
  activePage: number;
  dateRange?: DateRange;
}

export const UPDATE_CITY_FILTER = "UPDATE_CITY_FILTER";
export const UPDATE_ONLINE_FILTER = "UPDATE_ONLINE_FILTER";
export const UPDATE_INDUSTRY_FILTER = "UPDATE_INDUSTRY_FILTER";
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";
export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const UPDATE_SORT_FILTER = "UPDATE_SORT_FILTER";
export const UPDATE_DATE_FILTER = "UPDATE_DATE_FILTER";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

export const SET_HAS_APPLICATIONS = "SET_HAS_APPLICATIONS";
export const SET_PAST_APPLICATIONS = "SET_PAST_APPLICATIONS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

interface UpdateOnlineFilter {
  type: typeof UPDATE_ONLINE_FILTER;
  value: boolean;
}

interface UpdateCityFilter {
  type: typeof UPDATE_CITY_FILTER;
  selectedCity: string;
}

interface UpdateIndustryFilter {
  type: typeof UPDATE_INDUSTRY_FILTER;
  selectedId: number;
}

interface UpdateCompanyFilter {
  type: typeof UPDATE_COMPANY_FILTER;
  selectedId: number;
}

interface UpdateSearchFilter {
  type: typeof UPDATE_SEARCH_FILTER;
  searchText: string;
}

interface UpdateSortFilter {
  type: typeof UPDATE_SORT_FILTER;
  sortBy: SORT_BY;
}

interface UpdateDateFilter {
  type: typeof UPDATE_DATE_FILTER;
  payload?: { start: Date; end: Date };
}

interface SetActivePage {
  type: typeof SET_ACTIVE_PAGE;
  activePage: number;
}

interface SetHasApplications {
  type: typeof SET_HAS_APPLICATIONS;
  isActive?: boolean;
}

interface SetPastApplications {
  type: typeof SET_PAST_APPLICATIONS;
  isActive?: boolean;
}

interface ClearFilters {
  type: typeof CLEAR_FILTERS;
}

export type ShadowingFilterActionTypes =
  | UpdateOnlineFilter
  | UpdateCityFilter
  | UpdateIndustryFilter
  | UpdateCompanyFilter
  | UpdateSearchFilter
  | UpdateSortFilter
  | UpdateDateFilter
  | SetActivePage
  | SetHasApplications
  | SetPastApplications
  | ClearFilters;
