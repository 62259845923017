import React from "react";
import { Input, InputProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

type Props = InputProps;

const AppInput: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  placeholder = "common.placeholder",
  isDisabled,
  value,
  isValid,
  onBlur,
  ...props
}) => {
  const { t } = useTranslation();

  const handleBlur = () => {
    if (typeof value === "string" && value.trim() !== value) {
      onChange(value.trim());
    }
    onBlur();
  };

  return (
    <Input
      disabled={isDisabled}
      {...props}
      value={value ?? ""}
      placeholder={placeholder && t(placeholder)}
      onBlur={handleBlur}
      onChange={(_, data) => onChange(data.value)}
    />
  );
};

const AppInputController = withController(AppInput);

export { AppInputController };
export default AppInput;
