import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppForm from "../../../../components/form/Form";
import { fileSizeValidator, fileTypeValidator } from "../../../../app/helpers/validators";
import { useAppForm } from "../../../../components/form/useForm";
import useToaster from "../../../../app/hooks/useToaster";
import agent from "../../../../app/api/agent";
import Loader from "../../../../components/Loader";
import { userActions } from "../../../user/actions";
import { useAppDispatch } from "../../../../app/hooks/useAppDispatch";

interface Props {
  studentId: number;
}

const StudentProfileAgreementUpload = ({ studentId }: Props) => {
  const { t } = useTranslation();
  const formHook = useAppForm({ defaultValues: { file: null } });
  const file = formHook.watch("file");
  const { success, apiError } = useToaster();
  const [isSaving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (data: { file: File }) => {
      setSaving(true);

      agent.Students.uploadAgreement(studentId, data.file)
        .then(() => {
          success(t("my_profile.student.agreement.file_uploaded"));
          dispatch(userActions.updateUser({ agreement: { status: "uploaded" } }));
        })
        .catch((error) => {
          apiError(error);
          setSaving(false);
        });
    },
    [apiError, dispatch, studentId, success, t]
  );

  useEffect(() => {
    if (file) {
      handleSubmit({ file });
    }
  }, [file, handleSubmit]);

  return (
    <AppForm formHook={formHook} onSubmit={handleSubmit} isDisabled={isSaving}>
      <AppForm.Group name="file">
        <AppForm.File
          name="file"
          placeholder="my_profile.student.agreement.attach_file"
          accept={[".pdf", ".jpg", ".jpeg", ".png"]}
          rules={{
            validate: {
              filesize: (v) => fileSizeValidator()(v),
              filetype: (v) => fileTypeValidator(["pdf", "jpg", "jpeg", "png"])(v),
            },
          }}
        />
      </AppForm.Group>

      {isSaving && <Loader />}
    </AppForm>
  );
};

export default StudentProfileAgreementUpload;
