import React, { useState } from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Shadowing } from "../../shadowings/types";
import company from "../../../icons/company.svg";
import location from "../../../icons/location.svg";
import date from "../../../icons/date.svg";
import ApplicationForm from "./ApplicationForm";
import { prettifyDate } from "../../../app/helpers/dateHelpers";
import FormModal from "../../../components/FormModal";
import { IApplicationForm } from "../types";
import AppForm from "../../../components/form/Form";
import agent from "../../../app/api/agent";
import { fetchShadowings } from "../../shadowings/actions";
import useToaster from "../../../app/hooks/useToaster";
import { useAppForm } from "../../../components/form/useForm";
import Alert from "../../../components/Alert";
import { userActions } from "../../user/actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface IProps {
  shadowing: Shadowing;
  onCancel?: () => void;
}

const ApplicationModal = ({ shadowing, onCancel: handleCancel }: IProps) => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [isSaving, setSaving] = useState(false);
  const dispatch = useAppDispatch();

  const formHook = useAppForm<IApplicationForm>({
    defaultValues: { shadowingId: 0, motivationLetter: "", linkedinUrl: "", cv: null },
  });

  const handleSubmit = async (data: IApplicationForm) => {
    setSaving(true);

    try {
      await agent.Applications.create({ ...data, shadowingId: shadowing.id });
      // dispatch(userActions.updateUser({ seasonInfo: { canApply: false, applicationsLimitExceeded: true } }));
      dispatch(fetchShadowings());
      success(t("applications.notifications.application_sent"));
    } catch (error) {
      apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const renderLocation = () => {
    return shadowing.online ? t("shadowing.remote") : `${shadowing.city}, ${shadowing.address}`;
  };

  const actions = (
    <>
      <Button primary onClick={formHook.handleSubmit(handleSubmit)} disabled={isSaving} loading={isSaving}>
        {t("application.form.actions.send_application")}
      </Button>
    </>
  );

  return (
    <FormModal title="application.modal.header" isOpen={true} onClose={handleCancel} actions={actions} trigger={null}>
      <Modal.Content image>
        <Modal.Description>
          <Header>{shadowing.position}</Header>
          <p>
            <span>
              <img src={location} alt="location" />
              {renderLocation()}
            </span>
            <span>
              <img src={company} alt="company" />
              {shadowing.company.name}
            </span>
            <span>
              <img src={date} alt="date" />
              {prettifyDate(shadowing.date)}
            </span>
          </p>

          <AppForm formHook={formHook} onSubmit={handleSubmit} isDisabled={isSaving}>
            <ApplicationForm />
          </AppForm>
        </Modal.Description>
      </Modal.Content>
    </FormModal>
  );
};

export default ApplicationModal;
