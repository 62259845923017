import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import AppForm from "../../../../components/form/Form";
import { Button, Divider, Grid } from "semantic-ui-react";
import { SchoolProfileModel } from "../../../schools/types";
import { useAppForm } from "../../../../components/form/useForm";
import useHardcodedCities from "../../../../app/hooks/useHardcodedCities";

export interface SchoolUpdateForm {
  id: number;
  name: string;
  address: string;
  city: string;
  website: string;
  email: string;
  phoneNumber: string;
  contactInfo: {
    fullName: string;
    jobTitle: string;
    phoneNumber: string;
    email: string;
  };
}

interface Props {
  isSaving: boolean;
  school: SchoolProfileModel;
  handleSubmit: (data: SchoolUpdateForm) => void;
  handleCancel: () => void;
}

const SchoolProfileForm: React.FC<Props> = ({ isSaving, school, handleSubmit, handleCancel }) => {
  const { t } = useTranslation();
  const { cityOptions } = useHardcodedCities();

  const formHook = useAppForm<SchoolUpdateForm>({ defaultValues: school });

  return (
    <Fragment>
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <AppForm.Input name="id" type="hidden" />
        <Grid stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AppForm.Group name="name" label="common.title">
                <AppForm.Input name="name" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="city" label="common.city">
                <AppForm.Select name="city" options={cityOptions} rules={{ required: true }} disableAutoSelect />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="address" label="common.address">
                <AppForm.Input name="address" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="email" label="common.email">
                <AppForm.Email name="email" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="phoneNumber" label="common.phone">
                <AppForm.Phone name="phoneNumber" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AppForm.Group name="website" label="common.web">
                <AppForm.Url name="website" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.fullName" label="my_profile.responsible_person.fullName">
                <AppForm.Input name="contactInfo.fullName" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="contactInfo.phoneNumber" label="my_profile.responsible_person.phone">
                <AppForm.Phone name="contactInfo.phoneNumber" />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.jobTitle" label="my_profile.responsible_person.jobTitle">
                <AppForm.Input name="contactInfo.jobTitle" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>

      <div className="profile-form-actions">
        <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
          {t("common.actions.confirm")}
        </Button>
        <Button negative disabled={isSaving} onClick={handleCancel}>
          {t("common.actions.cancel")}
        </Button>
      </div>
    </Fragment>
  );
};

export default SchoolProfileForm;
