import React from "react";

interface Props {
  icon: React.ReactNode;
}

const ButtonIcon = ({ icon, className = "", ...props }: Props & JSX.IntrinsicElements["button"]) => (
  <button className={className + " button-icon"} {...props}>
    {icon}
  </button>
);

export default ButtonIcon;
