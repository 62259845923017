import React from "react";
import { InputProps } from "semantic-ui-react";
import { PropsInjectedByController } from "./types";
import AppInput from "./Input";
import { EMAIL_REGEXP } from "../../app/helpers/regexp";
import { withController } from "./withController";

type Props = InputProps;

const AppEmail: React.FC<PropsInjectedByController & Props> = (props) => {
  return <AppInput {...props} inputMode="email" type="text" />;
};

const AppEmailController = withController(AppEmail);
const AppEmailControllerWithRules = ({ rules, ...props }: Parameters<typeof AppEmailController>[0]) => (
  <AppEmailController
    rules={{
      ...rules,
      pattern: {
        value: EMAIL_REGEXP,
        message: "errors.email",
      },
    }}
    {...props}
  />
);

export { AppEmailControllerWithRules as AppEmailController };
export default AppEmail;
