import React, { useState } from "react";
import { Application } from "../types";
import { useTranslation } from "react-i18next";
import { Button, Card, Label, SemanticCOLORS } from "semantic-ui-react";
import company from "../../../icons/company.svg";
import location from "../../../icons/location.svg";
import date from "../../../icons/date.svg";
import { ApplicationStatus } from "../../../app/enums/applicationStatus";
import { prettifyDate } from "../../../app/helpers/dateHelpers";
import { Link } from "react-router-dom";
import ResponsiblePersonModal from "../../shadowings/components/ResponsiblePersonModal";
import ReflectionModal from "../../reflections/ReflectionModal";
import { isExistingReflection } from "../../../app/helpers/reflections";
import ReflectionPreviewModal from "../../reflections/ReflectionPreviewModal";
import agent from "../../../app/api/agent";

interface IProps {
  application: Application;
  past: boolean;
  handleCancel(applicationId: number): void;
}

const COLOR_STATUS_MAP: { [key: string]: ApplicationStatus[] } = {
  purple: [ApplicationStatus.WAITING_FOR_SCHOOL_APPROVAL, ApplicationStatus.WAITING_FOR_COMPANY_APPROVAL],
  red: [ApplicationStatus.COMPANY_REJECTED, ApplicationStatus.STUDENT_CANCELLED],
  green: [ApplicationStatus.COMPANY_APPROVED],
};

const ApplicationItem: React.FC<IProps> = ({ application, handleCancel, past }) => {
  const { t } = useTranslation();

  const [reflectionExists, setReflectionExists] = useState(isExistingReflection(application.reflections));

  const getLabelColor = () =>
    (Object.keys(COLOR_STATUS_MAP).find((key) => COLOR_STATUS_MAP[key].includes(application.status)) ||
      "red") as SemanticCOLORS;

  const renderCancelButton = () => {
    const showButton =
      [
        ApplicationStatus.COMPANY_APPROVED,
        ApplicationStatus.WAITING_FOR_SCHOOL_APPROVAL,
        ApplicationStatus.WAITING_FOR_COMPANY_APPROVAL,
      ].includes(application.status) && Date.parse(application.shadowing.date) > Date.now();

    return showButton ? (
      <Link to="#" onClick={() => handleCancel(application.id)}>
        {t("common.actions.cancel")}
      </Link>
    ) : null;
  };

  const renderLocation = () => {
    return application.shadowing.online
      ? t("shadowing.remote")
      : `${application.shadowing.city}, ${application.shadowing.address}`;
  };

  const handleReflectionSubmit = () => {
    setReflectionExists(true);
  };

  const renderReflectionButton = () => {
    const showButton = application.status === ApplicationStatus.COMPANY_APPROVED && past;
    if (!showButton) {
      return null;
    }
    if (reflectionExists) {
      const loadReflectionFn = () => agent.ApplicationReflections.get(application.id);
      return (
        <ReflectionPreviewModal title="reflection.modal_title" reflections={loadReflectionFn as any}>
          <Button basic style={{ whiteSpace: "nowrap" }}>
            {t("reflection.view")}
          </Button>
        </ReflectionPreviewModal>
      );
    }

    return (
      <ReflectionModal applicationId={application.id} handleReflectionSubmit={handleReflectionSubmit}>
        <Button primary style={{ whiteSpace: "nowrap" }}>
          {t("reflection.fill")}
        </Button>
      </ReflectionModal>
    );
  };

  return (
    <Card>
      <Card.Content>
        <div className="student-application-item__logo">
          <img src={application.shadowing.company.logoUrl || "/assets/logo_placeholder.png"} alt="logo" />
        </div>

        <Card.Header>{application.shadowing.position}</Card.Header>
        <Card.Meta>
          <div>
            <img src={location} alt="location" />
            {renderLocation()}
          </div>
          <div>
            <img src={company} alt="company" />
            {application.shadowing.company.name}
          </div>
          <div>
            <img src={date} alt="date" />
            {prettifyDate(application.shadowing.date)}
          </div>
        </Card.Meta>

        <ResponsiblePersonModal item={application.shadowing} />

        <Card.Description>
          <Label color={getLabelColor()} circular>
            {t(`applications.status.${application.status}`)}
          </Label>
          {renderCancelButton()}
          {renderReflectionButton()}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ApplicationItem;
