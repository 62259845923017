import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "semantic-ui-react";
import { UserRoles } from "../../app/auth/roles";
import { HasRole } from "../../app/helpers/userHelpers";
import AppForm from "../../components/form/Form";
import useMunicipalities from "../../app/hooks/useMunicipalities";
import useHardcodedCities from "../../app/hooks/useHardcodedCities";

interface Props {
  isEditMode?: boolean;
}

const SchoolCreateForm: React.FC<Props> = ({ isEditMode }) => {
  const { t } = useTranslation();
  const { cityOptions } = useHardcodedCities();
  const isAdmin = HasRole(UserRoles.admin);
  const showMunicipalityDropdown = isAdmin && !isEditMode;
  const { municipalityOptions } = useMunicipalities();

  const addressField = (
    <AppForm.Group name="address" label="common.address">
      <AppForm.Input name="address" placeholder="schools.form.placeholder.address" rules={{ required: true }} />
    </AppForm.Group>
  );
  const cityField = (
    <AppForm.Group name="city" label="common.city">
      <AppForm.Select name="city" options={cityOptions} rules={{ required: true }} disableAutoSelect />
    </AppForm.Group>
  );

  const municipalityField = showMunicipalityDropdown ? (
    <AppForm.Group name="municipalityId" label="common.municipality">
      <AppForm.Select name="municipalityId" options={municipalityOptions} rules={{ required: true }} />
    </AppForm.Group>
  ) : (
    <AppForm.Input name="municipalityId" type="hidden" />
  );

  const websiteField = (
    <AppForm.Group name="website" label="common.web">
      <AppForm.Url name="website" placeholder="schools.form.placeholder.web" rules={{ required: true }} />
    </AppForm.Group>
  );

  const emailField = (
    <AppForm.Group name="email" label="common.email">
      <AppForm.Email name="email" rules={{ required: true }} />
    </AppForm.Group>
  );

  const phoneField = (
    <AppForm.Group name="phoneNumber" label="common.phone">
      <AppForm.Phone name="phoneNumber" rules={{ required: true }} />
    </AppForm.Group>
  );

  return (
    <>
      <Grid stackable>
        {isEditMode ? <p> {t("my_profile.edit_help")}</p> : null}
        <Grid.Row columns={1}>
          <Grid.Column>
            <AppForm.Group name="name" label="common.title">
              <AppForm.Input name="name" placeholder="schools.form.placeholder.title" rules={{ required: true }} />
            </AppForm.Group>
          </Grid.Column>
        </Grid.Row>
        {showMunicipalityDropdown ? (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>{municipalityField}</Grid.Column>
              <Grid.Column>{addressField}</Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>{cityField}</Grid.Column>
              <Grid.Column>{phoneField}</Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>{emailField}</Grid.Column>
              <Grid.Column>{websiteField}</Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <>
            {municipalityField}
            <Grid.Row columns={2}>
              <Grid.Column>{addressField}</Grid.Column>
              <Grid.Column>{cityField}</Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>{emailField}</Grid.Column>
              <Grid.Column>{phoneField}</Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>{websiteField}</Grid.Column>
            </Grid.Row>
          </>
        )}
        <Divider />
        <Grid.Row columns={2}>
          <Grid.Column>
            <AppForm.Group name="contactInfo.fullName" label="schools.form.contact_info.coordinator_fullname">
              <AppForm.Input name="contactInfo.fullName" rules={{ required: true }} />
            </AppForm.Group>
          </Grid.Column>
          <Grid.Column>
            <AppForm.Group name="contactInfo.phoneNumber" label="common.phone">
              <AppForm.Phone name="contactInfo.phoneNumber" rules={{ required: true }} />
            </AppForm.Group>
          </Grid.Column>
        </Grid.Row>
        {isEditMode ? (
          <Grid.Row columns={1}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.jobTitle" label="my_profile.responsible_person.jobTitle">
                <AppForm.Input name="contactInfo.jobTitle" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.email" label="schools.form.contact_info.email">
                <AppForm.Input name="contactInfo.email" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="contactInfo.jobTitle" label="my_profile.responsible_person.jobTitle">
                <AppForm.Input name="contactInfo.jobTitle" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        )}
        {isAdmin ? (
          <Grid.Row columns={1}>
            <Grid.Column>
              <AppForm.Group name="hadTraining">
                <AppForm.Checkbox name="hadTraining" label="shadowing.hadTraining" />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    </>
  );
};

export default SchoolCreateForm;
