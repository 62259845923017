import React from "react";
import { useTranslation } from "react-i18next";
import ShadowingPreview from "../shadowings/components/ShadowingPreview";
import { ReportsDataShadowing } from "./models";

interface Props {
  data: ReportsDataShadowing;
}

const ReportsShadowing = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="report report--shadowing">
      <h3 className="report__title">{t(data.title)}</h3>
      {data.value.map((shadowing) => (
        <ShadowingPreview shadowing={shadowing} key={shadowing.id} />
      ))}
    </div>
  );
};

export default ReportsShadowing;
