import React, { PropsWithChildren } from "react";

interface IProps {
  titleHighlight?: string;
  title: string;
  description?: string;
  extra?: string;
  extraTopPadding?: boolean;
}

const PageTitle = ({
  titleHighlight,
  title,
  description,
  extra,
  extraTopPadding,
  children,
}: PropsWithChildren<IProps>) => {
  const extraPadding = extraTopPadding ? "extra-top-padding" : "";

  return (
    <div className={`page-title ${extraPadding}`}>
      <h1 className="title">
        {titleHighlight && <em>{titleHighlight}</em>}
        {title}
      </h1>
      {description && <p className="description">{description}</p>}
      {extra && <p className="description">{extra}</p>}
      {children}
    </div>
  );
};

export default PageTitle;
