import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { RootState } from "../../../../app/store/configureStore";
import StudentProfileAgreementUpload from "./StudentProfileAgreementUpload";
import { Student } from "../../../students/types";
import { downloadFile } from "../../../../app/helpers/fileHelper";
import agent from "../../../../app/api/agent";

interface Props {
  student: Student;
}

const StudentProfileAgreement = ({ student }: Props) => {
  const { t } = useTranslation();
  const agreementState = useSelector((state: RootState) => state.currentUser.agreement);

  const download = async (fileId: string) => {
    const response = await agent.Files.getAgreement(fileId);
    downloadFile(response);
  };

  const renderContent = () => {
    if (agreementState?.status === "approved") {
      return (
        <>
          <p>{t("my_profile.student.agreement.approved_text")}</p>
          {agreementState.file && (
            <button className="links__item" onClick={() => download(agreementState.file!)}>
              {t("my_profile.student.agreement.file")}
              <span>{t("common.download")}</span>
            </button>
          )}
        </>
      );
    } else if (agreementState?.status === "uploaded") {
      return (
        <>
          <p>{t("my_profile.student.agreement.uploaded_text")}</p>
          {agreementState.file && (
            <button className="links__item" onClick={() => download(agreementState.file!)}>
              {t("my_profile.student.agreement.file")}
              <span>{t("common.download")}</span>
            </button>
          )}
        </>
      );
    } else {
      return (
        <>
          <p>{t("my_profile.student.agreement.none_text")}</p>
          <a
            className="links__item"
            href={
              "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Sutikimas dėl asmens duomenų tvarkymo.pdf"
            }
            rel="noopener noreferrer"
            target="_blank"
            download
          >
            {t("my_profile.student.agreement.template")}
            <span>{t("common.download")}</span>
          </a>

          <StudentProfileAgreementUpload studentId={student.id} />
        </>
      );
    }
  };

  return (
    <Segment padded="very">
      <h3>{t("my_profile.student.agreement.title")}</h3>
      {renderContent()}
    </Segment>
  );
};

export default StudentProfileAgreement;
