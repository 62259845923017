import React from "react";
import { Student } from "../types";
import { Table } from "semantic-ui-react";
import StudentApplicationsModal from "./StudentApplicationsModal";
import GenderImage from "../../../components/GenderImage";
import StudentEditModal from "./StudentEditModal";
import StudentActivationButton from "./StudentActivationButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";

interface Filter {
  students: Student[];
  onStudentUpdate: () => void;
}

const StudentList: React.FC<Filter> = ({ students, onStudentUpdate }) => {
  const { email } = useSelector((state: RootState) => state.currentUser);
  // Hack but good enough for now.
  const isLjaSchool = email === "ask+school@lja.lt";

  let lastUsedLetter = "";
  const renderSurnameLetter = (fullName: string) => {
    const parts = fullName.split(" ");
    const letter = parts[parts.length - 1][0];

    if (lastUsedLetter !== letter) {
      lastUsedLetter = letter;
      return <span>{letter}</span>;
    }

    return null;
  };

  return (
    <Table unstackable basic="very" className="student-list">
      <Table.Body>
        {students.map((student) => (
          <Table.Row key={student.id}>
            <Table.Cell className="surname-letter">{renderSurnameLetter(student.contactInfo.fullName)}</Table.Cell>
            <Table.Cell singleLine>
              <GenderImage gender={student.gender} />
              {student.contactInfo.fullName}, {student.grade} kl.
            </Table.Cell>
            <Table.Cell>
              <a href={"mailto:" + student.contactInfo.email}>{student.contactInfo.email}</a>
              <br />
              {isLjaSchool && <p style={{ fontSize: 13 }}>{student.school}</p>}
            </Table.Cell>
            <Table.Cell className="student-list-actions student-list-actions--multiple">
              <StudentApplicationsModal student={student} />
              {" | "}
              <StudentEditModal student={student} onStudentUpdate={onStudentUpdate} />
              {" | "}
              <StudentActivationButton student={student} onActivationChange={onStudentUpdate} showSimpleButton />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default StudentList;
