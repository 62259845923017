import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReflectionReportModel } from "../../reflections/models";
import ReflectionReportContainer from "../../reflections/ReflectionReportContainer";
import agent from "../../../app/api/agent";
import { ReportFilterContext } from "../useReportFilter";

const SchoolReportsCompanyReflections = () => {
  const { t } = useTranslation();

  const { years, grades, companies } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years, companies, grades], [years, companies, grades]);

  return (
    <>
      <PageTitle title={t("reports.companyReflections")} />
      <ReflectionReportContainer filterConfig={filters} dataResourceFn={dataResourceFn} />
    </>
  );
};

const dataResourceFn = (filter?: ListFilterValue): Promise<ReflectionReportModel> => {
  const params: { grade?: string[]; years?: number[]; companyIds?: number[] } = {};
  if (Array.isArray(filter?.grade) && filter?.grade.length) {
    params.grade = filter.grade.map((x) => x.value);
  }
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map((x) => x.value);
  }
  if (Array.isArray(filter?.company) && filter?.company.length) {
    params.companyIds = filter.company.map((x) => x.value);
  }

  return agent.Reports.schoolReflections(params);
};

export default SchoolReportsCompanyReflections;
