import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../../../app/auth/routes";
import AuthLayout from "../AuthLayout";
import BaseAuthPage from "../BaseAuthPage";
import PasswordRecoveryForm from "./PasswordRecoveryForm";

const PasswordRecoveryPage = () => {
  const { t } = useTranslation();

  const header = (
    <>
      <h1>{t("auth.password_reset.title")}</h1>
      <p>{t("auth.password_reset.body")}</p>
    </>
  );

  return (
    <AuthLayout>
      <BaseAuthPage
        header={header}
        content={<PasswordRecoveryForm />}
        footer={<Link to={NonAuthRoutes.registration}>{t("auth.no_account")}</Link>}
      />
    </AuthLayout>
  );
};

export default PasswordRecoveryPage;
