import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Icon, Grid, Button, Card } from "semantic-ui-react";
import ScaledImage from "../../../components/ScaledImage";
import { CompanyProfileModel } from "../types";
import RejectCompanyModal from "./RejectCompanyModal";

interface Props {
  company: CompanyProfileModel;
  active: boolean;
  index: number;
  handleApprove?: (companyId: number) => Promise<any>;
  handleReject?: (companyId: number, reason: string) => void;
  handleAccordionClick: (e: any, titleProps: any) => void;
}

const CompanyListItem: React.FC<Props> = ({
  company,
  active,
  index,
  handleApprove: onApprove,
  handleReject,
  handleAccordionClick,
}) => {
  const { t } = useTranslation();
  const [isApproving, setApproving] = useState(false);

  const renderAccordionTrigger = () => {
    return active ? t("common.actions.collapse") : t("common.actions.expand_more_info");
  };

  const handleApprove = async (id: number) => {
    setApproving(true);
    try {
      await onApprove?.(id);
    } finally {
      setApproving(false);
    }
  };

  return (
    <Card fluid className="company-list__item">
      <Card.Content>
        <Grid stackable padded columns="equal">
          <Grid.Row>
            <Grid.Column width={3}>
              <ScaledImage url={company.logoUrl || "/assets/logo_placeholder.png"} width={"100%"} height={80} />
            </Grid.Column>
            <Grid.Column>
              <div className="company-list__item-header">{company.name}</div>
              <Accordion className="company-list__item-accordion">
                <Accordion.Title index={index} active={active} onClick={handleAccordionClick}>
                  <Icon name="dropdown" />
                  {renderAccordionTrigger()}
                </Accordion.Title>
                <Accordion.Content active={active}>
                  <Grid className="company-list__item-details" stackable>
                    <Grid.Row columns={1}>
                      <Grid.Column>{company.description}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <div className="company-list__item-details__header">{t("common.administration")}</div>
                        <div>
                          {t("companies.code")}: {company.code}
                        </div>
                        <div>
                          {t("common.industry")}: {company.industry?.name}
                        </div>
                        <div>
                          {t("common.email")}: {company.email}
                        </div>
                        <div>
                          {t("common.phone")}: {company.phoneNumber}
                        </div>
                        <div>
                          {t("common.web")}:{" "}
                          <a href={company.website} target="blank">
                            {company.website}
                          </a>
                        </div>
                        <div>
                          {t("common.address")}: {company.address}, {company.city}
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="company-list__item-details__header">{t("common.career_coordinator")}</div>
                        <div>
                          {company.contactInfo.fullName}, {company.contactInfo.jobTitle}
                        </div>
                        <div>
                          {t("common.email")}: {company.contactInfo.email}
                        </div>
                        <div>
                          {t("common.phone")}: {company.contactInfo.phoneNumber}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </Accordion>
              {handleApprove && handleReject && (
                <div className="company-list__item-actions">
                  <Button
                    primary
                    disabled={isApproving}
                    loading={isApproving}
                    content={t("pending_list.actions.approve")}
                    onClick={() => handleApprove!(company.id)}
                  />
                  <RejectCompanyModal companyName={company.name} companyId={company.id} handleReject={handleReject!} />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default CompanyListItem;
