import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Segment, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import useToaster from "../../app/hooks/useToaster";
import { Student } from "../students/types";
import { downloadFile } from "../../app/helpers/fileHelper";
import ButtonLink from "../../components/ButtonLink";

const StudentPendingAgreementList = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<Student[]>([]);
  const [isUpdating, setUpdating] = useState(false);
  const { apiError } = useToaster();

  const loadItems = async () => {
    setItems(await agent.Students.listPendingAgreements());
  };

  const handleReject = async (item: Student) => {
    setUpdating(true);
    try {
      await agent.Students.rejectAgreement(item.id);
      await loadItems();
    } catch (error: any) {
      apiError(error);
    }
    setUpdating(false);
  };

  const handleApprove = async (item: Student) => {
    setUpdating(true);
    try {
      await agent.Students.approveAgreement(item.id);
      await loadItems();
    } catch (error: any) {
      apiError(error);
    }
    setUpdating(false);
  };

  useEffect(() => {
    loadItems();
  }, []);

  const download = async (fileId: string) => {
    const response = await agent.Files.getAgreement(fileId);
    downloadFile(response);
  };

  if (items.length === 0) {
    return <>{t("studentAgreements.empty")}</>;
  }

  return (
    <>
      <Segment placeholder padded className="pending-registrations-segment">
        <div className="pending-registrations">
          <div className="pending-registrations-text">
            {t("studentAgreements.title")}: {items.length}
          </div>
          <Table basic="very" className="student-list pending">
            <Table.Body>
              {items.map((item) => (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.contactInfo.fullName}</Table.Cell>
                  <Table.Cell>
                    <a href={"mailto:" + item.contactInfo.email}>{item.contactInfo.email}</a>
                  </Table.Cell>
                  <Table.Cell>{item.contactInfo.phoneNumber}</Table.Cell>
                  <Table.Cell>{item.school}</Table.Cell>
                  <Table.Cell>
                    <ButtonLink onClick={() => download(item.agreement.file!)}>
                      {t("studentAgreements.file")}
                    </ButtonLink>
                  </Table.Cell>
                  <Table.Cell className="student-list-actions" singleLine>
                    <Button
                      primary
                      content={t("pending_list.actions.approve")}
                      disabled={isUpdating}
                      onClick={() => handleApprove(item)}
                    />
                    <Button
                      primary
                      negative
                      disabled={isUpdating}
                      content={t("pending_list.actions.reject")}
                      onClick={() => handleReject(item)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </>
  );
};

export default StudentPendingAgreementList;
