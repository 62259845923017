import React from "react";
import { userHelpers } from "../../app/helpers/userHelpers";
import CompanyReportsPage from "./company/CompanyReportsPage";
import SchoolReportsPage from "./school/SchoolReportsPage";
import MunicipalityReportsPage from "./municipality/MunicipalityReportsPage";
import useReportFilter, { ReportFilterContext } from "./useReportFilter";
import AdminReportsPage from "./admin/AdminReportsPage";

const ReportsPage = () => {
  const reportOptions = useReportFilter();

  const renderPage = () => {
    if (userHelpers.isCompany()) {
      return <CompanyReportsPage />;
    }

    if (userHelpers.isSchool()) {
      return <SchoolReportsPage />;
    }

    if (userHelpers.isMunicipality()) {
      return <MunicipalityReportsPage />;
    }

    if (userHelpers.isAdmin()) {
      return <AdminReportsPage />;
    }

    return null;
  };

  return <ReportFilterContext.Provider value={reportOptions}>{renderPage()}</ReportFilterContext.Provider>;
};

export default ReportsPage;
