import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Segment } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import PageTitle from "../../../../components/page-title/PageTitle";
import PasswordChangeModal from "../../../user/components/passwordChange/PasswordChangeModal";
import { User } from "../../../user/types";
import ProfileSegment from "../ProfileSegment";

const AdminProfile = () => {
  const { t } = useTranslation();
  const [admin, setAdmin] = useState<User>();

  useEffect(() => {
    agent.Admin.getProfile().then((response) => {
      setAdmin(response);
    });
  }, []);

  if (!admin) {
    return null;
  }

  const fields = {
    "common.password": <PasswordChangeModal />,
  };

  const columns = [{ header: admin.email, values: fields }];

  return (
    <Fragment>
      <PageTitle title={t("user.my_profile")} />
      <Segment padded="very">
        <ProfileSegment columns={columns} />
      </Segment>
    </Fragment>
  );
};

export default AdminProfile;
