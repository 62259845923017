import React, { useState } from "react";
import { Table, Button } from "semantic-ui-react";
import { Municipality } from "../types";
import { useTranslation } from "react-i18next";

interface IProps {
  municipality: Municipality;
  handleActiveToggle: (id: number) => Promise<void>;
}

const MunicipalityItem: React.FC<IProps> = ({ municipality, handleActiveToggle }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(municipality.isActive);

  const handleToggle = () => {
    handleActiveToggle(municipality.id).then(() => {
      setActive(!active);
    });
  };

  return (
    <Table.Row>
      <Table.Cell singleLine>{municipality.name}</Table.Cell>
      <Table.Cell singleLine>
        <p>{municipality.contactInfo.fullName}</p>
        <p>{municipality.contactInfo.phoneNumber}</p>
        <p>{municipality.contactInfo.email}</p>
      </Table.Cell>
      <Table.Cell>
        <Button toggle content={active ? t("common.on") : t("common.off")} active={active} onClick={handleToggle} />
      </Table.Cell>
    </Table.Row>
  );
};

export default MunicipalityItem;
