import { useEffect, useMemo, useState } from "react";
import agent from "../api/agent";
import { Municipality } from "../../features/municipalities/types";

export type City = string;

const useMunicipalities = () => {
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);

  useEffect(() => {
    agent.Municipalities.list().then(setMunicipalities);
  }, []);

  return useMemo(
    () => ({
      municipalities,
      municipalityOptions: municipalities.map((municipality) => ({
        key: municipality.id,
        value: municipality.id,
        text: municipality.name,
      })),
    }),
    [municipalities]
  );
};

export default useMunicipalities;
