export const prettifyDate = (date: string) => {
  return new Date(date).toLocaleString("lt-LT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const prettifyDateWithoutTime = (date: string | Date) => {
  return new Date(date).toLocaleString("lt-LT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const getISODate = (date: Date): string => {
  const isoDate = new Date(date);
  isoDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const dateString = isoDate.toISOString().slice(0, 10);

  return dateString;
};

export const getYearsUntilNow = (yearFrom = 2020): number[] => {
  const from = new Date(String(yearFrom));
  const to = new Date();
  const length = to.getFullYear() - from.getFullYear() + 1;

  return Array.from({ length }).map((_, i) => new Date(String(yearFrom + i)).getFullYear());
};

export const getCurrentAcademicYearStart = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  // Note: January is 0, February is 1, and so on.
  const currentMonth = now.getMonth() + 1;

  if (currentMonth >= 9) {
    return currentYear;
  }

  return currentYear - 1;
};

export const prettifyDuration = (totalMins: number) => {
  const hours = Math.floor(totalMins / 60);
  const mins = totalMins - hours * 60;
  let text = `${hours} val.`;
  if (mins > 0) {
    text += ` ${mins} min.`;
  }

  return text;
};

export function createDateTime(date: string, time: string): string {
  const result = new Date(date);
  const [hours, minutes] = time.split(":");
  result.setHours(Number(hours));
  result.setMinutes(Number(minutes));

  return result.toISOString();
}

export function getTime(dateTime: string | Date): string {
  const date = new Date(dateTime);
  const time = String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0");

  return time;
}
