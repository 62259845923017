/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PropsWithChildren } from "react";
import usePageClass from "../../app/hooks/usePageClass";
import ShIcon from "../../components/ShIcon";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";

interface Props {
  title?: string;
  isVisible?: boolean;
  onClose: () => void;
}

const sidenavRoot = document.getElementById("sidenav-root");

const SideNav = ({ children, onClose, title, isVisible = true }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  usePageClass(isVisible ? "sidenav-opened" : undefined);

  const sidenav = (
    <div className={`sidenav ${isVisible ? "sidenav--opened" : ""}`}>
      <div className="sidenav__content">
        <div className="sidenav__header">
          {title && t(title)}
          <button className="sidenav__close" onClick={onClose}>
            <ShIcon name="close" />
          </button>
        </div>
        {children}
      </div>
      <div className="sidenav__overlay" onClick={onClose}></div>
    </div>
  );

  return createPortal(sidenav, sidenavRoot!);
};

export default SideNav;
