import React from "react";
import { round } from "../../app/helpers/numberHelpers";
import Badge from "../../components/Badge";

interface Props {
  answersCount: number;
  percentage: number;
}

const ReportOptionValue = ({ answersCount, percentage }: Props) => {
  return <Badge value={`${answersCount} (${round(percentage)}%)`} />;
};

export default ReportOptionValue;
