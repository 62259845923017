import React from "react";
import { useTranslation } from "react-i18next";
import { Segment } from "semantic-ui-react";
import { CompanyProfileModel } from "../types";
import CompanyList from "./CompanyList";

interface Props {
  companies: CompanyProfileModel[];
  handleApprove: (companyId: number) => Promise<any>;
  handleReject: (companyId: number, reason: string) => void;
}

const PendingRegistrations: React.FC<Props> = ({ companies, handleApprove, handleReject }) => {
  const { t } = useTranslation();

  return (
    <Segment placeholder padded className="full-width-grey">
      <div className="pending-company-registrations__body">
        <div className="pending-company-registrations__count">
          {t("pending_list.title")}: {companies.length}
        </div>
        <CompanyList companies={companies} handleApprove={handleApprove} handleReject={handleReject} />
      </div>
    </Segment>
  );
};

export default PendingRegistrations;
