import React, { useCallback, useEffect, useState } from "react";
import { ListFilterConfig, ListFilterValue } from "../../components/list-filter/models";
import { getFilterInitialValue } from "../../components/list-filter/ListFilter";
import { ReflectionReportModel } from "./models";
import ReflectionReport from "./ReflectionReport";
import useToaster from "../../app/hooks/useToaster";

interface Props {
  filterConfig?: ListFilterConfig;
  initialFilterValue?: ListFilterValue;
  dataResourceFn: (filterValue?: ListFilterValue) => Promise<ReflectionReportModel>;
}

const ReflectionReportContainer = ({ filterConfig, initialFilterValue, dataResourceFn }: Props) => {
  const [values, setValues] = useState<ReflectionReportModel>({});
  const [filterValue, setFilterValue] = useState(
    initialFilterValue ?? (filterConfig && getFilterInitialValue(filterConfig))
  );
  const [isLoading, setLoading] = useState(true);
  const { apiError } = useToaster();

  const loadData = useCallback(
    async (filter?: ListFilterValue) => {
      setLoading(true);

      try {
        const response = await dataResourceFn(filter);
        setValues(response);
      } catch (error) {
        apiError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiError, dataResourceFn]
  );

  useEffect(() => {
    loadData(filterValue);
  }, [filterValue, loadData]);

  return (
    <ReflectionReport
      isLoading={isLoading}
      values={values}
      filterConfig={filterConfig}
      filterValue={filterValue}
      onFilterChange={setFilterValue}
    ></ReflectionReport>
  );
};

export default ReflectionReportContainer;
