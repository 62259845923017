import React, { ReactNode } from "react";
import SlickSlider, { Settings } from "react-slick";
import "./styles.less";

const Slider = ({ children }: { children: ReactNode }) => {
  const settings: Settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider">
      <SlickSlider {...settings}>{children}</SlickSlider>
    </div>
  );
};

export default Slider;
