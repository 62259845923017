import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Header } from "semantic-ui-react";
import AppForm from "../../../components/form/Form";
import { useAppForm } from "../../../components/form/useForm";
import FormModal from "../../../components/FormModal";
import RejectCompanyForm from "./RejectCompanyForm";

interface Props {
  companyId: number;
  companyName: string;
  handleReject: (companyId: number, reason: string) => void;
}

const RejectCompanyModal: React.FC<Props> = ({ companyName, companyId, handleReject }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const formHook = useAppForm({
    defaultValues: { reason: "" },
  });
  const [isRejecting, setRejecting] = useState(false);

  const handleSubmit = async ({ reason }: any) => {
    setRejecting(true);
    try {
      await handleReject(companyId, reason);
      setOpen(false);
    } finally {
      setRejecting(false);
    }
  };

  const actions = (
    <>
      <Button primary disabled={isRejecting} loading={isRejecting} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("common.actions.reject")}
      </Button>
      <Button negative disabled={isRejecting} onClick={() => setOpen(false)}>
        {t("common.actions.cancel")}
      </Button>
    </>
  );

  return (
    <FormModal
      title="companies.actions.reject_company"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      isOpen={open}
      actions={actions}
      trigger={<Button negative content={t("common.actions.reject")} />}
      isDisabled={isRejecting}
    >
      <Modal.Content>
        <Modal.Description>
          <Header>{companyName}</Header>
          <AppForm formHook={formHook} onSubmit={handleSubmit}>
            <RejectCompanyForm />
          </AppForm>
        </Modal.Description>
      </Modal.Content>
    </FormModal>
  );
};

export default RejectCompanyModal;
