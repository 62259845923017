import React, { Fragment, PropsWithChildren } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import AppForm from "../../../../components/form/Form";
import { Student } from "../../../students/types";
import { useAppForm } from "../../../../components/form/useForm";
import useGrades from "../../../../app/hooks/useGrades";

export interface StudentUpdateForm {
  grade: number;
  phoneNumber: string;
}

interface Props {
  isSaving: boolean;
  student: Student;
  handleSubmit: (data: StudentUpdateForm) => void;
}

const StudentProfileForm = ({ isSaving, student, handleSubmit, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const formHook = useAppForm<StudentUpdateForm>({
    defaultValues: { grade: student.grade, phoneNumber: student.contactInfo.phoneNumber },
  });
  const { gradeOptions } = useGrades();

  return (
    <Fragment>
      <AppForm formHook={formHook} onSubmit={handleSubmit}>
        <AppForm.Group name="grade" label="common.grade">
          <AppForm.Select name="grade" options={gradeOptions} rules={{ required: true }} />
        </AppForm.Group>
        <AppForm.Group name="phoneNumber" label="common.phone">
          <AppForm.Phone name="phoneNumber" rules={{ required: true }} />
        </AppForm.Group>
      </AppForm>

      <Button primary loading={isSaving} disabled={isSaving} onClick={formHook.handleSubmit(handleSubmit)}>
        {t("common.actions.confirm")}
      </Button>
      {children}
    </Fragment>
  );
};

export default StudentProfileForm;
