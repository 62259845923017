import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateShadowingSortFilter } from "../actions";
import { RootState } from "../../../app/store/configureStore";
import { SORT_BY } from "../sort_options";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { UserRoles } from "../../../app/auth/roles";
import { HasRole } from "../../../app/helpers/userHelpers";

interface Props {
  text?: string;
  icon?: any;
}

const ShadowingSorter = ({ text, icon }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAdmin = HasRole(UserRoles.admin);

  const options = [
    {
      key: "nearest",
      text: <h4>{t("common.sort_by.nearest")}</h4>,
      value: SORT_BY.nearestFirst,
      content: t("common.sort_by.nearest"),
    },
    {
      key: "furthest",
      text: <h4>{t("common.sort_by.furthest")}</h4>,
      value: SORT_BY.furthestFirst,
      content: t("common.sort_by.furthest"),
    },
    ...(isAdmin
      ? [
          {
            key: "newest",
            text: <h4>{t("common.sort_by.newest")}</h4>,
            value: "createdOn_asc",
            content: t("common.sort_by.newest"),
          },
          {
            key: "oldest",
            text: <h4>{t("common.sort_by.oldest")}</h4>,
            value: "createdOn_desc",
            content: t("common.sort_by.oldest"),
          },
        ]
      : []),
  ];

  const handleChange = (_: any, { value }: any) => {
    dispatch(updateShadowingSortFilter(value));
  };

  const currentSortBy: string = useSelector((state: RootState) => state.shadowingsFilter.sortBy);

  return (
    <div className="shadowing-sorter">
      <Dropdown icon={icon} text={text} options={options} value={currentSortBy} onChange={handleChange} />
    </div>
  );
};

export default ShadowingSorter;
