import React from "react";
import { Checkbox } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateShadowingOnlineFilter } from "../actions";
import { RootState } from "../../../app/store/configureStore";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const OnlineFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isSelected = useSelector((state: RootState) => state.shadowingsFilter.isOnline);

  const handleChange = (value: boolean) => {
    dispatch(updateShadowingOnlineFilter(value));
  };

  return (
    <Checkbox
      onChange={() => handleChange(!isSelected)}
      checked={isSelected}
      className="sh-filter"
      label={<label>{t("shadowings_filter.is_online")}</label>}
    />
  );
};

export default OnlineFilter;
