import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { Student } from "../types";
import { useTranslation } from "react-i18next";
import agent from "../../../app/api/agent";
import useToaster from "../../../app/hooks/useToaster";
import { Link } from "react-router-dom";

interface IProps {
  student: Student;
  showSimpleButton?: boolean;
  onActivationChange?: () => void;
}

const StudentActivationButton = ({ student, onActivationChange, showSimpleButton }: IProps) => {
  const { t } = useTranslation();
  const { apiError, success } = useToaster();

  const [isUpdating, setUpdating] = useState(false);
  const [showDeactivationConfirmation, setDeactivationConfirmation] = useState(false);
  const [showActivationConfirmation, setActivationConfirmation] = useState(false);

  const handleDeactivate = () => {
    setUpdating(true);

    agent.Students.deactivate(student.id)
      .then(() => {
        success(t("student_list.deactivation.success"));
        onActivationChange?.();
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleActivate = () => {
    setUpdating(true);

    agent.Students.activate(student.id)
      .then(() => {
        success(t("student_list.activation.success"));
        onActivationChange?.();
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  if (student.isDeactivated) {
    return (
      <>
        {showSimpleButton ? (
          <Link to="#" onClick={() => setActivationConfirmation(true)}>
            {t("student_list.activation.button")}
          </Link>
        ) : (
          <Button positive disabled={isUpdating} onClick={() => setActivationConfirmation(true)}>
            {t("student_list.activation.button")}
          </Button>
        )}
        <Confirm
          closeOnDimmerClick={false}
          closeOnEscape={false}
          size="tiny"
          content={t("student_list.activation.confirmation.text")}
          open={showActivationConfirmation}
          confirmButton={t("student_list.activation.confirmation.yes")}
          cancelButton={t("student_list.activation.confirmation.no")}
          onCancel={() => setActivationConfirmation(false)}
          onConfirm={handleActivate}
        />
      </>
    );
  }

  return (
    <>
      {showSimpleButton ? (
        <Link to="#" onClick={() => setDeactivationConfirmation(true)}>
          {t("student_list.deactivation.button")}
        </Link>
      ) : (
        <Button negative disabled={isUpdating} onClick={() => setDeactivationConfirmation(true)}>
          {t("student_list.deactivation.button")}
        </Button>
      )}
      <Confirm
        closeOnDimmerClick={false}
        closeOnEscape={false}
        size="tiny"
        content={t("student_list.deactivation.confirmation.text")}
        open={showDeactivationConfirmation}
        confirmButton={t("student_list.deactivation.confirmation.yes")}
        cancelButton={t("student_list.deactivation.confirmation.no")}
        onCancel={() => setDeactivationConfirmation(false)}
        onConfirm={handleDeactivate}
      />
    </>
  );
};

export default StudentActivationButton;
