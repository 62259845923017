import React from "react";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

interface Props {
  min?: number;
  max?: number;
}

const AppPoints: React.FC<PropsInjectedByController<number> & Props> = ({
  onChange,
  isDisabled,
  value,
  name,
  min = 0,
  max = 10,
}) => {
  const points = Array.from({ length: max - min + 1 }).map((_, i) => min + i);

  return (
    <ul className="points-group">
      {points.map((point) => {
        const id = name + "-" + point;

        return (
          <li key={point}>
            <input
              type="radio"
              name={name}
              checked={value === point}
              id={id}
              onChange={() => onChange(point)}
              disabled={isDisabled}
            />
            <label className="point" htmlFor={id}>
              {point}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

const AppPointsController = withController(AppPoints);

export { AppPointsController };
export default AppPoints;
