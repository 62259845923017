import React from "react";
import { Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/page-title/PageTitle";

interface IProps {
  handleTabChange(_: any, data: any): void;
}

const ApplicationFilters: React.FC<IProps> = ({ handleTabChange }) => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("applications.student.upcoming.title"),
      render: () => (
        <PageTitle
          title={t("applications.student.upcoming.title")}
          description={t("applications.student.upcoming.description")}
        />
      ),
    },
    {
      menuItem: t("applications.student.past.title"),
      render: () => (
        <PageTitle
          title={t("applications.student.past.title")}
          description={t("applications.student.past.description")}
        />
      ),
    },
  ];

  return (
    <Tab
      onTabChange={handleTabChange}
      className={"applications-filter"}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  );
};

export default ApplicationFilters;
