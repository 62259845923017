import { Question, QuestionType } from "./models";

export const reflectionQuestions: Record<number, Question> = {
  1: {
    id: 1,
    title: "Ar žinote, kokios karjeros sritys ir profesijos jus domina?",
    type: QuestionType.Radio,
    options: [
      { title: "Taip, tiksliai žinau profesiją, kurią noriu įgyti", value: 0 },
      { title: "Taip, galiu įvardinti kelias profesijas, kurios mane domina", value: 1 },
      { title: "Dar ne, bet pradedu galvoti apie galimas profesijas", value: 2 },
      { title: "Ne, dar nežinau", value: 3 },
    ],
  },
  2: {
    id: 2,
    title: "Kokį planuojate įgyti išsilavinimą?",
    type: QuestionType.Radio,
    options: [
      { title: "Universitetinį", value: 0 },
      { title: "Koleginį", value: 1 },
      { title: "Profesinį", value: 2 },
      { title: "Neplanuoju įgyti išsilavinimo", value: 3 },
    ],
  },
  3: {
    id: 3,
    title:
      "Kritinis ir analitinis mąstymas: gebėjimas strategiškai ir logiškai mąstyti, apibendrinti turimą informaciją ir daryti išvadas.",
    type: QuestionType.Points,
  },
  4: {
    id: 4,
    title:
      "Problemų sprendimas: gebėjimas analitiškai ir kūrybiškai mąstyti, siekiant nustatyti pagrindinę problemos priežastį (-is).",
    type: QuestionType.Points,
  },
  5: {
    id: 5,
    title: "Karjeros planavimas: gebėjimas atlikti darbo paiešką ir parengti asmeninį karjeros planą.",
    type: QuestionType.Points,
  },
  6: {
    id: 6,
    title: "Įtraukianti komunikacija: gebėjimas aiškiai komunikuoti, sklandžiai ir logiškai dėstyti savo mintis.",
    type: QuestionType.Points,
  },
  7: {
    id: 7,
    title:
      "Sprendimų priėmimas: gebėjimas analizuoti svarbią informaciją, apsvarstyti galimas pasekmes ir įvertinti alternatyvas.",
    type: QuestionType.Points,
  },
  8: {
    id: 8,
    title:
      "Tarpasmeniniai įgūdžiai: gebėjimas kurti, palaikyti ir plėtoti tvirtus asmeninius bei dalykinius ryšius; gebėjimas gauti naudingą informaciją naudojant ryšius.",
    type: QuestionType.Points,
  },
  9: {
    id: 9,
    title: "Savarankiškumas: gebėjimas savarankiškai atlikti užduotis ir laiku pasiekti tikslus.",
    type: QuestionType.Points,
  },
  10: {
    id: 10,
    title:
      "Kultūrinis sąmoningumas: gebėjimas įvertinti savo elgesį ir jį palyginti su nustatytomis vertybinėmis nuostatomis.",
    type: QuestionType.Points,
  },
  11: {
    id: 11,
    title:
      "Kūrybiškumas: gebėjimas kūrybiškai spręsti klausimus ar problemas ir matyti nesėkmes kaip galimybes tobulėti.",
    type: QuestionType.Points,
  },
  12: {
    id: 12,
    title: "Ar šešėliavimas jums buvo teigiama patirtis?",
    type: QuestionType.Boolean,
  },
  13: {
    id: 13,
    title: "Kodėl taip atsakėte?",
    type: QuestionType.Textbox,
  },
  14: {
    id: 14,
    title: "Kaip vertinate savo pasiruošimą vizitui organizacijoje? (0 – Labai blogai / 10 – Labai gerai)",
    type: QuestionType.Points,
  },
  //
  10014: {
    id: 10014, // related to questionId 14
    title: "Kodėl taip atsakėte?",
    type: QuestionType.Textbox,
  },
  15: {
    id: 15,
    title: "Kaip vertinate vizitą organizacijoje? (0 – Labai blogai / 10 – Labai gerai)",
    type: QuestionType.Points,
  },
  10015: {
    id: 10015, // related to questionId 15
    title: "Kodėl taip atsakėte?",
    type: QuestionType.Textbox,
  },
  16: {
    id: 16,
    title:
      "Ar vizito metu gauta informacija ir įgyta patirtis padėjo jums geriau suprasti, ką konkrečiai veikia šios profesijos atstovas?",
    type: QuestionType.Boolean,
  },
  17: {
    id: 17,
    title: "Ar norėtumėte dirbti tokioje pozicijoje?",
    type: QuestionType.Boolean,
  },
  18: {
    id: 18,
    title: "Kodėl taip arba kodėl ne?",
    type: QuestionType.Textbox,
  },
  19: {
    id: 19,
    title: "Kiek tikėtina, kad rekomenduotumėte savo šešėliavimo vadovą (jus priėmusį asmenį) draugui ar draugei?",
    type: QuestionType.Points,
  },
  20: {
    id: 20,
    title: "Jūsų pasiūlymai, komentarai",
    type: QuestionType.Textbox,
    optional: true,
  },
  // Application feedback questions
  21: {
    id: 21,
    title: "Kaip vertinate mokinio pasiruošimą vizitui jūsų organizacijoje?",
    type: QuestionType.Points,
  },
  22: {
    id: 22,
    title: "Kodėl?",
    type: QuestionType.Textbox,
  },
  27: {
    id: 27,
    title: "Kaip vertinate mokinio aktyvumą vizito metu jūsų organizacijoje?",
    type: QuestionType.Points,
  },
  28: {
    id: 28,
    title: "Kodėl?",
    type: QuestionType.Textbox,
  },
  // Shadowing feedback questions
  23: {
    id: 23,
    title: "Mūsų organizacijos nuomone, ši patirtis buvo naudinga/nenaudinga mūsų organizacijai, nes",
    type: QuestionType.Textbox,
  },
  24: {
    id: 24,
    title: "Mūsų organizacijos nuomone, ši patirtis buvo naudinga/nenaudinga mokiniui, nes",
    type: QuestionType.Textbox,
  },
  25: {
    id: 25,
    title: "Kitą kartą mūsų organizacija vėl sutiktų dalyvauti šešėliavimo programoje ir priimti mokinį vizitui.",
    type: QuestionType.Radio,
    options: [
      { title: "Sutinku", value: 0 },
      { title: "Nesutinku", value: 1 },
      { title: "Nesu tikras", value: 2 },
    ],
  },
  26: {
    id: 26,
    title: "Jūsų pasiūlymai",
    type: QuestionType.Textbox,
  },
};

// eslint-disable-next-line prettier/prettier
export const reflectionQuestionOrder = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 10014, 15, 10015, 16, 17, 18, 19, 20, 21, 22, 27, 28, 23, 24, 25, 26,
];
