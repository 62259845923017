import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../../../app/api/agent";
import { Student } from "../../../students/types";
import PageTitle from "../../../../components/page-title/PageTitle";
import GenderImage from "../../../../components/GenderImage";
import { genderTranslation } from "../../../../app/helpers/genderHelpers";
import ProfileSegment from "../ProfileSegment";
import { Segment } from "semantic-ui-react";
import PasswordChangeModal from "../../../user/components/passwordChange/PasswordChangeModal";
import StudentProfileModal from "./StudentProfileModal";
import StudentProfileAgreement from "./StudentProfileAgreement";

const StudentProfile = () => {
  const { t } = useTranslation();

  const [student, setStudent] = useState<Student>();

  useEffect(() => {
    agent.Students.getProfile().then((response) => {
      setStudent(response);
    });
  }, []);

  if (!student) {
    return null;
  }

  const fields = {
    "common.email": student.contactInfo.email,
    "common.phone": student.contactInfo.phoneNumber,
    "common.gender": genderTranslation(student.gender, t),
    "common.school": student.school,
    "common.grade": `${student.grade}`,
    "common.password": <PasswordChangeModal />,
  };

  const nameWithAvatar = (
    <Fragment>
      <GenderImage gender={student.gender} />
      <h3>{student.contactInfo.fullName}</h3>
    </Fragment>
  );

  const columns = [{ header: nameWithAvatar, values: fields }];

  return (
    <Fragment>
      <PageTitle title={t("user.my_profile")} />
      <Segment padded="very">
        <ProfileSegment columns={columns} />
        <StudentProfileModal student={student} onUpdate={setStudent} />
      </Segment>

      <StudentProfileAgreement student={student} />
    </Fragment>
  );
};

export default StudentProfile;
