import { useSelector } from "react-redux";
import { RootState } from "../store/configureStore";

const useIsLoggedIn = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.currentUser);

  return isLoggedIn;
};

export default useIsLoggedIn;
