import React from "react";
import { fileSizeValidator, fileTypeValidator } from "../../../app/helpers/validators";
import AppForm from "../../../components/form/Form";

const ApplicationForm = () => {
  return (
    <>
      <AppForm.Group name="motivationLetter" label="application.form.motivation_letter">
        <AppForm.TextArea
          name="motivationLetter"
          placeholder="application.form.placeholder.motivation_letter"
          rules={{ required: true }}
        />
      </AppForm.Group>
      <AppForm.Group name="linkedinUrl" label="application.form.linkedin">
        <AppForm.Url name="linkedinUrl" placeholder="application.form.placeholder.linkedin" />
      </AppForm.Group>
      <AppForm.Group name="cv">
        <AppForm.File
          name="cv"
          placeholder="application.modal.attach_cv"
          accept={[".pdf", ".doc", ".docx"]}
          rules={{
            validate: {
              filesize: (v) => fileSizeValidator()(v),
              filetype: (v) => fileTypeValidator(["pdf", "doc", "docx"])(v),
            },
          }}
        />
      </AppForm.Group>
    </>
  );
};

export default ApplicationForm;
