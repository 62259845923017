import React from "react";
import { Season } from "./models";
import "./styles.less";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { prettifyDateWithoutTime } from "../../app/helpers/dateHelpers";
import SeasonsRoundAdmin from "./SeasonsRoundAdmin";
import { getActive } from "./season-utils";

interface Props {
  seasons: Season[];
  isSeasonStateChanging: boolean;
  isRoundStateChanging: boolean;
  onSeasonStart: () => void;
  onSeasonStop: () => void;
  onRoundStart: () => void;
  onRoundStop: () => void;
}

const SeasonsAdmin = ({
  seasons,
  isSeasonStateChanging,
  isRoundStateChanging,
  onSeasonStart: handleSeasonStart,
  onSeasonStop: handleSeasonStop,
  onRoundStart: handleRoundStart,
  onRoundStop: handleRoundStop,
}: Props) => {
  const { t } = useTranslation();
  const activeSeason = getActive(seasons);
  const activeRound = getActive(activeSeason?.rounds);
  const lastSeason = seasons[0];

  const isDisabled = isSeasonStateChanging || isRoundStateChanging;

  if (!activeSeason) {
    return (
      <div className="season">
        <p>
          {t("seasons.noActiveSeason")}
          {lastSeason?.stopDate ? (
            <>
              <br />
              {t("seasons.lastSeasonFinished", { date: prettifyDateWithoutTime(lastSeason.stopDate) })}
            </>
          ) : (
            ""
          )}
        </p>
        <Button primary onClick={handleSeasonStart} loading={isSeasonStateChanging} disabled={isDisabled}>
          {t("seasons.startSeason")}
        </Button>
      </div>
    );
  }

  return (
    <div className="season">
      <p>{t("seasons.seasonStarted", { date: prettifyDateWithoutTime(activeSeason.startDate) })}</p>
      {!activeRound && (
        <Button color="red" onClick={handleSeasonStop} loading={isSeasonStateChanging} disabled={isDisabled}>
          {t("seasons.stopSeason")}
        </Button>
      )}
      <SeasonsRoundAdmin
        activeSeason={activeSeason}
        isDisabled={isSeasonStateChanging || isRoundStateChanging}
        isRoundStateChanging={isRoundStateChanging}
        onRoundStart={handleRoundStart}
        onRoundStop={handleRoundStop}
      />
    </div>
  );
};

export default SeasonsAdmin;
