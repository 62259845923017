import React, { createContext, PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { UseFormReturn, FormProvider, FieldValues } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { AppInputController } from "./Input";
import AppFormGroup from "./FormGroup";
import { AppCheckboxController } from "./Checkbox";
import { AppSelectController } from "./Select";
import { AppTextAreaController } from "./Textarea";
import { AppDateTimeController } from "./DateTime";
import { AppIntegerController } from "./Integer";
import { AppEmailController } from "./Email";
import { AppUrlController } from "./Url";
import { AppFileController } from "./File";
import { AppPasswordController } from "./Password";
import { AppDateController } from "./Date";
import { AppPhoneController } from "./Phone";
import { AppRadioController } from "./Radio";
import { AppPointsController } from "./Points";
import { AppTimeController } from "./Time";

interface Props<T extends FieldValues> {
  formHook: UseFormReturn<T>;
  isDisabled?: boolean;
  onSubmit: (d: T) => any;
}

export const AppFormContext = createContext<Required<Omit<Props<any>, "formHook" | "onSubmit">>>(undefined!);

const AppForm = ({ formHook, onSubmit, isDisabled = false, children }: PropsWithChildren<Props<any>>) => {
  const appFormContextValue = { isDisabled };

  return (
    <AppFormContext.Provider value={appFormContextValue}>
      <FormProvider {...formHook}>
        <Form onSubmit={formHook.handleSubmit(onSubmit)}>
          {children}
          {/* adding invisible submit button, to enable form submiting with Enter key */}
          <input type="submit" style={{ display: "none" }} />
        </Form>
      </FormProvider>
    </AppFormContext.Provider>
  );
};

AppForm.Group = AppFormGroup;

AppForm.Select = AppSelectController;
AppForm.Input = AppInputController;
AppForm.Integer = AppIntegerController;
AppForm.Checkbox = AppCheckboxController;
AppForm.TextArea = AppTextAreaController;
AppForm.Date = AppDateController;
AppForm.Time = AppTimeController;
AppForm.DateTime = AppDateTimeController;
AppForm.Email = AppEmailController;
AppForm.Url = AppUrlController;
AppForm.File = AppFileController;
AppForm.Password = AppPasswordController;
AppForm.Phone = AppPhoneController;
AppForm.Radio = AppRadioController;
AppForm.Points = AppPointsController;

export default AppForm;
