import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Grid, Icon, List } from "semantic-ui-react";
import { School } from "./types";
import SchoolEditModal from "./SchoolEditModal";

interface Props {
  school: School;
  active: boolean;
  index: number;
  handleAccordionClick: (e: any, titleProps: any) => void;
  reloadSchools: () => void;
}

const SchoolListItem: React.FC<Props> = ({ school, active, index, handleAccordionClick, reloadSchools }) => {
  const { t } = useTranslation();
  const hasContactInfo: boolean = school.contactInfo !== null;
  const renderAccordionTrigger = () => {
    return active ? t("common.actions.collapse") : t("common.actions.expand");
  };

  return (
    <List.Item>
      <List.Content>
        <List.Header>{school.name}</List.Header>
        <List.Description>
          <Accordion>
            <Accordion.Title index={index} active={active} onClick={handleAccordionClick}>
              <Icon name="dropdown" />
              {renderAccordionTrigger()}
            </Accordion.Title>
            <Accordion.Content active={active}>
              <Grid className="school-list__item-details" stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <div className="school-list__item-details__header">{t("common.administration")}</div>
                    <div>
                      {t("common.email")}: {school.email}
                    </div>
                    <div>
                      {t("common.phone")}: {school.phoneNumber}
                    </div>
                    <div>
                      {t("common.web")}:{" "}
                      <a href={school.website} target="blank">
                        {school.website}
                      </a>
                    </div>
                    <div>
                      {t("common.address")}: {school.address}, {school.city}
                    </div>
                  </Grid.Column>
                  {hasContactInfo && (
                    <Grid.Column>
                      <div className="school-list__item-details__header">{t("common.career_coordinator")}</div>
                      <div>
                        {school.contactInfo.fullName}, {school.contactInfo.jobTitle}
                      </div>
                      <div>
                        {t("common.email")}: {school.contactInfo.email}
                      </div>
                      <div>
                        {t("common.phone")}: {school.contactInfo.phoneNumber}
                      </div>
                    </Grid.Column>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="school-list__item-details-actions">
                    <SchoolEditModal school={school} reloadSchools={reloadSchools} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

export default SchoolListItem;
