import React, { useState } from "react";
import { InputProps } from "semantic-ui-react";
import { PropsInjectedByController } from "./types";
import AppInput from "./Input";
import { withController } from "./withController";
import Icons from "../../icons/icons.svg";

type Props = InputProps;

const AppPassword: React.FC<PropsInjectedByController & Props> = ({ onChange, ...props }) => {
  const [isVisible, setVisible] = useState(false);

  const icon = (
    <i className="icon">
      <svg
        className={`icon sh-icon-hidden`}
        width={24}
        height={24}
        onMouseDown={() => setVisible(true)}
        onMouseUp={() => setVisible(false)}
        onMouseLeave={() => setVisible(false)}
      >
        <use xlinkHref={`${Icons}#sh-icon-${isVisible ? "visible" : "hidden"}`} />
      </svg>
    </i>
  );
  const type = isVisible ? "text" : "password";

  const handleChange = (value: unknown) => {
    onChange(typeof value === "string" ? value.trim() : value);
  };

  return <AppInput autoComplete="off" className="pass" {...props} onChange={handleChange} {...{ type, icon }} />;
};

const AppPasswordController = withController(AppPassword);

export { AppPasswordController };
export default AppPassword;
