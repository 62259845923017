import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NonAuthRoutes } from "../../../../app/auth/routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import BaseAuthPage from "../BaseAuthPage";
import { Message } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store/configureStore";
import AuthLayout from "../AuthLayout";

interface Props {
  returnUrl?: string;
}

const LoginPage = ({ returnUrl = "/" }: Props) => {
  const { t } = useTranslation();
  const [hasTriedToLogin, setTriedToLogin] = useState(false);
  const query = new URLSearchParams(useLocation().search);

  const { isLoggedIn } = useSelector((state: RootState) => state.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  const renderEmailConfirmationMessage = () => {
    if (hasTriedToLogin || !query.get("emailConfirmed")) return null;

    return (
      <Message positive>
        <Message.Header>{t("auth.email_confirmed.title")}</Message.Header>
        <p>{t("auth.email_confirmed.description")}</p>
      </Message>
    );
  };

  const header = (
    <Fragment>
      <h1>{t("auth.login")}</h1>
      <p>{t("auth.who_can_login_explanation")}</p>
    </Fragment>
  );

  return (
    <AuthLayout>
      {renderEmailConfirmationMessage()}
      <BaseAuthPage
        header={header}
        content={<LoginForm onLogin={() => setTriedToLogin(true)} returnUrl={returnUrl} />}
        footer={<Link to={NonAuthRoutes.registration}>{t("auth.no_account")}</Link>}
      />
    </AuthLayout>
  );
};

export default LoginPage;
