import { ShadowingCreateFormModel } from "./types";

export const emptyShadowingModel: ShadowingCreateFormModel = {
  position: "",
  city: "",
  address: "",
  participantsCapacity: null!,
  visitDuration: null!,
  online: false,
  date: "",
  time: "",
  description: "",
  useHqAddress: true,
  industryIds: [],
  responsiblePerson: {
    fullName: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
  },
};
