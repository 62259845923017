import React, { useEffect, useState } from "react";
import { Modal, Item } from "semantic-ui-react";
import { Student } from "../types";
import { useTranslation } from "react-i18next";
import { SeparatedApplications } from "../../applications/types";
import agent from "../../../app/api/agent";
import ApplicationListItem from "../../applications/components/ApplicationListItem";
import { Link } from "react-router-dom";
import { isExistingReflection } from "../../../app/helpers/reflections";
import { ApplicationStatus } from "../../../app/enums/applicationStatus";

interface IProps {
  student: Student;
}

const StudentApplicationsModal: React.FC<IProps> = ({ student }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState<SeparatedApplications>({
    past: [],
    upcoming: [],
  });

  useEffect(() => {
    if (open) {
      loadApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const loadApplications = () => {
    agent.Applications.list(student.id.toString()).then((response) => {
      setApplications(response);
    });
  };

  const [applicationsCount, setApplicationsCount] = useState<number>(student.applicationsCount);

  const handleCancelApplication = (applicationId: number) => {
    agent.Applications.cancel(applicationId).then(() => {
      loadApplications();
      setApplicationsCount((prevState) => prevState - 1);
    });
  };

  const renderNoVisits = () => <p>{t("common.no_visits")}</p>;

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Link to="#">
          {t("applications.shortTitle")}: {applicationsCount}
        </Link>
      }
    >
      <Modal.Header>
        {student.contactInfo.fullName}, {student.grade} kl.
      </Modal.Header>
      <Modal.Content scrolling>
        <h3>{t("applications.school.upcoming.title")}</h3>
        <Item.Group>
          {applications.upcoming.length > 0
            ? applications.upcoming.map((application) => (
                <ApplicationListItem
                  student={student}
                  key={application.id}
                  application={application}
                  showReadFeedback={false}
                  allowCancelApplication={application.status === ApplicationStatus.COMPANY_APPROVED}
                  handleCancelApplication={handleCancelApplication}
                />
              ))
            : renderNoVisits()}
        </Item.Group>

        <h3>{t("applications.school.past.title")}</h3>
        <Item.Group>
          {applications.past.length > 0
            ? applications.past.map((application) => (
                <ApplicationListItem
                  student={student}
                  key={application.id}
                  application={application}
                  showReadFeedback={
                    isExistingReflection(application.reflections) || isExistingReflection(application.businessFeedback)
                  }
                  allowCancelApplication={false}
                  handleCancelApplication={handleCancelApplication}
                />
              ))
            : renderNoVisits()}
        </Item.Group>
      </Modal.Content>
    </Modal>
  );
};

export default StudentApplicationsModal;
