import React from "react";
import { useTranslation } from "react-i18next";
import { Segment } from "semantic-ui-react";
import PageTitle from "../../components/page-title/PageTitle";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const mailToElement = <a href={"mailto:" + t("footer.email")}>{t("footer.email")}</a>;
  const { host } = window.location;
  const hostUrlElement = (
    <a href={host} target="_blank" rel="noreferrer">
      {host}
    </a>
  );

  return (
    <>
      <PageTitle title={t("common.privacy_policy")} />
      <Segment padded="very">
        <article>
          <p>Įsigaliojimo data: 2021-09-01</p>
          <p>
            Viešoji įstaiga „Lietuvos Junior Achievement“ (toliau – LJA) vertina Jūsų susidomėjimą šia interneto
            svetaine. Todėl, norėdami užtikrinti Jūsų privatumą, mes imamės atitinkamų veiksmų savo sistemoje ir
            norėtume Jus su jais supažindinti.
          </p>
          <p>
            {hostUrlElement} interneto svetainės privatumo politikos (toliau – privatumo politika) tikslas – nustatyti
            pagrindinius asmens duomenų tvarkymo, teisių įgyvendinimo principus ir tvarką naudojantis interneto svetaine{" "}
            {hostUrlElement} (toliau – svetainė).
          </p>
          <p>
            Privatumo politika parengta vadovaujantis 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamentu
            (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir
            kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos reglamentas) (toliau – BDAR), Lietuvos
            Respublikos asmens duomenų teisinės apsaugos įstatymu, kitais teisės aktais, reglamentuojančiais asmens
            duomenų apsaugą.
          </p>

          <p>Šioje privatumo politikoje vartojamos sąvokos:</p>
          <ul>
            <li>
              asmens duomenys – bet kuri informacija, susijusi su fiziniu asmeniu, kurio tapatybė yra žinoma arba gali
              būti tiesiogiai arba netiesiogiai nustatyta pasinaudojant asmens duomenimis, pvz.: vardas, pavardė, gimimo
              data, adresas, el. pašto adresas, buvimo vietos duomenys, interneto identifikatorius ir kt.;
            </li>
            <li>duomenų subjektas – fizinis asmuo, svetainės lankytojas, kurio asmens duomenys renkami LJA;</li>
            <li>
              tvarkymas – tai bet koks su asmens duomenimis atliekamas veiksmas ar veiksmų rinkinys (rinkimas, įrašymas,
              naudojimas, susipažinimas, saugojimas, atskleidimas ir kt.);
            </li>
            <li>
              duomenų valdytojas – viešoji įstaiga „Lietuvos Junior Achievement“, juridinio asmens kodas 191832513, A.
              Goštauto g. 12-121, Vilnius.
            </li>
          </ul>

          <p>
            Kitos privatumo politikoje vartojamos sąvokos atitinka BDAR ir Lietuvos Respublikos asmens duomenų teisinės
            apsaugos įstatyme vartojamas sąvokas.
          </p>

          <p>
            <strong>Informacija ir naudojimas, asmens duomenų tvarkymo tikslai ir apimtis</strong>
          </p>
          <p>
            Kiekvieną kartą apsilankius mūsų svetainėje, serveriai automatiškai surenka reikalingus duomenis, kurie mums
            leistų keistis informacija su vartotojo kompiuteriu naršymo metu.
          </p>
          <p>
            Duomenis, surenkamus registracijos metu bei vartotojui atliekant veiksmus, mes naudojame statistikai sekti
            poveikio matavimo tikslais. Duomenys tretiesiems asmenims nėra perduodami. Jums paprašius, mes imsimės visų
            atitinkamų priemonių tam, kad pašalintumėme Jūsų asmens duomenis iš mūsų duomenų bazės užtikrinant, kad iš
            mūsų nebegautumėte tolimesnės komunikacijos. Jums informavus, kad mūsų turimi asmens duomenys apie Jus yra
            netikslūs, mes imsimės atitinkamų veiksmų juos ištaisyti. Norint kuo greičiau įvykdyti Jūsų prašymą, mes
            norėtume, kad, esant galimybei, suteiktumėte visą reikalingą informaciją, kokiomis priemonėmis Jūs perdavėte
            informaciją, pavyzdžiui, specifinio prekių ar paslaugų reklamos atveju. Kreipkitės - {mailToElement}.
          </p>
          <p>LJA, kaip duomenų valdytoja, naudodamasi svetaine renka ir toliau tvarko asmens duomenis:</p>
          <ul>
            <li>
              dalyvavimo „JA Šešėliavimas“ programoje tikslu – asmens duomenis, kurie gaunami duomenų subjektui
              jungiantis prie LJA paslaugų sistemos per svetainę: vardą, pavardę, lytį, gimimo datą, el. pašto adresą,
              mokymosi vietos savivaldybę, mokyklos pavadinimą, klasę;
            </li>
            <li>
              konkretaus prašymo išnagrinėjimo tikslu – vardą, pavardę, kontaktinius duomenis ryšiui palaikyti, kitus
              asmens duomenis, pateiktus duomenų subjekto iniciatyva;
            </li>
            <li>
              statistikai sekti poveikio matavimo tikslais – lytį, amžių, klasę, grįžtamąjį ryšį mokyklą, savivaldybę
              (mokiniams); industriją, savivaldybę, vizitų grįžtamąjį ryšį (verslui);
            </li>
            <li>
              svetainės funkcijų prieinamumo, tobulinimo, geresnių galimybių naršyti svetainėje sudarymo tikslais –
              slapukais surinktus svetainės lankytojų asmens duomenis: IP adresą, naršymo informaciją.
            </li>
          </ul>

          <p>
            <strong>Informacija, talpinama Jūsų kompiuteryje</strong>
          </p>
          <p>
            Mes galime talpinti tam tikrą informaciją (dažniausiai žinomą kaip slapukai (angl. cookies)) Jūsų
            kompiuteryje Jums naršant ar naudojantis svetaine. Ši informacija leidžia prisitaikyti prie Jūsų naršymo ir
            padeda išvengti tos pačios informacijos įvedimo dar kartą. Jeigu norite, šią informaciją galite lengvai
            ištrinti arba užblokuoti iš Jūsų kompiuterio.
          </p>

          <p>
            <strong>Nuorodos</strong>
          </p>
          <p>
            Svetainėje yra nuorodų į kitas svetaines, kurios nepriklauso, jų nevaldo ir neplėtoja VšĮ „Lietuvos Junior
            Achievement“. Mes nesame kitų svetainių atstovai ir nesame atsakingi už kitų svetainių privatumo politikas
            ir (ar) jų laikymąsi. Rekomenduojame perskaityti jų privatumo politikas ir, iškilus klausimams, kreiptis į
            atitinkamos svetainės administratorių.
          </p>

          <p>
            <strong>Informacijos sklaida tretiesiems asmenims</strong>
          </p>
          <p>
            Nors komunikuojant internetu ir naudojantis kitais kanalais informacija sklinda arba patenka tretiesiems
            asmenims, LJA nepardavinėja Jūsų asmens duomenų tretiesiems asmenims, kad jie galėtų Jums pasiūlyti savo
            produktus ar paslaugas. LJA suteikia prieigą prie nuasmenintos statistikos savivaldybės švietimo skyriaus
            atsakingam darbuotojui. Mokinių duomenys bus prieinami su jais dirbančiais bei LJA programoje
            dalyvaujantiems mokytojams. Bendra statistika, neidentifikuojant asmens duomenų, kuri yra surinkta LJA, gali
            būti persiųsta tarp atskirų LJA skyrių bei susijusių organizacijų visame pasaulyje, taip pat gali būti
            persiunčiama atitinkamiems partneriams.
          </p>
          <p>Mes turime teisę perduoti Jūsų asmens duomenis kitiems subjektams, kai to reikalauja teisės aktai.</p>
          <p>
            Mes taip pat turime teisę dalintis informacija su trečiaisiais asmenimis nuasmeninta apibendrinta ar
            tapatybės neatskleidžiančia forma.
          </p>

          <p>
            <strong>Nuasmenintų duomenų persiuntimas</strong>
          </p>
          <p>
            Statistinė informacija, neidentifikuojant asmens duomenų, surinkta apie Jus, gali būti persiųsta ne tik
            šalyje, kur ji buvo surinkta, bet ir už jos ribų. Šalys, į kurias informacija gali būti persiųsta, gali
            turėti kitokius duomenų apsaugos teisės aktus, negu valstybė, kurioje pateikėte informaciją. Šie statistinės
            informacijos persiuntimai gali būti vykdomi iš valstybių, esančių Europos Ekonominėje Erdvėje, į valstybes,
            nesančias EEE. Pateikdamas duomenis svetainėje, lankytojas eksplicitiškai sutinka, kad jo nuasmeninti
            duomenys gali būti persiųsti tokiu būdu, įskaitant tarpvalstybinius duomenų persiuntimus.
          </p>

          <p>
            <strong>Neskelbtina informacija</strong>
          </p>
          <p>
            LJA nesiekia gauti jokių specialių kategorijų asmens duomenų mūsų svetainėje, nebent LJA turi teisinę
            pareigą tai padaryti, pavyzdžiui, įdarbinimo atvejais. Specialių kategorijų asmens duomenys yra rasė ar
            etninė kilmė, politinės pažiūros, religiniai ar filosofiniai įsitikinimai, priklausymas profesinei sąjungai,
            fizinė ar protinė sveikata, lytinis gyvenimas, lytinė orientacija, genetiniai ar biometriniai duomenys. LJA
            taip pat nerenka ir netvarko asmens duomenų apie teistumą (apie apkaltinamuosius nuosprendžius ir
            nusikalstamas veikas). Mes rekomenduojame Jums niekada nepateikti tokio tipo duomenų.
          </p>

          <p>
            <strong>Duomenų subjektų teisių įgyvendinimas</strong>
          </p>
          <p>
            Jūs, kaip duomenų subjektas, turite teisę kreiptis į LJA dėl klausimų, susijusių Jūsų asmens duomenų
            tvarkymu, t. y. turite šias teises:
          </p>
          <ol>
            <li>Teisę gauti informaciją apie duomenų tvarkymą;</li>
            <li>Teisę susipažinti su duomenimis;</li>
            <li>Teisę reikalauti ištaisyti duomenis;</li>
            <li>Teisę reikalauti ištrinti duomenis („teisė būti pamirštam“);</li>
            <li>Teisę apriboti duomenų tvarkymą.</li>
          </ol>
          <p>
            Norėdami pasinaudoti BDAR Jums suteikiamomis teises, Jūs galite kreiptis į LJA su rašytiniu prašymu,
            patvirtinant savo tapatybę. LJA kaip duomenų valdytoja turi teisę naudotis visomis pagrįstomis priemonėmis,
            kad patikrintų prašymą teikiančio duomenų subjekto tapatybę. Prašymai dėl BDAR teisių įgyvendinimo, nesant
            galimybės nustatyti prašymą teikiančio asmens tapatybę, LJA nėra nagrinėjami.
          </p>
          <p>Prašymai LJA dėl Jūsų asmens duomenų tvarkymo klausimų gali būti pateikiami:</p>
          <ol>
            <li>Asmeniškai LJA adresu: Vokiečių g. 13A, 01130 Vilnius;</li>
            <li>
              Paštu ar per pašto ar kitų siuntinių pristatymo paslaugas teikiančius asmenis adresu: Vokiečių g. 13A,
              01130 Vilnius;
            </li>
            <li>Elektroniniu paštu {mailToElement}.</li>
          </ol>

          <p>
            Gavę prašymą, bandysime jį kuo greičiau įvykdyti. Kaip ir minėta anksčiau, mums būtų naudinga, jei
            paminėtumėte aplinkybes, kur pateikėte informaciją. Gavus Jūsų kaip duomenų subjekto prašymą, ne vėliau kaip
            per vieną mėnesį nuo prašymo gavimo, LJA Jums pateiks informaciją apie tai, kokių veiksmų buvo imtasi pagal
            gautą prašymą. Jeigu bus vėluojama pateikti informaciją, per nurodytą terminą LJA informuos Jus kaip duomenų
            subjektą apie tai, nurodant vėlavimo priežastis ir apie galimybę pateikti skundą Valstybinei duomenų
            apsaugos inspekcijai.
          </p>

          <p>
            <strong>Naudojimo sąlygos, įspėjimai ir persvarstymai</strong>
          </p>

          <p>
            Jūsų naršymas mūsų svetainėje ir bet kokie nesutarimai dėl privatumo yra šios svetainės privatumo politikos
            ir bendrųjų svetainės naudojimo taisyklių dalykas, įskaitant nuostolių apribojimus, ginčų sprendimus ir
            teisės aktų taikymus. Mes turime teisę bet kuriuo metu pakeisti privatumo politikos sąlygas be įspėjimo.
          </p>

          <p>
            <strong>Susisiekimas su VšĮ „Lietuvos Junior Achievement“ dėl privatumo politikos turinio</strong>
          </p>

          <p>
            Kilus klausimams dėl privatumo politikos sąlygų, prašome susisiekti su VšĮ „Lietuvos Junior Achievement“
            biuru žemiau nurodytu adresu:
          </p>
          <p>
            Viešoji įstaiga „Lietuvos Junior Achievement“ <br />
            Vokiečių g. 13A, 01130 Vilnius
            <br />
            {mailToElement}
          </p>
          <br />

          <p>
            Atlikus privatumo politikos pakeitimus, juos paskelbsime svetainėje ir pakeisime atnaujinimo datą, esančią
            tinklalapio viršuje. Patariame Jums reguliariai tikrinti svetainę, kad pastebėtumėte privatumo politikos
            atnaujinimus.
          </p>

          <p>
            <strong>Slapukai</strong>
          </p>
          <p>
            Mums teikiant paslaugas, yra naudojami interneto slapukai (angl. cookies) ir svetainės signalai (angl. web
            beacons), taip pat kitos vietinio duomenų saugojimo technologijos. Ši skiltis paaiškins, kas tai yra ir kam
            jos gali būti naudojamos.
          </p>
          <p>
            <strong>Slapukai ir kitos vietinio duomenų saugojimo technologijos</strong>
          </p>
          <p>
            Bendrai kalbant, slapukai yra tekstiniai dokumentai, kurie yra patalpinami Jūsų įrenginio interneto
            naršyklėje, kad padėtų ją atpažinti iš skirtingų tinklalapių ir interneto svetainių bei naršymo sesijų. Jūsų
            naršyklė ar įrenginys gali pasiūlyti nustatymus, susijusius su slapukais. Pavyzdžiui, Jūs galite
            susikonfigūruoti savo naršyklę netaikyti slapukų ar įspėti, kuomet slapukai yra naudojami. Norint sužinoti
            apie šių nustatymų prieinamumą, ką jie daro ir kaip jie veikia, prašome apsilankyti Jūsų naršyklės ar
            įrenginio pagalbos skiltyje. Jums nusprendus nepriimti slapukų iš mūsų svetainės, galite negauti visos
            naudos, prieinamos iš mūsų teikiamų Paslaugų. Slapukai yra talpinami Jūsų įrenginyje ar vietiniame diske.
            Mes taip pat galime naudoti ir kitas duomenų saugojimo technologijas, tokias kaip vietinio dalijimosi
            objektus (angl. Local Shared Objects). Šios technologijos yra panašios į slapukus tuo, jog yra talpinamos
            Jūsų įrenginyje ir gali būti naudojamos siekiant išlaikyti informacijos apie Jūsų veiksmus ir prioritetus
            prieinamumą.
          </p>
        </article>
      </Segment>
    </>
  );
};

export default PrivacyPolicyPage;
