import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import ApplicationModal from "../../applications/components/ApplicationModal";
import { ShadowingWithApplications } from "../types";
import { Button } from "semantic-ui-react";
import InitialReflectionModal from "../../reflections/InitialReflectionModal";
import AgreementMissingModal from "./AgreementMissingModal";

interface Props {
  shadowing: ShadowingWithApplications;
}

const ShadowingApplyAction = ({ shadowing }: Props) => {
  const { t } = useTranslation();

  const [applicationInProgress, setApplicationInProgress] = useState(false);

  const needToFillReflection = useSelector((state: RootState) => !!state.currentUser.needToFillReflection);
  const hasAgreementApproved = useSelector((state: RootState) => !!state.currentUser.seasonInfo?.agreementApproved);

  if (!hasAgreementApproved) {
    return <AgreementMissingModal />;
  }

  const button = (
    <Button content={t("shadowing.actions.applicate")} primary onClick={() => setApplicationInProgress(true)} />
  );

  if (!applicationInProgress) {
    return button;
  }

  if (needToFillReflection) {
    return (
      <>
        {button}
        <InitialReflectionModal onCancel={() => setApplicationInProgress(false)} />;
      </>
    );
  }

  return (
    <>
      {button}
      <ApplicationModal shadowing={shadowing} onCancel={() => setApplicationInProgress(false)} />;
    </>
  );
};

export default ShadowingApplyAction;
