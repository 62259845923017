import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";
import MunicipalityReportsCompanyReflections from "./MunicipalityReportsCompanyReflections";
import MunicipalityReportsStatistics from "./MunicipalityReportsStatistics";
import MunicipalityReportsStudentFeedback from "./MunicipalityReportsStudentFeedback";
import MunicipalityReportsCompetencies from "./MunicipalityReportsCompetencies";

const MunicipalityReportsPage = () => {
  const { t } = useTranslation();

  const panes = [
    {
      menuItem: t("reports.statistics"),
      render: () => <MunicipalityReportsStatistics />,
    },
    {
      menuItem: t("reports.competences"),
      render: () => <MunicipalityReportsCompetencies />,
    },
    {
      menuItem: t("reports.companyReflections"),
      render: () => <MunicipalityReportsCompanyReflections />,
    },
    {
      menuItem: t("reports.studentReflections"),
      render: () => <MunicipalityReportsStudentFeedback />,
    },
  ];

  return (
    <div className="reports-page">
      <Tab className="tabs" menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default MunicipalityReportsPage;
