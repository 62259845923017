import React, { useState } from "react";
import { School } from "./types";
import { List } from "semantic-ui-react";
import SchoolListItem from "./SchoolListItem";

interface IProps {
  schools: School[];
  reloadSchools: () => void;
}

const SchoolList: React.FC<IProps> = ({ schools, reloadSchools }) => {
  const [activeIndices, setActiveIndex] = useState<number[]>([]);

  const handleAccordionClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    activeIndices.includes(index)
      ? setActiveIndex(activeIndices.filter((x) => x !== index))
      : setActiveIndex([...activeIndices, index]);
  };

  return (
    <List divided relaxed className="school-list">
      {schools.map((school, index) => (
        <SchoolListItem
          key={school.id}
          school={school}
          index={index}
          active={activeIndices.includes(index)}
          handleAccordionClick={handleAccordionClick}
          reloadSchools={reloadSchools}
        />
      ))}
    </List>
  );
};

export default SchoolList;
