import React from "react";

interface Props {
  count: number;
  active: number;
  onChange: (page: number) => void;
}

const SimplePagination = ({ count, active, onChange }: Props) => {
  const pages = Array.from({ length: count }).map((_, index) => index + 1);

  return (
    <div className="simple-pagination">
      {pages.map((page) => (
        <button
          key={page}
          className={active === page ? "active" : ""}
          type="button"
          title={String(page)}
          onClick={() => onChange(page)}
        >
          {page}
        </button>
      ))}
    </div>
  );
};

export default SimplePagination;
