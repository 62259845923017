import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListFilterConfig, ListFilterValue } from "../../../components/list-filter/models";
import PageTitle from "../../../components/page-title/PageTitle";
import { ReflectionReportModel } from "../../reflections/models";
import ReflectionReportContainer from "../../reflections/ReflectionReportContainer";
import agent from "../../../app/api/agent";
import { ReportFilterContext } from "../useReportFilter";

const CompanyReportsCompanyReflections = () => {
  const { t } = useTranslation();
  const { years } = useContext(ReportFilterContext);
  const filters: ListFilterConfig = useMemo(() => [years], [years]);

  return (
    <>
      <PageTitle title={t("reports.companyReflections")} />
      <ReflectionReportContainer filterConfig={filters} dataResourceFn={dataResourceFn} />
    </>
  );
};

const dataResourceFn = (filter?: ListFilterValue): Promise<ReflectionReportModel> => {
  const params: { years?: number[] } = {};
  if (Array.isArray(filter?.years) && filter?.years.length) {
    params.years = filter.years.map((x) => x.value);
  }

  return agent.Reports.companyReflections(params);
};

export default CompanyReportsCompanyReflections;
