import { useEffect, useMemo, useState } from "react";
import { Industry } from "../../features/industries/types";
import agent from "../api/agent";

const useIndustries = () => {
  const [industries, setIndustries] = useState<Industry[]>([]);

  useEffect(() => {
    agent.Industries.list().then(setIndustries);
  }, []);

  return useMemo(
    () => ({
      industries,
      industryOptions: industries.map(({ id, name }) => ({ key: id, value: id, text: name })),
    }),
    [industries]
  );
};

export default useIndustries;
