import React from "react";
import { InputProps } from "semantic-ui-react";
import { PropsInjectedByController } from "./types";
import AppInput from "./Input";
import { INTEGER_REGEXP } from "../../app/helpers/regexp";
import { withController } from "./withController";

type Props = InputProps;

const AppInteger: React.FC<PropsInjectedByController & Props> = ({ onChange, ...props }) => {
  return (
    <AppInput
      {...props}
      inputMode="numeric"
      onChange={(originalValue: unknown) => {
        const number = originalValue === "" || originalValue == null ? Number.NaN : Number(originalValue);
        const value = Number.isNaN(number) ? originalValue : number;

        return onChange(value);
      }}
    />
  );
};

const AppIntegerController = withController(AppInteger);
const AppIntegerControllerWithRules = ({ rules, ...props }: Parameters<typeof AppIntegerController>[0]) => (
  <AppIntegerController
    rules={{
      ...rules,
      pattern: {
        value: INTEGER_REGEXP,
        message: "errors.integer",
      },
    }}
    {...props}
  />
);

export { AppIntegerControllerWithRules as AppIntegerController };
export default AppInteger;
