import React from "react";
import { InputProps } from "semantic-ui-react";
import { PropsInjectedByController } from "./types";
import AppInput from "./Input";
import { withController } from "./withController";
import { PHONE_REGEXP } from "../../app/helpers/regexp";

type Props = InputProps;

const AppPhone: React.FC<PropsInjectedByController & Props> = (props) => {
  return <AppInput placeholder="common.phone_placeholder" {...props} inputMode="tel" type="tel" />;
};

const AppPhoneController = withController(AppPhone);
const AppPhoneControllerWithRules = ({ rules, ...props }: Parameters<typeof AppPhoneController>[0]) => (
  <AppPhoneController
    rules={{
      ...rules,
      pattern: {
        value: PHONE_REGEXP,
        message: "errors.phone",
      },
    }}
    {...props}
  />
);

export { AppPhoneControllerWithRules as AppPhoneController };
export default AppPhone;
