import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import useToaster from "../../../../app/hooks/useToaster";
import PageTitle from "../../../../components/page-title/PageTitle";
import { SchoolProfileModel } from "../../../schools/types";
import PasswordChangeModal from "../../../user/components/passwordChange/PasswordChangeModal";
import ProfileFormModal from "../ProfileFormModal";
import ProfileSegment from "../ProfileSegment";
import { SchoolUpdateForm } from "./SchoolProfileForm";

const SchoolProfile = () => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [triggerModal, setTriggerModal] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleUpdate = (data: SchoolUpdateForm) => {
    setSaving(true);

    agent.Schools.updateProfile(data.id, data)
      .then((response) => {
        setSchool(response);
        success(t("my_profile.save_success"));
        setTriggerModal(!triggerModal);
      })
      .catch(() => {
        apiError(t("common.errors.general"));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const [school, setSchool] = useState<SchoolProfileModel>({
    id: 0,
    name: "",
    address: "",
    city: "",
    website: "",
    email: "",
    phoneNumber: "",
    contactInfo: {
      fullName: "",
      jobTitle: "",
      phoneNumber: "",
      email: "",
    },
  });

  useEffect(() => {
    agent.Schools.getProfile().then((response) => setSchool(response));
  }, []);

  const fields = {
    "common.address": `${school.address}, ${school.city}`,
    "common.email": school.email,
    "common.phone": school.phoneNumber,
    "common.web": (
      <a href={school.website} target="blank">
        {school.website}
      </a>
    ),
  };

  const personFields = {
    "my_profile.job_position": school.contactInfo.jobTitle,
    "common.email": school.contactInfo.email,
    "common.phone": school.contactInfo.phoneNumber,
    "common.password": <PasswordChangeModal />,
  };

  const columns = [
    { header: school.name, values: fields },
    { header: school.contactInfo.fullName, values: personFields },
  ];

  return (
    <Fragment>
      <PageTitle title={t("common.my_profile")} />
      <Segment padded="very">
        <ProfileSegment columns={columns} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ProfileFormModal
                isSaving={isSaving}
                triggerModal={triggerModal}
                subject={school}
                type="School"
                handleUpdate={handleUpdate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default SchoolProfile;
