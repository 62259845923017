export enum BusinessRegistrationStep {
  Company,
  ContactInfo,
  Description,
  Success,
}

export interface BusinessRegistrationCompanyStepFormModel {
  companyType: string;
  name: string;
  code: string;
  industryId: number;
  website: string;
  logo: string | File;
  city: string;
  address: string;
}

export interface BusinessRegistrationContactInfoStepFormModel {
  contactInfo: {
    fullName: string;
    phoneNumber: string;
    email: string;
    jobTitle: string;
  };
  password: string;
}

export interface BusinessRegistrationDescriptionStepFormModel {
  description: string;
}

export interface BusinessRegistrationModel
  extends BusinessRegistrationCompanyStepFormModel,
    BusinessRegistrationContactInfoStepFormModel,
    BusinessRegistrationDescriptionStepFormModel {}
