import { UserRoles } from "../auth/roles";
import { localStorageHelpers } from "./localStorageHelpers";
import { useSelector } from "react-redux";
import { RootState } from "../store/configureStore";

const isStudent = () => roles().includes(UserRoles.student);
const isCompany = () => roles().includes(UserRoles.company);
const isSchool = () => roles().includes(UserRoles.school);
const isMunicipality = () => roles().includes(UserRoles.municipality);
const isAdmin = () => roles().includes(UserRoles.admin);

export const HasRole = (role: string) => {
  const { isLoggedIn, roles } = useSelector((state: RootState) => state.currentUser);

  if (!isLoggedIn) return false;

  return roles.some((userRoles) => userRoles.includes(role));
};

export const GetCurrentUserId = () => localStorageHelpers.getUserId();

const roles = () => localStorageHelpers.getUserRoles();

export const userHelpers = { isStudent, isCompany, isSchool, isMunicipality, isAdmin };
