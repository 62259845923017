import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Segment } from "semantic-ui-react";
import agent from "../../../../app/api/agent";
import useToaster from "../../../../app/hooks/useToaster";
import PageTitle from "../../../../components/page-title/PageTitle";
import { CompanyProfileModel } from "../../../companies/types";
import PasswordChangeModal from "../../../user/components/passwordChange/PasswordChangeModal";
import ProfileFormModal from "../ProfileFormModal";
import ProfileSegment from "../ProfileSegment";
import { CompanyUpdateForm } from "./CompanyProfileForm";

const CompanyProfile = () => {
  const { t } = useTranslation();
  const { success, apiError } = useToaster();
  const [triggerModal, setTriggerModal] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleUpdate = (data: CompanyUpdateForm) => {
    setSaving(true);

    const formData = new FormData();
    formData.append("code", data.code);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("email", data.email ?? "");
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("website", data.website);
    formData.append("industryId", data.industryId.toString());
    formData.append("contactInfo.fullName", data.contactInfo.fullName);
    formData.append("contactInfo.jobTitle", data.contactInfo.jobTitle);
    formData.append("contactInfo.phoneNumber", data.contactInfo.phoneNumber);
    formData.append("logo", data.logo);
    formData.append("isLogoDeleted", data.logo ? "false" : "true");

    agent.Companies.update(data.id, formData)
      .then((response) => {
        setCompany(response);
        success(t("my_profile.save_success"));
        setTriggerModal(!triggerModal);
      })
      .catch(() => apiError(t("common.errors.general")))
      .finally(() => {
        setSaving(false);
      });
  };

  const [company, setCompany] = useState<CompanyProfileModel>({
    id: 0,
    code: "",
    name: "",
    description: "",
    logoUrl: "",
    address: "",
    city: "",
    website: "",
    email: "",
    phoneNumber: "",
    companyType: "",
    industry: {
      id: 0,
      name: "",
    },
    contactInfo: {
      fullName: "",
      jobTitle: "",
      phoneNumber: "",
      email: "",
    },
  });

  useEffect(() => {
    agent.Companies.getProfile().then((response) => {
      setCompany(response);
    });
  }, []);

  const companyFields = {
    "companies.code": company.code,
    "common.industry": company.industry.name,
    "common.address": `${company.address}, ${company.city}`,
    "common.email": company.email,
    "common.web": (
      <a href={company.website} target="blank">
        {company.website}
      </a>
    ),
  };

  const personFields = {
    "my_profile.job_position": company.contactInfo.jobTitle,
    "common.email": company.contactInfo.email,
    "common.phone": company.contactInfo.phoneNumber,
    "common.password": <PasswordChangeModal />,
  };

  const columns = [
    { header: company.name, values: companyFields },
    { header: company.contactInfo.fullName, values: personFields },
  ];

  const description = {
    "my_profile.company.description": company.description,
  };

  return (
    <Fragment>
      <PageTitle title={t("common.my_profile")} />
      <Segment padded="very">
        <ProfileSegment logoUrl={company.logoUrl} columns={columns} extra={description} />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <ProfileFormModal
                isSaving={isSaving}
                triggerModal={triggerModal}
                subject={company}
                type="Company"
                handleUpdate={handleUpdate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default CompanyProfile;
