export enum ReportDataType {
  Count = "Count",
  Shadowing = "Shadowing",
  Top = "Top",
}

interface ReportsDataBase {
  title: string;
}

export interface ReportsDataCounts extends ReportsDataBase {
  type: ReportDataType.Count;
  value: Array<{ value: number; unit: string }>;
}

export interface ReportsDataShadowing extends ReportsDataBase {
  type: ReportDataType.Shadowing;
  value: ShadowingLike[];
}

export interface ReportsDataTop extends ReportsDataBase {
  type: ReportDataType.Top;
  unit?: string;
  value: Array<{ title: string; value: number }>;
}

export type ReportsData = ReportsDataCounts | ReportsDataShadowing | ReportsDataTop;

export interface ShadowingLike {
  id: number;
  position: string;
  visitsCount: number;
  companyTitle?: string;
  companyLogoUrl?: string;
}

export interface ReportsCompanyStatsResponse {
  shadowingsCount: number;
  applicationCount: number;
  visitsCount: number;
  bestShadowings: ShadowingLike[];
}

export interface ReportsSchoolStatsResponse {
  applicationCount: number;
  visitsCount: number;
  totalRegisteredStudentsCount: number;
  finishedProgramCount: number;
  mostActiveGrades: Array<{ count: number; grade: string; schoolName?: string }>;
  bestShadowings: ShadowingLike[];
  bestCompanies: Array<{ id: number; visitsCount: number; companyTitle: string; companyLogoUrl: string }>;
}

export interface ReportsMunicipalityStatsResponse {
  shadowingsCount: number;
  applicationCount: number;
  visitsCount: number;
  totalRegisteredStudentsCount: number;
  finishedProgramCount: number;
  mostActiveGrades: Array<{ count: number; grade: string; schoolName?: string }>;
  bestShadowings: ShadowingLike[];
  bestCompanies: Array<{ id: number; visitsCount: number; companyTitle: string; companyLogoUrl: string }>;
  bestSchools: Array<{ id: number; visitsCount: number; name: string }>;
}

export interface ReportFilterResponse {
  companies: Array<{ id: number; name: string }>;
  schools: Array<{ id: number; name: string }>;
  grades: Array<string | number>;
  years: number[];
}
