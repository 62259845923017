import React, { PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { get, useFormContext, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import Errors from "./Errors";

interface Props {
  name: string;
  label?: string;
}

const AppFormGroup: React.FC<PropsWithChildren<Props>> = ({ name, label, children }) => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const error: FieldError | undefined = get(formState.errors, name);
  const showError = !!error && (!!get(formState.touchedFields, name) || formState.isSubmitted);

  return (
    <Form.Field error={showError}>
      {label && <label>{t(label)}</label>}
      {children}
      {showError && <Errors {...{ error: error! }} />}
    </Form.Field>
  );
};

export default AppFormGroup;
