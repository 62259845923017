import React from "react";
import { Item, Label, SemanticCOLORS } from "semantic-ui-react";
import location from "../../../icons/location.svg";
import company from "../../../icons/company.svg";
import date from "../../../icons/date.svg";
import { prettifyDate } from "../../../app/helpers/dateHelpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Application } from "../types";
import ResponsiblePersonModal from "../../shadowings/components/ResponsiblePersonModal";
import ButtonLink from "../../../components/ButtonLink";
import ApplicationItemReflection from "./ApplicationItemReflection";
import { Student } from "../../students/types";
import { ApplicationStatus } from "../../../app/enums/applicationStatus";

interface IProps {
  application: Application;
  student: Student;
  showReadFeedback: boolean;
  allowCancelApplication: boolean;
  handleCancelApplication: (applicationId: number) => void;
}

const COLOR_STATUS_MAP: { [key: string]: ApplicationStatus[] } = {
  purple: [ApplicationStatus.WAITING_FOR_SCHOOL_APPROVAL, ApplicationStatus.WAITING_FOR_COMPANY_APPROVAL],
  red: [ApplicationStatus.COMPANY_REJECTED, ApplicationStatus.STUDENT_CANCELLED],
  green: [ApplicationStatus.COMPANY_APPROVED],
};

const ApplicationListItem: React.FC<IProps> = ({
  application,
  showReadFeedback,
  allowCancelApplication,
  handleCancelApplication,
  student,
}) => {
  const { t } = useTranslation();
  const shadowing = application.shadowing;

  const renderLocation = () => {
    return application.shadowing.online
      ? t("shadowing.remote")
      : `${application.shadowing.city}, ${application.shadowing.address}`;
  };

  const getLabelColor = () =>
    (Object.keys(COLOR_STATUS_MAP).find((key) => COLOR_STATUS_MAP[key].includes(application.status)) ||
      "red") as SemanticCOLORS;

  return (
    <Item className="shadowing-list-item">
      <Item.Image src={shadowing.company.logoUrl || "/assets/logo_placeholder.png"} alt="logo" style={{ width: 70 }} />
      <Item.Content>
        <Item.Header>{shadowing.position}</Item.Header>
        <Item.Meta>
          <span>
            <img src={location} alt="location" />
            {renderLocation()}
          </span>
          <span>
            <img src={company} alt="company" />
            {shadowing.company.name}
          </span>
          <span>
            <img src={date} alt="date" />
            {prettifyDate(shadowing.date)}
          </span>
        </Item.Meta>
        <Item.Description>
          <ResponsiblePersonModal item={shadowing} />
        </Item.Description>
        <Item.Description>
          <Label color={getLabelColor()} circular>
            {t(`applications.status.${application.status}`)}
          </Label>
        </Item.Description>
        {allowCancelApplication && (
          <Item.Description>
            <Link to="#" onClick={() => handleCancelApplication(application.id)}>
              {t("common.actions.cancel")}
            </Link>
          </Item.Description>
        )}
        {showReadFeedback && (
          <Item.Description>
            <ApplicationItemReflection application={application} student={student!}>
              <ButtonLink>{t("common.read_application_feedbacks")}</ButtonLink>
            </ApplicationItemReflection>
          </Item.Description>
        )}
      </Item.Content>
    </Item>
  );
};

export default ApplicationListItem;
