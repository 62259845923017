import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MunicipalityList from "./MunicipalityList";

const MunicipalityDashboard = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <MunicipalityList />
      <Button floated="right" primary content={t("common.actions.create")} as={Link} to="/municipalities/new" />
    </Fragment>
  );
};

export default MunicipalityDashboard;
