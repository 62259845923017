import React, { Fragment } from "react";
import TextBlock from "../../../../../../components/TextBlock";
import { useTranslation } from "react-i18next";

const SuccessStep = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <TextBlock title={t("auth.steps.success.title")} body={t("auth.steps.success.body")} />
    </Fragment>
  );
};

export default SuccessStep;
