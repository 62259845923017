import React, { useState } from "react";
import { articleSliderTabs } from "./data";
import ArticleSlide from "./ArticleSlide";
import Slider from "../../../components/slider/Slider";
import "./styles.less";

const HomePageArticleSlider = () => {
  const [activeTab, setActiveTab] = useState(articleSliderTabs[0]);

  return (
    <section className="homepage-article-slider">
      <h2 className="article__title">Sėkmės istorijos</h2>

      {articleSliderTabs.length > 1 && (
        <div className="quick-tabs">
          {articleSliderTabs.map((tab) => (
            <button onClick={() => setActiveTab(tab)} className={tab === activeTab ? "active" : ""} key={tab.label}>
              {tab.label}
            </button>
          ))}
        </div>
      )}

      <Slider>
        {activeTab.items.map((item, index) => (
          <ArticleSlide
            title={item.title}
            subtitle={item.subtitle}
            image={item.image}
            key={activeTab.label + index}
            className={`${activeTab.name}-slide`}
          >
            {item.text}
          </ArticleSlide>
        ))}
      </Slider>
    </section>
  );
};

export default HomePageArticleSlider;
