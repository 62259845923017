import React, { PropsWithChildren } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  trigger: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  isDisabled?: boolean;
  actions?: React.ReactNode;
  closeable?: boolean;
}

const FormModal = ({
  title,
  trigger,
  isOpen,
  onOpen,
  onClose,
  children,
  actions,
  isDisabled,
  closeable = true,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Modal
      style={{ maxWidth: 700 }}
      closeIcon={!isDisabled && closeable}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      dimmer="inverted"
      {...{ trigger, open: isOpen, onOpen, onClose }}
    >
      <Modal.Header>{t(title)}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      {actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  );
};

export default FormModal;
