import React, { Fragment } from "react";
import { Divider, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

interface Column {
  header: string | JSX.Element;
  values: { [key: string]: string | JSX.Element };
}

interface Props {
  columns: Column[];
  extra?: { [key: string]: string | JSX.Element };
  logoUrl?: string;
}

const ProfileSegment: React.FC<Props> = ({ columns, extra, logoUrl }) => {
  const { t } = useTranslation();

  const divider = () => <Divider className="profile-data-table-divider" />;

  const renderLogo = () => {
    if (!logoUrl) return null;

    return (
      <div className="profile-data-company-logo-wrapper">
        <img src={logoUrl} alt="logo" />
      </div>
    );
  };

  const renderColumnHeader = (value: string | JSX.Element) => (
    <Fragment>
      <Grid.Row>{typeof value === "string" ? <h3>{value}</h3> : value}</Grid.Row>
      <Grid.Row>{divider()}</Grid.Row>
    </Fragment>
  );

  const renderFields = (fields: { [key: string]: string | JSX.Element }) =>
    Object.keys(fields).map((field) => (
      <Fragment key={field}>
        <div className="data-row-wrapper">
          <div>
            <h4>{t(field)}</h4>
          </div>
          <div>{fields[field]}</div>
        </div>
        {divider()}
      </Fragment>
    ));

  return (
    <Fragment>
      {renderLogo()}
      <Grid className="profile-data-table" stackable columns="equal">
        <Grid.Row>
          {columns.map((column, index) => (
            <Grid.Column key={index}>
              {renderColumnHeader(column.header)}
              {renderFields(column.values)}
            </Grid.Column>
          ))}
        </Grid.Row>
        {extra && (
          <Grid.Row>
            <Grid.Column className="profile-data-table-description">
              {Object.keys(extra).map((key) => (
                <Fragment key={key}>
                  <h4>{t(key)}</h4>
                  <p>{extra[key]}</p>
                </Fragment>
              ))}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Fragment>
  );
};

export default ProfileSegment;
