import React from "react";

interface Props {
  youtubeId: string;
  title: string;
  author: string;
}

const VideoSlide = ({ youtubeId, title, author }: Props) => {
  return (
    <a
      className="video-slide"
      href={`https://www.youtube.com/watch?v=${youtubeId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="video-slide__image">
        <img src={`https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`} alt="" />
      </div>
      <h3 className="article__title-sm">{title}</h3>
      <p>{author}</p>
    </a>
  );
};

export default VideoSlide;
