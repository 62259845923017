import React from "react";
import { useTranslation } from "react-i18next";
import { Partner } from "./models";

interface Props {
  partner: Partner;
}

const PartnersItem = ({ partner: { title, description, moreInfoLink, images, partners } }: Props) => {
  const { t } = useTranslation();

  return (
    <article className="partners-item">
      <div className="partners-item__images">
        {images.map((image, index) =>
          image.link ? (
            <a key={index} href={image.link} target="_blank" rel="noopener noreferrer">
              <img src={image.src} alt={image.title} width={image.width} />
            </a>
          ) : (
            <img key={index} src={image.src} alt={image.title} width={image.width} />
          )
        )}
      </div>
      <div className="partners-item__content">
        <h3 className="partners-item__title">{title}</h3>
        <div className="partners-item__description">
          <p>{description}</p>
        </div>
        {moreInfoLink && (
          <div className="partners-item__link">
            {t("common.more_info")}: 
            <a href={moreInfoLink} target="_blank" rel="noopener noreferrer">
              {moreInfoLink}
            </a>
          </div>
        )}
        {partners && partners?.length > 0 && (
          <div className="partners-item__partners partners-item-partners">
            <h4 className="partners-item-partners__title">{t("common.partners")}</h4>

            <div className="partners-item-partners__list">
              {partners.map((partner, index) =>
                partner.link ? (
                  <a key={index} href={partner.link} target="_blank" rel="noopener noreferrer">
                    <img src={partner.src} alt={partner.title} width={partner.width} />
                  </a>
                ) : (
                  <img key={index} src={partner.src} alt={partner.title} width={partner.width} />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export default PartnersItem;
