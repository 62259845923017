import React, { useState, useEffect } from "react";
import agent from "../../../../app/api/agent";
import { Student } from "../../../students/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store/configureStore";
import StudentProfileModal from "./StudentProfileModal";
import { userHelpers } from "../../../../app/helpers/userHelpers";

const StudentProfileForceUpdateModal = () => {
  const isStudent = userHelpers.isStudent();
  const needToUpdateProfile = useSelector((state: RootState) => !!state.currentUser.needToUpdateProfile);

  const [student, setStudent] = useState<Student>();

  useEffect(() => {
    if (isStudent && needToUpdateProfile) {
      agent.Students.getProfile().then((response) => {
        setStudent(response);
      });
    }
  }, [isStudent, needToUpdateProfile]);

  if (!student || !needToUpdateProfile) {
    return null;
  }

  return <StudentProfileModal student={student} isForceUpdateModal />;
};

export default StudentProfileForceUpdateModal;
