import { UserRoles } from "../../app/auth/roles";
import {
  ActiveShadowingFilter,
  ShadowingFilterActionTypes,
  UPDATE_INDUSTRY_FILTER,
  UPDATE_COMPANY_FILTER,
  UPDATE_SEARCH_FILTER,
  UPDATE_SORT_FILTER,
  SET_ACTIVE_PAGE,
  SET_HAS_APPLICATIONS,
  SET_PAST_APPLICATIONS,
  UPDATE_DATE_FILTER,
  CLEAR_FILTERS,
  UPDATE_CITY_FILTER,
  UPDATE_ONLINE_FILTER,
} from "../../app/store/types/shadowingFilter";
import { GET_CURRENT_USER, UserActionTypes } from "../../app/store/types/user";
import { SORT_BY } from "./sort_options";

const initialState: ActiveShadowingFilter = {
  search: "",
  industryIds: [],
  companyIds: [],
  cities: [],
  isOnline: false,
  hasApplications: undefined,
  past: undefined,
  sortBy: SORT_BY.nearestFirst,
  dateRange: undefined,
  activePage: 1,
};

export default (state = initialState, action: ShadowingFilterActionTypes | UserActionTypes): ActiveShadowingFilter => {
  switch (action.type) {
    case UPDATE_ONLINE_FILTER: {
      return { ...state, isOnline: action.value, activePage: 1 };
    }
    case UPDATE_CITY_FILTER: {
      const isCityFiltered = state.cities.includes(action.selectedCity);
      if (isCityFiltered) {
        return {
          ...state,
          cities: [...state.cities.filter((city) => city != action.selectedCity)],
          activePage: 1,
        };
      } else {
        return { ...state, cities: [...state.cities, action.selectedCity], activePage: 1 };
      }
    }
    case UPDATE_INDUSTRY_FILTER: {
      const index = state.industryIds.findIndex((industryId) => industryId === action.selectedId);
      if (index === -1) {
        return { ...state, industryIds: [...state.industryIds, action.selectedId], activePage: 1 };
      } else {
        return {
          ...state,
          industryIds: [...state.industryIds.filter((industryId) => industryId != action.selectedId)],
          activePage: 1,
        };
      }
    }
    case UPDATE_COMPANY_FILTER: {
      const index = state.companyIds.findIndex((companyId) => companyId === action.selectedId);
      if (index === -1) {
        return { ...state, companyIds: [...state.companyIds, action.selectedId], activePage: 1 };
      } else {
        return {
          ...state,
          companyIds: [...state.companyIds.filter((companyId) => companyId != action.selectedId)],
          activePage: 1,
        };
      }
    }
    case UPDATE_SEARCH_FILTER:
      return { ...state, search: action.searchText, activePage: 1 };

    case UPDATE_DATE_FILTER:
      return { ...state, dateRange: action.payload, activePage: 1 };
    case UPDATE_SORT_FILTER:
      return { ...state, sortBy: action.sortBy, activePage: 1 };
    case SET_ACTIVE_PAGE:
      return { ...state, activePage: action.activePage };
    case SET_HAS_APPLICATIONS:
      return { ...state, hasApplications: action.isActive, activePage: 1 };
    case GET_CURRENT_USER:
      const past = action.user.roles.includes(UserRoles.school) ? false : undefined;
      return { ...state, past: past, activePage: 1 };
    case SET_PAST_APPLICATIONS:
      return { ...state, past: action.isActive, activePage: 1 };
    case CLEAR_FILTERS:
      return { ...state, industryIds: [], companyIds: [], search: "", dateRange: undefined };
    default:
      return state;
  }
};
