import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SiteUser } from "./types";
import agent from "../../app/api/agent";
import FormModal from "../../components/FormModal";
import useToaster from "../../app/hooks/useToaster";
import UserUpdateForm, { UserEditForm } from "./UserUpdateForm";

interface IProps {
  user: SiteUser;
  onUserUpdate: () => void;
}

const UserEditModal = ({ user, onUserUpdate }: IProps) => {
  const { t } = useTranslation();
  const { apiError, success } = useToaster();

  const [open, setOpen] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const handleUpdate = (data: UserEditForm) => {
    setUpdating(true);

    agent.Users.update(user.id, data.email)
      .then(() => {
        success(t("users.update_success"));
        setOpen(false);
        onUserUpdate();
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handlePasswordReset = (email: string) => {
    setUpdating(true);

    agent.Users.adminPasswordResetLink(email)
      .then(() => {
        success(t("users.password_reset_link_sent"));
        setOpen(false);
        onUserUpdate();
      })
      .catch((err) => {
        apiError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  return (
    <FormModal
      title={user.fullName}
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Link to="#">{t("common.edit")}</Link>}
    >
      <UserUpdateForm
        user={user}
        isSaving={isUpdating}
        handleSubmit={handleUpdate}
        handlePasswordReset={handlePasswordReset}
      />
    </FormModal>
  );
};

export default UserEditModal;
