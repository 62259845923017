import React from "react";
import { BooleanQuestion, Question, QuestionType, ReflectionFormModel } from "./models";
import { useAppForm } from "../../components/form/useForm";
import AppForm from "../../components/form/Form";
// eslint-disable-next-line no-restricted-imports
import { UseFormReturn } from "react-hook-form";

interface Props {
  model?: ReflectionFormModel;
  questions: Question[];
  onSubmit?: (m: ReflectionFormModel) => any;
  children: (content: JSX.Element, formHook: UseFormReturn<Record<string, any>>) => JSX.Element;
  isDisabled?: boolean;
}

const defaultBooleanOptions: BooleanQuestion["options"] = {
  true: "common.yes",
  false: "common.no",
};

const ReflectionForm = ({
  questions,
  children: render,
  model = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit = () => {},
  isDisabled,
}: Props) => {
  const formHook = useAppForm();

  const renderField = (question: Question) => {
    const rules = question.optional ? undefined : { required: true };

    switch (question.type) {
      case QuestionType.Radio: {
        return (
          <AppForm.Radio
            defaultValue={model[question.id] != null ? Number(model[question.id]) : ""}
            name={String(question.id)}
            options={question.options}
            rules={rules}
          />
        );
      }

      case QuestionType.Boolean: {
        const options = question.options ?? defaultBooleanOptions;
        return (
          <AppForm.Radio
            defaultValue={model[question.id] != null ? Number(model[question.id]) : ""}
            name={String(question.id)}
            options={[
              { title: options!.true, value: 1 },
              { title: options!.false, value: 0 },
            ]}
            rules={rules}
          />
        );
      }

      case QuestionType.Points: {
        return (
          <AppForm.Points
            defaultValue={model[question.id] != null ? Number(model[question.id]) : ""}
            name={String(question.id)}
            rules={rules}
          />
        );
      }

      case QuestionType.Textbox: {
        return (
          <AppForm.TextArea
            defaultValue={model[question.id] ?? ""}
            name={String(question.id)}
            rules={rules}
            maxLength={1000}
          />
        );
      }
    }
  };

  const content = (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit} isDisabled={!!isDisabled}>
        {questions.map((question) => (
          <AppForm.Group key={question.id} name={String(question.id)} label={question.title}>
            {renderField(question)}
          </AppForm.Group>
        ))}
      </AppForm>
    </>
  );

  return render(content, formHook);
};

export default ReflectionForm;
