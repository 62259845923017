import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Dropdown, Icon, Label } from "semantic-ui-react";
import DebouncedSearch from "../filters/DebouncedSearch";
import { ListFilterConfigItemDateRange, ListFilterConfigItemSearch } from "./models";
import { HasRole } from "../../app/helpers/userHelpers";
import {
  ListFilterConfig,
  ListFilterConfigItemType,
  ListFilterConfigItemMultiSelect,
  ListFilterConfigItemMultiSelectOption,
} from "./models";
import ListFilterMultiselect from "./ListFilterMultiselect";
import ListFilterDateRange from "./ListFilterDateRange";
import { prettifyDateWithoutTime } from "../../app/helpers/dateHelpers";

interface Props {
  config: ListFilterConfig;
  value: { [name: string]: any };
  onChange: (value: { [name: string]: any }) => void;
}

const useListFilter = ({ config, value, onChange }: Props) => {
  const { t } = useTranslation();

  const onMultiSelectChange = (name: string, option: ListFilterConfigItemMultiSelectOption): void => {
    const currentValue: ListFilterConfigItemMultiSelectOption[] = value[name];
    const isAlreadySelectedValue = currentValue.some((x) => x.key === option.key);
    if (isAlreadySelectedValue) {
      return;
    }
    onChange({ ...value, [name]: [...currentValue, option] });
  };

  const onMultiSelectDelete = (name: string, option: ListFilterConfigItemMultiSelectOption): void => {
    const currentValue: ListFilterConfigItemMultiSelectOption[] = value[name];
    const newValue = currentValue.filter((x) => x.key !== option.key);
    if (currentValue.length === newValue.length) {
      return;
    }
    onChange({ ...value, [name]: newValue });
  };

  const onFilterDelete = (name: string): void => {
    const { [name]: filterValue, ...newValue } = value;
    onChange(newValue);
  };

  const onMultiSelectToggle = (name: string, option: ListFilterConfigItemMultiSelectOption, value: boolean): void => {
    if (value) {
      onMultiSelectChange(name, option);
    } else {
      onMultiSelectDelete(name, option);
    }
  };

  const onSearchChange = (name: string, searchValue: string): void => {
    onChange({ ...value, [name]: searchValue });
  };

  const onDateRangeChange = (name: string, dateRangeValue: { start: Date; end: Date }): void => {
    onChange({ ...value, [name]: dateRangeValue });
  };

  const items = config.filter((item) => !item.accessRole || item.accessRole.some((role) => HasRole(role)));

  const multiSelects = items.filter(
    (item): item is ListFilterConfigItemMultiSelect => item.type === ListFilterConfigItemType.MultiSelect
  );
  const dateRanges = items.filter(
    (item): item is ListFilterConfigItemDateRange => item.type === ListFilterConfigItemType.DateRange
  );
  const searches = items.filter(
    (item): item is ListFilterConfigItemSearch => item.type === ListFilterConfigItemType.Search
  );

  const renderDropdowns = () => (
    <>
      {multiSelects.map((item) => (
        <Dropdown scrolling text={t(item.title)} key={item.name}>
          <Dropdown.Menu>
            {item.options.map((option) => (
              <Dropdown.Item
                key={option.key}
                onClick={() => onMultiSelectChange(item.name, option)}
                text={option.text}
                value={option.value}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </>
  );

  const renderSearch = () => (
    <>
      {searches.map((item) => (
        <DebouncedSearch
          key={item.name}
          value={value[item.name]}
          placeholder={t(item.title)}
          onChange={(v) => onSearchChange(item.name, v)}
        />
      ))}
    </>
  );

  const hasSearch = searches.length > 0;

  const renderDeleteBadges = () => (
    <>
      {multiSelects.map((item) =>
        value[item.name].map((option: ListFilterConfigItemMultiSelectOption) => (
          <Label key={option.key}>
            {option.text}
            <Icon name="delete" onClick={() => onMultiSelectDelete(item.name, option)} />
          </Label>
        ))
      )}
      {dateRanges
        .filter((item) => value[item.name])
        .map((item) => (
          <Label key={item.name}>
            {prettifyDateWithoutTime(value[item.name].start)} - {prettifyDateWithoutTime(value[item.name].end)}
            <Icon name="delete" onClick={() => onFilterDelete(item.name)} />
          </Label>
        ))}
    </>
  );

  const renderFilterAccordion = () => {
    const panels = [];

    panels.push(
      ...multiSelects.map((item) => ({
        key: item.name,
        title: t(item.title),
        content: {
          content: (
            <ListFilterMultiselect
              key={item.name}
              item={item}
              value={value[item.name]}
              onChange={(option, checked) => onMultiSelectToggle(item.name, option, checked)}
            />
          ),
        },
      }))
    );

    panels.push(
      ...dateRanges.map((item) => ({
        key: item.name,
        title: t(item.title),
        content: {
          content: (
            <ListFilterDateRange value={value[item.name]} onChange={(value) => onDateRangeChange(item.name, value)} />
          ),
        },
      }))
    );

    return <Accordion defaultActiveIndex={[0, 1, 2, 3, 4]} panels={panels} exclusive={false} fluid />;
  };

  return { renderDropdowns, renderSearch, renderDeleteBadges, renderFilterAccordion, hasSearch };
};

export default useListFilter;
