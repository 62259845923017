import React from "react";

interface IProps {
  title: string;
  body: string | React.ReactNode;
}

const TextBlock: React.FC<IProps> = ({ title, body }) => {
  return (
    <div className="sh-text-block">
      <div className="sh-text-block-title">{title}</div>
      <div className="sh-text-block-body">{body}</div>
    </div>
  );
};

export default TextBlock;
