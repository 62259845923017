import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import { Form, Checkbox, CheckboxProps } from "semantic-ui-react";
import { setHasApplicationsFilter } from "../actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

const HasApplicationsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasApplications: boolean | undefined = useSelector(
    (state: RootState) => state.shadowingsFilter.hasApplications
  );

  const handleChange = (e: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    checked ? dispatch(setHasApplicationsFilter(true)) : dispatch(setHasApplicationsFilter(undefined));
  };

  return (
    <Form>
      <Form.Field>
        <Checkbox
          onChange={handleChange}
          checked={hasApplications}
          className="sh-filter"
          label={<label>{t("shadowings_filter.has_applications")}</label>}
        />
      </Form.Field>
    </Form>
  );
};

export default HasApplicationsFilter;
