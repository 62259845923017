import { useEffect, useMemo, useState } from "react";
import { CompanyType } from "../../features/companies/types";
import agent from "../api/agent";

const useCompanyTypes = () => {
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);

  useEffect(() => {
    agent.Companies.types().then(setCompanyTypes);
  }, []);

  return useMemo(
    () => ({
      companyTypes,
      companyTypeOptions: companyTypes.map((type) => ({ key: type, value: type, text: type })),
    }),
    [companyTypes]
  );
};

export default useCompanyTypes;
