import React, { FormEvent } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import { updateShadowingCompanyFilter } from "../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store/configureStore";
import useItemFilter from "../../../app/hooks/useItemFilter";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface SearchableCompany {
  id: number;
  name: string;
}
interface IProps {
  companies: SearchableCompany[];
}

const CompanyFilter: React.FC<IProps> = ({ companies }) => {
  const dispatch = useAppDispatch();
  const handleChange = (_: FormEvent<HTMLInputElement>, { value }: any) => {
    dispatch(updateShadowingCompanyFilter(value as number));
  };
  const { t } = useTranslation();
  const { filteredItems: filteredCompanies, renderFilter } = useItemFilter(companies);

  const selectedIds: number[] = useSelector((state: RootState) => state.shadowingsFilter.companyIds);

  return (
    <>
      {renderFilter()}
      <Form className="shadowing-filter__checkbox-group">
        {filteredCompanies.length === 0 ? (
          <span>{t("common.no_records")}</span>
        ) : (
          filteredCompanies.map((company) => (
            <Form.Field key={company.id}>
              <Checkbox
                onChange={handleChange}
                key={company.id}
                value={company.id}
                checked={selectedIds.includes(company.id)}
                className="sh-filter"
                label={<label>{company.name}</label>}
              />
            </Form.Field>
          ))
        )}
      </Form>
    </>
  );
};

export default CompanyFilter;
