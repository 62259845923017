export const articleSliderTabs: Array<{
  label: string;
  name: string;
  items: Array<{ title: string; subtitle: string; image: string; text: string }>;
}> = [
  {
    label: "Mokinių",
    name: "student",
    items: [
      {
        title: "Gabrielė Grybaitė",
        subtitle: "Vilniaus Žirmūnų gimnazija",
        image: "/assets/homepage/slider/Gabriele.jpeg",
        text: `„Gavau progą „pasimatuoti“ savo ateities profesiją ir įsitikinti, kad tikslingai atlieku studijų pasirinkimus.“`,
      },
      {
        title: "Aistė Rimkutė",
        subtitle: "Kauno Juozo Grušo meno gimnazija",
        image: "/assets/homepage/slider/Aiste.jpeg",
        text: `
        „Šešėliavimas padėjo geriau suprasti ką norėčiau veikti ateityje, o ko ne.
        Buvo tikrai labai naudinga ir nepaprastai įdomi patirtis.“`,
      },
      {
        title: "Arnas Šalaševičius",
        subtitle: "Vilniaus savivaldybės Grigiškių „Šviesos“ gimnazija",
        image: "/assets/homepage/slider/Arnas.jpeg",
        text: `
        „Esu labai patenkintas ir laimingas, kad galėjau dalyvauti šešėliavimo programoje - 
        išvydau ir sužinojau tai, ką norėjau, ir net daugiau. Gavau nemažai gyvenimo pamokų, kurios tikrai pravers.“`,
      },
      {
        title: "Judita Davydovaitė",
        subtitle: "Kauno r. Domeikavos gimnazija",
        image: "/assets/homepage/slider/Judita.jpeg",
        text: `
        „Šešėliavimo programa tikrai praplėtė akiratį, padėjo suprasti
        į ką reikėtų atsižvelgti renkantis profesiją. Man tai parodė, jog susidomėjimas nėra rodiklis,
        kad tau ta profesija patiks ir patenkins tavo poreikius.“`,
      },
      {
        title: "Patricija Žebuolytė",
        subtitle: "KTU inžinerijos licėjus",
        image: "/assets/homepage/slider/Patricija.jpeg",
        text: `„Tikrai kartočiau ir rekomenduočiau!“`,
      },
      {
        title: "Laurynas Greblikas",
        subtitle: "Kauno r. Garliavos Juozo Lukšos gimnazija",
        image: "/assets/homepage/slider/laurynas.jpg",
        text: `„Pasirinkęs šešėliavimo vizitą turėjau unikalią galimybę susipažinti su sraigtasparnių inžinerija, pačiu
      pilotavimu, net teko sėdėti virtualioje sraigtasparnio kabinoje. Susipažinau su instruktoriais, turinčiais
      įdomios darbo patirties. Šiltas priėmimas, įtraukianti veikla, įdomios patirtys bei galimybė susipažinti iš
      arčiau su aviacija – visa tai man suteikė „Šešėliavimo“ komanda.“`,
      },
      {
        title: "Kamilė Karengaitė",
        subtitle: "Kauno r. Garliavos Juozo Lukšos gimnazija",
        image: "/assets/homepage/slider/kamile.jpg",
        text: `
      „Išgirdau daug naudingos informacijos, kuri leido susipažinti su įmonių veikla. Lankiausi įmonėje
      „Scandagra“, kuri labai šiltai priėmė, pavaišino ir dar įteikė dovanų. Tikrai likau nustebusi tokiu
      svetingumu! Taip pat lankiausi įmonėje „Adeoweb“ , kuri irgi neapsiėjo be šilto priėmimo. Ši įmonė man leido
      suprasti, jog komunikacija ne vien tik burnos aušinimas!“`,
      },
    ],
  },
  {
    label: "Mokytojų",
    name: "teacher",
    items: [
      {
        title: "Lijana Pocienė",
        subtitle: "Vilniaus Simono Daukanto gimnazija, Vilniaus šv. Kristoforo gimnazija",
        image: "/assets/homepage/slider/Lijana.jpeg",
        text: `
        „Džiaugiamės šia alternatyva! Mokiniams suteikiate galimybę išsamiau, plačiau,
        praktiškiau atrasti ir pažinti veiklas. Susitikimai buvo labai įdomūs ir naudingi.
        Nuoširdžiai dėkojame ir tikimės tolimesnio bendradarbiavimo.“`,
      },
      {
        title: "Nijolė Šerpytenė",
        subtitle: "Vilniaus r. Pagirių gimnazija",
        image: "/assets/homepage/slider/Nijole.jpeg",
        text: `
        „Tik geriausi atsiliepimai, nuostabi galimybė mokiniams tiek individualiai,
        tiek ekskursijų forma šešėliauti. Absoliučiai visi mokiniai liko labai patenkinti,
        dalis šešėliavo net kelis kartus, taip susidomėjo, kad mielu noru būtų šešėliavę ir daugiau.
        Mokiniai net nenumanė, kad toks dalykas kaip šešėliavimas yra įmanomas, džiaugėsi savo patirtimi.
        Aš, kaip mokytoja, esu labai laiminga, kad visų mūsų dėka mokiniai turėjo šią galimybę.
        Ačiū jums, ačiū organizacijoms.“`,
      },
      {
        title: "Odeta Belazarė",
        subtitle: "Kauno r. Domeikavos gimnazija",
        image: "/assets/homepage/slider/Odeta.jpeg",
        text: `
        „LJA Šešėliavime dalyvavome pirmą kartą. Prisipažįstame, į programą žiūrėjome be didelio entuziazmo,
        todėl, kad vyksta daugybė renginių mokiniams, kuriuos sunku atsirinkti dėl savo kokybės
        ir neaiškaus aktualumo mokiniams, tačiau LJA Šešėliavimas buvo kažkas „VAU“ ir mano, ir mokinių nuomone.
        Mokinių nuomone, tai viena geriausių patirčių mokykloje kokią jie kada nors turėjo.
        Manome, kad tokioje programoje turėtų turėti galimybę dalyvauti visi Lietuvos moksleiviai,
        nes tai yra tikrasis kokybiškas profesinis veiklinimas mokiniams!“`,
      },
      {
        title: "Gintarė Orintienė",
        subtitle: "Kauno r. Garliavos Juozo Lukšos gimnazija",
        image: "/assets/homepage/slider/gintare_orintiene.jpeg",
        text: `„JA šešėliavimas mokiniams suteikė savęs pažinimo laisvę. Šešėliavę mokiniai grįžo su geriausiais įspūdžiais,
        su iškeltais sau tikslais kitiems mokslo metams ir begaliniu noru šešėliauti dar ir dar kartą.
        Mokinių motyvacija ir iniciatyvumas šešėliauti turėtų būti didesni, nes tai - būdas patiems pasitikrinti
        ar teisingus karjeros tikslus yra išsikėlę ir sumažinti abejones prieš pasirenkant studijas.
        Šio projekto ir kiekvienos įmonės dėka jauniems žmonėms buvo suteikta galimybė tobulėti ir pažinti profesijų pasaulį.“`,
      },
      {
        title: "Kolomba Bulotienė",
        subtitle: "Kauno r. Kulautuvos pagrindinė mokykla, Kauno r. Čekiškės Prano Dovydaičio gimnazija",
        image: "/assets/homepage/slider/kolomba_bulotiene.jpeg",
        text: `„Šešėliavimas - tai unikali galimybė mokiniui pažinti save, ugdyti savarankiškumą, planuoti savo ateitį ir karjerą.
        Mokiniai išmoksta save pristatyti, susipažįsta su pasirinkta profesija ir ją ,,pasimatuoja“.
        Iš dalies tai padeda išvengti neteisingo profesijos pasirinkimo. Mokiniai noriai renkasi šešėliuoti, nes tai nauja ir įdomu.“`,
      },
    ],
  },
  {
    label: "Organizacijų",
    name: "business",
    items: [
      {
        title: "SEB grupės paslaugų centras",
        subtitle: "",
        image: "/assets/homepage/slider/seb.png",
        text: `„Patirtis tikrai naudinga, smagu sutikti motyvuotus moksleivius. Džiaugiamės galėdami prisidėti prie žinių dalinimosi tarp verslo ir moksleivių, matome tame didelę prasmę.“`,
      },
      {
        title: "DeepDesign",
        subtitle: "",
        image: "/assets/homepage/slider/deepdesign.png",
        text: `„Man, kaip žmogui, ši patirtis buvo naudinga tiesiog žmogiškai - buvo gera žinoti, kad galbūt kažkam padėjau apsispręsti rinktis mano profesiją. Gera skirti savo laiko geriems darbams!“`,
      },
      {
        title: "Europos Komisijos atstovybė Lietuvoje",
        subtitle: "",
        image: "/assets/homepage/slider/eukomisija.jpeg",
        text: `„Patirtis naudinga, nes priverčia pasižiūrėti į savo darbą iš šalies, pagalvoti kaip jį įdomiai pristatyti, taip pat tai padeda užmegzti ryšius su tikrai motyvuotu jaunimu.“`,
      },
      {
        title: "UAB Adeo Web",
        subtitle: "",
        image: "/assets/homepage/slider/adeoweb.png",
        text: `„Programa leido į savo organizacijos ir veiklos specifiką pažiūrėti išorinio žmogaus akimis.“`,
      },
      {
        title: "UAB Telesoftas",
        subtitle: "",
        image: "/assets/homepage/slider/telesoftas.png",
        text: `„Gera prisidėti prie šviesios ir žingeidžios kartos augimo!“`,
      },
    ],
  },
];
