import React from "react";
import { Grid } from "semantic-ui-react";
import AppForm from "../../../components/form/Form";
import SchoolRegistrationActions from "../SchoolRegistrationActions";
import { useAppForm } from "../../../components/form/useForm";
import { SchoolRegistrationContactStepFormModel, SchoolRegistrationModel } from "../types";
import useSchools from "../../../app/hooks/useSchools";

interface Props {
  model: Partial<SchoolRegistrationModel>;
  onSubmit: (m: SchoolRegistrationContactStepFormModel) => any;
}

const SchoolRegistrationContactStep = ({ model, onSubmit }: Props) => {
  const formHook = useAppForm({ defaultValues: { ...defaultValues, ...model } });

  const { schoolOptions, isLoading: isLoadingSchools } = useSchools(true);

  return (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="schoolId" label="auth.steps.school.select_school">
                <AppForm.Select
                  search
                  name="schoolId"
                  options={schoolOptions}
                  rules={{ required: true }}
                  isLoading={isLoadingSchools}
                  disabled={isLoadingSchools}
                  disableAutoSelect
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.fullName" label="schools.form.contact_info.coordinator_fullname">
                <AppForm.Input name="contactInfo.fullName" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="contactInfo.phoneNumber" label="common.phone">
                <AppForm.Phone name="contactInfo.phoneNumber" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <AppForm.Group name="contactInfo.email" label="schools.form.contact_info.email">
                <AppForm.Input name="contactInfo.email" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column>
              <AppForm.Group name="contactInfo.jobTitle" label="my_profile.responsible_person.jobTitle">
                <AppForm.Input name="contactInfo.jobTitle" rules={{ required: true }} />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>
      <SchoolRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: SchoolRegistrationContactStepFormModel = {
  id: "",
  name: "",
  contactInfo: {
    fullName: "",
    jobTitle: "",
    phoneNumber: "",
    email: "",
  },
};

export default SchoolRegistrationContactStep;
