import React from "react";
import { TextArea, TextAreaProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

type Props = TextAreaProps;

const AppTextArea: React.FC<PropsInjectedByController & Props> = ({
  onChange,
  placeholder = "common.placeholder",
  isDisabled,
  isValid,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TextArea
      disabled={isDisabled}
      {...props}
      placeholder={placeholder && t(placeholder)}
      onChange={(_, data) => onChange(data.value)}
    />
  );
};

const AppTextAreaController = withController(AppTextArea);

export { AppTextAreaController };
export default AppTextArea;
