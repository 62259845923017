import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import PageTitle from "../../components/page-title/PageTitle";
import StudentPendingAgreementList from "./StudentPendingAgreementList";

const StudentAgreementPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <PageTitle title={t("studentAgreements.page_title")} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StudentPendingAgreementList />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default StudentAgreementPage;
