import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { PropsInjectedByController } from "./types";
import { withController } from "./withController";

type Props = Omit<CheckboxProps, "value">;

const AppCheckbox: React.FC<PropsInjectedByController<boolean> & Props> = ({
  onChange,
  value,
  label,
  isDisabled,
  isValid,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      disabled={isDisabled}
      {...props}
      label={t(label)}
      checked={value}
      onChange={(_, data) => onChange(!!data.checked)}
    />
  );
};

const AppCheckboxController = withController(AppCheckbox);

export { AppCheckboxController };
export default AppCheckbox;
