import React from "react";
import AppForm from "../../../components/form/Form";

const RejectCompanyForm = () => {
  return (
    <>
      <AppForm.Group name="reason" label="companies.form.reject.reason">
        <AppForm.TextArea name="reason" rules={{ required: true }} />
      </AppForm.Group>
    </>
  );
};

export default RejectCompanyForm;
